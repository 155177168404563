import React, { useState, useCallback, useEffect } from "react";
import { Form, Button } from "reactstrap";
import { useForm } from "react-hook-form";
import { api } from "../../../../../../network";
import { headers, notifyError, notifyOk } from "../../../../../../utils/utils";
import { ToastContainer } from "react-toastify";

export const EditProviderForm = ({ id, setShowEdit, reload }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [data, setData] = useState({
    provider:{},
    products: [],
    reload: false,
    loadingprovider: true,
    loadingProducts: true,
  });

  const [state, setState] = useState({
    isLoading: false,
    mainError: false,
  });

  const getProduct = useCallback(async () => {
    try {
      setData((old) => ({ ...old, loadingProducts: true }));
      const response = await api.get(`/v1/productos/${id}`, {
        headers: headers(), // ask only for active personas
      });

      if (response && response.status !== 200) {
        notifyError("ERROR");
        return;
      }

      setData((old) => ({
        ...old,
        products: response.data.data,
        loadingProducts: false,
      }));
    } catch (e) {
      notifyError("ERROR");
    }
  }, [id]);

  const getRegisterProvider = useCallback(async () => {
    try {
      setData((old) => ({ ...old, loadingprovider: true }));
      const response = await api.get(`/v1/proveedores/${id}`, {
        headers: headers(), // ask only for active personas
      });

      if (response && response.status !== 200) {
        notifyError("ERROR");
        return;
      }

      setData((old) => ({
        ...old,
        loadingprovider: false,
        provider: response.data.data,
      }));
    } catch (e) {
      notifyError("ERROR");
    }
  }, [id]);

  const onSubmit = async (data) => {
    try {
      setState((old) => ({ ...old, isLoading: true }));
      await api.put(`/v1/proveedores/${id}`, data, {
        headers: headers(),
      });
      notifyOk("Proveedor Editado con Éxito", { autoClose: 3000 });
      reload();
      setState((old) => ({ ...old, isLoading: false }));
      setShowEdit(false);
    } catch (error) {
      notifyError("ERROR");
    }
  };

  useEffect(() => {
    getProduct();
    getRegisterProvider();
  }, [getProduct, getRegisterProvider]);

  setValue("nombre_proveedor", data.provider.nombre_proveedor ? data.provider.nombre_proveedor : "");
  setValue("contacto_proveedor", data.provider.contacto_proveedor ? data.provider.contacto_proveedor : "");
  setValue("pais_proveedor", data.provider.pais_proveedor ? data.provider.pais_proveedor : "");
  // setValue("producto_id", data.provider.nombre_producto ? data.provider.nombre_producto : "");
  
      
  return (
    <>
      <ToastContainer />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group mb-3">
          <label>Nombre Proveedor</label>
          <input
            className="form-control"
            placeholder="Proveedor"
            {...register("nombre_proveedor", {
              required: { value: true, message: "El proveedor es requerido" },
              value: data.provider.nombre_proveedor,
            })}
          >
          </input>
          <small className="form-text text-danger">
            {errors.nombre_proveedor && errors.nombre_proveedor.message}
          </small>
        </div>
        <div className="form-group mb-3">
          <label>Contacto Proveedor</label>
          <input
            className="form-control"
            placeholder="Contacto"
            {...register("contacto_proveedor", {
              required: { value: true, message: "El contacto es requerido" },
            })}
          >
          </input>
          <small className="form-text text-danger">
            {errors.contacto_proveedor && errors.contacto_proveedor.message}
          </small>
        </div>
        <div className="form-group mb-3">
          <label>País Proveedor</label>
          <input
            className="form-control"
            placeholder="País"
            {...register("pais_proveedor", {
              required: { value: true, message: "El País es requerido" },
            })}
          >
          </input>
          <small className="form-text text-danger">
            {errors.pais_proveedor && errors.pais_proveedor.message}
          </small>
        </div>
        {/* <div className="form-group mb-3">
          <label>Producto</label>
          <input
            className="form-control"
            placeholder="Producto"
            {...register("producto_id", {
              required: { value: true, message: "El Producto es requerido" },
              value: data.provider.nombre_producto,
            })}
            defaultValue={data.provider.nombre_producto}
            disabled
          >
          </input>
          <small className="form-text text-danger">
            {errors.producto_id && errors.producto_id.message}
          </small>
        </div> */}
        {state.isLoading ? null : <Button color="success" type="submit" >Guardar</Button>}
      </Form>
    </>
  );
};
