import { useEffect, useState } from "react";
import {
  s_getHomologacion,
  s_updateHomologacion,
} from "../../../../services/homologacionCtl";
import { notifyError } from "../../../../utils/utils";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import ClearFilter from "../../../../assets/ic_filter.svg";
import { useForm } from "react-hook-form";
import DetalleHomologacion from "./components/detalleHomologacion";

import "./index.css";
import { FilePlus, FileText, XCircle, Check } from "react-feather";
import EditStatusObser from "./components/editStatusObser";

function Homologacion({ pageName }) {
  const { register, handleSubmit, reset } = useForm();

  const [showDetalle, setShowDetalle] = useState(false);
  const [selectHomologacion, setSelectHomologacion] = useState(null);
  const [editStatus, setEditStatus] = useState(false);
  const toggleDetails = (eventoRow) => {
    setEditStatus(!editStatus);
    setSelectHomologacion(eventoRow);
  };

  const [data, setData] = useState({
    homologaciones: [],
    reload: false,
    loading: true,
    status: "SUCCESS",
  });

  const [, setFilter] = useState({
    status: "",
    identificacion: "",
  });

  const _getHomologacion = async (data) => {
    try {
      setData((old) => ({ ...old, status: "PROCESSING" }));
      const response = await s_getHomologacion({ ...data });
      setData((old) => ({
        ...old,
        homologaciones: response,
        status: "SUCCESS",
      }));
    } catch (error) {
      setData((old) => ({ ...old, status: "FAILED" }));
      if (error.response.status >= 500) {
        notifyError("ERROR");
        return;
      }
      notifyError(error.response.data.message, { autoClose: 3000 });
    }
  };

  const _updateHomologacion = async (data, id) => {
    try {
      await s_updateHomologacion(data, id);
      reload();
    } catch (error) {
      setData((old) => ({ ...old, status: "FAILED" }));
      if (error.response.status >= 500) {
        notifyError("ERROR");
        return;
      }
      notifyError(error.response.data.message, { autoClose: 3000 });
    }
  };

  useEffect(() => {
    _getHomologacion();
  }, [data.reload]);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    setFilter(data);
    const dataTemp = { ...data, page: 0, size: 15 };
    _getHomologacion(dataTemp);
  };

  const clearFilter = () => {
    reset();
    setFilter({
      status: "",
      identificacion: "",
    });
    setData((old) => ({
      ...old,
      qualifications: [],
      status: "SUCCESS",
    }));
    _getHomologacion();
  };

  const reload = () =>
    setData((old) => ({
      homologaciones: [],
      reload: !old.reload,
      loading: !old.loading,
      status: "PROCESSING",
    }));

  return (
    <div>
      {showDetalle ? (
        <DetalleHomologacion
          selectHomologacion={selectHomologacion}
          onBackTo={() => setShowDetalle(false)}
        />
      ) : (
        <>
          <Offcanvas
            direction="end"
            fade={false}
            toggle={toggleDetails}
            isOpen={editStatus}
          >
            <OffcanvasHeader toggle={toggleDetails}>
              Editar observación y estado
            </OffcanvasHeader>
            <OffcanvasBody>
              {editStatus && (
                <EditStatusObser
                  homologacion={selectHomologacion}
                  onClose={() => {
                    toggleDetails(null);
                    reload();
                  }}
                />
              )}
            </OffcanvasBody>
          </Offcanvas>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3>{pageName}</h3>
          </div>
          <Card className="my-3">
            <CardHeader style={{ backgroundColor: "var(--yellow)" }}>
              Filtro
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col md={3}>
                    <div className="form-group mb-3">
                      <label>Cédula:</label>
                      <input
                        {...register("identificacion")}
                        defaultValue={""}
                        className="form-control"
                        placeholder="Ingrese numero de cédula o RUC"
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="form-group mb-3">
                      <label>Estado:</label>
                      <select
                        {...register("status")}
                        defaultValue={""}
                        className="form-control chosen-select"
                      >
                        <option value="">Seleccione un estado</option>
                        <option value="PENDIENTE">Pendiente</option>
                        <option value="EN_PROCESO">En progreso</option>
                        <option value="FINALIZADA">Finalizada</option>
                        <option value="RECHAZADO">Rechazado</option>
                        <option value="EDITAR">Editar</option>
                      </select>
                    </div>
                  </Col>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div></div>
                    <div>
                      <Button
                        type="button"
                        color="light"
                        className="mx-2"
                        onClick={clearFilter}
                      >
                        <img
                          src={ClearFilter}
                          width="20px"
                          style={{ marginRight: 10 }}
                          alt=""
                        />
                        Limpiar filtro
                      </Button>
                      <Button color="success">Buscar</Button>
                    </div>
                  </div>
                </Row>
              </Form>
            </CardBody>
          </Card>
          {data.status === "SUCCESS" && (
            <>
              {data.homologaciones.length !== 0 ? (
                <div>
                  <Table>
                    <thead>
                      <tr>
                        <th>Cédula</th>
                        <th>Nombres</th>
                        <th>Placa</th>
                        <th>Observación</th>
                        <th>Estado</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.homologaciones.map((homologacion) => (
                        <tr key={homologacion.id_homologacion}>
                          <td>{homologacion.identificacion}</td>
                          <td>
                            {homologacion.nombres} {homologacion.apellidos}
                          </td>
                          <td>{homologacion.placa}</td>
                          <td>{homologacion.observacion}</td>
                          <td>
                            {homologacion.status === "PENDIENTE" && (
                              <Badge color="warning">Pendiente</Badge>
                            )}
                            {homologacion.status === "EN_PROCESO" && (
                              <Badge color="success">En proceso</Badge>
                            )}
                            {homologacion.status === "FINALIZADA" && (
                              <Badge color="success">Finalizado</Badge>
                            )}
                            {homologacion.status === "RECHAZADO" && (
                              <Badge color="danger">Rechazado</Badge>
                            )}
                            {homologacion.status === "EDITAR" && (
                              <Badge color="secondary">Editar</Badge>
                            )}
                          </td>
                          <td>
                            <FileText
                              style={{
                                width: 20,
                                height: 20,
                                cursor: "pointer",
                                marginRight: 5,
                              }}
                              id={`edit-${homologacion.id_homologacion}`}
                              onClick={() => {
                                setSelectHomologacion(homologacion);
                                setShowDetalle(true);
                              }}
                            />
                            <UncontrolledTooltip
                              placement="top"
                              target={`edit-${homologacion.id_homologacion}`}
                            >
                              Ver detalle
                            </UncontrolledTooltip>
                            {homologacion.status !== "FINALIZADA" &&
                              homologacion.status !== "RECHAZADO" && (
                                <>
                                  <FilePlus
                                    style={{
                                      width: 20,
                                      height: 20,
                                      cursor: "pointer",
                                      marginRight: 5,
                                    }}
                                    id={`observacion-${homologacion.id_homologacion}`}
                                    onClick={() => toggleDetails(homologacion)}
                                  />
                                  <UncontrolledTooltip
                                    placement="top"
                                    target={`observacion-${homologacion.id_homologacion}`}
                                  >
                                    Editar observacion y estado
                                  </UncontrolledTooltip>
                                  <XCircle
                                    style={{
                                      width: 20,
                                      height: 20,
                                      cursor: "pointer",
                                      marginRight: 5,
                                    }}
                                    id={`rechazar-${homologacion.id_homologacion}`}
                                    onClick={() => {
                                      _updateHomologacion(
                                        { status: "RECHAZADO" },
                                        homologacion.id_homologacion
                                      );
                                    }}
                                  />
                                  <UncontrolledTooltip
                                    placement="top"
                                    target={`rechazar-${homologacion.id_homologacion}`}
                                  >
                                    Rechazar
                                  </UncontrolledTooltip>
                                  <Check
                                    style={{
                                      width: 20,
                                      height: 20,
                                      cursor: "pointer",
                                      marginRight: 5,
                                    }}
                                    id={`aprovada-${homologacion.id_homologacion}`}
                                    onClick={() => {
                                      _updateHomologacion(
                                        { status: "FINALIZADA" },
                                        homologacion.id_homologacion
                                      );
                                    }}
                                  />
                                  <UncontrolledTooltip
                                    placement="top"
                                    target={`aprovada-${homologacion.id_homologacion}`}
                                  >
                                    Aprobar
                                  </UncontrolledTooltip>
                                </>
                              )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <div className="mt-5">
                  <h1 className="alert-title">
                    No hay homologaciones al momento
                  </h1>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}
export default Homologacion;
