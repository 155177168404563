import { Button, Form } from "reactstrap";
import TheLoader from "../../../../elements/theLoader";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { s_updateCategoria } from "../../../../../services/categoriaCtl";
import { notifyError, notifyOk } from "../../../../../utils/utils";

export default function UpdateCategory({
  getCategoria,
  setShowEdit,
  selectedCategory,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loader, setloadeder] = useState(false);

  const _onSubmit = async (data) => {
    setloadeder(true);
    const res = await s_updateCategoria(
      data,
      selectedCategory.categoria_estado_peaje_id
    );
    if (!res) {
      setloadeder(false);
      return notifyError("Error en el servidor, por favor intente nuevamente");
    }
    setloadeder(false);
    getCategoria();
    setShowEdit(false);
    return notifyOk("Categoría editada de manera satisfactoria");
  };
  return (
    <div>
      {loader ? <TheLoader /> : null}
      <Form onSubmit={handleSubmit(_onSubmit)}>
        <div className="form-group mb-3">
          <label>Nombre de la categoría</label>
          <input
            type="text"
            className="form-control"
            placeholder="Nombre de la categoría"
            {...register("descripcion", {
              required: {
                value: true,
                message: "El nombre de la categoría es obligatorio",
              },
            })}
            defaultValue={selectedCategory.descripcion}
          />
          <small className="form-text text-danger">
            {errors.name && errors.name.message}
          </small>
        </div>
        <Button className="btn-tagexpress">Editar</Button>
      </Form>
    </div>
  );
}
