import { useEffect, useMemo, useRef, useState } from "react";
import {
  MapContainer,
  Marker,
  TileLayer,
  useMap,
  useMapEvents,
} from "react-leaflet";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import L from "leaflet";
import "leaflet-geosearch/dist/geosearch.css";

import IconMarker from "../../../assets/venue_location_icon.svg";

import "leaflet/dist/leaflet.css";

const defaulCenter = {
  lat: -0.219313,
  lng: -78.520644,
};

export default function MapDraggableMarker({ statePosition }) {
  return (
    <MapContainer
      style={{ width: "100%", height: 600 }}
      center={defaulCenter}
      zoom={15}
      scrollWheelZoom={false}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <DraggableMarker center={defaulCenter} statePosition={statePosition} />
      {/* <LeafletgeoSearch /> */}
    </MapContainer>
  );
}

function DraggableMarker({ center, statePosition }) {
  const [, setOnPosition] = statePosition;
  const [position, setPosition] = useState(center);
  const markerRef = useRef(null);
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setPosition(marker.getLatLng());
          setOnPosition(marker.getLatLng());
        }
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const mapHook = useMap();

  const map = useMapEvents({
    locationfound(e) {
      setPosition(e.latlng);
      setOnPosition(e.latlng);
      map.flyTo(e.latlng, map.getZoom());
    },
  });

  useEffect(() => {
    if (!map) return;
    map.locate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const provider = new OpenStreetMapProvider();

    const searchControl = new GeoSearchControl({
      provider,
      marker: {
        icon: VenueLocationIcon,
      },
      showMarker: false,
      showPopup: false,
      updateMap: true,
    });

    mapHook.addControl(searchControl);

    return () => mapHook.removeControl(searchControl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Marker
      draggable
      eventHandlers={eventHandlers}
      position={position}
      ref={markerRef}
      icon={VenueLocationIcon}
    ></Marker>
  );
}

const VenueLocationIcon = L.icon({
  iconUrl: IconMarker,
  iconRetinaUrl: IconMarker,
  iconAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: [40, 35],
  className: "leaflet-venue-icon",
});
