import React, { useState, useRef } from "react";
import { Form, Button } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { ToastContainer } from "react-toastify";
import { s_setDailySalaTagState } from "../../../../../services/dailySalesCtl";
import { notifyError, notifyOk } from "../../../../../utils/utils";
import '../dailySales.css';


export const NewTagsForm = ({ reload, setShowModalTags, store }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    control,
  } = useForm();

  const [state, setState] = useState({
    isLoading: false,
    mainError: false,
  });

  const _onSubmit = async (data) => {
    try {
      setState((old) => ({ ...old, isLoading: true }));
      data.store = store.punto_de_venta_id
      const resp = await s_setDailySalaTagState(data);
      notifyOk(resp, { autoClose: 3000 });
      reload();
      setShowModalTags(false);
      setState((old) => ({ ...old, isLoading: false }));
    } catch (error) {
      setState((old) => ({ ...old, isLoading: false }));
      notifyError(
        "Ocurrió un error al guardar el tags, por favor inténtelo más tarde."
      );
    }
  };

  const selectTipoVentaRef = useRef(undefined);

  const _onChangeCantidad = (e) => {
    const cantidad = e.target.value;
    const tipoVenta = selectTipoVentaRef.current;

    if (tipoVenta === undefined) return;
    if (cantidad === undefined) return;

    let total = 0;
    if (tipoVenta === "Cliente") {
      total = cantidad * 7;
    } else if (tipoVenta === "Revendedor") {
      total = cantidad * 4.75;
    } else if (tipoVenta === "Cruz azul / Phamarcy") {
      total = cantidad * 5.68;
    } else if (tipoVenta === "Tía") {
      total = cantidad * 8.12;
    }
    setValue("total", total.toFixed(2), { shouldValidate: true });
  };

  const _onChangeTipoVenta = (e) => {
    const cantidad = getValues("cantidad");
    const tipoVenta = e.target.value;
    selectTipoVentaRef.current = e.target.value;
    if (cantidad === undefined) return;
    if (tipoVenta === undefined) return;
    console.log(tipoVenta);
    let total = 0;
    if (tipoVenta === "Cliente") {
      total = cantidad * 7;
    } else if (tipoVenta === "Revendedor") {
      total = cantidad * 4.75;
    } else if (tipoVenta === "Cruz azul / Phamarcy") {
      const subTotal = cantidad * 5.68;
      const iva = subTotal * 0.12;
      total = subTotal + iva;
    } else if (tipoVenta === "Tía") {
      total = cantidad * 8.12;
    }
    setValue("total", total.toFixed(2), { shouldValidate: true });
  };

  return (
    <div>
      <ToastContainer />
      <Form onSubmit={handleSubmit(_onSubmit)}>
        <div className="form-group mb-3">
          <label>Cantidad</label>
          <input
            type="number"
            className="form-control"
            placeholder="Cantidad"
            {...register("cantidad", {
              required: { value: true, message: "La cantidad es requerido" },
              onChange: _onChangeCantidad,
            })}
          />
          <small className="form-text text-danger">
            {errors.cantidad && errors.cantidad.message}
          </small>
        </div>
        <div className="form-group mb-3">
          <label>Tipo de venta</label>
          <Controller
            control={control}
            name="tipo"
            rules={{
              required: {
                value: true,
                message: "El tipo de venta es requerido",
              },
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <select
                className="form-control"
                onChange={(e) => {
                  _onChangeTipoVenta(e);
                  onChange(e);
                }}
                onBlur={onBlur}
                selected={value}
              >
                <option defaultValue={null}>Seleccione un tipo</option>
                <option defaultValue="Cliente">Cliente</option>
                <option defaultValue="Revendedor">Revendedor</option>
                <option defaultValue="Cruz azul / Phamarcy">
                  Cruz azul / Phamarcy
                </option>
                <option defaultValue="Tía">Tía</option>
              </select>
            )}
          />

          <small className="form-text text-danger">
            {errors.tipo && errors.tipo.message}
          </small>
        </div>
        <div className="form-group mb-3">
          <label>Tipo de pago</label>
          <Controller
            control={control}
            name="tipo_pago"
            rules={{
              required: {
                value: true,
                message: "El tipo de pago es requerido",
              },
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <select
                className="form-control"
                onChange={onChange}
                onBlur={onBlur}
                selected={value}
              >
                <option defaultValue="">Seleccione un tipo de pago</option>
                <option value="EFECTIVO">Efectivo</option>
                <option value="DEPOSITO">Deposito</option>
                <option value="TRANSFERENCIA_CALPRANDINA">
                  Transferencia a cuenta CALPRANDINA
                </option>
              </select>
            )}
          />
          <small className="form-text text-danger">
            {errors.tipo_pago && errors.tipo_pago.message}
          </small>
        </div>
        <div className="form-group mb-3">
          <label>Retención ($)</label>
          <input
            type="number"
            className="form-control"
            placeholder="Retención"
            {...register("retencion")}
            step="0.01"
            min="0.00"
            max="1000.00"
          />
        </div>
        <div className="form-group mb-3">
          <label>Total</label>
          <input
            type="text"
            className="form-control"
            placeholder="Total"
            {...register("total", {
              required: { value: true, message: "El total es requerido" },
            })}
            disabled={true}
          />
          <small className="form-text text-danger">
            {errors.total && errors.total.message}
          </small>
        </div>
        {state.isLoading ? null : <Button type="submit" className="btn-save-sales">Guardar</Button>}
      </Form>
    </div>
  );
};
