import { api } from "../network";
import { headers, queryString } from "../utils/utils";

const typeAssistanceCtlErrors = {
  ERROR_OBTENCION_DATOS: "ERROR_OBTENCION_DATOS",
};

async function s_getTypeAssistance(params) {
  const { status, page, size } = params;
  const queryObj = { page };

  if (status) {
    queryObj.status = status;
  }

  if (size) {
    queryObj.size = size;
  }

  const query = queryString(queryObj);

  const response = await api.get(`/v1/tipo-asist?${query}`, {
    headers: headers(),
  });
  if (response && response.status !== 200) {
    throw typeAssistanceCtlErrors.ERROR_OBTENCION_DATOS;
  }

  return response.data.response;
}

async function s_postTypeAssistance(data) {
  const response = await api.post(`/v1/tipo-asist`, data, {
    headers: headers(),
  });

  if (response && response.status !== 200) {
    throw typeAssistanceCtlErrors.ERROR_OBTENCION_DATOS;
  }

  return response.data;
}

async function s_updateTypeAssistance(data, id) {
  const response = await api.put(`/v1/tipo-asist/${id}`, data, {
    headers: headers(),
  });

  if (response && response.status !== 200) {
    throw typeAssistanceCtlErrors.ERROR_OBTENCION_DATOS;
  }

  return response.data;
}

async function s_deleteTypeAssistance(id) {
  const response = await api.delete(`/v1/tipo-asist/${id}`, {
    headers: headers(),
  });

  if (response && response.status !== 200) {
    throw typeAssistanceCtlErrors.ERROR_OBTENCION_DATOS;
  }

  return response.data;
}

export {
  s_getTypeAssistance,
  s_postTypeAssistance,
  s_updateTypeAssistance,
  s_deleteTypeAssistance,
};
