import React, { useState, useCallback, useEffect } from "react";
import { Form, Button } from "reactstrap";
import { useForm } from "react-hook-form";
import { api } from "../../../../../network";
import { headers, notifyError, notifyOk } from "../../../../../utils/utils";
import { ToastContainer } from "react-toastify";

export const EditRegisterDeliveryForm = ({ item, setShowEdit, reload }) => {
  console.log('data para actualizar ', item);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [data, setData] = useState({
    registers: {},
    persons: [],
    products: [],
    reload: false,
    loadingRegisters: true,
    loadindPersons: true,
    loadingProducts: true,
  });

  const [state, setState] = useState({
    isLoading: false,
    mainError: false,
  });

  const getPersons = useCallback(async () => {
    try {
      setData((old) => ({ ...old, loadindPersons: true }));
      const response = await api.get("/v1/personas", {
        headers: headers(),
        params: { estado: "A" },
      });

      if (response && response.status !== 200) {
        notifyError("ERROR");
        return;
      }

      setData((old) => ({
        ...old,
        persons: response.data.data,
        loadindPersons: false,
      }));
    } catch (e) {
      notifyError("ERROR");
    }
  }, []);

  const getProduct = useCallback(async () => {
    try {
      setData((old) => ({ ...old, loadingProducts: true }));
      const response = await api.get("/v1/productos", {
        headers: headers(),
      });

      if (response && response.status !== 200) {
        notifyError("ERROR");
        return;
      }

      setData((old) => ({
        ...old,
        products: response.data.data,
        loadingProducts: false,
      }));
    } catch (e) {
      notifyError("ERROR");
    }
  }, []);

  const getRegisterDelivery = useCallback(async () => {
    try {
      setData((old) => ({ ...old, loadingRegisters: true }));
      const response = await api.get(`/v1/registerDelivery/${item.id_registro}`, {
        headers: headers(), // ask only for active personas
      });

      if (response && response.status !== 200) {
        notifyError("ERROR");
        return;
      }

      setData((old) => ({
        ...old,
        loadingRegisters: false,
        registers: response.data.data,
      }));
    } catch (e) {
      notifyError("ERROR");
    }
  }, [item]);

  const onSubmit = async (data) => {
    //ad the store to the object
    data.puntoVenta = item.nombre
    try {
      setState((old) => ({ ...old, isLoading: true }));
      const resp = await api.put(`/v1/registerDelivery/update/${item.id_registro}`, data, {
        headers: headers(),
      });
      notifyOk(resp.data.message, { autoClose: 3000 });
      reload();
      setState((old) => ({ ...old, isLoading: false }));
      setShowEdit(false);
    } catch (error) {
      notifyError(
        "Ocurrió un error al actualizar el registro, por favor inténtelo más tarde."
      );
    }
  };

  useEffect(() => {
    getRegisterDelivery();
    // getPersons();
    getProduct();
  }, [getRegisterDelivery, getProduct, getPersons]);

  setValue("producto", data.registers.producto ? data.registers.producto : "");
  setValue("cantidad", data.registers.cantidad ? data.registers.cantidad : "");
  setValue(
    "descripcion",
    data.registers.descripcion ? data.registers.descripcion : ""
  );
  setValue(
    "usuario_destino",
    data.registers.usuario_destino ? data.registers.usuario_destino : ""
  );

  return (
    <>
      <ToastContainer />
      {data.loadingRegisters && data.loadindPersons && data.loadingProducts ? (
        <h1>Loading</h1>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group mb-3">
            <label>Producto</label>
            <select
              className="form-control"
              {...register("producto", {
                required: { value: true, message: "El producto es requerido" },
                value: data.registers.producto,
              })}
              value={data.registers.producto}
            >
              <option value='' disabled>
                Seleccione un producto
              </option>
              {data.products.map((product) => (
                <option
                  value={`${product.id_producto}`}
                  key={product.id_producto}
                >
                  {product.nombre_producto}
                </option>
              ))}
            </select>
            <small className="form-text text-danger">
              {errors.producto && errors.producto.message}
            </small>
          </div>
          <div className="form-group mb-3">
            <label>Cantidad</label>
            <input
              type="number"
              className="form-control"
              placeholder="Cantidad"
              {...register("cantidad", {
                required: { value: true, message: "La cantidad es requerido" },
              })}
              defaultValue={data.registers.cantidad}
            />
            <small className="form-text text-danger">
              {errors.cantidad && errors.cantidad.message}
            </small>
          </div>
          <div className="form-group mb-3">
            <label>Descripción</label>
            <textarea
              className="form-control"
              placeholder="Descripción"
              {...register("descripcion", {
                maxLength: { value: 100, message: "Maximo de letras 100" },
              })}
              rows="3"
              defaultValue={data.registers.descripcion}
            ></textarea>

            <small className="form-text text-danger">
              {errors.descripcion && errors.descripcion.message}
            </small>
          </div>
          {state.isLoading ? null : (
            <Button type="submit" className="btn-tagexpress">
              Guardar
            </Button>
          )}
        </Form>
      )}
    </>
  );
};
