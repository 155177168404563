import { headers, queryString } from "../utils/utils";
import { apiClientes } from "../network";

const compensationCtlErrors = {
  ERROR_OBTENCION_DATOS: "ERROR_OBTENCION_DATOS",
  ERROR_ACTUALIZANDO_COMPENSACION: "ERROR_ACTUALIZANDO_COMPENSACION",
};

async function s_getCompensations(params) {
  const { end_date, start_date, domain, external_entity } = params;
  const queryObj = {};

  if (end_date) {
    queryObj.end_date = end_date;
  }

  if (start_date) {
    queryObj.start_date = start_date;
  }

  if (domain) {
    queryObj.domain = domain;
  }

  if (external_entity) {
    queryObj.external_entity = external_entity;
  }

  const query = queryString(queryObj);

  const response = await apiClientes.get(`/compensacion?${query}`, {
    headers: headers(),
  });

  if (response && response.status !== 200) {
    throw compensationCtlErrors.ERROR_OBTENCION_DATOS;
  }

  return response.data;
}

async function s_getCompensationsEnte(params) {
  const { end_date, start_date, domain, external_entity } = params;
  const queryObj = {};

  if (end_date) {
    queryObj.end_date = end_date;
  }

  if (start_date) {
    queryObj.start_date = start_date;
  }

  if (domain) {
    queryObj.domain = domain;
  }

  if (external_entity) {
    queryObj.external_entity = external_entity;
  }

  const query = queryString(queryObj);

  const response = await apiClientes.get(`/compensacion-ente?${query}`, {
    headers: headers(),
  });

  if (response && response.status !== 200) {
    throw compensationCtlErrors.ERROR_OBTENCION_DATOS;
  }

  return response.data;
}

async function s_updataStateCompensations(data) {
  const response = await apiClientes.put(`/compensacion`, data, {
    headers: headers(),
  });

  if (response && response.status !== 200) {
    throw compensationCtlErrors.ERROR_ACTUALIZANDO_COMPENSACION;
  }
  return response.data.message;
}

async function s_updataStateCompensationsEnte(data) {
  const response = await apiClientes.put(`/compensacion-ente`, data, {
    headers: headers(),
  });

  if (response && response.status !== 200) {
    throw compensationCtlErrors.ERROR_ACTUALIZANDO_COMPENSACION;
  }
  return response.data.message;
}

async function s_postCompensationsEnte(data) {
  const response = await apiClientes.post(`/compensacion-ente`, data, {
    headers: headers(),
  });

  if (response && response.status !== 200) {
    throw compensationCtlErrors.ERROR_OBTENCION_DATOS;
  }

  return response.data;
}

export {
  s_getCompensations,
  s_updataStateCompensations,
  s_getCompensationsEnte,
  s_postCompensationsEnte,
  s_updataStateCompensationsEnte,
};
