import { headers } from "../utils/utils";
import { api } from "../network";

const storeCtlErrors = {
    ERROR_CREACION_PUNTO_VENTA: "ERROR EN PUNTO DE VENTA",
};

const s_geStores = async () => {
    try {
        const response = await api.get(`/v1/store`, {
            headers: headers(),
        });
        if (response && response.status !== 200) {
            throw storeCtlErrors.ERROR_CREACION_PUNTO_VENTA;
        }

        return response.data.data;
    } catch (e) {
        ;
        return false;
    }
}

const s_geStore = async (storeId) => {
    try {
        const response = await api.get(`/v1/store/${storeId}`, {
            headers: headers(),
        });
        if (response && response.status !== 200) {
            throw storeCtlErrors.ERROR_CREACION_PUNTO_VENTA;
        }

        return response.data.data;
    } catch (e) {
        return false;
    }
}

const s_getUserStoreTags = async () => {
    try {
        const response = await api.get(`/v1/store/user/stock`, {
            headers: headers(),
        });
        if (response && response.status !== 200) {
            throw storeCtlErrors.ERROR_CREACION_PUNTO_VENTA;
        }
        return response.data.data;
    } catch (e) {
        return false;
    }
}

const s_createStore = async (data) => {

    try {
        const response = await api.post(`/v1/store`, data, {
            headers: headers(),
        });
        if (response && response.status !== 200) {
            throw storeCtlErrors.ERROR_CREACION_PUNTO_VENTA;

        }
        return true;
    } catch (e) {
        return false
    }

}

const s_updateStore = async (data, storeId) => {
    console.log(data, storeId);
    try {
        const response = await api.put(`/v1/store/${storeId}`, data, {
            headers: headers(),
        });
        console.log('La response ', response);
        if (response && response.status !== 200) {
            console.log('ingrese aqui');
            throw storeCtlErrors.ERROR_CREACION_PUNTO_VENTA;
        }
        return true;
    } catch (e) {

        return false;
    }
}

const s_deleteStore = async (storeId) => {
    try {
        const response = await api.delete(`/v1/store/${storeId}`, {
            headers: headers(),
        });
        if (response && response.status !== 200) {
            throw storeCtlErrors.ERROR_CREACION_PUNTO_VENTA;
        }
        return true
    } catch (e) {
        return false;
    }

}

export {
    s_geStores,
    s_geStore,
    s_getUserStoreTags,
    s_createStore,
    s_updateStore,
    s_deleteStore
};
