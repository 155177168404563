import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  UncontrolledTooltip,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Table,
} from "reactstrap";
import ClearFilter from "../../../../assets/ic_filter.svg";
import {
  s_deleteTypeAssistance,
  s_getTypeAssistance,
} from "../../../../services/typeAssistanceCtl";
import { notifyError, notifyOk } from "../../../../utils/utils";
import { generateSequentialArray } from "../../mantenimientos";
import { Edit, PlusSquare, Trash } from "react-feather";
import CrearTypeAssistance from "./components/crearTypeAssistance";
import EditTypeAssistance from "./components/editTypeAssistance";
import { ToastContainer } from "react-toastify";
import ConfirmModal from "../../../../utils/components/ConfirmModal";

const TypeAssistance = ({ pageName }) => {
  const { register, handleSubmit, reset } = useForm();

  const [data, setData] = useState({
    typeAssistance: [],
    reload: false,
    loading: true,
    status: "SUCCESS",
  });
  const [filter, setFilter] = useState({
    status: "",
  });

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [tipo, setTipo] = useState();

  const [createTypeAssistance, setCreateTypeAssistance] = useState(false);
  const toggleCreateTypeAssistance = () => {
    setCreateTypeAssistance(!createTypeAssistance);
  };
  const [editTypeAssistance, setEditTypeAssistance] = useState(false);
  const toggleEditTypeAssistance = (type) => {
    setTipo(type);
    setEditTypeAssistance(!editTypeAssistance);
  };
  const [confirm, setConfirm] = useState(false);
  const toggleConfirm = () => setConfirm(!confirm);

  const _getTypeAssistance = async (data) => {
    try {
      setData((old) => ({ ...old, status: "PROCESSING" }));
      const response = await s_getTypeAssistance({
        ...data,
        size: 15,
      });
      setData((old) => ({
        ...old,
        typeAssistance: response.rows,
        status: "SUCCESS",
      }));
      setTotalPages(response.totalPages);
      setCurrentPage(response.currentPage);
    } catch (error) {
      console.log(error);
      setData((old) => ({ ...old, status: "FAILED" }));
      if (error.response.status >= 500) {
        notifyError("ERROR");
        return;
      }
      notifyError(error.response.data.message, { autoClose: 3000 });
    }
  };

  useEffect(() => {
    _getTypeAssistance({ page: 0 });
  }, [data.reload]);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    setFilter(data);
    const dataTemp = { ...data, page: 0, size: 15 };
    _getTypeAssistance(dataTemp);
  };

  const clearFilter = () => {
    reset();
    setFilter({
      status: "",
    });
    setData((old) => ({
      ...old,
      typeAssistance: [],
      status: "SUCCESS",
      reload: !old.reload,
    }));
  };

  const reload = () =>
    setData((old) => ({
      typeAssistance: [],
      reload: !old.reload,
      status: "PROCESSING",
    }));

  const _handleDeleteTipo = async () => {
    try {
      await s_deleteTypeAssistance(tipo.tipo_asistencia_id);
      notifyOk("Tipo de Asistencia desactivado de manera correcta", {
        autoClose: 3000,
      });
      setConfirm(false);
      reload();
    } catch (error) {
      if (error.response.status === 409) {
        notifyError(error.response.data.message);
        return;
      }
      notifyError(
        "Ocurrió un error al agregar el tipo, por favor inténtelo más tarde."
      );
    }
  };

  return (
    <div>
      <ToastContainer />
      <ConfirmModal
        toggle={toggleConfirm}
        isOpen={confirm}
        onClickYes={_handleDeleteTipo}
      />
      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggleCreateTypeAssistance}
        isOpen={createTypeAssistance}
      >
        <OffcanvasHeader toggle={toggleCreateTypeAssistance}>
          Nuevo tipo de asistencia
        </OffcanvasHeader>
        <OffcanvasBody>
          <CrearTypeAssistance
            onClose={() => {
              setCreateTypeAssistance(false);
              reload();
            }}
          />
        </OffcanvasBody>
      </Offcanvas>
      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggleEditTypeAssistance}
        isOpen={editTypeAssistance}
      >
        <OffcanvasHeader toggle={toggleEditTypeAssistance}>
          Editar tipo de asistencia
        </OffcanvasHeader>
        <OffcanvasBody>
          {tipo && (
            <EditTypeAssistance
              tipo={tipo}
              onClose={() => {
                setEditTypeAssistance(false);
                reload();
              }}
            />
          )}
        </OffcanvasBody>
      </Offcanvas>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3>{pageName}</h3>
        <div>
          <Button
            className="btn-tagexpress"
            onClick={toggleCreateTypeAssistance}
          >
            <PlusSquare data-tip="Crear un nuevo tipo" />
          </Button>
        </div>
      </div>
      <Card className="my-3">
        <CardHeader style={{ backgroundColor: "var(--yellow)" }}>
          Filtro
        </CardHeader>
        <CardBody>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={3}>
                <div className="form-group mb-3">
                  <label>Estado:</label>
                  <select
                    {...register("status")}
                    defaultValue={""}
                    className="form-control"
                  >
                    <option value="">Seleccione un estado</option>
                    <option value="A">Activo</option>
                    <option value="I">Inactivo</option>
                  </select>
                </div>
              </Col>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <div>
                  <Button
                    type="button"
                    color="light"
                    className="mx-2"
                    onClick={clearFilter}
                  >
                    <img
                      src={ClearFilter}
                      width="20px"
                      style={{ marginRight: 10 }}
                      alt=""
                    />
                    Limpiar filtro
                  </Button>
                  <Button color="success">Buscar</Button>
                </div>
              </div>
            </Row>
          </Form>
        </CardBody>
      </Card>
      {data.status === "SUCCESS" && (
        <>
          {data.typeAssistance.length !== 0 ? (
            <div
              style={{
                overflow: "auto",
              }}
            >
              <Table>
                <thead>
                  <tr>
                    <th>
                      Nombre
                    </th>
                    <th>
                      Tipo
                    </th>
                    <th>
                      Descripción
                    </th>
                    <th>
                      Estado
                    </th>
                    <th>
                      Acción
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.typeAssistance.map((type) => (
                    <tr key={type.tipo_asistencia_id}>
                      <td >{type.nombre}</td>
                      <td >{type.tipo}</td>
                      <td >{type.descripcion || "-"}</td>
                      <td >
                        {type.estado === "I" && (
                          <Badge color="warning">Inactivo</Badge>
                        )}
                        {type.estado === "A" && (
                          <Badge color="success">Activo</Badge>
                        )}
                      </td>
                      <td >
                        {type.estado === "A" && (
                          <>
                            <Trash
                              style={{
                                width: 20,
                                height: 20,
                                cursor: "pointer",
                                marginRight: 5,
                                color: "red",
                              }}
                              id={`delete-${type.tipo_asistencia_id}`}
                              onClick={() => {
                                setConfirm(!confirm);
                                setTipo(type);
                              }}
                            />
                            <UncontrolledTooltip
                              placement="top"
                              target={`delete-${type.tipo_asistencia_id}`}
                            >
                              Desactivar
                            </UncontrolledTooltip>
                          </>
                        )}
                        <Edit
                          style={{
                            width: 20,
                            height: 20,
                            cursor: "pointer",
                            marginRight: 5,
                          }}
                          id={`edit-${type.tipo_asistencia_id}`}
                          onClick={() => toggleEditTypeAssistance(type)}
                        />
                        <UncontrolledTooltip
                          placement="top"
                          target={`edit-${type.tipo_asistencia_id}`}
                        >
                          Editar
                        </UncontrolledTooltip>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <div className="mt-5">
              <h1 className="alert-title">
                No hay tipo de asistencia al momento
              </h1>
            </div>
          )}
        </>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Pagination>
          {generateSequentialArray(totalPages).map((item, index) => (
            <PaginationItem
              key={index}
              active={index === currentPage}
              onClick={() =>
                _getTypeAssistance({
                  ...filter,
                  page: index,
                  status: filter.status,
                })
              }
            >
              <PaginationLink href="#">{index + 1}</PaginationLink>
            </PaginationItem>
          ))}
        </Pagination>
      </div>
    </div>
  );
};

export default TypeAssistance;
