import React, { useState, useCallback, useEffect } from "react";
import { Form, Button } from "reactstrap";
import { useForm } from "react-hook-form";
import { api } from "../../../../../../network";
import { headers, notifyError, notifyOk } from "../../../../../../utils/utils.js";
import { ToastContainer } from "react-toastify";

export const NewRegisterProviderForm = ({
  setShowModalNewRegister,
  reload,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [data, setData] = useState({
    provider: [],
    products: [],
    reload: false,
    loading: true,
  });

  const [state, setState] = useState({
    isLoading: false,
    mainError: false,
  });

  const getProduct = useCallback(async () => {
    try {
      setData((old) => ({ ...old, loading: true }));
      const response = await api.get("/v1/productos", {
        headers: headers(), // ask only for active personas
      });

      if (response && response.status !== 200) {
        notifyError("ERROR");
        return;
      }

      setData((old) => ({
        ...old,
        loading: false,
        products: response.data.data,
      }));
    } catch (e) {
      notifyError("ERROR");
    }
  }, []);


  const onSubmit = async (data) => {
    try {
      setState((old) => ({ ...old, isLoading: true }));
      await api.post("/v1/proveedores", data, {
        headers: headers(),
      });
      notifyOk("Nuevo Proveedor Creado", { autoClose: 3000 });
      reload();
      setState((old) => ({ ...old, isLoading: false }));
      setShowModalNewRegister(false);
    } catch (error) {
      notifyError("ERROR DE SERVIDOR");
    }
  };

  useEffect(() => {
    getProduct();
  }, [getProduct]);

  return (
    <>
      <ToastContainer />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group mb-3">
          <label>Nombre Proveedor</label>
          <input
            className="form-control"
            placeholder="Proveedor"
            {...register("nombre_proveedor", {
              required: { value: true, message: "El proveedor es requerido" },
            })}
          >
          </input>
          <small className="form-text text-danger">
            {errors.nombre_proveedor && errors.nombre_proveedor.message}
          </small>
        </div>
        <div className="form-group mb-3">
          <label>Contacto Proveedor</label>
          <input
            className="form-control"
            placeholder="Contacto"
            {...register("contacto_proveedor", {
              required: { value: true, message: "El contacto es requerido" },
            })}
          >
          </input>
          <small className="form-text text-danger">
            {errors.contacto_proveedor && errors.contacto_proveedor.message}
          </small>
        </div>
        <div className="form-group mb-3">
          <label>País Proveedor</label>
          <input
            className="form-control"
            placeholder="País"
            {...register("pais_proveedor", {
              required: { value: true, message: "El País es requerido" },
            })}
          >
          </input>
          <small className="form-text text-danger">
            {errors.pais_proveedor && errors.pais_proveedor.message}
          </small>
        </div>
        <div className="form-group mb-3">
          <label>Producto</label>
          <select
            className="form-control"
            placeholder="---Seleccione Producto---"
            {...register("producto_id", {
              required: { value: true, message: "El producto es requerido" },
            })}
          >
            <option value="">
              Seleccione...
            </option>
            {data.products.map((product, index) => (
              <option
                value={`${product.id_producto}`}
                key={product.id_producto}
              >
                {product.nombre_producto}
              </option>
            ))}
          </select>
          <small className="form-text text-danger">
            {errors.producto_id && errors.producto_id.message}
          </small>
        </div>
        {state.isLoading ? null : <Button color="success" type="submit" >Guardar</Button>}
      </Form>
    </>
  );
};
