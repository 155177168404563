import React, { useState, useCallback, useEffect, Fragment } from "react";
import { PlusSquare, RefreshCcw } from "react-feather";
import { ToastContainer } from "react-toastify";
import {
  Row,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap";
import { api } from "../../../../../network";
import {
  headers,
  notifyError,
  notifyOk,
  excelFilterRep,
} from "../../../../../utils/utils.js";
import { NewRegisterProductForm } from "./components/NewRegisterProductForm";
import { TableRegisterProduct } from "./components/TableRegisterProduct";
import XLSX from "xlsx";
import excelImg from "../../../../../assets/Excel-Logo.png";
import ReactTooltip from "react-tooltip";

const RegisterProduct = ({ pageName }) => {
  const [createSidebar, setCreateSidebar] = useState(false);
  const toggleCreateSidebar = () => {
    setCreateSidebar(!createSidebar);
  };

  const [data, setData] = useState({
    products: [],
    reload: false,
    loading: true,
  });

  const reload = () =>
    setData((old) => ({
      products: [],
      reload: !old.reload,
      loading: !old.loading,
    }));

  const getProduct = useCallback(async () => {
    try {
      setData((old) => ({ ...old, loading: true }));
      const response = await api.get("/v1/productos", {
        headers: headers(), // ask only for active personas
      });

      if (response && response.status !== 200) {
        notifyError("ERROR");
        return;
      }
      notifyOk(response.data.message, { autoClose: 3000 });
      setData((old) => ({
        ...old,
        loading: false,
        products: response.data.data,
      }));
    } catch (e) {
      notifyError("ERROR EN EL SERVIDOR");
    }
  }, []);

  useEffect(() => {
    getProduct();
  }, [data.reload, getProduct]);

  const handleOnExport = () => {
    const filterArray = excelFilterRep(
      data.products,
      "id_producto",
      "valor_producto",
      "deleted"
    );
    console.log("Arreglo filtrado ", filterArray);
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(filterArray);

    XLSX.utils.book_append_sheet(wb, ws, "Reporte productos");

    XLSX.writeFile(wb, "Productos.xlsx");
  };

  return (
    <>
      <ToastContainer style={{ width: "400px" }} />
      <ReactTooltip type="light" />
      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggleCreateSidebar}
        isOpen={createSidebar}
      >
        <OffcanvasHeader toggle={toggleCreateSidebar}>
          Ingresar nuevo Producto
        </OffcanvasHeader>
        <OffcanvasBody>
          <NewRegisterProductForm
            setShowModalNewRegister={setCreateSidebar}
            reload={reload}
          />
        </OffcanvasBody>
      </Offcanvas>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        className="mb-4"
      >
        <h3>{pageName}</h3>
        <div>
          {!data.loading && (
            <img
              src={excelImg}
              alt="excel"
              style={{ height: "40px" }}
              onClick={handleOnExport}
              data-tip="Exportar excel"
            />
          )}
          <Button
            style={{ marginRight: 20 }}
            onClick={reload}
            data-tip="Refrescar"
          >
            <RefreshCcw />
          </Button>
          <Button
            color="warning"
            data-tip="Crear nuevo producto"
            onClick={() => toggleCreateSidebar()}
          >
            <PlusSquare />
          </Button>
        </div>
      </div>
      <Row>
        {data.loading ? (
          <h1>Cargando</h1>
        ) : (
          <>
            {data.products.length > 0 ? (
              <>
                <TableRegisterProduct data={data.products} reload={reload} />
              </>
            ) : (
              <div className="mt-5">
                <h1 className="alert-title">
                  No hay registro de productos activos por el momento
                </h1>
              </div>
            )}
          </>
        )}
      </Row>
    </>
  );
};

export default RegisterProduct;
