import { useCallback, useEffect, useState } from "react";
import { Button, Form, FormGroup, Label } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { api } from "../../../../../network";
import { headers, notifyError, notifyOk } from "../../../../../utils/utils";
import { s_geStores } from "../../../../../services/store.Ctl";

function NewUsersForm({ toggleSidebar, getUsers }) {
  const [isLoading, setIsLoading] = useState(false);

  const [people, setPeople] = useState([]);
  const [roles, setRoles] = useState([]);
  const [stores, setStores] = useState([]);
  const [tolls, setTolls] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
  } = useForm();

  const getPeople = useCallback(async () => {
    try {
      const response = await api.get("/v1/personas", {
        headers: headers(),
        params: { estado: "A" },
      });
      setPeople(response.data.data);
    } catch (e) {
      notifyError("Ocurrió un error al obtener las personas.");
    }
  }, []);

  const getRoles = useCallback(async () => {
    try {
      const response = await api.get("/v1/roles", {
        headers: headers(),
        params: { estado: "A" },
      });
      setRoles(response.data.data);
    } catch (e) {
      notifyError("Ocurrió un error al obtener los roles.");
    }
  }, []);

  const getTolls = useCallback(async () => {
    try {
      const response = await api.get("/v1/peajes", {
        headers: headers(),
        params: { estado: "A" },
      });

      setTolls(response.data.data);
    } catch (e) {
      notifyError("Ocurrió un error al obtener los peajes.");
    }
  }, []);

  const getStores = async () => {
    try {
      const res = await s_geStores();
      if (!res) {
        return notifyError("Error al traer los puntos de venta");
      }
      setStores(res);
    } catch (error) {
      notifyError("Ocurrió un error al obtener los punto de venta.");
    }
  };

  async function handleCreateUsuario(data) {
    setIsLoading(true);
    try {
      await api.post("/v1/usuarios/create", data, {
        headers: headers(),
      });
      notifyOk("Usuario creado.");
      getUsers();
      toggleSidebar();
      reset();
    } catch (error) {
      if (error.response.data.data === "USUARIO_YA_EXISTE") {
        notifyError("El usuario ya existe.");
        return;
      }
      notifyError("Ocurrió un error al crear el usuario.");
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getPeople();
    getRoles();
    getTolls();
    getStores();
  }, [getPeople, getRoles, getTolls]);

  return (
    <div>
      <Form onSubmit={handleSubmit(handleCreateUsuario)}>
        <FormGroup>
          <Label>Persona: (*)</Label>
          <Controller
            control={control}
            name="persona_id"
            rules={{
              required: {
                value: true,
                message: "La persona es obligatoria.",
              },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <select
                className="form-control"
                onChange={(e) => onChange(e)}
                onBlur={onBlur}
                selected={value}
              >
                <option value="">Seleccione un persona</option>
                {people.map((per) => (
                  <option value={per.persona_id} key={per.persona_id}>
                    {per.nombres + " " + per.apellidos}
                  </option>
                ))}
              </select>
            )}
          />
          <small className="form-text text-danger">
            {errors.persona_id && errors.persona_id.message}
          </small>
        </FormGroup>
        <FormGroup>
          <Label>Usuario: (*)</Label>
          <input
            type="text"
            className="form-control"
            placeholder="Ingrese el usuario"
            {...register("usuario", {
              required: {
                value: true,
                message: "El usuario es obligatorio.",
              },
            })}
          />
          <small className="form-text text-danger">
            {errors.usuario && errors.usuario.message}
          </small>
        </FormGroup>
        <FormGroup>
          <Label>Contraseña: (*)</Label>
          <input
            type="password"
            className="form-control"
            placeholder="Ingrese la contraseña"
            {...register("password", {
              required: {
                value: true,
                message: "La contraseña es obligatoria.",
              },
            })}
          />
          <small className="form-text text-danger">
            {errors.password && errors.password.message}
          </small>
        </FormGroup>
        <FormGroup>
          <Label>Confirmar contraseña: (*)</Label>
          <input
            type="password"
            className="form-control"
            placeholder="Confirmar contraseña"
            {...register("confPassword", {
              required: {
                value: true,
                message: "Confirmar contraseña es obligatoria.",
              },
              validate: (val) => {
                if (watch("password") !== val) {
                  return "Sus contraseñas no coinciden.";
                }
              },
            })}
          />
          <small className="form-text text-danger">
            {errors.confPassword && errors.confPassword.message}
          </small>
        </FormGroup>
        <FormGroup>
          <Label>Rol: (*)</Label>
          <Controller
            control={control}
            name="rol_id"
            rules={{
              required: {
                value: true,
                message: "El rol es obligatorio.",
              },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <select
                className="form-control"
                onChange={(e) => onChange(e)}
                onBlur={onBlur}
                selected={value}
              >
                <option value="">Seleccione un rol</option>
                {roles.map((role) => (
                  <option value={role.rol_id} key={role.rol_id}>
                    {role.descripcion}
                  </option>
                ))}
              </select>
            )}
          />
          <small className="form-text text-danger">
            {errors.rol_id && errors.rol_id.message}
          </small>
        </FormGroup>
        <FormGroup>
          <Label>Punto de venta</Label>
          <Controller
            control={control}
            name="punto_de_venta_id"
            render={({ field: { onChange, onBlur, value } }) => (
              <select
                className="form-control"
                onChange={(e) => onChange(e)}
                onBlur={onBlur}
                selected={value}
              >
                <option value="">Seleccione un punto de venta</option>
                {stores.map((store) => (
                  <option
                    key={store.punto_de_venta_id}
                    value={store.punto_de_venta_id}
                  >
                    {store.nombre}
                  </option>
                ))}
              </select>
            )}
          />
        </FormGroup>
        <FormGroup>
          <Label>Peaje</Label>
          <Controller
            control={control}
            name="peaje_id"
            render={({ field: { onChange, onBlur, value } }) => (
              <select
                className="form-control"
                onChange={(e) => onChange(e)}
                onBlur={onBlur}
                selected={value}
              >
                <option value="">Seleccione un peaje</option>
                {tolls.map((toll) => (
                  <option value={toll.peaje_id} key={toll.peaje_id}>
                    {toll.descripcion}
                  </option>
                ))}
              </select>
            )}
          />
        </FormGroup>
        <Button className="btn-tagexpress" disabled={isLoading}>
          Crear
        </Button>
      </Form>
    </div>
  );
}
export default NewUsersForm;
