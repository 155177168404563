import { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Card, CardBody, Col, Form, Label, Row } from "reactstrap";
import { sVerifyUser } from "../../../../services/userCtl";
import { notifyError } from "../../../../utils/utils";
import { ToastContainer } from "react-toastify";
import UserInfoModal from "./components/userInfoModal";
import TheLoader from "../../../elements/theLoader";

function VerifyUser({ pageName }) {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const [isLoading, setIsloading] = useState(false);

    const [user, setUser] = useState(null);

    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    const onSubmit = async (data) => {
        try {
            setIsloading(true);
            const res = await sVerifyUser(data);
            setUser(res);
            setModal(true);
            reset();
        } catch (error) {
            if (error.response.status === 404) {
                notifyError("No existe una cuenta para este usuario.");
                return;
            }
            notifyError(
                "Error de servidor. Por favor intente mas tarde o notifique al equipo de desarrollo"
            );
        }
        finally {
            setIsloading(false);
        }
    };

    return (
        <div>
            <ToastContainer />
            {isLoading && <TheLoader />}
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <h3>{pageName}</h3>
            </div>
            <Row>
                <small className="text-danger">* Realice su busqueda por correo o identificación del usuario</small>
                <Col md={4}>
                    <Card className="my-3">
                        <CardBody>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group mb-3">
                                    <Label>Correo electrónico:</Label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Escriba el correo electrónico"
                                        {...register("email")}
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <Label>Identificación:</Label>
                                    <input
                                        className="form-control"
                                        placeholder="Ingrese la identificación del usuario"
                                        {...register("id")}
                                    />
                                    <small className="form-text text-danger">
                                        {errors.identification && errors.identification.message}
                                    </small>
                                </div>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    {!isLoading && <Button className="btn-tagexpress" type="submit">
                                        Buscar
                                    </Button>}
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
                {
                    user &&
                    <Col md={4}>
                        <UserInfoModal user={user} toggle={toggle} modal={modal} />
                    </Col>
                }
            </Row>
        </div>
    );
}
export default VerifyUser;
