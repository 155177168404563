import React, { useState, useEffect } from "react";
import {
  Form, Button, Row, Col, CardBody, CardHeader, Card, Table
} from "reactstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { notifyError, excelFilterRep } from '../../../../utils/utils';
import XLSX from "xlsx";
import excelImg from '../../../../assets/Excel-Logo.png';
import './reporteRecargWallet.css';
import { s_getWalletTransaction } from "../../../../services/dailySalesCtl";
import { s_geStores } from "../../../../services/store.Ctl";
const initialValues = {
  store: "",
  Initdate: "",
  endDate: "",
};

const ReporteRecargWalletPage = () => {
  const [data, setdata] = useState(initialValues);
  const [puntoDeVenta, setPuntoDeVenta] = useState([]);
  const [transactionTable, setTransactionTable] = useState([]);

  const _handleOnExport = () => {

    const filterArray = excelFilterRep(
      transactionTable,
      'id_venta_recarga_wallet',
      'cierre_de_caja',
      'updated',
      'usuario_id',
      'punto_de_venta_id'
    );
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(filterArray);

    XLSX.utils.book_append_sheet(wb, ws, "Reporte ventas diarias wallet");

    XLSX.writeFile(wb, "Ventas diarias wallet.xlsx");
  };

  const handleInputChange = async (e) => {
    const { name, value } = e.target;

    setdata({
      ...data,
      [name]: value,
    });
  };

  const handleSubmitFilter = async () => {
    const result = await s_getWalletTransaction(data.Initdate, data.endDate, data.store);
    if (!result) {
      notifyError('Error al consultar los datos, por favor intente mas tarde');
      return;
    }
    setTransactionTable(result);
  }

  const getSellingPoints = async () => {
    const result = await s_geStores();
    if (!result) {
      notifyError('Error al consultar los datos, por favor intente mas tarde');
      return;
    }
    setPuntoDeVenta(result);
  }

  const getTransactionData = async () => {
    const result = await s_getWalletTransaction();
    if (!result) {
      notifyError('Error al consultar los datos, por favor intente mas tarde');
      return;
    }
    setTransactionTable(result);
  }

  useEffect(() => {
    getSellingPoints();
    getTransactionData();
  }, []);


  return (
    <div>
      <ToastContainer />
      <Card>
        <CardHeader>Filtro</CardHeader>
        <CardBody>
          <Form>
            <Row>
              <Col md={4}>
                <div className="form-group mb-3">
                  <label>Desde:</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Fecha de inicio"
                    value={data.Initdate}
                    onChange={(e) => handleInputChange(e)}
                    name="Initdate"
                    label="Fecha inicio"
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="form-group mb-3">
                  <label>Hasta:</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Fecha de inicio"
                    value={data.endDate}
                    onChange={(e) => handleInputChange(e)}
                    name="endDate"
                    label="Fecha fin"
                  />
                </div>
              </Col>

              <Col md={4}>
                <div className="form-group mb-3">
                  <label>Punto de venta:</label>
                  <select
                    className="form-control"
                    placeholder="Fecha de inicio"
                    value={data.store}
                    onChange={(e) => handleInputChange(e)}
                    name="store"
                    label="Store"
                  >
                    <option value="">Seleccione un punto de venta...</option>
                    {puntoDeVenta.map((store, index) => (
                      <option value={store.punto_de_venta_id} key={index}>
                        {store.nombre}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {transactionTable.length > 0 && (
                  <img
                    src={excelImg}
                    alt="excel"
                    style={{ height: "40px" }}
                    onClick={_handleOnExport}
                  />
                )}
                <Button color="success" onClick={handleSubmitFilter}>Buscar</Button>
              </div>
            </Row>
          </Form>
        </CardBody>
      </Card>
      {transactionTable.length > 0 ?

        <Card className="mt-3">
          <CardBody>
            <Table>
              <thead style={{ backgroundColor: "#e8b800" }}>
                <tr>
                  <th>Item</th>
                  <th>Monto</th>
                  <th>Comisión</th>
                  <th>Tipo pago</th>
                  <th>Fecha</th>
                  <th>Usuario</th>
                  <th>Punto de venta</th>
                </tr>
              </thead>
              <tbody>
                {transactionTable.map((report, index) => (
                  <tr key={index}>
                    <td>
                      {index + 1}
                    </td>
                    <td>
                      {report.monto}
                    </td>
                    <td>
                      {report.comision}
                    </td>
                    <td>
                      {report.tipo_pago}
                    </td>
                    <td>
                      {report.created}
                    </td>
                    <td >
                      {report.usuario.usuario}
                    </td>
                    <td >
                      {report.punto_de_ventum.nombre}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
        : <p>No se encontraron transacciones, por favor busque en otras fechas</p>
      }
    </div>

  )
};

export default ReporteRecargWalletPage;