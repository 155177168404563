import dayjs from "dayjs";
import React, { useState } from "react";
import { Monitor, Smartphone } from "react-feather";
import { useForm } from "react-hook-form";
import { Button, Col, Form, Label, Row, UncontrolledTooltip } from "reactstrap";
import { notifyError, notifyOk } from "../../../../utils/utils";
import { formatEstado } from "../utils/utils";
import { s_updateNewAsist } from "../../../../services/asistVialCtl";

const EditRequestForm = ({ onClose, evento, vehicle }) => {
  const { register, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      usuario_nombres: evento.usuario_nombres,
      usuario_telefono: evento.usuario_telefono,
      usuario_placa: evento.usuario_placa,
      ubicacion: evento.ubicacion_asistencia,
      vehiculo_descripcion: evento.vehiculo_descripcion,
      descripcion: evento.descripcion,
      chofer_asistencia: evento.chofer_asistencia,
    }
  });

  const [state, setState] = useState({
    isLoading: false,
    mainError: false,
  });

  const onSubmit = async (data, e) => {
    data.evento_asistencia_id = evento.evento_asistencia_id;
    const vehicleSelected = vehicle.filter(
      (item) => item.vehiculo_asistencia_id === parseInt(data.vehicle)
    );
    const dataTemp = {
      ...data,
      vehiculo: [data.vehicle],
      tipo_vehiculo:
        vehicleSelected.length > 0
          ? vehicleSelected[0].categoria_vehiculos_peaje
            .categoria_vehiculo_peaje
          : null,
      kilometraje_salida:
        vehicleSelected.length > 0
          ? vehicleSelected[0].vehiculo_asistencium.kilometraje
          : null,
    };
    try {
      setState((old) => ({ ...old, isLoading: true }));
      await s_updateNewAsist({
        ...dataTemp,
        evento_asistencia_id: evento.evento_asistencia_id,
      });
      setState((old) => ({ ...old, isLoading: false }));
      notifyOk("Datos actualizados correctamente.", {
        autoClose: 3000,
      });
      onClose();
    } catch (error) {
      setState((old) => ({ ...old, isLoading: false }));
      if (error.response.status === 409) {
        notifyError(error.response.data.message);
        return;
      }
      notifyError(
        "Ocurrió un error al agregar producto, por favor inténtelo más tarde."
      );
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          gap: 10,
        }}
      >
        <h6 style={{ margin: 0 }}>{formatEstado(evento)}</h6>
        {evento.usuario_modo_de_solicitud === "web" && (
          <>
            <Monitor id="web" />
            <UncontrolledTooltip placement="top" target="web">
              Web
            </UncontrolledTooltip>
          </>
        )}
        {evento.usuario_modo_de_solicitud === "mobile" && (
          <>
            <Smartphone id="mobile" />
            <UncontrolledTooltip placement="top" target="mobile">
              Móvil
            </UncontrolledTooltip>
          </>
        )}
      </div>
      {evento.usuario_modo_de_solicitud === "mobile" ? (
        <>
          <h6>Detalle del usuario</h6>
          <ul>
            <li>
              <strong>Nombres: </strong>
              {evento.usuario_nombres}
            </li>
            <li>
              <strong>Teléfono: </strong>
              {evento.usuario_telefono}
            </li>
            <li>
              <strong>Placa: </strong>
              {evento.usuario_placa}
            </li>
            <li>
              <strong>Tipo de vehículo: </strong>
              {evento.tipo_vehiculo}
            </li>
            <li>
              <strong>Descripción: </strong>
              {evento.vehiculo_descripcion}
            </li>
          </ul>

          {evento.fecha_registro && (
            <>
              <h6>Vehículos asignados</h6>
              <ul>
                {evento.fecha_registro ? (
                  <>
                    {evento.vehiculo_asistencia.map((vehiculo, index) => {
                      return (
                        <li key={index}>{`${vehiculo.tipo} ${vehiculo.placa}`}</li>
                      );
                    })}
                  </>
                ) : (
                  <>-</>
                )}
              </ul>
              <h6>Datos de la solicitud</h6>
              <ul>
                <li>
                  <strong>Descripción: </strong> {evento.descripcion}
                </li>
                <li>
                  <strong>Usuario: </strong> {evento.user_registro}
                </li>
                <li>
                  <strong>Fecha de creación: </strong>
                  {dayjs(evento.created_at).format("YYYY-MM-DD HH:mm:ss")}
                </li>
                <li>
                  <strong>Fecha de atención: </strong> {evento.fecha_registro}
                </li>
                <li>
                  <strong>Tiempo asignado: </strong>
                  {evento.tiempo_asignado
                    ? `${evento.tiempo_asignado} minutos`
                    : "-"}
                </li>
                <li>
                  <strong>Tiempo real: </strong>
                  {evento.tiempo_real ? `${evento.tiempo_real} minutos` : "-"}
                </li>
              </ul>
            </>
          )}
          {evento.fecha_solucion && (
            <>
              <h6>Datos de solución</h6>
              <ul>
                <li>
                  <strong>Descripción: </strong>
                  {evento.detalle_solucion}
                </li>
                <li>
                  <strong>Fecha: </strong>
                  {evento.fecha_solucion}
                </li>
                <li>
                  <strong>Usuario: </strong> {evento.user_solucion}
                </li>
              </ul>
            </>
          )}
          {evento.fecha_cancelacion && (
            <>
              <h6>Motivo de cancelación</h6>
              <ul>
                <li>
                  <strong>Descripción: </strong>
                  {evento.detalle_cancelacion}
                </li>
                <li>
                  <strong>Fecha: </strong>
                  {evento.fecha_cancelacion}
                </li>
                <li>
                  <strong>Usuario: </strong> {evento.user_cancelacion}
                </li>
              </ul>
            </>
          )}
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md="4">
                <div className="form-group mb-3">
                  <Label>Inicio de operativo:</Label>
                  <input
                    type="datetime-local"
                    className="form-control"
                    {...register("llegada_inicio_operativo")}
                    defaultValue={evento.llegada_inicio_operativo}
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="form-group mb-3">
                  <Label>
                    Sale con{" "}
                    {evento.vehiculo_asistencia[0].categoria_vehiculo_peaje === "AMBULANCIA"
                      ? "paciente"
                      : "vehículo"}
                    :
                  </Label>
                  <input
                    type="datetime-local"
                    className="form-control"
                    {...register("sale_con_paciente")}
                    defaultValue={evento.sale_con_paciente}
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="form-group mb-3">
                  <Label>Termina operativo:</Label>
                  <input
                    type="datetime-local"
                    className="form-control"
                    {...register("termina_operativo")}
                    defaultValue={evento.termina_operativo}
                  />
                </div>
              </Col>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {state.isLoading ? null : (
                  <Button
                    className="btn-tagexpress"
                    type="submit"
                    style={{ width: 300 }}
                  >
                    Editar
                  </Button>
                )}
              </div>
            </Row>
          </Form>
        </>
      )
        :
        (
          <>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group mb-3">
                <Label>Nombres: (*)</Label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nombre del usuario"
                  {...register("usuario_nombres", {
                    required: {
                      value: true,
                      message: "Nombre es requerido",
                    },
                  })}
                />
                <small className="form-text text-danger">
                  {errors.usuario_nombres && errors.usuario_nombres.message}
                </small>
              </div>
              <div className="form-group mb-3">
                <Label>Teléfono: (*)</Label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="teléfono del usuario"
                  {...register("usuario_telefono", {
                    required: {
                      value: true,
                      message: "Teléfono es requerido",
                    },
                  })}
                />
                <small className="form-text text-danger">
                  {errors.usuario_telefono && errors.usuario_telefono.message}
                </small>
              </div>
              <div className="form-group mb-3">
                <Label>Placa: (*)</Label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="AAA-1234"
                  {...register("usuario_placa", {
                    required: {
                      value: true,
                      message: "Placa es requerida",
                    },
                  })}
                />
                <small className="form-text text-danger">
                  {errors.usuario_placa && errors.usuario_placa.message}
                </small>
              </div>
              <div className="form-group mb-3">
                <Label>Ubicacion: (*)</Label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Km 12, vía a el pan"
                  {...register("ubicacion", {
                    required: {
                      value: true,
                      message: "Ubicación es requerida",
                    },
                  })}
                />
                <small className="form-text text-danger">
                  {errors.ubicacion && errors.ubicacion.message}
                </small>
              </div>
              <div className="form-group mb-3">
                <Label>Descripción del vehiculo: (*)</Label>
                <textarea
                  rows="3"
                  className="form-control"
                  placeholder="Color rojo, marca mazda"
                  {...register("vehiculo_descripcion", {
                    required: {
                      value: true,
                      message: "Descripción del vehículo es requerida",
                    },
                  })}
                />
                <small className="form-text text-danger">
                  {errors.vehiculo_descripcion &&
                    errors.vehiculo_descripcion.message}
                </small>
              </div>
              <div className="form-group mb-3">
                <Label>Descripción de la asistencia: (*)</Label>
                <textarea
                  rows="3"
                  className="form-control"
                  placeholder="Escriba una descripción"
                  {...register("descripcion", {
                    required: {
                      value: true,
                      message: "Descripción de la asistencia es requerida",
                    },
                  })}
                />
                <small className="form-text text-danger">
                  {errors.descripcion && errors.descripcion.message}
                </small>
              </div>
              <div className="form-group mb-3">
                <Label>Vehículo asistencia: (*)</Label>
                <select
                  {...register("vehicle", {
                    required: {
                      value: true,
                      message: "Vehículo es requerido",
                    },
                  })}
                  className="form-control"
                >
                  <option value={evento.vehiculo_asistencia[0].vehiculo_asistencia_id}>{evento.vehiculo_asistencia[0].categoria_vehiculo_peaje + '/' + evento.vehiculo_asistencia[0].placa}</option>
                  {vehicle.map((vehicleOption, index) => (
                    <option
                      value={`${vehicleOption.vehiculo_asistencia_id}`}
                      name={vehicleOption.categoria_vehiculos_peaje.categoria_vehiculo_peaje}
                      key={index}
                      disabled={vehicleOption.vehiculo_asistencium.estado_asistencia === "Ocupado"}
                    >
                      {`${vehicleOption.categoria_vehiculos_peaje.categoria_vehiculo_peaje} / ${vehicleOption.vehiculo_asistencium.placa}`}
                    </option>
                  ))}
                </select>
                <small className="form-text text-danger">
                  {errors.vehicle && errors.vehicle.message}
                </small>
              </div>
              <div className="form-group mb-3">
                <Label>Nombre del chofer de asistencia: (*)</Label>
                <textarea
                  rows="3"
                  className="form-control"
                  placeholder="Nombre del chofer"
                  {...register("chofer_asistencia", {
                    required: {
                      value: true,
                      message: "Nombre del chofer es requerido",
                    },
                  })}
                />
                <small className="form-text text-danger">
                  {errors.chofer_asistencia && errors.chofer_asistencia.message}
                </small>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {state.isLoading ? null : (
                  <Button
                    className="btn-tagexpress"
                    type="submit"
                    style={{ width: 300 }}
                  >
                    Editar
                  </Button>
                )}
              </div>
            </Form>
          </>
        )
      }
    </>
  );
};

export default EditRequestForm;
