import axios from "axios";
import { ADMIN_API_URL, API_URL } from "./utils/constants";

const api = axios.create({ baseURL: ADMIN_API_URL });
const apiClientes = axios.create({ baseURL: API_URL });

export {
  api,
  apiClientes
};
