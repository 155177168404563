import { headers, queryString } from "../utils/utils";
import { api, apiClientes } from "../network";

const dailySaleCtlErrors = {
  ERROR_OBTENCION_DATOS: "ERROR_OBTENCION_DATOS",
  ERROR_ACTUALIZANDO_VENTA: "ERROR_ACTUALIZANDO_VENTA",
};

async function s_getDailySaleTmp() {
  const response = await api.get("/v1/dailySale", {
    headers: headers(),
  });
  if (response && response.status !== 200) {
    throw dailySaleCtlErrors.ERROR_OBTENCION_DATOS;
  }

  return response.data.data;
}

async function s_getDailySaleWallet() {
  const response = await api.get("/v1/dailySale/wallet", {
    headers: headers(),
  });
  if (response && response.status !== 200) {
    throw dailySaleCtlErrors.ERROR_OBTENCION_DATOS;
  }
  return response.data;
}

async function s_getDailySaleFilter(params) {
  const { end_date, start_date, product, type, store } = params;
  const queryObj = {};

  if (end_date) {
    queryObj.end_date = end_date;
  }

  if (start_date) {
    queryObj.start_date = start_date;
  }

  if (product) {
    queryObj.product = product;
  }

  if (type) {
    queryObj.type = type;
  }
  if (store) {
    queryObj.store = store;
  }

  const query = queryString(queryObj);

  const response = await api.get(`/v1/dailySale/filter?${query}`, {
    headers: headers(),
  });
  if (response && response.status !== 200) {
    throw dailySaleCtlErrors.ERROR_OBTENCION_DATOS;
  }

  return response.data.data;
}

async function s_setDailySaleHomologationState(data) {
  const response = await api.post("/v1/dailySale/homologation", data, {
    headers: headers(),
  });

  if (response && response.status !== 200) {
    throw dailySaleCtlErrors.ERROR_ACTUALIZANDO_RECARGA;
  }

  return response.data.message;
}

async function s_setDailySaleRechargeState(data) {
  const response = await api.post("/v1/dailySale/recharge", data, {
    headers: headers(),
  });

  if (response && response.status !== 200) {
    throw dailySaleCtlErrors.ERROR_ACTUALIZANDO_RECARGA;
  }
  return response.data.message;
}

async function s_setDailySalaTagState(data) {
  const response = await api.post("/v1/dailySale/saletag", data, {
    headers: headers(),
  });

  if (response && response.status !== 200) {
    throw dailySaleCtlErrors.ERROR_ACTUALIZANDO_RECARGA;
  }
  return response.data.message;
}

async function s_updateRetention(data) {
  const { id, type, retencion } = data;
  const response = await api.put(
    `/v1/dailySale/retention/${id}?type=${type}`,
    { retencion },
    {
      headers: headers(),
    }
  );

  if (response && response.status !== 200) {
    throw dailySaleCtlErrors.ERROR_OBTENCION_DATOS;
  }
  return response.data.message;
}

const s_checkIfUserHasWallet = async (identificacion) => {
  try {
    await apiClientes.get(`/wallet/user?identificacion=${identificacion}`, {
      headers: headers(),
    });
    return true;
  } catch (e) {
    return false;
  }
};

const s_setDailySaleWalletTransaction = async (data) => {
  try {
    const response = await api.post("/v1/dailySale/wallet", data, {
      headers: headers(),
    });
    return response.data.message;
  } catch (e) {
    return false;
  }
};

const s_setWalletTransaction = async (data) => {
  try {
    const response = await apiClientes.post(
      "/recargas/cashier/wallet/tagexpress",
      data,
      {
        headers: headers(),
      }
    );
    return response.data.message;
  } catch (e) {
    return false;
  }
};

const s_getWalletTransaction = async (startDate, endDate, store) => {
  const response = await api.get(`/v1/dailySale/wallet/report`, {
    headers: headers(),
    params: {
      startDate,
      endDate,
      store,
    },
  });

  return response.data.data;
};

const s_getDailySaleWalletTransactions = async (
  dateInicio,
  dateFin,
  cajero
) => {
  const response = await apiClientes.get(`/transacciones/wallet`, {
    headers: headers(),
    params: {
      dateInicio,
      dateFin,
      cajero,
    },
  });
  return response.data.data;
};

async function closeCashRegister() {
  const response = await api.post(
    "/v1/dailySale/closeCashRegister",
    {},
    {
      headers: headers(),
    }
  );
  if (response && response.status !== 200) {
    throw dailySaleCtlErrors.ERROR_ACTUALIZANDO_RECARGA;
  }

  return response.data.message;
}

export {
  s_getDailySaleTmp,
  s_getDailySaleWallet,
  s_getDailySaleFilter,
  s_setDailySaleHomologationState,
  s_setDailySaleRechargeState,
  s_getWalletTransaction,
  s_setDailySalaTagState,
  s_updateRetention,
  s_checkIfUserHasWallet,
  s_setWalletTransaction,
  s_setDailySaleWalletTransaction,
  s_getDailySaleWalletTransactions,
  closeCashRegister,
};
