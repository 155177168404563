import React, { useCallback, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { notifyError, notifyOk } from "../../../../../utils/utils";
import { s_getUser } from "../../../../../services/userCtl";
import TheLoader from "../../../../elements/theLoader";
import { ToastContainer } from "react-toastify";
import { s_getReportRegisterDelivery } from "../../../../../services/registerDeliveryCtl";
import { TableRegisterDelivery } from "./TableRegisterDelivery";
import {
    Form,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";


const SearchFilterDelivery = ({ setShowModalFilter }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const [data, setData] = useState({
        users: [],
        deliveryReport: [],
    });
    const [isLoading, seIsLoading] = useState(true);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const toggleDelete = () => setShowModalDelete(!showModalDelete);


    const getUsers = useCallback(async () => {
        const res = await s_getUser();
        setData((old) => ({
            ...old,
            users: res,
        }));
        seIsLoading(false);
    }, []);

    const onSubmit = async (data) => {
        const res = await s_getReportRegisterDelivery(data);
        if (!res) {
            notifyError('Error al traer los datos, por favor intente nuevamente');
            setShowModalFilter(false);
            return;
        }
        setData((old) => ({
            ...old,
            deliveryReport: res,
        }));
        notifyOk('Filtrado realizado de manera exitosa');
        return;
    }

    useEffect(() => {
        getUsers();
    }, [getUsers])

    return (
        <>
            <ToastContainer />
            {isLoading ? <TheLoader /> : null}
            <Modal isOpen={showModalDelete} toggle={toggleDelete} size="lg">
                <ModalHeader toggle={toggleDelete}>Reporte de entregas</ModalHeader>
                <ModalBody style={{ width: '100%' }}>
                    {data.deliveryReport.length > 0
                        ?
                        <div className="mt-4">
                            <TableRegisterDelivery data={data.deliveryReport} />
                        </div>
                        :
                        null
                    }
                </ModalBody>
                <ModalFooter className="justify-content-center">
                </ModalFooter>
            </Modal>

            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group mb-3">
                    <label>Desde</label>
                    <div className="form-group mb-3">
                        <input
                            type="date"
                            className="form-control"
                            {...register("initDate", {
                                required: { value: true, message: "La fecha de inicio es requerida" },
                            })}
                        />
                    </div>
                    <small className="form-text text-danger">
                        {errors.initDate && errors.initDate.message}
                    </small>
                </div>
                <div className="form-group mb-3">
                    <label>Hasta</label>
                    <div className="form-group mb-3">
                        <input
                            type="date"
                            className="form-control"
                            {...register("finalDate", {
                                required: { value: true, message: "La fecha final es requerido" },
                            })}
                        />
                    </div>
                    <small className="form-text text-danger">
                        {errors.finalDate && errors.finalDate.message}
                    </small>
                </div>
                <div className="form-group mb-3">
                    <label>Usuario</label>
                    <select
                        className="form-control"
                        placeholder="Seleccione el punto de venta"
                        {...register("usuario", {
                            required: { value: true, message: "El punto de venta es requerido" },
                        })}
                    >
                        <option value="">Seleccione...</option>
                        {data.users?.map((user) => (
                            <option
                                value={`${user.usuario_id}`}
                                key={user.usuario_id}
                            >
                                {user.usuario}
                            </option>
                        ))}
                    </select>
                    <small className="form-text text-danger">
                        {errors.usuario && errors.usuario.message}
                    </small>
                </div>
                {isLoading ? null : (
                    <Button type="submit" className="btn-tagexpress" onClick={() => setShowModalDelete(true)}>
                        Buscar
                    </Button>
                )}
            </Form>
        </>
    )
}

export default SearchFilterDelivery;