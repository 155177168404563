import { useCallback, useEffect, useState } from "react";
import { Button, Form, FormGroup, Label } from "reactstrap";
import { headers, notifyError, notifyOk } from "../../../../../utils/utils";
import { api } from "../../../../../network";
import { Controller, useForm } from "react-hook-form";
import { s_geStores } from "../../../../../services/store.Ctl";

function UpdateUsersForm({ selectedUser, toggleSidebar, getUsers }) {
  const { rol_id, punto_de_venta_id, peaje_id, usuario_id, estado } =
    selectedUser;

  const [isLoading, setIsLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [stores, setStores] = useState([]);
  const [tolls, setTolls] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm();

  const getStores = async () => {
    try {
      const res = await s_geStores();
      if (!res) {
        return notifyError("Error al traer los puntos de venta");
      }
      setStores([
        { punto_de_venta_id: "", nombre: "Seleccione un punto de venta" },
        ...res,
      ]);
    } catch (error) {
      notifyError("Ocurrió un error al obtener los punto de venta.");
    }
  };

  const getTolls = useCallback(async () => {
    try {
      const response = await api.get("/v1/peajes", {
        headers: headers(),
        params: { estado: "A" },
      });

      setTolls([
        {
          peaje_id: "",
          descripcion: "Seleccione un peaje",
        },
        ...response.data.data,
      ]);
    } catch (e) {
      notifyError("Ocurrió un error al obtener los peajes.");
    }
  }, []);

  const getRoles = useCallback(async () => {
    try {
      const response = await api.get("/v1/roles", {
        headers: headers(),
        params: { estado: "A" },
      });
      setRoles(response.data.data);
    } catch (e) {
      notifyError("Ocurrió un error al obtener los roles.");
    }
  }, []);

  const handleUpdateUsuario = async (data) => {
    try {
      setIsLoading(true);
      await api.put(
        "/v1/usuarios/update",
        { ...data, usuario_id },
        {
          headers: headers(),
        }
      );
      notifyOk("Usuario actualizado correctamente.");
      getUsers();
      toggleSidebar();
    } catch (error) {
      notifyError("Ocurrió un error al actualizar el usuario.");
    }
    setIsLoading(false);
  };
  const handleDeleteUsuario = async () => {
    try {
      setIsLoading(true);
      await api.delete(`/v1/usuarios/delete/${usuario_id}`, {
        headers: headers(),
      });
      if (estado === "A") {
        notifyOk("Usuario deshabilitado.");
      } else {
        notifyOk("Usuario habilitado.");
      }
      getUsers();
      toggleSidebar();
    } catch (error) {
      notifyError("Ocurrió un error al realizar esta acción.");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getRoles();
    getStores();
    getTolls();
  }, [getRoles, getTolls]);

  return (
    <>
      <h3>{selectedUser.usuario}</h3>
      <Form onSubmit={handleSubmit(handleUpdateUsuario)}>
        <FormGroup>
          <Label>Contraseña</Label>
          <input
            type="password"
            className="form-control"
            placeholder="Ingrese la contraseña"
            {...register("password")}
          />
          <small className="form-text text-danger">
            {errors.password && errors.password.message}
          </small>
        </FormGroup>
        <FormGroup>
          <Label>Confirmar Contraseña</Label>
          <input
            type="password"
            className="form-control"
            placeholder="Confirmar contraseña"
            {...register("confPassword", {
              validate: (val) => {
                if (watch("password") !== val) {
                  return "Sus contraseñas no coinciden.";
                }
              },
            })}
          />
          <small className="form-text text-danger">
            {errors.confPassword && errors.confPassword.message}
          </small>
        </FormGroup>
        <FormGroup>
          <Label>Rol</Label>
          <Controller
            control={control}
            name="rol_id"
            rules={{
              required: {
                value: true,
                message: "El rol es obligatorio.",
              },
            }}
            defaultValue={rol_id}
            render={({ field: { onChange, onBlur, value } }) => (
              <select
                className="form-control"
                onChange={(e) => onChange(e)}
                onBlur={onBlur}
                selected={value}
              >
                <option value={rol_id}>{selectedUser.rol.descripcion}</option>
                {roles
                  .filter((rol) => rol.rol_id !== rol_id)
                  .map((role) => (
                    <option value={role.rol_id} key={role.rol_id}>
                      {role.descripcion}
                    </option>
                  ))}
              </select>
            )}
          />
          <small className="form-text text-danger">
            {errors.rol_id && errors.rol_id.message}
          </small>
        </FormGroup>
        <FormGroup>
          <Label>Punto de venta</Label>
          <Controller
            control={control}
            name="punto_de_venta_id"
            defaultValue={punto_de_venta_id || ""}
            render={({ field: { onChange, onBlur, value } }) => (
              <select
                className="form-control"
                onChange={(e) => onChange(e)}
                onBlur={onBlur}
                selected={value}
              >
                {stores.map((store) => (
                  <option
                    key={store.punto_de_venta_id}
                    value={store.punto_de_venta_id}
                    selected={store.punto_de_venta_id === punto_de_venta_id}
                  >
                    {store.nombre}
                  </option>
                ))}
              </select>
            )}
          />
        </FormGroup>
        <FormGroup>
          <Label>Peaje</Label>
          <Controller
            control={control}
            name="peaje_id"
            defaultValue={peaje_id || ""}
            render={({ field: { onChange, onBlur, value } }) => (
              <select
                className="form-control"
                onChange={(e) => onChange(e)}
                onBlur={onBlur}
                selected={value}
              >
                {tolls.map((toll) => (
                  <option
                    value={toll.peaje_id}
                    key={toll.peaje_id}
                    selected={toll.peaje_id === peaje_id}
                  >
                    {toll.descripcion}
                  </option>
                ))}
              </select>
            )}
          />
        </FormGroup>
        <div
          style={{
            display: "flex",
            gap: 10,
          }}
        >
          <Button className="btn-tagexpress" disabled={isLoading}>
            Modificar
          </Button>
          <Button
            className={`${
              estado === "A"
                ? "btn-tagexpress-danger"
                : "btn-tagexpress-success"
            }`}
            onClick={handleDeleteUsuario}
            disabled={isLoading}
          >
            {estado === "A" ? "Deshabilitar" : "Habilitar"}
          </Button>
        </div>
      </Form>
    </>
  );
}
export default UpdateUsersForm;
