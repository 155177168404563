import React, { useState, useRef } from "react";
import { Form, Button } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { ToastContainer } from "react-toastify";
import { notifyError, notifyOk } from "../../../../../utils/utils";
import { s_setDailySaleHomologationState } from "../../../../../services/dailySalesCtl";

export const NewHomologationForm = ({
  tolls,
  reload,
  setShowModalHomologation,
  store
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    control,
  } = useForm();

  const [state, setState] = useState({
    isLoading: false,
    mainError: false,
  });

  const _onSubmit = async (data) => {
    data.store = store.punto_de_venta_id
    try {
      setState((old) => ({ ...old, isLoading: true }));
      const resp = await s_setDailySaleHomologationState(data);
      notifyOk(resp, { autoClose: 3000 });
      reload();
      setState((old) => ({ ...old, isLoading: false }));
      setShowModalHomologation(false);
    } catch (error) {
      setState((old) => ({ ...old, isLoading: false }));
      if (error.response.status === 409) {
        notifyError(error.response.data.message);
        return;
      }
      notifyError(
        "Ocurrió un error al guardar la homologación, por favor inténtelo más tarde."
      );
    }
  };

  const selectConcesionRef = useRef(undefined);

  const _onChangeCantidad = (e) => {
    const concesion = selectConcesionRef.current;
    if (concesion === undefined) return;

    if (
      concesion === "Guayasamin" ||
      concesion === "Panavial" ||
      concesion === "Peaje Autopista General Rumiñahui"
    ) {
      const total = e.target.value * 2;
      setValue("total", total.toFixed(2), { shouldValidate: true });
      return;
    }

    const total = e.target.value * 1;
    setValue("total", total.toFixed(2), { shouldValidate: true });
  };

  const _onChangeConcesion = (e) => {
    const concesion = e.target.value;
    selectConcesionRef.current = e.target.value;
    const cantidad = getValues("cantidad");

    if (cantidad === undefined) return;

    if (
      concesion === "Guayasamin" ||
      concesion === "Panavial" ||
      concesion === "Peaje Autopista General Rumiñahui"
    ) {
      const total = cantidad * 2;
      setValue("total", total.toFixed(2), { shouldValidate: true });
      return;
    }

    const total = cantidad * 1;
    setValue("total", total.toFixed(2), { shouldValidate: true });
    return e;
  };

  return (
    <div>
      <ToastContainer />
      <Form onSubmit={handleSubmit(_onSubmit)}>
        <div className="form-group mb-3">
          <label>Cantidad</label>
          <input
            type="number"
            className="form-control"
            placeholder="Cantidad"
            {...register("cantidad", {
              required: { value: true, message: "La cantidad es requerido" },
              onChange: _onChangeCantidad,
            })}
          />
          <small className="form-text text-danger">
            {errors.cantidad && errors.cantidad.message}
          </small>
        </div>
        <div className="form-group mb-3">
          <label>Consesión</label>
          <Controller
            control={control}
            name="concesion"
            rules={{
              required: {
                value: true,
                message: "La consesión es requerido",
              },
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <select
                className="form-control"
                onChange={(e) => {
                  _onChangeConcesion(e);
                  onChange(e);
                }}
                onBlur={onBlur}
                selected={value}
              >
                <option defaultValue="">Seleccione una consesión</option>
                {tolls.map((toll, index) => (
                  <option value={`${toll.descripcion}`} key={index}>
                    {toll.descripcion}
                  </option>
                ))}
              </select>
            )}
          />
          <small className="form-text text-danger">
            {errors.concesion && errors.concesion.message}
          </small>
        </div>
        <div className="form-group mb-3">
          <label>Tipo de pago</label>
          <Controller
            control={control}
            name="tipo_pago"
            rules={{
              required: {
                value: true,
                message: "El tipo de pago es requerido",
              },
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <select
                className="form-control"
                onChange={onChange}
                onBlur={onBlur}
                selected={value}
              >
                <option defaultValue="">Seleccione un tipo de pago</option>
                <option value="EFECTIVO">Efectivo</option>
                <option value="DEPOSITO">Deposito</option>
                <option value="TRANSFERENCIA_CALPRANDINA">
                  Transferencia a cuenta CALPRANDINA
                </option>
              </select>
            )}
          />
          <small className="form-text text-danger">
            {errors.tipo_pago && errors.tipo_pago.message}
          </small>
        </div>
        <div className="form-group mb-3">
          <label>Retención ($)</label>
          <input
            type="number"
            className="form-control"
            placeholder="Retención"
            {...register("retencion")}
            step="0.01"
            min="0.00"
            max="1000.00"
          />
        </div>
        <div className="form-group mb-3">
          <label>Total ($)</label>
          <input
            type="number"
            className="form-control"
            placeholder="Total"
            {...register("total", {
              required: { value: true, message: "La total es requerido" },
            })}
            disabled={true}
          />
          <small className="form-text text-danger">
            {errors.total && errors.total.message}
          </small>
        </div>
        {state.isLoading ? null : <Button type="submit">Guardar</Button>}
      </Form>
    </div>
  );
};
