import { useRef, useState, useEffect } from "react";

const replaceChar = (str, cursorPosition) => {
  const strAsArray = str.split('');
  strAsArray.splice(cursorPosition, 1);
  return strAsArray.join('');
};

const restoreZero = (str, cursorPosition) => {
  const cursorPositionsAllowedToDelete = [0, 1, 3, 4];

  if(!cursorPositionsAllowedToDelete.includes(cursorPosition)) {
    return str;
  }

  const strAsArray = str.split('');
  const strAsArrayWithZero = [...strAsArray.slice(0, cursorPosition), '0', ...strAsArray.slice(cursorPosition)];
  return strAsArrayWithZero.join('');
}

export const TimeInput = ({ onChange }) => {
  const [time, setTime] = useState('00:00');
  const [previousCursorPosition, setCursorPosition] = useState(0);
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.selectionStart = previousCursorPosition;
    inputRef.current.selectionEnd = previousCursorPosition;
  }, [previousCursorPosition]);

  const handleChange = (event) => {
    const value =  event.target.value;
    const selectionStart = event.target.selectionStart;
    setCursorPosition(selectionStart);

    const cursorIsGonnaAddChars = selectionStart === 6;
    const cursorIsRightBeforeColon = selectionStart === 2;
    const charWasRemoved = value.length === 4;

    if(cursorIsGonnaAddChars) {
      return;
    }

    let processedValue;

    if(charWasRemoved) {
      processedValue = restoreZero(value, selectionStart);
    }
    else {
      processedValue = replaceChar(value, selectionStart);

      if(cursorIsRightBeforeColon) {
        setCursorPosition(3);
      }
    }

    if (/^\d{2}:\d{2}$/.test(processedValue)) {
      setTime(processedValue);
      onChange(processedValue);
    }
  };

  return (
    <input
      ref={inputRef}
      className="form-control"
      type="text"
      value={time}
      onChange={handleChange}
      placeholder="hh:mm"
    />
  );
};