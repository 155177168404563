import { Modal, ModalHeader, ModalBody } from 'reactstrap';
const UserInfoModal = ({ user, modal, toggle }) => {
    return (
        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>Datos del usuario</ModalHeader>
            <ModalBody>
                <ul>
                    <li><strong>Usuario:</strong> {user.user.usuario}</li>
                    <li><strong>Correo:</strong> {user.user.correo}</li>
                    <li><strong>Identificación:</strong> {user.user.identificacion}</li>
                </ul>
            </ModalBody>
        </Modal>
    )
}

export default UserInfoModal;