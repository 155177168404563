import React from 'react';
import { Rings } from 'react-loader-spinner';
import './theLoader.css';


const TheLoader = () => {
    return (
        <div className="the-loader-container">
            <div className="the-loader">
                <Rings
                    type="Rings"
                    color="#ffe66d"
                    height={100}
                    width={100}
                />
            </div>
        </div>
    );
};

export default TheLoader;