import React, { Component } from 'react';
import { ToastContainer } from "react-toastify";
import logoTag from '../../assets/logo-sin-fondo-tag-express.png';
import { getDate, notifyInfo } from '../../utils/utils';
import { s_getRegisterDeliveryNotification } from '../../services/registerDeliveryCtl'

class ClockDashboard extends Component {

  constructor(props) {
    super(props);
    this.state = { digitalClock: getDate(true).split(" ") };
    this.intervalId = null;
  }

  componentDidMount() {
    this.notification()
    this.intervalId = setInterval(() => {
      this.setState({ digitalClock: getDate(true).split(" ") })
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  async notification() {
    const resp = await s_getRegisterDeliveryNotification()
    if (resp.message !== null) {
      notifyInfo(resp.message)
    }
  }

  render() {
    return (
      <>
        <ToastContainer style={{ width: "400px" }} />
        <div className="logo-dashboard-wrapper">
          <div className="logo-dashboard">
            <img src={logoTag} alt="logo" />
            <h1 className="digital-clock">{this.state.digitalClock[1]}</h1>
          </div>
        </div>
      </>
    );
  }
}

export default ClockDashboard;
