import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Label } from "reactstrap";
import { s_updateTypeAssistance } from "../../../../../services/typeAssistanceCtl";
import { notifyError, notifyOk } from "../../../../../utils/utils";

const EditTypeAssistance = ({ onClose, tipo }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [state, setState] = useState({
    isLoading: false,
    mainError: false,
  });
  const onSubmit = async (data, e) => {
    try {
      setState((old) => ({ ...old, isLoading: true }));
      await s_updateTypeAssistance(data, tipo.tipo_asistencia_id);
      notifyOk("Tipo de Asistencia se actualizo de manera correcta", {
        autoClose: 3000,
      });
      setState((old) => ({ ...old, isLoading: false }));
      onClose();
    } catch (error) {
      setState((old) => ({ ...old, isLoading: false }));
      if (error.response.status === 409) {
        notifyError(error.response.data.message);
        return;
      }
      notifyError(
        "Ocurrió un error al actualizar el tipo, por favor inténtelo más tarde."
      );
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group mb-3">
        <Label>Nombre: (*)</Label>
        <input
          className="form-control"
          placeholder="Escriba un nombre"
          {...register("nombre", {
            required: {
              value: true,
              message: "El nombre es requerido",
            },
          })}
          defaultValue={tipo.nombre}
        />
        <small className="form-text text-danger">
          {errors.nombre && errors.nombre.message}
        </small>
      </div>
      <div className="form-group mb-3">
        <Label>Tipo: (*)</Label>
        <input
          className="form-control"
          placeholder="Escriba un tipo"
          {...register("tipo", {
            required: {
              value: true,
              message: "El tipo es requerido",
            },
          })}
          defaultValue={tipo.tipo}
        />
        <small className="form-text text-danger">
          {errors.tipo && errors.tipo.message}
        </small>
      </div>
      <div className="form-group mb-3">
        <Label>Descripción:</Label>
        <textarea
          rows="3"
          className="form-control"
          placeholder="Escriba una descripción"
          {...register("descripcion")}
          defaultValue={tipo.descripcion}
        />
      </div>
      <div className="form-group mb-3">
        <label>Estado:</label>
        <select
          {...register("estado")}
          defaultValue={tipo.estado}
          className="form-control"
        >
          <option value="">Seleccione un estado</option>
          <option value="A">Activo</option>
          <option value="I">Inactivo</option>
        </select>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {state.isLoading ? null : (
          <Button className="btn-tagexpress" type="submit">
            Guardar
          </Button>
        )}
      </div>
    </Form>
  );
};

export default EditTypeAssistance;
