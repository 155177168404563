import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ToastContainer } from "react-toastify";
import { Button, Form } from "reactstrap";
import { s_setReportedProduct } from "../../../../../services/reportedProduct";
import { api } from "../../../../../network";
import { headers, notifyError, notifyOk } from "../../../../../utils/utils";

export const NewReportedProduct = ({ setShowModalReportedProduct }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [data, setData] = useState({
    products: [],
    reload: false,
    loading: true,
  });

  const [state, setState] = useState({
    isLoading: false,
    mainError: false,
  });

  const getProduct = useCallback(async () => {
    try {
      setData((old) => ({ ...old, loading: true }));
      const response = await api.get("/v1/productos", {
        headers: headers(), // ask only for active personas
      });

      if (response && response.status !== 200) {
        notifyError("ERROR");
        return;
      }

      setData((old) => ({
        ...old,
        loading: false,
        products: response.data.data,
      }));
    } catch (error) {
      if (error.response.status >= 500) {
        notifyError("ERROR");
        return;
      }
      notifyError(error.response.data.message, { autoClose: 3000 });
    }
  }, []);

  const onSubmit = async (data) => {
    try {
      setState((old) => ({ ...old, isLoading: true }));
      const form = new FormData();
      form.append("photo", data.photo[0]);
      form.append("product", data.product);
      form.append("quantitty", data.quantitty);
      form.append("numeration", data.numeration);
      form.append("description", data.description);
      const response = await s_setReportedProduct(form);
      notifyOk('Producto reportado de manera correcta', { autoClose: 3000 });
      setState((old) => ({ ...old, isLoading: false }));
      setShowModalReportedProduct(false);
      notifyOk(response.message, { autoClose: 3000 });
    } catch (error) {
      setState((old) => ({ ...old, isLoading: false }));
      notifyError(
        "Ocurrió un error al aprobar el registro, por favor inténtelo más tarde."
      );
    }
  };

  useEffect(() => {
    getProduct();
  }, [getProduct]);

  return (
    <>
      <ToastContainer />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group mb-3">
          <label>cantidad</label>
          <input
            type="number"
            className="form-control"
            placeholder="Cantidad"
            {...register("quantitty", {
              required: { value: true, message: "La cantidad es requerida" },
            })}
          />

          <small className="form-text text-danger">
            {errors.numeration && errors.numeration.message}
          </small>
        </div>
        <div className="form-group mb-3">
          <label>Producto</label>
          <select
            className="form-control"
            placeholder="Descripción"
            {...register("product", {
              required: { value: true, message: "El producto es requerido" },
            })}
          >
            <option value="">Seleccione...</option>
            {data.products.map((product) => (
              <option
                value={`${product.nombre_producto}`}
                key={product.id_producto}
              >
                {product.nombre_producto}
              </option>
            ))}
          </select>
          <small className="form-text text-danger">
            {errors.product && errors.product.message}
          </small>
        </div>
        <div className="form-group mb-3">
          <label>Foto</label>
          <input
            type="file"
            className="form-control"
            placeholder="Foto"
            {...register("photo", {
              maxLength: { value: 20, message: "Maximo de letras 120" },
            })}
          />

          <small className="form-text text-danger">
            {errors.photo && errors.photo.message}
          </small>
        </div>
        <div className="form-group mb-3">
          <label>Numeración</label>
          <input
            type="text"
            className="form-control"
            placeholder="Numeración"
            {...register("numeration", {
              maxLength: { value: 20, message: "Maximo de letras 120" },
            })}
          />

          <small className="form-text text-danger">
            {errors.numeration && errors.numeration.message}
          </small>
        </div>
        <div className="form-group mb-3">
          <label>Descripción</label>
          <textarea
            className="form-control"
            placeholder="Descripción"
            {...register("description", {
              maxLength: { value: 120, message: "Maximo de letras 120" },
            })}
            rows="3"
          ></textarea>

          <small className="form-text text-danger">
            {errors.description && errors.description.message}
          </small>
        </div>
        {state.isLoading ? null : (
          <Button type="submit" className="btn-tagexpress">
            Guardar
          </Button>
        )}
      </Form>
    </>
  );
};
