import { headers } from "../utils/utils";
import { api } from "../network";

async function getRoadSupportVehicles(params) {
  const response = await api.get("/v1/asistVial/vehiculos?status=A", {
    headers: headers(),
  });

  return response.data.data;
}

async function s_getAsistVial(params) {
  const response = await api.get("/v1/asistVial", {
    headers: headers(),
    params
  });

  return {
    data: response.data.data,
    time: response.data.dataTime,
  };
}

async function s_postAcceptAsistVial(data) {
  const response = await api.post(`/v1/asistVial/accept`, data, {
    headers: headers(),
  });

  return response.data;
}

async function s_postCreateAsistVial(data) {
  const response = await api.post(`/v1/asistVial/create`, data, {
    headers: headers(),
  });

  return response.data;
}

async function s_postCreateAsistVialCau(data) {
    const response = await api.post(`/v1/asistVial/cau`, data, {
      headers: headers(),
    });

    return response.data;
}

async function s_updateCreateAsistVial(data) {
  const response = await api.post(`/v1/asistVial/update`, data, {
    headers: headers(),
  });

  return response.data;
}

async function s_updateNewAsist(data) {
  const response = await api.put(`/v1/asistVial/update/new-asist`, data, {
    headers: headers(),
  });
  return response.data;

}

async function s_updateEndAsistVial(data) {
  const response = await api.put(`/v1/asistVial/end`, data, {
    headers: headers(),
  });

  return response.data;
}

export {
  getRoadSupportVehicles,
  s_getAsistVial,
  s_postAcceptAsistVial,
  s_postCreateAsistVial,
  s_updateCreateAsistVial,
  s_updateEndAsistVial,
  s_postCreateAsistVialCau,
  s_updateNewAsist
};
