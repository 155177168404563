import dayjs from "dayjs";
import { Button, Col, Form, Row } from "reactstrap";
import ClearFilter from "../../../../../assets/ic_filter.svg";
import Excel from "../../../../../assets/Excel-Logo.png";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { s_getVehicle } from "../../../../../services/vehicle.Ctl";
import { notifyError } from "../../../../../utils/utils";

import "../reporteAsist.css";

function FilterForm({
  onSubmit,
  toggleExport,
  assistanceVehicle,
  getAssistance,
}) {
  const { register, handleSubmit, reset } = useForm();
  const [data, setData] = useState({
    vehicles: [],
    reload: false,
    loading: true,
  });

  const _getVehicle = async () => {
    try {
      setData((old) => ({
        ...old,
        loading: true,
      }));
      const resp = await s_getVehicle();
      setData((old) => ({
        ...old,
        vehicles: resp,
        loading: false,
      }));
    } catch (error) {
      notifyError("ERROR");
    }
  };

  const clearFilter = () => {
    reset();
    getAssistance();
    setData((old) => ({
      ...old,
      assistanceVehicle: [],
      status: "PROCESSING",
    }));
  };

  useEffect(() => {
    _getVehicle();
  }, [data.reload]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col md={2}>
          <div className="form-group mb-3">
            <label>Desde:</label>
            <input
              type="date"
              className="form-control"
              placeholder="Fecha de inicio"
              {...register("start_date")}
              defaultValue={dayjs(new Date()).format("YYYY-MM-DD")}
            />
          </div>
        </Col>
        <Col md={2}>
          <div className="form-group mb-3">
            <label>Hasta:</label>
            <input
              type="date"
              className="form-control"
              placeholder="Fecha de inicio"
              {...register("end_date")}
              defaultValue={dayjs(new Date()).format("YYYY-MM-DD")}
            />
          </div>
        </Col>
        <Col md={2}>
          <div className="form-group mb-3">
            <label>Estado:</label>
            <select
              {...register("status")}
              defaultValue={""}
              className="form-control"
            >
              <option value="">Seleccione un estado</option>
              <option value="canceled">Cancelado</option>
              <option value="terminated">Finalizado</option>
              <option value="in_process">En proceso</option>
            </select>
          </div>
        </Col>
        <Col md={2}>
          <div className="form-group mb-3">
            <label>Vehículo:</label>
            <select
              {...register("vehicle")}
              defaultValue={""}
              className="form-control"
            >
              <option value="">Seleccione un vehículo</option>
              {data.vehicles.map((vehicle, index) => (
                <option value={`${vehicle.vehiculo_asistencia_id}`} key={index}>
                  {`${vehicle.categoria_vehiculos_peaje.categoria_vehiculo_peaje}  ${vehicle.vehiculo_asistencium.placa}`}
                </option>
              ))}
            </select>
          </div>
        </Col>
        <Col md={2}>
          <div className="form-group mb-3">
            <label>Categoría:</label>
            <select
              {...register("type")}
              defaultValue={""}
              className="form-control"
            >
              <option value="">Seleccione una categoría</option>
              <option value="AMBULANCIA">Ambulancia</option>
              <option value="GRÚA">Grúa</option>
            </select>
          </div>
        </Col>
        <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
          <div>
            <p className="font-weight-lighter">
              * El rango de fecha se le aplicará al registro de una asistencia
            </p>
          </div>
          <div className="d-flex">
            <Button
              type="button"
              color="light"
              className="mx-2 d-flex align-items-center justify-content-center"
              onClick={clearFilter}
            >
              <img
                src={ClearFilter}
                width="20px"
                style={{ marginRight: 10 }}
                alt=""
              />
              <label className="d-none d-lg-block">Limpiar filtro</label>
            </Button>
            <Button color="success">Buscar</Button>
            {assistanceVehicle.length !== 0 ? (
              <Button
                type="button"
                color="light"
                className="mx-2"
                onClick={() => {
                  toggleExport();
                }}
              >
                <img
                  src={Excel}
                  width="60px"
                  style={{ marginRight: 10 }}
                  alt=""
                />
              </Button>
            ) : null}
          </div>
        </div>
      </Row>
    </Form>
  );
}
export default FilterForm;
