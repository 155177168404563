import React, { useState, useEffect, useCallback } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Badge,
  Table,
} from "reactstrap";
import { api } from "../../../../network";
import TheLoader from "../../../elements/theLoader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./peajes.css";
import { headers } from "../../../../utils/utils";
import { notifyError, notifyOk } from "../../../../utils/utils";
import { PlusSquare } from "react-feather";
import ReactTooltip from "react-tooltip";
import { s_getProvince } from "../../../../services/peajesCtl";
import { Edit } from "react-feather";

const PeajesView = ({ pageName }) => {
  const [peajeToUpdate, setPeajeToUpdate] = useState({});
  const [peajes, setPeajes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [descripcion, setDescripcion] = useState("");
  const [provincias, setProvincias] = useState([]);
  const [provincia, setProvincia] = useState("");

  const [createSidebar, setCreateSidebar] = useState(false);
  const toggleCreateSidebar = () => {
    _clearForm();
    setCreateSidebar(!createSidebar);
  };

  const [updateSidebar, setUpdateSidebar] = useState(false);
  const toggleUpdateSidebar = () => {
    setUpdateSidebar(!updateSidebar);
  };

  const _getPeajes = useCallback(async () => {
    try {
      const response = await api.get("/v1/peajes", {
        headers: headers(),
      });

      if (response && response.status !== 200) {
        notifyError("ERROR");
        return;
      }

      setPeajes(response.data.data);
    } catch (e) {}
  }, []);

  const _getProvince = useCallback(async () => {
    const response = await s_getProvince();
    if (!response && response.status !== 200) {
      return notifyError("Error, por favor recargue la página");
    }
    setProvincias(response);
  }, []);

  function _clearForm() {
    setPeajeToUpdate({});
    setDescripcion("");
    setProvincia("");
  }

  async function _handleCreatePeaje(e) {
    e.preventDefault();

    if (provincia.trim() === "") {
      notifyError("Llene todos los campos");
      return;
    }

    const data = {
      descripcion: descripcion,
      id_provincia: provincia,
    };

    setIsLoading(true);

    try {
      const response = await api.post("/v1/peajes/create", data, {
        headers: headers(),
      });

      if (response) {
        if (response.data.status === "OK") {
          notifyOk("Peaje creado");
          _getPeajes();
          toggleCreateSidebar();

          // reset form
          _clearForm();
        } else {
          notifyError("Error al crear peaje");
        }
      } else {
        notifyError("No hay conexión con el servidor");
      }
    } catch (e) {}

    setIsLoading(false);
  }

  async function _handleUpdatePeaje(e) {
    e.preventDefault();

    if (descripcion.trim() === "") {
      notifyError("Llene todos los campos");
      return;
    }

    const data = {
      peaje_id: peajeToUpdate.peaje_id,
      descripcion,
      id_provincia: provincia,
    };

    setIsLoading(true);

    try {
      const response = await api.put("/v1/peajes/update", data, {
        headers: headers(),
      });

      if (response) {
        if (response.data.status === "OK") {
          notifyOk("Peaje modificado");
          _getPeajes();
          toggleUpdateSidebar();

          // reset form
          _clearForm();
        } else {
          notifyError("Error al modificar peaje");
        }
      } else {
        notifyError("No hay conexión con el servidor");
      }
    } catch (e) {}

    setIsLoading(false);
  }

  async function _handleDeletePeaje(e) {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await api.delete(
        "/v1/peajes/delete/" + peajeToUpdate.peaje_id,
        { headers: headers() }
      );

      if (response) {
        if (response.data.status === "OK") {
          if (peajeToUpdate.estado === "A") {
            notifyOk("Peaje deshabilitado");
          } else {
            notifyOk("Peaje habilitado");
          }

          _getPeajes();
          toggleUpdateSidebar();
          _clearForm();
        } else {
          notifyError("Error al eliminar peajes");
        }
      } else {
        notifyError("No hay conexión con el servidor");
      }
    } catch (e) {}

    setIsLoading(false);
  }

  function _handleRowClick(peaje) {
    _getProvince();
    setPeajeToUpdate((prevPeajeToUpdate) => {
      return {
        ...prevPeajeToUpdate,
        ...peaje,
      };
    });

    setDescripcion(peaje.descripcion);
    setProvincia(peaje.provincium.id_provincia);
    toggleUpdateSidebar();
  }

  function formCreate() {
    return (
      <div>
        <Form onSubmit={_handleCreatePeaje}>
          <FormGroup>
            <Label>Peaje</Label>
            <Input
              type="text"
              value={descripcion}
              maxLength={50}
              onChange={(e) => {
                setDescripcion(e.target.value.toUpperCase());
              }}
            />
            <Label className="mt-2">Provincia</Label>

            <Input
              type="select"
              value={provincia}
              onChange={(e) => {
                setProvincia(e.target.value);
              }}
            >
              <option value={0}>Seleccione...</option>
              {provincias.map((provincia, index) => {
                return (
                  <>
                    {provincia.estado === "A" ? (
                      <option
                        key={provincia.id_provincia}
                        value={provincia.id_provincia}
                      >
                        {provincia.nombre_provincia}
                      </option>
                    ) : null}
                  </>
                );
              })}
            </Input>
          </FormGroup>
          <Button className="btn-tagexpress" onClick={_handleCreatePeaje}>
            Crear
          </Button>
        </Form>
      </div>
    );
  }

  function formUpdate() {
    return (
      <div>
        <Form onSubmit={_handleUpdatePeaje}>
          <FormGroup>
            <h3>{peajeToUpdate.descripcion}</h3>
            <Label>Descripción</Label>
            <Input
              type="text"
              value={descripcion}
              maxLength={50}
              onChange={(e) => {
                setDescripcion(e.target.value.toUpperCase());
              }}
            />

            <Label>Provincia</Label>
            <Input
              type="select"
              value={provincia}
              onChange={(e) => {
                setProvincia(e.target.value);
              }}
            >
              {provincias.map((provincia, index) => {
                return (
                  <>
                    {provincia.estado === "A" ? (
                      <option
                        key={provincia.id_provincia}
                        value={provincia.id_provincia}
                      >
                        {provincia.nombre_provincia}
                      </option>
                    ) : null}
                  </>
                );
              })}
            </Input>
          </FormGroup>
          <div
            style={{
              display: "flex",
              gap: 10,
            }}
          >
            <Button className="btn-tagexpress" onClick={_handleUpdatePeaje}>
              Modificar
            </Button>
            {peajeToUpdate.estado === "A" ? (
              <Button
                className="btn-tagexpress-danger"
                onClick={_handleDeletePeaje}
              >
                Deshabilitar
              </Button>
            ) : (
              <Button
                className="btn-tagexpress-success"
                onClick={_handleDeletePeaje}
              >
                Habilitar
              </Button>
            )}
          </div>
        </Form>
      </div>
    );
  }

  // Get data on first render
  useEffect(() => {
    _getPeajes();
    _getProvince();
  }, [_getPeajes, _getProvince]);

  return (
    <div>
      {isLoading ? <TheLoader /> : null}
      <ToastContainer style={{ width: "400px" }} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        className="mb-4"
      >
        <h3>{pageName}</h3>
        <div>
          <Button
            onClick={toggleCreateSidebar}
            className="btn-tagexpress"
            data-tip="Crear nuevo peaje"
          >
            <PlusSquare />
          </Button>
          <ReactTooltip type="light" />
        </div>
      </div>
      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggleCreateSidebar}
        isOpen={createSidebar}
      >
        <OffcanvasHeader toggle={toggleCreateSidebar}>
          Crear nuevo peaje
        </OffcanvasHeader>
        <OffcanvasBody>{formCreate()}</OffcanvasBody>
      </Offcanvas>
      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggleUpdateSidebar}
        isOpen={updateSidebar}
      >
        <OffcanvasHeader toggle={toggleUpdateSidebar}>
          Actualizar peaje
        </OffcanvasHeader>
        <OffcanvasBody>{formUpdate()}</OffcanvasBody>
      </Offcanvas>
      <Row className="mt-2">
        <Table responsive>
          <thead className="tableHeader">
            <tr>
              <td>ID</td>
              <td>Descripción</td>
              <td>Provincia</td>
              <td>Estado</td>
              <td>Acciones</td>
            </tr>
          </thead>
          <tbody>
            {peajes.map((peaje, index) => (
              <tr key={peaje.peaje_id}>
                <td>{index}</td>
                <td>{peaje.descripcion}</td>
                <td>{peaje.provincium.nombre_provincia}</td>
                <td>
                  {peaje.estado === "A" ? (
                    <Badge color="success">Activo</Badge>
                  ) : (
                    <Badge color="warning">Inactivo</Badge>
                  )}
                </td>
                <td style={{ cursor: "pointer" }}>
                  <Edit onClick={() => _handleRowClick(peaje)} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
    </div>
  );
};

export default PeajesView;
