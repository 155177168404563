import React, { useState, useEffect, useCallback } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Table,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Badge,
} from "reactstrap";
import { api } from "../../../../network";
import TheLoader from "../../../elements/theLoader";
import { ToastContainer } from "react-toastify";
import { notifyError, notifyOk } from "../../../../utils/utils";
import "react-toastify/dist/ReactToastify.css";
import "./roles.css";
import { headers } from "../../../../utils/utils";
import { Edit, PlusSquare } from "react-feather";
import ReactTooltip from "react-tooltip";

const RoleView = ({ pageName }) => {
  const [roleToUpdate, setRoleToUpdate] = useState({});
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [descripcion, setDescripcion] = useState("");

  const [createSidebar, setCreateSidebar] = useState(false);
  const toggleCreateSidebar = () => {
    setRoleToUpdate({});
    setDescripcion("");
    setCreateSidebar(!createSidebar);
  };

  const [updateSidebar, setUpdateSidebar] = useState(false);
  const toggleUpdateSidebar = () => {
    setUpdateSidebar(!updateSidebar);
  };

  const _getRoles = useCallback(async () => {
    try {
      const response = await api.get("/v1/roles", {
        headers: headers(),
      });

      if (response && response.status !== 200) {
        notifyError("ERROR");
        return;
      }

      setRoles(response.data.data);
    } catch (e) {
      // console.log(e);
    }
  }, []);

  async function _handleCreateRole(e) {
    e.preventDefault();

    if (descripcion === 0) {
      notifyError("Llene todos los campos");
      return;
    }
    const data = {
      descripcion,
    };
    setIsLoading(true);
    try {
      const response = await api.post("/v1/roles/create", data, {
        headers: headers(),
      });

      if (response) {
        if (response.data.status === "OK") {
          notifyOk("Rol creado");
          _getRoles();
          toggleCreateSidebar();

          // reset form
          setDescripcion("");
        } else {
          notifyError("Error al crear rol");
        }
      } else {
        notifyError("No hay conexión con el servidor");
      }
    } catch (error) {}

    setIsLoading(false);
  }

  async function _handleUpdateRole(e) {
    e.preventDefault();

    const data = {
      rol_id: roleToUpdate.rol_id,
      descripcion,
    };
    setIsLoading(true);
    try {
      const response = await api.put("/v1/roles/update", data, {
        headers: headers(),
      });
      if (response) {
        if (response.data.status === "OK") {
          notifyOk("Rol modificado");
          _getRoles();
          setUpdateSidebar();
          // reset form
          setDescripcion("");
        } else {
          notifyError("Error al modificar rol");
        }
      } else {
        notifyError("No hay conexión con el servidor");
      }
    } catch (error) {}

    setIsLoading(false);
  }

  async function _handleDeleteRole(e) {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await api.delete(
        "/v1/roles/delete/" + roleToUpdate.rol_id,
        { headers: headers() }
      );

      if (response) {
        if (response.data.status === "OK") {
          if (roleToUpdate.estado === "A") {
            notifyOk("Rol deshabilitado");
          } else {
            notifyOk("Rol habilitado");
          }

          _getRoles();
          setUpdateSidebar();

          // reset form
          setDescripcion("");
        } else {
          notifyError("Error al eliminar rol");
        }
      } else {
        notifyError("No hay conexión con el servidor");
      }
    } catch (error) {
      // console.log(error)
    }

    setIsLoading(false);
  }

  function _handleRowClick(role) {
    setRoleToUpdate((prevRoleToUpdate) => {
      return {
        ...prevRoleToUpdate,
        ...role,
      };
    });
    setDescripcion(role.descripcion);
    toggleUpdateSidebar();
  }

  function formCreate() {
    return (
      <Form onSubmit={_handleCreateRole}>
        <FormGroup>
          <Label>Descripcion</Label>
          <Input
            type="text"
            value={descripcion}
            onChange={(e) => {
              setDescripcion(e.target.value);
            }}
          />
        </FormGroup>
        <Button className="btn-tagexpress" onClick={_handleCreateRole}>
          Crear
        </Button>
      </Form>
    );
  }

  function formUpdate() {
    return (
      <div>
        <Form onSubmit={_handleUpdateRole}>
          <FormGroup>
            <h3>{roleToUpdate.descripcion}</h3>

            <Label>Descripcion</Label>
            <Input
              type="text"
              value={descripcion}
              onChange={(e) => {
                setDescripcion(e.target.value);
              }}
            />
          </FormGroup>
          <div
            style={{
              display: "flex",
              gap: 10,
            }}
          >
            <Button className="btn-tagexpress" onClick={_handleUpdateRole}>
              Modificar
            </Button>
            {roleToUpdate.estado === "A" ? (
              <Button
                className="btn-tagexpress-danger"
                onClick={_handleDeleteRole}
              >
                Deshabilitar
              </Button>
            ) : (
              <Button
                className="btn-tagexpress-success"
                onClick={_handleDeleteRole}
              >
                Habilitar
              </Button>
            )}
          </div>
        </Form>
      </div>
    );
  }

  // Get data on first render
  useEffect(() => {
    _getRoles();
  }, [_getRoles]);

  return (
    <div>
      {isLoading ? <TheLoader /> : null}
      <ToastContainer style={{ width: "400px" }} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        className="mb-4"
      >
        <h3>{pageName}</h3>
        <div>
          <Button
            onClick={toggleCreateSidebar}
            className="btn-tagexpress"
            data-tip="Crear nuevo rol"
          >
            <PlusSquare />
          </Button>
          <ReactTooltip type="light" />
        </div>
      </div>
      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggleCreateSidebar}
        isOpen={createSidebar}
      >
        <OffcanvasHeader toggle={toggleCreateSidebar}>
          Crear nuevo rol
        </OffcanvasHeader>
        <OffcanvasBody>{formCreate()}</OffcanvasBody>
      </Offcanvas>
      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggleUpdateSidebar}
        isOpen={updateSidebar}
      >
        <OffcanvasHeader toggle={toggleUpdateSidebar}>
          Actualizar rol
        </OffcanvasHeader>
        <OffcanvasBody>{formUpdate()}</OffcanvasBody>
      </Offcanvas>
      <Table responsive className="table-container">
        <thead className="tableHeader">
          <tr>
            <td>ID</td>
            <td>Descripción</td>
            <td>Estado</td>
            <td>Acciones</td>
          </tr>
        </thead>
        <tbody>
          {roles.map((role, index) => (
            <tr key={role.rol_id}>
              <td>{index + 1}</td>
              <td>{role.descripcion}</td>
              <td>
                {role.estado === "A" ? (
                  <Badge color="success">Activo</Badge>
                ) : (
                  <Badge color="warning">Inactivo</Badge>
                )}
              </td>
              <td>
                <Edit onClick={() => _handleRowClick(role)} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default RoleView;
