import React, { useState, useEffect, useCallback } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Input,
  CardHeader,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Pagination,
  PaginationItem,
  PaginationLink,
  UncontrolledTooltip,
  Table,
} from "reactstrap";

import { api } from "../../../network";
import TheLoader from "../../elements/theLoader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./mantenimientos.css";

import ConfirmModal from "../../../utils/components/ConfirmModal";
import { Edit, PlusSquare, Trash } from "react-feather";
import {
  notifyOk,
  notifyError,
  notifyInfo,
  headers,
  getDate,
  excelFilterRep,
} from "../../../utils/utils";
import dayjs from "dayjs";
import XLSX from "xlsx";
import IconExcel from "../../../assets/ic_excel.svg";
import ModalDetalleMantenimiento from "./modalDetalle";

export const generateSequentialArray = (limit) => {
  return new Array(limit).fill(null).map((_, i) => i);
};

const PAGE_SIZE = 15;
const MantenimientosView = ({ pageName }) => {
  const [vehicleForFiltering, setVehicleForFiltering] = useState(null);
  const [vehicleForMaintenance, setVehicleForMaintenance] = useState(null);
  const [mantenimientoToUpdate, setMantenimientoToUpdate] = useState(null);
  const [mantenimientoToDelete, setMantenimientoToDelete] = useState(null);
  const [mantenimientos, setMantenimientos] = useState([]);
  const [nearestMant, setNearestMant] = useState(null);
  const [vehiculos, setVehiculos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [descripcion, setDescripcion] = useState("");
  const [kilometraje, setKilometraje] = useState("");
  const [conclusion, setConclusion] = useState("");
  const [taller_encargado, setTaller_encargado] = useState("");
  const [mecanico_encargado, setMecanico_encargado] = useState("");
  const [telefono_mecanico, setTelefono_mecanico] = useState("");
  const [costo, setCosto] = useState("0.00");
  const [fecha_inicio_planeada, setFecha_inicio_planeada] = useState(
    getDate(false)
  );
  const [fecha_fin_planeada, setFecha_fin_planeada] = useState(getDate(false));
  const [fecha_inicio_real, setFecha_inicio_real] = useState(getDate(false));
  const [fecha_fin_real, setFecha_fin_real] = useState(getDate(false));
  const [vehiculo_asistencia_id, setVehiculo_asistencia_id] = useState(0);
  const [fechaInicio, setFechaInicio] = useState(getDate(false));
  const [fechaFin, setFechaFin] = useState(
    dayjs(getDate(false)).add(7, "days").format("YYYY-MM-DD")
  );
  const [confirm, setConfirm] = useState(false);

  const [detailsMante, setDetailsMante] = useState(false);
  const toggleDetailsMante = () => {
    _clearForm();
    setDetailsMante(!detailsMante);
  };
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);

  const toggleConfirm = () => setConfirm(!confirm);

  const _getVehiculos = useCallback(async () => {
    try {
      const response = await api.get("/v1/asistVial/vehiculos", {
        headers: headers(),
      });

      if (response && response.status !== 200) {
        notifyError("No se pudo cargar los vehículos");
        return;
      }

      setVehiculos(response.data.data);
    } catch (e) {}
  }, []);

  const fechaSplit = (fecha) => {
    return fecha.split(" ")[0];
  };

  async function _getExportMantenimientos(vehiculoId) {
    try {
      const resp = await api.get(
        `/v1/asistVial/mantenimientos${
          vehiculoId ? `/${vehiculoId}` : ""
        }?size=${totalItems}&page=0`,
        {
          params: {
            fechaInicio,
            fechaFin,
          },
          headers: headers(),
        }
      );

      if (resp && resp.status !== 200) {
        notifyError("Hubo un error al obtener datos de mantenimientos");
      }
      const mantainenceExcelExport = resp.data.response.rows.map(
        (mantainence) => {
          let dataToExport = {
            ...mantainence,
            ...mantainence.vehiculo_asistencium,
          };
          return dataToExport;
        }
      );
      return mantainenceExcelExport;
    } catch (e) {}
  }

  async function _getMantenimientos(vehiculoId, page) {
    try {
      const resp = await api.get(
        `/v1/asistVial/mantenimientos/${
          vehiculoId ? `${vehiculoId}/` : ""
        }?size=${PAGE_SIZE}&page=${page}`,
        {
          params: {
            fechaInicio,
            fechaFin,
          },
          headers: headers(),
        }
      );

      if (resp && resp.status !== 200) {
        notifyError("Hubo un error al obtener datos de mantenimientos");
      }

      const now = new Date();
      let nearest = null;
      let tmpTime;
      let lastClosestTime = new Date("2100-01-01T00:00:00.000Z");
      if (page === 0) {
        resp.data.response.rows.forEach((m, i) => {
          // Calculate nearest mantenimiento
          tmpTime = new Date(m.fecha_inicio_planeada) - now;
          if (tmpTime >= 0 && tmpTime < lastClosestTime) {
            nearest = i; // We need the index, nothing else
            lastClosestTime = tmpTime;
          }

          // Convert to local time
          m.fecha_inicio_planeada = fechaSplit(m.fecha_inicio_planeada);
          m.fecha_fin_planeada = fechaSplit(m.fecha_fin_planeada);
          m.fecha_inicio_real = fechaSplit(m.fecha_inicio_real);
          m.fecha_fin_real = fechaSplit(m.fecha_fin_real);
        });
      }
      setNearestMant(nearest);
      setTotalPages(resp.data.response.totalPages);
      setCurrentPage(resp.data.response.currentPage);
      setTotalItems(resp.data.response.totalItems);
      setMantenimientos(resp.data.response.rows);
      return true;
    } catch (e) {}
    return false;
  }

  function _clearForm() {
    setVehicleForMaintenance(null);
    setMantenimientoToUpdate(null);
    setDescripcion("");
    setKilometraje("");
    setConclusion("");
    setTaller_encargado("");
    setMecanico_encargado("");
    setTelefono_mecanico("");
    setCosto("0.00");
    setFecha_inicio_planeada(getDate(false));
    setFecha_fin_planeada(getDate(false));
    setFecha_inicio_real(getDate(false));
    setFecha_fin_real(getDate(false));
    setVehiculo_asistencia_id(0);
    setNearestMant(nearestMant);
  }

  function notificarFechaIncorrecta(e) {
    if (e.response) {
      const errorData = e.response.data.data;
      if (errorData === "FECHAS_PLANEADAS_INVALIDAS") {
        notifyError("Las fechas planeadas no se pueden modificar", {
          autoClose: 4000,
        });
      }
      if (errorData === "FECHAS_INVALIDAS_MENOR_A_FECHA_AHORA") {
        notifyError("La fecha ingresada no puede ser menor a la fecha de hoy", {
          autoClose: 4000,
        });
      }
      if (errorData === "CASO_FINALIZADO") {
        notifyError("Caso ha finalizado. No se puede modificar.", {
          autoClose: 4000,
        });
      }
    }
  }

  function _formIsValid() {
    if (
      !vehicleForMaintenance ||
      !descripcion ||
      !kilometraje ||
      !taller_encargado ||
      !mecanico_encargado ||
      !costo
    ) {
      notifyError("Todos los campos son obligatorios");
      return false;
    }
    return true;
  }

  const data = {
    vehiculo_asistencia_id: vehicleForMaintenance && vehicleForMaintenance,
    descripcion,
    kilometraje,
    conclusion,
    taller_encargado,
    mecanico_encargado,
    telefono_mecanico,
    costo,
    fecha_inicio_planeada,
    fecha_fin_planeada,
    fecha_inicio_real,
    fecha_fin_real,
  };

  async function _handleMantenimientoOperations(e) {
    e.preventDefault();

    if (!_formIsValid()) {
      return;
    }

    setIsLoading(true);

    try {
      let response;
      let processType = "create";
      // Create
      if (vehiculo_asistencia_id === 0) {
        response = await api.post(
          "/v1/asistVial/mantenimientos/create",
          { ...data, mantenimiento_id: 0 },
          { headers: headers() }
        );
      }
      // Update
      else {
        processType = "update";
        response = await api.put(
          "/v1/asistVial/mantenimientos/update/" +
            mantenimientoToUpdate.mantenimiento_id,
          { ...data, mantenimiento_id: mantenimientoToUpdate.mantenimiento_id },
          { headers: headers() }
        );
      }

      if (response) {
        if (response.data.status === "OK") {
          if (processType === "create") {
            notifyOk("Mantenimiento creado");
          } else {
            notifyOk("Mantenimiento actualizado");
          }

          await _getMantenimientos(vehicleForFiltering, currentPage);
          setDetailsMante(false);

          // reset form
          _clearForm();
        } else {
          if (processType === "create") {
            notifyError("Error al crear mantenimiento");
          } else {
            notifyError("Error al actualizar mantenimiento");
          }
        }
      } else {
        notifyInfo("No hay conexión con el servidor");
      }
    } catch (e) {
      notificarFechaIncorrecta(e);
    }

    setIsLoading(false);
  }

  async function _handleDeleteClick(mant) {
    setMantenimientoToDelete(mant);
    setConfirm(!confirm);
  }

  async function _handleDeleteMantenimiento() {
    const mant = mantenimientoToDelete;
    setIsLoading(true);

    try {
      const response = await api.delete(
        "/v1/asistVial/mantenimientos/delete/" + mant.mantenimiento_id,
        { headers: headers() }
      );

      if (response) {
        if (response.data.status === "OK") {
          notifyOk("Mantenimiento eliminado", { autoClose: 2000 });
          _getMantenimientos(vehicleForFiltering, currentPage);
          setConfirm(false); // Hide the modal manually
        } else {
          notifyError("Error al eliminar mantenimiento", { autoClose: 2000 });
        }
      } else {
        notifyInfo("No hay conexión con el servidor");
      }
    } catch (e) {}

    setIsLoading(false);
  }

  function _showModalDetalle(mant) {
    setMantenimientoToUpdate({ ...mant });
    setVehicleForMaintenance(mant.vehiculo_asistencia_id);
    setDescripcion(mant.descripcion);
    setKilometraje(mant.kilometraje);
    setConclusion(mant.conclusion);
    setTaller_encargado(mant.taller_encargado);
    setMecanico_encargado(mant.mecanico_encargado);
    setTelefono_mecanico(mant.telefono_mecanico);
    setCosto(mant.costo);
    setFecha_inicio_planeada(mant.fecha_inicio_planeada.split("T")[0]);
    setFecha_fin_planeada(mant.fecha_fin_planeada.split("T")[0]);
    setFecha_inicio_real(mant.fecha_inicio_real.split("T")[0]);
    setFecha_fin_real(mant.fecha_fin_real.split("T")[0]);
    setVehiculo_asistencia_id(mant.vehiculo_asistencia_id);
    setDetailsMante(true);
  }

  const _handleOnExport = async () => {
    const mantainenceExcelExport = await _getExportMantenimientos(
      vehicleForFiltering
    );
    const filterArray = excelFilterRep(
      mantainenceExcelExport,
      "estado",
      "usuario_cancelacion",
      "usuario_modificacion",
      "usuario_registro",
      "vehiculo_asistencia_id",
      "peaje",
      "vehiculo_asistencium",
      "peaje_id",
      "estado_asistencia",
      "kilometraje"
    );
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(filterArray);

    XLSX.utils.book_append_sheet(wb, ws, "Reporte mantenimiento");

    XLSX.writeFile(wb, "Reporte mantenimiento.xlsx");
  };

  // Get data on first render
  useEffect(() => {
    _getVehiculos();
    _getMantenimientos(null, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_getVehiculos]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        className="mb-4"
      >
        <h3>{pageName}</h3>
        <div>
          <Button onClick={toggleDetailsMante} className="btn-tagexpress">
            <PlusSquare data-tip="Registrar mantenimiento para este vehículo" />
          </Button>
        </div>
      </div>
      <ConfirmModal
        toggle={toggleConfirm}
        isOpen={confirm}
        onClickYes={_handleDeleteMantenimiento}
      />
      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggleDetailsMante}
        isOpen={detailsMante}
      >
        <OffcanvasHeader toggle={toggleDetailsMante}>
          Detalle mantenimiento
        </OffcanvasHeader>
        <OffcanvasBody>
          <ModalDetalleMantenimiento
            isUpdate={Boolean(mantenimientoToUpdate)}
            vehicles={vehiculos}
            vehicleForMaintenance={vehicleForMaintenance}
            onClick={_handleMantenimientoOperations}
            onVehicleChange={setVehicleForMaintenance}
            descripcion={descripcion}
            kilometraje={kilometraje}
            conclusion={conclusion}
            taller_encargado={taller_encargado}
            mecanico_encargado={mecanico_encargado}
            telefono_mecanico={telefono_mecanico}
            costo={costo}
            fecha_inicio_planeada={fecha_inicio_planeada}
            fecha_fin_planeada={fecha_fin_planeada}
            fecha_inicio_real={fecha_inicio_real}
            fecha_fin_real={fecha_fin_real}
            setDescripcion={setDescripcion}
            setKilometraje={setKilometraje}
            setTaller_encargado={setTaller_encargado}
            setMecanico_encargado={setMecanico_encargado}
            setTelefono_mecanico={setTelefono_mecanico}
            setCosto={setCosto}
            setFecha_inicio_planeada={setFecha_inicio_planeada}
            setFecha_fin_planeada={setFecha_fin_planeada}
            setFecha_inicio_real={setFecha_inicio_real}
            setFecha_fin_real={setFecha_fin_real}
            setConclusion={setConclusion}
          />
        </OffcanvasBody>
      </Offcanvas>

      {isLoading ? <TheLoader /> : null}

      <ToastContainer />
      <Card className="my-3">
        <CardHeader style={{ backgroundColor: "var(--yellow)" }}>
          Filtro
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={3}>
              <div className="form-group mb-3">
                <label>Desde:</label>
                <Input
                  type="date"
                  value={fechaInicio}
                  maxLength={10}
                  onChange={(e) => {
                    setFechaInicio(e.target.value);
                  }}
                />
              </div>
            </Col>
            <Col md={3}>
              <div className="form-group mb-3">
                <label>Hasta:</label>
                <Input
                  type="date"
                  value={fechaFin}
                  maxLength={10}
                  onChange={(e) => {
                    setFechaFin(e.target.value);
                  }}
                />
              </div>
            </Col>
            <Col md={3}>
              <div className="form-group mb-3">
                <label>Vehículo:</label>
                <select
                  defaultValue={vehicleForFiltering}
                  className="form-control"
                  onChange={(e) => setVehicleForFiltering(e.target.value)}
                >
                  <option value="">Seleccione un vehículo</option>
                  {vehiculos.map((vehicle, index) => (
                    <option
                      value={
                        vehicle.vehiculo_asistencium.vehiculo_asistencia_id
                      }
                      key={index}
                    >
                      {`${vehicle.categoria_vehiculos_peaje.categoria_vehiculo_peaje} / ${vehicle.vehiculo_asistencium.placa}`}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div></div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: " 1fr 1fr",
                  gap: "15px",
                }}
              >
                {mantenimientos.length > 0 && (
                  <Button
                    type="button"
                    className="btn-tagexpress-success mx-2"
                    onClick={_handleOnExport}
                  >
                    <img
                      src={IconExcel}
                      width="20px"
                      style={{ marginRight: 10 }}
                      alt=""
                    />
                    Exportar
                  </Button>
                )}
                <Button
                  className="btn-tagexpress"
                  onClick={() => _getMantenimientos(vehicleForFiltering, 0)}
                >
                  Buscar
                </Button>
              </div>
            </div>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          {mantenimientos.length === 0 ? (
            <h4 style={{ color: "var(--blue)", textAlign: "center" }}>
              No hay mantenimientos registrados para este vehículo
            </h4>
          ) : (
            <Table responsive>
              <thead>
                <tr>
                  <th>Placa</th>
                  <th>Código vehículo</th>
                  <th>Fecha inicio planeada</th>
                  <th>Fecha fin planeada</th>
                  <th>Kilometraje</th>
                  <th>Descripción</th>
                  <th>Taller</th>
                  <th>Costo</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {mantenimientos.map((item) => (
                  <tr key={item.mantenimiento_id}>
                    <td>{item.vehiculo_asistencium.placa}</td>
                    <td>{item.vehiculo_asistencium.codigo_vehiculo}</td>
                    <td>{item.fecha_inicio_planeada}</td>
                    <td>{item.fecha_fin_planeada}</td>
                    <td>{item.kilometraje}</td>
                    <td>{item.descripcion}</td>
                    <td>{item.taller_encargado}</td>
                    <td>${parseFloat(item.costo).toFixed(2)}</td>
                    <td>
                      <Trash
                        style={{
                          width: 20,
                          height: 20,
                          cursor: "pointer",
                          marginRight: 5,
                          color: "red",
                        }}
                        id={`delete-${item.mantenimiento_id}`}
                        onClick={(e) => {
                          _handleDeleteClick(item);
                          e.stopPropagation();
                        }}
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target={`delete-${item.mantenimiento_id}`}
                      >
                        Eliminar
                      </UncontrolledTooltip>
                      <Edit
                        style={{
                          width: 20,
                          height: 20,
                          cursor: "pointer",
                          marginRight: 5,
                        }}
                        id={`edit-${item.mantenimiento_id}`}
                        onClick={(e) => {
                          _showModalDetalle(item);
                        }}
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target={`edit-${item.mantenimiento_id}`}
                      >
                        Editar
                      </UncontrolledTooltip>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Pagination>
              {generateSequentialArray(totalPages).map((item, index) => (
                <PaginationItem
                  key={index}
                  active={index === currentPage}
                  onClick={() => _getMantenimientos(vehicleForFiltering, index)}
                >
                  <PaginationLink href="#">{index + 1}</PaginationLink>
                </PaginationItem>
              ))}
            </Pagination>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default MantenimientosView;
