import React, { useEffect, useRef, useState } from "react";
import { useCentralContext } from "../../centralContext";
import TheLoader from "../elements/theLoader";
import { useNavigate, Outlet } from "react-router";
import { headers } from "../../utils/utils";
import { api } from "../../network";

const ProtectedRoute = () => {
  const navigate = useNavigate();
  const context = useCentralContext();
  const [componentToShow, setComponentToShow] = useState(<TheLoader />);
  const isMounted = useRef(true);

  useEffect(() => {
    if (context.isLogged) {
      setComponentToShow(<Outlet />);
    }

    if (!localStorage.getItem("token")) {
      context.updateIsLogged(false);
      context.updateUser(null);
      context.updateRole(null);
      navigate("/login");
    }

    api
      .post("/v1/auth/ack", {}, { headers: headers() })
      .then((response) => {
        if (!isMounted.current) {
          return;
        }

        if (response.data.status === "autorizado_ok") {
          context.updateRole(response.data.data.r);
          context.updateUser(response.data.data.u);
          context.updateIsLogged(true);
          setComponentToShow(<Outlet />);
        } else {
          throw new Error();
        }
      })
      .catch((e) => {
        localStorage.removeItem("token");

        if (!isMounted.current) {
          return;
        }

        context.updateIsLogged(false);
        context.updateUser(null);
        context.updateRole(null);
        navigate("/login");
      });

    return () => (isMounted.current = false);
  }, [navigate, context]);

  return componentToShow;
};

export default ProtectedRoute;
