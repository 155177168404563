import { ConfirmModal } from "../../../../elements/modals/confirm";

export const DeleteCompensationModal = ({ isOpen, toggle, onYes }) => {
  return (
    <ConfirmModal
      isOpen={isOpen}
      toggle={toggle}
      header="Eliminar compensación"
      body={
        <>
          <p style={{ margin: 0 }}>
            ¿Estás seguro de querer eliminar la compensación? No se podrá revertir esta acción.
          </p>
        </>
      }
      onYes={onYes}
    />
  );
} 