import React, { useEffect, useMemo } from "react";
import { Link, Outlet } from "react-router-dom";
import logoSinFrase from "../../assets/logo-sin-frase.png";
import { useCentralContext } from '../../centralContext';
import { getRoutesByRole } from "../../routing/utils";
import { useNavigate } from "react-router";

import "./dashboard.css";
import { checkCashClosing } from "../../services/userCtl";
import { notifyError, notifyInfo } from "../../utils/utils";

const Dashboard = () => {
  const navigate = useNavigate();
  const context = useCentralContext();

  useEffect(() => {
    // To hide the sidebar when an option is clicked (mobile view: < 600px)
    document.addEventListener("click", (event) => {
      if (event.target.classList.contains("menu-option-text") && window.screen.width <= 600) {
        document
          .getElementsByClassName("page-wrapper")[0]
          .classList.remove("toggled");
      }
    });
    let dropDowns = document.querySelectorAll(".sidebar-dropdown > span");
    dropDowns.forEach((i) => {
      i.addEventListener("click", function () {

        if (i.parentElement.classList.contains("active")) {

          if (this.nextElementSibling) {
            this.nextElementSibling.classList.remove("displayed-submenu");
          }

          i.parentElement.classList.remove("active");
        } else {

          if (this.nextElementSibling) {
            this.nextElementSibling.classList.add("displayed-submenu");
          }

          i.parentElement.classList.add("active");
        }
      });
    });


    // Toggle the sidebar
    document
      .getElementById("close-sidebar")
      .addEventListener("click", function () {
        document
          .getElementsByClassName("page-wrapper")[0]
          .classList.remove("toggled");
      });

    document
      .getElementById("show-sidebar")
      .addEventListener("click", function () {
        document
          .getElementsByClassName("page-wrapper")[0]
          .classList.add("toggled");
      });
  }, []);

  // Builds the sidebar menu
  const menuItems = useMemo(() => getRoutesByRole(context.role).map(menuItem => (
    <li className="sidebar-dropdown" key={menuItem.name}>
      <span>
        <a style={{ cursor: "pointer" }} href={menuItem.url}>
          <i className={`icon-md fa ${menuItem.icon}`}></i>
          {menuItem.name}
        </a>
      </span>
      {
        menuItem.children && <div className="sidebar-submenu">
          <ul>
            {
              menuItem.children.map(child => (
                <li className="sidebar-dropdown" key={child.url}>
                  <span style={{ cursor: "pointer" }} >
                    <Link className="menu-option-text" to={child.url}>
                      <i className={`icon-md fa ${child.icon}`}></i>
                      {child.name}
                    </Link>
                  </span>
                </li>
              ))
            }
          </ul>
        </div>
      }

    </li>
  )), [context]);


  const logout = async () => {
    if (context.role === 'operador_tagexpress') {
      try {
        const resp = await checkCashClosing()
        if (!resp.log_out) {
          notifyInfo(resp.message, { autoClose: 2000 })
          return
        }
        localStorage.removeItem('token');
        context.updateRole(null);
        context.updateUser(null);
        navigate('/login');
      } catch (error) {
        notifyError("Ocurrió un error al cerrar sesión, por favor inténtelo más tarde.");
      }
    } else {
      localStorage.removeItem('token');
      context.updateRole(null);
      context.updateUser(null);
      navigate('/login');
    }
  }

  return (
    <div className="page-wrapper chiller-theme toggled">
      <button id="show-sidebar" className="btn btn-sm btn-dark" style={{ zIndex: 100 }}>
        <i style={{ color: "#31353D" }} className="fa fa-bars"></i>
      </button>

      <nav id="sidebar" className="sidebar-wrapper">
        <div className="sidebar-content">
          <div className="sidebar-brand">
            <Link to="/">
              <img src={logoSinFrase} alt="tagexpress logo" className="logoNav" />
            </Link>
            <div id="close-sidebar">
              <i className="fa fa-times" style={{ color: "var(--blue)" }}></i>
            </div>
          </div>

          <div className="sidebar-header">
            <div className="user-info ml-4">
              <span className="user-name">
                {context.user}
              </span>
              <span className="user-status">
                <span style={{ color: "var(--yellow)" }}><strong>En línea</strong></span>
              </span>
            </div>
          </div>

          <div className="sidebar-menu">
            <ul>
              {menuItems}
            </ul>
          </div>

          <div className="sidebar-footer pl-2" style={{ marginBottom: '1rem' }}>
            <div className="text-center">
              <button
                onClick={logout}
                style={{ cursor: "pointer" }}
                className="btonexit bton-sm"
              >
                <i className="fa fa-arrow-circle-left pr-2" style={{ fontSize: "18px", marginRight: '0.5rem' }} aria-hidden="true"></i>
                <strong>Salir</strong>
              </button>
            </div>
          </div>


        </div>
      </nav >

      <main className="page-content">
        <Outlet />
      </main>
    </div >
  );
};

export default Dashboard;
