import { BaseModal } from "../../../../elements/modals/base";
import { NewCompensation } from "./NewCompensation";

export const CreateCompensationModal = ({ isOpen, toggle, onSuccess }) => {
  return (
    <BaseModal
      isOpen={isOpen}
      toggle={toggle}
      header="Crear compensación"
      body={
        <NewCompensation
          onSuccess={onSuccess}
        />
      }
    />
  );
} 