import React, { useState, useEffect, useCallback, Fragment } from "react";
import {
  Button,
  Badge,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Table,
  Card,
  CardBody,
} from "reactstrap";
import { useCentralContext } from "../../../../centralContext";
import { api } from "../../../../network";
import { s_getCategory } from "../../../../services/categoryTollVehicles";
import TheLoader from "../../../elements/theLoader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./vehiculosAsist.css";
import ROLES from "../../../../utils/roles";

import { notifyError, headers } from "../../../../utils/utils";
import { PlusSquare, Edit } from "react-feather";
import ReactTooltip from "react-tooltip";
import NewAssistanceForm from "./components/NewAssistanceForm";
import UpdateAssistanceForm from "./components/UpdateAssistanceForm";

const VehiculosAsistView = ({ pageName }) => {
  const centralContext = useCentralContext();

  const [vehiculos, setVehiculos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  //This state contains all categories for toll vehicles
  const [vehicleCategories, setVehicleCategories] = useState([]);
  const [peajes, setPeajes] = useState([]);

  const [createSidebar, setCreateSidebar] = useState(false);
  const toggleCreateSidebar = () => setCreateSidebar(!createSidebar);
  const [updateSidebar, setUpdateSidebar] = useState(false);
  const toggleUpdateSidebar = () => setUpdateSidebar(!updateSidebar);

  const [selectedVehicle, setSelectedVehicle] = useState(null);

  const _getVehiculos = useCallback(async () => {
    try {
      const response = await api.get(`/v1/asistVial/vehiculos`, {
        headers: headers(),
      });

      if (response && response.status !== 200) {
        notifyError("ERROR");
        return;
      }
      setVehiculos(response.data.data);
    } catch (e) {
      console.log(e);
      notifyError("Error al traer los datos, por favor intente nuevamente");
      return;
    }
  }, []);

  async function _getPeajes() {
    if (peajes.length > 0) {
      return;
    }
    try {
      const response = await api.get("/v1/peajes", {
        headers: headers(),
      });
      if (response && response.status !== 200) {
        notifyError("ERROR");
        return;
      }
      setPeajes(response.data.data);
    } catch (e) {}
  }

  function _handleRowClick(veh) {
    toggleUpdateSidebar();
    setSelectedVehicle(veh);
  }

  const _getVehiculosCategory = useCallback(async () => {
    setIsLoading(true);
    const response = await s_getCategory();

    if (!response) {
      notifyError("ERROR AL TRAER LOS VEHÍCULOS");
      setIsLoading(false);
      return;
    }
    setVehicleCategories(response);
    setIsLoading(false);
  }, []);

  // Get data on first render
  useEffect(() => {
    _getVehiculos();
    _getVehiculosCategory();
    _getPeajes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_getVehiculos, _getVehiculosCategory]);

  return (
    <div>
      {isLoading ? <TheLoader /> : null}
      <ToastContainer style={{ width: "400px" }} />
      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggleCreateSidebar}
        isOpen={createSidebar}
      >
        <OffcanvasHeader toggle={toggleCreateSidebar}>
          Crear nuevo vehículo asistencial
        </OffcanvasHeader>
        <OffcanvasBody>
          <NewAssistanceForm
            onClose={toggleCreateSidebar}
            reload={_getVehiculos}
            vehicleCategories={vehicleCategories}
            peajes={peajes}
          />
        </OffcanvasBody>
      </Offcanvas>
      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggleUpdateSidebar}
        isOpen={updateSidebar}
      >
        <OffcanvasHeader toggle={toggleUpdateSidebar}>
          Actualizar vehículo asistencial
        </OffcanvasHeader>
        <OffcanvasBody>
          {selectedVehicle !== null && (
            <UpdateAssistanceForm
              onClose={toggleUpdateSidebar}
              reload={_getVehiculos}
              vehicleCategories={vehicleCategories}
              selectedVehicle={selectedVehicle}
              peajes={peajes}
            />
          )}
        </OffcanvasBody>
      </Offcanvas>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        className="mb-4"
      >
        <h3>{pageName}</h3>
        <div>
          <Button
            onClick={toggleCreateSidebar}
            className="btn-tagexpress"
            data-tip="Crear nuevo vehículo"
          >
            <PlusSquare />
          </Button>
          <ReactTooltip type="light" />
        </div>
      </div>
      <Card>
        <CardBody style={{ cursor: "default" }}>
          <Table responsive>
            <thead>
              <tr>
                <td className="header-item">Código vehículo</td>
                <td className="header-item">Placa</td>
                <td className="header-item">kilometraje</td>
                <td className="header-item">Categoría</td>
                <td className="header-item">Disponibilidad</td>
                {(centralContext.role === ROLES.usuariot1000 ||
                  centralContext.role === ROLES.cau ||
                  centralContext.role === ROLES.admin_tagexpress) && (
                  <>
                    <td className="header-item">Peaje</td>
                    <td className="header-item">Provincia</td>
                  </>
                )}
                <td className="header-item">Estado</td>
                <td className="header-item">Acciones</td>
              </tr>
            </thead>
            <tbody>
              {vehiculos.map((veh) => (
                <tr key={veh.vehiculo_asistencium.vehiculo_asistencia_id}>
                  <td className="body-item">
                    {veh.vehiculo_asistencium.codigo_vehiculo}
                  </td>
                  <td className="body-item">
                    {veh.vehiculo_asistencium.placa}
                  </td>
                  <td className="body-item">
                    {veh.vehiculo_asistencium.kilometraje}
                  </td>
                  <td className="body-item">
                    {veh.categoria_vehiculos_peaje.categoria_vehiculo_peaje +
                      " / " +
                      veh.categoria_vehiculos_peaje.sub_categoria_vehiculo}
                  </td>
                  <td className="body-item">
                    {veh.vehiculo_asistencium.estado_asistencia}
                  </td>
                  {(centralContext.role === ROLES.usuariot1000 ||
                    centralContext.role === ROLES.cau ||
                    centralContext.role === ROLES.admin_tagexpress) && (
                    <>
                      <td className="body-item">{veh.peaje.descripcion}</td>
                      <td className="body-item">
                        {veh.peaje.provincium.nombre_provincia}
                      </td>
                    </>
                  )}
                  <td className="body-item">
                    {veh.vehiculo_asistencium.estado === "A" ? (
                      <Badge color="success">Activo</Badge>
                    ) : (
                      <Badge color="warning">Inactivo</Badge>
                    )}
                  </td>
                  <td className="body-item" style={{ cursor: "pointer" }}>
                    <Edit onClick={() => _handleRowClick(veh)} />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </div>
  );
};

export default VehiculosAsistView;
