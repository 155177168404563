import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap";
import { RefreshCcw, AlertCircle } from "react-feather";
import { ToastContainer } from "react-toastify";
import { NewHomologationForm } from "./components/NewHomologationForm";
import { NewRechargeForm } from "./components/NewRechargeForm";
import { NewTagsForm } from "./components/NewTagsForm";
import {
  closeCashRegister,
  s_getDailySaleTmp,
  s_getDailySaleWallet,
  // s_getDailySaleWalletTransactions,
} from "../../../../services/dailySalesCtl";
import { s_getPeajes } from "../../../../services/peajesCtl";
import { s_getUserStoreTags } from "../../../../services/store.Ctl";
import { notifyError, notifyOk } from "../../../../utils/utils";
import { Details } from "./components/Details";
import TheLoader from "../../../elements/theLoader";
import { AddBalanceWalletForm } from "./components/AddBalanceWalletForm";
// import { getDate } from "../../../../utils/utils";
// import { useCentralContext } from "../../../../centralContext";
import './dailySales.css';


const DailySales = ({ pageName }) => {
  const [data, setData] = useState({
    dailySale: [],
    tolls: [],
    userStore: [],
    _reload: false,
    loading: true,
  });

  // const UserContext = useCentralContext();

  const [walletDataSales, setWalletDataSales] = useState([]);
  // const [walletDataSalesTransactions, setWalletDataSalesTransactions] = useState([]);

  const [showModalAddBalanceWallet, setShowModalAddBalanceWallet] = useState(false);
  const toggleAddBalanceWallet = () =>
    setShowModalAddBalanceWallet(!showModalAddBalanceWallet);

  const [showModalHomologation, setShowModalHomologation] = useState(false);
  const toggleHomologation = () =>
    setShowModalHomologation(!showModalHomologation);
  const [showModalRecharge, setShowModalRecharge] = useState(false);
  const toggleRecharge = () => setShowModalRecharge(!showModalRecharge);
  const [showModalTags, setShowModalTags] = useState(false);
  const toggleTags = () => setShowModalTags(!showModalTags);

  const [showModalCloseCash, setShowModalCloseCash] = useState(false);
  const toggleCloseCash = () => setShowModalCloseCash(!showModalCloseCash);

  const [details, setDetails] = useState([]);
  const [showModalDetails, setShowModalDetails] = useState(false);
  const toggleDetails = () => setShowModalDetails(!showModalDetails);

  const _handlerDetailsModal = (type) => {
    if (type === "homologation")
      setDetails({ ...data.dailySale.homologation, type });
    if (type === "recharge") setDetails({ ...data.dailySale.recharge, type });
    if (type === "salesTag") setDetails({ ...data.dailySale.salesTag, type });
    // if (type === "wallet") setDetails(walletDataSalesTransactions);
    setShowModalDetails(true);
  };

  const _reload = () =>
    setData((old) => ({
      dailySale: [],
      tolls: [],
      userStore: {},
      _reload: !old._reload,
      loading: !old.loading,
    }));

  const _getDailySale = async () => {
    try {
      setData((old) => ({ ...old, loading: true }));
      const resp = await s_getDailySaleTmp();
      setData((old) => ({
        ...old,
        loading: false,
        dailySale: resp,
      }));
    } catch (error) {
      notifyError("ERROR");
    }
  };

  const _getUserStoreTags = async () => {
    const resp = await s_getUserStoreTags();
    if (!resp) {
      notifyError("Erro al cargar el stock, por favor recargue la página");
    }
    setData((old) => ({
      ...old,
      userStore: resp,
    }));
  };

  const _getDailyWalletSales = async () => {
    try {
      const resp = await s_getDailySaleWallet();
      setWalletDataSales(resp);
    } catch (error) {
      notifyError("Error de conexión con el servidor de la billetera");
    }
  };


  // const _getDailyWalletSalesTransactions = useCallback(async () => {
  //   try {
  //     const resp = await s_getDailySaleWalletTransactions(getDate(), getDate(), UserContext.user);
  //     setWalletDataSalesTransactions(resp);
  //   } catch (error) {
  //     notifyError("ERROR");
  //   }
  // }, [UserContext.user])

  const _getTolls = async () => {
    try {
      const resp = await s_getPeajes();
      setData((old) => ({
        ...old,
        tolls: resp,
      }));
    } catch (error) {
      notifyError("ERROR");
    }
  };

  const _closeCash = async () => {
    try {
      const resp = await closeCashRegister();
      notifyOk(resp, { autoClose: 2000 });
      setShowModalCloseCash(false);
      _reload();
    } catch (error) {
      notifyError(
        "Ocurrió un error al cerrar caja, por favor inténtelo más tarde."
      );
    }
  };



  useEffect(() => {
    _getDailySale();
    _getTolls();
    _getDailyWalletSales();
    // _getDailyWalletSalesTransactions();
    _getUserStoreTags();
  }, [data._reload]);


  return (
    <div>
      <ToastContainer style={{ width: "400px" }} />
      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggleAddBalanceWallet}
        isOpen={showModalAddBalanceWallet}
        style={{ width: 500 }}
      >
        <OffcanvasHeader toggle={toggleAddBalanceWallet}>
          Recarga Billetera
        </OffcanvasHeader>
        <OffcanvasBody>
          <AddBalanceWalletForm
            reload={_reload}
            setShowModalAddBalanceWallet={setShowModalAddBalanceWallet}
            store={data.userStore.punto_de_ventum}
          />
        </OffcanvasBody>
      </Offcanvas>
      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggleHomologation}
        isOpen={showModalHomologation}
        style={{ width: 500 }}
      >
        <OffcanvasHeader toggle={toggleHomologation}>
          Nueva homologación
        </OffcanvasHeader>
        <OffcanvasBody>
          <NewHomologationForm
            tolls={data.tolls}
            reload={_reload}
            setShowModalHomologation={setShowModalHomologation}
            store={data.userStore.punto_de_ventum}
          />
        </OffcanvasBody>
      </Offcanvas>
      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggleRecharge}
        isOpen={showModalRecharge}
        style={{ width: 500 }}
      >
        <OffcanvasHeader toggle={toggleRecharge}>
          Recarga de Tag
        </OffcanvasHeader>
        <OffcanvasBody>
          <NewRechargeForm
            tolls={data.tolls}
            reload={_reload}
            setShowModalRecharge={setShowModalRecharge}
            store={data.userStore.punto_de_ventum}
          />
        </OffcanvasBody>
      </Offcanvas>

      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggleTags}
        isOpen={showModalTags}
        style={{ width: 500 }}
      >
        <OffcanvasHeader toggle={toggleTags}>
          Venta de Tag
        </OffcanvasHeader>
        <OffcanvasBody>
          <NewTagsForm reload={_reload} setShowModalTags={setShowModalTags} store={data.userStore.punto_de_ventum} />
        </OffcanvasBody>
      </Offcanvas>

      <Modal isOpen={showModalDetails} toggle={toggleDetails} size="lg">
        <ModalHeader toggle={toggleDetails}>Detalle</ModalHeader>
        <ModalBody>
          <Details
            details={details}
            setShowModalDetails={setShowModalDetails}
            reload={_reload}
          />
        </ModalBody>
      </Modal>

      <Modal
        isOpen={showModalCloseCash}
        toggle={toggleCloseCash}
        centered
        size="sm"
        backdrop={"static"}
      >
        <ModalHeader toggle={toggleCloseCash} style={{ borderBottom: "none" }}>
          Confirmar cierre de caja
        </ModalHeader>
        <ModalBody style={{ padding: "0px 1rem" }}>
          <div style={{ display: "flex" }}>
            <AlertCircle style={{ marginRight: 8 }} />
            <p style={{ margin: 0 }}>
              Estas seguro de cerrar la caja, no sé podra revertir esta acción.
            </p>
          </div>
        </ModalBody>
        <ModalFooter style={{ borderTop: "none" }}>
          <Button
            size="sm"
            style={{ padding: "5px 20px" }}
            color="light"
            onClick={toggleCloseCash}
          >
            No
          </Button>
          <Button
            size="sm"
            style={{ padding: "5px 20px" }}
            color="danger"
            onClick={_closeCash}
          >
            Si
          </Button>
        </ModalFooter>
      </Modal>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        className="mb-4"
      >
        <h3 style={{ margin: 0 }}>{pageName}</h3>

        {!data.loading && (
          <div>
            <Button
              color="warning"
              style={{ marginRight: 20 }}
              onClick={_reload}
            >
              <RefreshCcw />
            </Button>
            {data.dailySale.homologation.closeHomologation &&
              data.dailySale.recharge.closeRecharge &&
              data.dailySale.salesTag.closeSalesTag ? null : (
              <>
                <Button
                  className='btn-sales'
                  onClick={() => setShowModalAddBalanceWallet(true)}
                >
                  Nueva Recargar billetera
                </Button>
                <Button
                  className='btn-sales'
                  onClick={() => setShowModalHomologation(true)}
                >
                  Nueva Homologación
                </Button>
                <Button
                  className='btn-sales'
                  onClick={() => setShowModalRecharge(true)}
                >
                  Nueva recarga
                </Button>
                <Button
                  className='btn-sales'
                  onClick={() => setShowModalTags(true)}
                  disabled={
                    !data.dailySale.salesTag
                      ? true
                      : data.dailySale.salesTag.stock === "0"
                        ? true
                        : false
                  }
                >
                  Vender tags
                </Button>
                <Button
                  style={{ marginRight: 15 }}
                  onClick={() => setShowModalCloseCash(true)}
                  color="danger"
                >
                  Cerrar caja
                </Button>
              </>
            )}
          </div>
        )}
      </div>

      {data.loading ? (
        <TheLoader />
      ) : (
        <>
          <Row className="mb-2">
            <Col xs="4">
              <Card>
                <CardBody>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <CardTitle tag="h5">Venta de Tags</CardTitle>
                    <span className="fw-lighter">
                      <strong>Stock</strong> (<b>{data.userStore.punto_de_ventum?.cantidad}</b>)
                    </span>
                  </div>
                  <Table>
                    <thead style={{ backgroundColor: "#e8b800" }}>
                      <tr>
                        <th>Cantidad</th>
                        <th>Tipo de Tags</th>
                        <th>T. unidad</th>
                        <th style={{ textAlign: "end" }}>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.dailySale.salesTag.salesTagQuantityType.map(
                        (item, index) => (
                          <tr key={index}>
                            <th>{item.quantity}</th>
                            <td>{item.tipo}</td>
                            <td>
                              {item.tipo === "Cliente" && "$ 7.00"}
                              {item.tipo === "Revendedor" && "$ 4.75"}
                              {item.tipo === "Cruz azul / Phamarcy" && "$ 8.12"}
                              {item.tipo === "Tía" && "$ 8.12"}
                            </td>
                            <td style={{ textAlign: "end" }}>$ {item.total}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                    <tfoot>
                      <tr style={{ color: "red" }}>
                        <th id="total" colSpan="3" style={{ textAlign: "end" }}>
                          Retención :
                        </th>
                        <td style={{ textAlign: "end" }}>
                          {" "}
                          ${" "}
                          {!data.dailySale.salesTag.salesTagRetention
                            ? "0.00"
                            : data.dailySale.salesTag.salesTagRetention}
                        </td>
                      </tr>
                      <tr>
                        <th id="total" colSpan="3" style={{ textAlign: "end" }}>
                          Total :
                        </th>
                        <td style={{ textAlign: "end" }}>
                          {" "}
                          ${" "}
                          {!data.dailySale.salesTag.total
                            ? "0.00"
                            : data.dailySale.salesTag.total}
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                  <Button
                    style={{ marginRight: 15 }}
                    color="warning"
                    outline
                    size="sm"
                    onClick={() => _handlerDetailsModal("salesTag")}
                    disabled={
                      data.dailySale.salesTag.details.length > 0 ? false : true
                    }
                  >
                    Ver detalle
                  </Button>
                </CardBody>
              </Card>
            </Col>
            <Col xs="4">
              <Card>
                <CardBody>
                  <CardTitle tag="h5">Homologaciones</CardTitle>
                  <Table>
                    <thead style={{ backgroundColor: "#e8b800" }}>
                      <tr>
                        <th>Cantidad</th>
                        <th>Concesión</th>
                        <th>T. unidad</th>
                        <th style={{ textAlign: "end" }}>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.dailySale.homologation.homologationConcessionGrouped.map(
                        (item, index) => (
                          <tr key={index}>
                            <th>{item.quantity}</th>
                            <td>{item.concesion}</td>
                            <td>
                              {item.concesion === "Guayasamin" ||
                                item.concesion === "AGR" ||
                                item.concesion === "Panavial"
                                ? "$ 2.00"
                                : "$ 1.00"}
                            </td>
                            <td style={{ textAlign: "end" }}>$ {item.total}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                    <tfoot>
                      <tr style={{ color: "red" }}>
                        <th id="total" colSpan="3" style={{ textAlign: "end" }}>
                          Retención :
                        </th>
                        <td style={{ textAlign: "end" }}>
                          {" "}
                          ${" "}
                          {!data.dailySale.homologation.homologationRetention
                            ? "0.00"
                            : data.dailySale.homologation.homologationRetention}
                        </td>
                      </tr>
                      <tr>
                        <th id="total" colSpan="3" style={{ textAlign: "end" }}>
                          Total :
                        </th>
                        <td style={{ textAlign: "end" }}>
                          {" "}
                          ${" "}
                          {!data.dailySale.homologation.total
                            ? "0.00"
                            : data.dailySale.homologation.total}
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                  <Button
                    style={{ marginRight: 15 }}
                    color="warning"
                    outline
                    size="sm"
                    onClick={() => _handlerDetailsModal("homologation")}
                    disabled={
                      data.dailySale.homologation.details.length > 0
                        ? false
                        : true
                    }
                  >
                    Ver detalle
                  </Button>
                </CardBody>
              </Card>
            </Col>
            <Col xs="4">
              <Card>
                <CardBody>
                  <CardTitle tag="h5">Recargas</CardTitle>
                  <Table>
                    <thead style={{ backgroundColor: "#e8b800" }}>
                      <tr>
                        <th>Cantidad</th>
                        <th>Concesion</th>
                        <th>Comisión por unidad</th>
                        <th style={{ textAlign: "end" }}>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.dailySale.recharge.rechargeConcessionGrouped.map(
                        (item, index) => (
                          <tr key={index}>
                            <th>{item.quantity}</th>
                            <td>{item.concesion}</td>
                            <td style={{ textAlign: "center" }}>$ 0.56</td>
                            <td style={{ textAlign: "end" }}>$ {item.total}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                    <tfoot>
                      <tr style={{ color: "red" }}>
                        <th id="total" colSpan="3" style={{ textAlign: "end" }}>
                          Retención :
                        </th>
                        <td style={{ textAlign: "end" }}>
                          {" "}
                          ${" "}
                          {!data.dailySale.recharge.rechargeRetention
                            ? "0.00"
                            : data.dailySale.recharge.rechargeRetention}
                        </td>
                      </tr>
                      <tr>
                        <th id="total" colSpan="3" style={{ textAlign: "end" }}>
                          Total :
                        </th>
                        <td style={{ textAlign: "end" }}>
                          {" "}
                          ${" "}
                          {!data.dailySale.recharge.total
                            ? "0.00"
                            : data.dailySale.recharge.total}
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                  <Button
                    style={{ marginRight: 15 }}
                    color="warning"
                    outline
                    size="sm"
                    onClick={() => _handlerDetailsModal("recharge")}
                    disabled={
                      data.dailySale.recharge.details.length > 0 ? false : true
                    }
                  >
                    Ver detalle
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="mb-2" xs="4">
              <Card>
                <CardBody>
                  <CardTitle tag="h5">Recargas Wallet</CardTitle>
                  <Table>
                    <thead style={{ backgroundColor: "#e8b800" }}>
                      <tr>
                        <th>Cantidad</th>
                        <th>T.recarga</th>
                        <th>T.Comisión </th>
                        <th style={{ textAlign: "end" }}>Total</th>
                      </tr>
                    </thead>
                    {walletDataSales?.length > 0 && walletDataSales ?
                      <>
                        <tbody>
                          <tr>
                            <td><strong>{walletDataSales.count}</strong></td>
                            <td>${walletDataSales.monto}</td>
                            <td>${walletDataSales.comision}</td>
                            <td style={{ textAlign: "end" }}>${Number(walletDataSales.monto) + Number(walletDataSales.comision)}</td>
                          </tr>

                        </tbody>
                        <tfoot>
                          <tr style={{ color: 'red' }}>
                            <th id="total" colSpan="3" style={{ textAlign: "end" }}>
                              Retención:
                            </th>
                            <td style={{ textAlign: "end" }}>
                              $ 0.00
                            </td>
                          </tr>
                          <tr>
                            <th id="total" colSpan="3" style={{ textAlign: "end" }}>
                              Total:
                            </th>
                            {
                              walletDataSales.monto
                                ?
                                <td style={{ textAlign: "end" }}>
                                  $ {Number(walletDataSales.monto) + Number(walletDataSales.comision)}
                                </td>
                                :
                                <td style={{ textAlign: "end" }}>
                                  $ 0.00
                                </td>
                            }

                          </tr>
                        </tfoot>
                      </>
                      :
                      null
                    }
                  </Table>
                  {/* <Button
                    style={{ marginRight: 15 }}
                    color="warning"
                    outline
                    size="sm"
                    onClick={() => _handlerDetailsModal("wallet")}
                    disabled={
                      walletDataSalesTransactions?.length > 0 && walletDataSalesTransactions ? false : true
                    }
                  >
                    Ver detalle
                  </Button> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row />
          <Row style={{ justifyContent: "flex-end" }}>
            <Col md={7}>
              <Card>
                <CardBody>
                  <Table>
                    <tbody style={{ textAlign: "end" }}>
                      <tr>
                        <td>
                          <b>Valor transferencia a cuenta CALPRANDINA</b>
                        </td>
                        <td>
                          ${" "}
                          {!data.dailySale.totalPaymentType
                            ? "0.00"
                            : Number(data.dailySale.totalPaymentType.transferCalprandina)}
                          {/* : Number(data.dailySale.totalPaymentType.transferCalprandina) + Number(walletDataSales?.totalMontoTrans)} */}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Deposito</b>
                        </td>
                        <td>
                          ${" "}
                          {!data.dailySale.totalPaymentType
                            ? "0.00"
                            : data.dailySale?.totalPaymentType.deposit.toFixed(
                              2
                            )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Efectivo</b>
                        </td>
                        <td>
                          ${" "}
                          {!data.dailySale.totalPaymentType
                            ? "0.00"
                            : Number(data.dailySale.totalPaymentType.cash)
                            // ?
                            // Number(data.dailySale.totalPaymentType.cash) + Number(walletDataSales?.totalMontoEfectivo)
                            // :
                          }
                        </td>
                      </tr>
                    </tbody>
                    <tfoot style={{ textAlign: "end" }}>
                      <tr
                        style={{ backgroundColor: "#e8b800", textAlign: "end" }}
                      >
                        <th id="total" colSpan="1">
                          VALOR TOTAL VENTAS DEL DÍA :
                        </th>
                        <td style={{ textAlign: "end" }}>
                          {" "}
                          {
                            !data.dailySale.totalPaymentType.cash
                              ? '0.00'
                              : Number(data.dailySale.totalPaymentType.total)
                            // (Number(data.dailySale.totalPaymentType.total) + Number(walletDataSales.data.totalMontoEfectivo) + Number(walletDataSales.data.totalMontoTrans)).toFixed(2)
                          }
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </CardBody>
              </Card>
            </Col>
            <Col xs="3">
              <Card>
                <CardBody>
                  <Table>
                    <tbody style={{ textAlign: "end" }}>
                      <tr>
                        <td>
                          <b>Tags</b>
                        </td>
                        <td>
                          ${" "}
                          {!data.dailySale.salesTag.total
                            ? "0.00"
                            : data.dailySale.salesTag.total}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Homologaciones</b>
                        </td>
                        <td>
                          ${" "}
                          {!data.dailySale.homologation.total
                            ? "0.00"
                            : data.dailySale.homologation.total}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Recargas</b>
                        </td>
                        <td>
                          ${" "}
                          {!data.dailySale.recharge.total
                            ? "0.00"
                            : data.dailySale.recharge.total}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>W. recargas</b>
                        </td>
                        <td>
                          ${" "}
                          {!walletDataSales && !walletDataSales?.monto
                            ? "0.00"
                            : walletDataSales.monto}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>W. comisión</b>
                        </td>
                        <td>
                          ${" "}
                          {!walletDataSales && !walletDataSales.comision
                            ? "0.00"
                            : walletDataSales.comision}
                        </td>
                      </tr>
                      <tr style={{ color: "red" }}>
                        <td>
                          <b>Retención</b>
                        </td>
                        <td>
                          ${" "}
                          {!data.dailySale.allRetention
                            ? "0.00"
                            : data.dailySale.allRetention}
                        </td>
                      </tr>
                    </tbody>
                    <tfoot style={{ textAlign: "end" }}>
                      <tr style={{ backgroundColor: "#e8b800" }}>
                        <th id="total" colSpan="1">
                          VALOR TOTAL VENTAS DEL DÍA :
                        </th>
                        <td>
                          {" "}
                          ${" "}
                          {isNaN(data.dailySale.allTotal)
                            ? "0.00"
                            : Number(data.dailySale.allTotal)
                            // (Number(data.dailySale.allTotal) + Number(walletDataSales.data.monto) + Number(walletDataSales.data.comision)).toFixed(2)}
                          }
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default DailySales;
