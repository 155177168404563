import { ArrowLeft } from "react-feather";
import { Badge, Card, CardBody, CardHeader, Col, Row } from "reactstrap";

function DetalleHomologacion({ selectHomologacion, onBackTo }) {
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 10,
          marginBottom: 20,
        }}
      >
        <ArrowLeft onClick={onBackTo} style={{ cursor: "pointer" }} />
        <h3
          style={{
            margin: 0,
          }}
        >
          Detalle de la homologación
        </h3>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h5>Número de tag: ({selectHomologacion.numero_tag})</h5>
        <div>
          {selectHomologacion.status === "PENDIENTE" && (
            <Badge color="warning">Pendiente</Badge>
          )}
          {selectHomologacion.status === "EN_PROCESO" && (
            <Badge color="success">En proceso</Badge>
          )}
          {selectHomologacion.status === "FINALIZADA" && (
            <Badge color="success">Finalizado</Badge>
          )}
          {selectHomologacion.status === "RECHAZADO" && (
            <Badge color="danger">Rechazado</Badge>
          )}
          {selectHomologacion.status === "EDITAR" && (
            <Badge color="secondary">Editar</Badge>
          )}
        </div>
      </div>
      <Row>
        <Col md={6}>
          <Card className="my-3">
            <CardHeader style={{ backgroundColor: "var(--yellow)" }}>
              Datos pesonales
            </CardHeader>
            <CardBody>
              <div className="form-group mb-3">
                <label>Nombres:</label>
                <input
                  defaultValue={selectHomologacion.nombres}
                  className="form-control"
                  disabled
                />
              </div>
              <div className="form-group mb-3">
                <label>Apellidos:</label>
                <input
                  defaultValue={selectHomologacion.apellidos}
                  className="form-control"
                  disabled
                />
              </div>
              <div className="form-group mb-3">
                <label>Tipo de identificación:</label>
                <input
                  defaultValue={selectHomologacion.tipo_identificacion}
                  className="form-control"
                  disabled
                />
              </div>
              <div className="form-group mb-3">
                <label>Identificación:</label>
                <input
                  defaultValue={selectHomologacion.identificacion}
                  className="form-control"
                  disabled
                />
              </div>
              <div className="form-group mb-3">
                <label>Razón social:</label>
                <input
                  defaultValue={selectHomologacion.razon_social}
                  className="form-control"
                  disabled
                />
              </div>
              <div className="form-group mb-3">
                <label>Correo electrónico:</label>
                <input
                  defaultValue={selectHomologacion.correo}
                  className="form-control"
                  disabled
                />
              </div>
              <div className="form-group mb-3">
                <label>Dirección:</label>
                <input
                  defaultValue={selectHomologacion.direccion}
                  className="form-control"
                  disabled
                />
              </div>
              <div className="form-group mb-3">
                <label>Teléfono:</label>
                <input
                  defaultValue={selectHomologacion.telefono}
                  className="form-control"
                  disabled
                />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="my-3">
            <CardHeader style={{ backgroundColor: "var(--yellow)" }}>
              Datos del vehículo
            </CardHeader>
            <CardBody>
              <div className="form-group mb-3">
                <label>Marca:</label>
                <input
                  defaultValue={selectHomologacion.marca}
                  className="form-control"
                  disabled
                />
              </div>
              <div className="form-group mb-3">
                <label>Modelo:</label>
                <input
                  defaultValue={selectHomologacion.modelo}
                  className="form-control"
                  disabled
                />
              </div>
              <div className="form-group mb-3">
                <label>Placa:</label>
                <input
                  defaultValue={selectHomologacion.placa}
                  className="form-control"
                  disabled
                />
              </div>
              <div className="form-group mb-3">
                <label>Categoría:</label>
                <input
                  defaultValue={selectHomologacion.categoria}
                  className="form-control"
                  disabled
                />
              </div>
              <div className="form-group mb-3">
                <label>Color:</label>
                <input
                  defaultValue={selectHomologacion.color}
                  className="form-control"
                  disabled
                />
              </div>
              <div className="form-group mb-3">
                <label>Ejes:</label>
                <input
                  defaultValue={selectHomologacion.ejes}
                  className="form-control"
                  disabled
                />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md={12}>
          <Card className="my-3">
            <CardHeader style={{ backgroundColor: "var(--yellow)" }}>
              Imagenes
            </CardHeader>
            <CardBody>
              <div className="imagenes-container">
                <div className="img-container">
                  <label>Cédula anverso</label>
                  <img
                    src={selectHomologacion.cedula_anverso}
                    alt="Cédula anverso"
                  />
                </div>
                <div className="img-container">
                  <label>Cédula reverso</label>
                  <img
                    src={selectHomologacion.cedula_reverso}
                    alt="Cédula anverso"
                  />
                </div>
              </div>
              <div className="imagenes-container">
                <div className="img-container">
                  <label>Matrícula anverso</label>
                  <img
                    src={selectHomologacion.matricula_anverso}
                    alt="Matrícula anverso"
                  />
                </div>
                <div className="img-container">
                  <label>Matrícula reverso</label>
                  <img
                    src={selectHomologacion.matricula_reverso}
                    alt="Matrícula anverso"
                  />
                </div>
              </div>
              <div className="imagenes-container">
                <div className="img-container">
                  <label>Placa (Foto frontal del vehículo)</label>
                  <img src={selectHomologacion.foto_placa} alt="Placa" />
                </div>
                {selectHomologacion.tag && (
                  <div className="img-container">
                    <label>Tag</label>
                    <img src={selectHomologacion.tag} alt="Matrícula anverso" />
                  </div>
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}
export default DetalleHomologacion;
