import React, { useEffect, useRef, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { Button } from "reactstrap";
import {
  excelFilterRep,
  getCalificacion,
  notifyInfo,
} from "../../../../../utils/utils";
import XLSX from "xlsx";
import dayjs from "dayjs";
import ReactToPrint from "react-to-print";
import "../reporteAsist.css";

ChartJS.register(ArcElement, Tooltip, Legend);

export const Preview = ({ data, typeVehicle }) => {
  const refGraphic = useRef(null);
  const [datasets, setDatasets] = useState({
    a: 0,
    b: 0,
    c: 0,
    d: 0,
    e: 0,
  });
  const labels = ["MUY MALO", "MALO", "REGULAR", "BUENO", "EXCELENTE"];
  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    data.map((item) => {
      if (item.evento_calificacion === 1) {
        setDatasets((old) => ({ ...old, a: old.a + 1 }));
      } else if (item.evento_calificacion === 2) {
        setDatasets((old) => ({ ...old, b: old.b + 1 }));
      } else if (item.evento_calificacion === 3) {
        setDatasets((old) => ({ ...old, c: old.c + 1 }));
      } else if (item.evento_calificacion === 4) {
        setDatasets((old) => ({ ...old, d: old.d + 1 }));
      } else if (item.evento_calificacion === 5) {
        setDatasets((old) => ({ ...old, e: old.e + 1 }));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dataExample = {
    labels: labels,
    datasets: [
      {
        label: "# of Votes",
        data: Object.values(datasets),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const handleOnExport = () => {
    let eventAsist;
    let keyHeaders;
    let keyHeadersResumen = [
      "MUY MALO",
      "MALO",
      "REGULAR",
      "BUENO",
      "EXCELENTE",
    ];
    if (typeVehicle === "AMBULANCIA") {
      eventAsist = data.map((e, index) => {
        const vihicle = e.vehiculo_asistencia.map((v) => {
          return {
            VEHICULO: v.categoria_vehiculo_peaje,
            PLACA: v.placa,
          };
        });
        return {
          ITEM: index + 1,
          CONCESION: e.peaje.descripcion,
          ESTACION: e.peaje.provincia,
          ...vihicle[0],
          NUMERO_DE_REPORTE: e.numero_reporte,
          FECHA_DE_ASISTENCIA: dayjs(new Date(e.created_at)).format(
            "DD/MM/YYYY"
          ),
          MEDIO_DE_AVISO: e.medio_de_aviso
            ? e.medio_de_aviso.toUpperCase()
            : "-",
          ACTIVIDAD: e.actividad ? e.actividad.toUpperCase() : "-",
          HORA_DE_AVISO: e.fecha_registro
            ? dayjs(new Date(e.fecha_registro)).format("HH:mm")
            : "-",
          SALE_DE_LA_ESTACION: e.sale_estacion
            ? dayjs(new Date(e.sale_estacion)).format("HH:mm")
            : "-",
          LLEGA_INICIO_OPERATIVO: e.llegado_inicio_operativo
            ? dayjs(new Date(e.llegado_inicio_operativo)).format("HH:mm")
            : "-",
          SALE_CON_PACIENTE: e.sale_con_paciente
            ? dayjs(new Date(e.sale_con_paciente)).format("HH:mm")
            : "-",
          TERMINA_OPERATIVO: e.termina_operativo
            ? dayjs(new Date(e.termina_operativo)).format("HH:mm")
            : "-",
          REGRESA_A_ESTACION: e.regresa_estacion
            ? dayjs(new Date(e.regresa_estacion)).format("HH:mm")
            : "-",
          CODIGO_DE_VEHICULO: e.codigo_vehiculo
            ? e.codigo_vehiculo.toUpperCase()
            : "-",
          KILOMETRAJE_AL_SALIR: e.kilometraje_salida
            ? e.kilometraje_salida
            : "-",
          KILOMETRAJE_AL_LLEGAR: e.kilometraje_llegada
            ? e.kilometraje_llegada
            : "-",
          VIA: e.via ? e.via : "-",
          KILOMETRO_DE_VIA: e.kilometro_via ? e.kilometro_via : "-",
          SENTIDO_DE_VIA: e.sentido_via ? e.sentido_via.toUpperCase() : "-",
          TIPO_DE_ASISTENCIA: e.tipo_asistencia_nombre,
          DETALLE_DE_LA_ASISTENCIA: e.descripcion ? e.descripcion : "-",
          CLIMA: e.clima ? e.clima.toUpperCase() : "-",
          ESTADO_DE_LA_VIA: e.estado_via ? e.estado_via.toUpperCase() : "-",
          USUARIO_NOMBRES: e.usuario_nombres ? e.usuario_nombres : "-",
          NOMBRES_DEL_PACIENTE: e.nombres_paciente ? e.nombres_paciente : "-",
          EDAD_DEL_PACIENTE: e.edad_paciente ? e.edad_paciente : "-",
          CEDULA_DEL_PACIENTE: e.cedula_paciente ? e.cedula_paciente : "-",
          DIAGNOSTICO: e.diagnostico ? e.diagnostico : "-",
          PRESCRIPCION_MEDICA: e.prescripcion_medica
            ? e.prescripcion_medica
            : "-",
          ESTADO: e.estado_paciente ? e.estado_paciente.toUpperCase() : "-",
          RESULTADO: e.resultado_paciente
            ? e.resultado_paciente.toUpperCase()
            : "-",
          TRASLADADO_A: e.traslado_paciente
            ? e.traslado_paciente.toUpperCase()
            : "-",
          TRASLADADO_DETALLE: e.detalle_traslado ? e.detalle_traslado : "-",
          AUTORIDAD_PRESENTE: e.autoridad_presente
            ? e.autoridad_presente.toUpperCase()
            : "-",
          MEDICO_DE_TURNO: e.medico_turno ? e.medico_turno : "-",
          CHOFER_DE_ASISTENCIA: e.chofer_asistencia ? e.chofer_asistencia : "-",
          NOMBRES_ACOMPAÑANTE: e.nombres_acompanante
            ? e.nombres_acompanante
            : "-",
          CEDULA_ACOMPAÑANTE: e.cedula_acompanante ? e.cedula_acompanante : "-",
          TELEFONO_ACOMPAÑANTE: e.telefono_acompanante
            ? e.telefono_acompanante
            : "-",
          ENCUESTA_DEL_SERVICIO: e.evento_calificacion
            ? getCalificacion(e.evento_calificacion)
            : "-",
        };
      });
      keyHeaders = [
        "ITEM",
        "CONCESION",
        "ESTACION",
        "VEHICULO",
        "PLACA",
        "NUMERO DE REPORTE",
        "FECHA DE ASISTENCIA",
        "MEDIO DE AVISO",
        "ACTIVIDAD",
        "HORA DE AVISO",
        "SALE DE LA ESTACION",
        "LLEGA/INICIO DE OPERATIVO",
        "SALE CON PACIENTE",
        "TERMINA OPERATIVO",
        "REGRESA A ESTACION",
        "CODIGO DE VEHICULO",
        "KILOMETRAJE AL SALIR",
        "KILOMETRAJE AL LLEGAR",
        "VIA",
        "KILOMETRO DE VIA",
        "SENTIDO DE VIA",
        "TIPO DE ASISTENCIA",
        "DETALLE DE LA ASISTENCIA",
        "CLIMA",
        "ESTADO DE LA VIA",
        "NOMBRES DEL USUARIO",
        "NOMBRES DEL PACIENTE",
        "EDAD DEL PACIENTE",
        "CEDULA DEL PACIENTE",
        "DIAGNOSTICO",
        "PRESCRIPCION MEDICA",
        "ESTADO",
        "RESULTADO",
        "TRASLADADO A",
        "TRASLADADO DETALLE",
        "AUTORIDAD PRESENTE",
        "MEDICO DE TURNO",
        "CHOFER DE ASISTENCIA",
        "NOMBRES ACOMPAÑANTE",
        "CEDULA ACOMPAÑANTE",
        "TELEFONO ACOMPAÑANTE",
        "ENCUESTA DEL SERVICIO",
      ];
    } else if (typeVehicle === "GRÚA") {
      eventAsist = data.map((e, index) => {
        const vihicle = e.vehiculo_asistencia.map((v) => {
          return {
            VEHICULO: v.categoria_vehiculo_peaje,
            PLACA: v.placa,
          };
        });
        return {
          ITEM: index + 1,
          CONCESION: e.peaje.descripcion,
          ESTACION: e.peaje.provincia,
          ...vihicle[0],
          NUMERO_DE_REPORTE: e.numero_reporte,
          FECHA_DE_ASISTENCIA: dayjs(new Date(e.created_at)).format(
            "DD/MM/YYYY"
          ),
          MEDIO_DE_AVISO: e.medio_de_aviso
            ? e.medio_de_aviso.toUpperCase()
            : "-",
          ACTIVIDAD: e.actividad ? e.actividad.toUpperCase() : "-",
          HORA_DE_AVISO: e.fecha_registro
            ? dayjs(new Date(e.fecha_registro)).format("HH:mm")
            : "-",
          SALE_DE_LA_ESTACION: e.sale_estacion
            ? dayjs(new Date(e.sale_estacion)).format("HH:mm")
            : "-",
          LLEGA_INICIO_OPERATIVO: e.llegado_inicio_operativo
            ? dayjs(new Date(e.llegado_inicio_operativo)).format("HH:mm")
            : "-",
          SALE_CON_PACIENTE: e.sale_con_paciente
            ? dayjs(new Date(e.sale_con_paciente)).format("HH:mm")
            : "-",
          TERMINA_OPERATIVO: e.termina_operativo
            ? dayjs(new Date(e.termina_operativo)).format("HH:mm")
            : "-",
          REGRESA_A_ESTACION: e.regresa_estacion
            ? dayjs(new Date(e.regresa_estacion)).format("HH:mm")
            : "-",
          CODIGO_DE_VEHICULO: e.codigo_vehiculo
            ? e.codigo_vehiculo.toUpperCase()
            : "-",
          KILOMETRAJE_AL_SALIR: e.kilometraje_salida
            ? e.kilometraje_salida
            : "-",
          KILOMETRAJE_AL_LLEGAR: e.kilometraje_llegada
            ? e.kilometraje_llegada
            : "-",
          VIA: e.via ? e.via : "-",
          KILOMETRO_DE_VIA: e.kilometro_via ? e.kilometro_via : "-",
          SENTIDO_DE_VIA: e.sentido_via ? e.sentido_via.toUpperCase() : "-",
          TIPO_DE_ASISTENCIA: e.tipo_asistencia_nombre,
          DETALLE_DE_LA_ASISTENCIA: e.descripcion ? e.descripcion : "-",
          CLIMA: e.clima ? e.clima.toUpperCase() : "-",
          ESTADO_DE_LA_VIA: e.estado_via ? e.estado_via.toUpperCase() : "-",
          AUTORIDAD_PRESENTE: e.autoridad_presente
            ? e.autoridad_presente.toUpperCase()
            : "-",
          CHOFER_DE_ASISTENCIA: e.chofer_asistencia ? e.chofer_asistencia : "-",
          USUARIO_NOMBRES: e.usuario_nombres ? e.usuario_nombres : "-",
          NOMBRES_ACOMPAÑANTE: e.nombres_acompanante
            ? e.nombres_acompanante
            : "-",
          CEDULA_ACOMPAÑANTE: e.cedula_acompanante ? e.cedula_acompanante : "-",
          TELEFONO_ACOMPAÑANTE: e.telefono_acompanante
            ? e.telefono_acompanante
            : "-",
          TIPO: e.tipo ? e.tipo : "-",
          MARCA: e.marca ? e.marca : "-",
          PLACAS: e.placa ? e.placa : "-",
          COLOR: e.color ? e.color : "-",
          SITUACION_ESPECIAL: e.situacion_especial ? e.situacion_especial : "-",
          ESTADO_VEHICULO: e.estado_vehiculo
            ? e.estado_vehiculo.toUpperCase()
            : "-",
          DETALLE_VEHICULO: e.detalle_estado_vehiculo
            ? e.detalle_estado_vehiculo
            : "-",
          ENCUESTA_DEL_SERVICIO: e.evento_calificacion
            ? getCalificacion(e.evento_calificacion)
            : "-",
        };
      });
      keyHeaders = [
        "ITEM",
        "CONCESION",
        "ESTACION",
        "VEHICULO",
        "PLACA",
        "NUMERO DE REPORTE",
        "FECHA DE ASISTENCIA",
        "MEDIO DE AVISO",
        "ACTIVIDAD",
        "HORA DE AVISO",
        "SALE DE LA ESTACION",
        "LLEGA/INICIO DE OPERATIVO",
        "SALE CON VEHICULO",
        "TERMINA OPERATIVO",
        "REGRESA A ESTACION",
        "CODIGO DE VEHICULO",
        "KILOMETRAJE AL SALIR",
        "KILOMETRAJE AL LLEGAR",
        "VIA",
        "KILOMETRO DE VIA",
        "SENTIDO DE VIA",
        "TIPO DE ASISTENCIA",
        "DETALLE DE LA ASISTENCIA",
        "CLIMA",
        "ESTADO DE LA VIA",
        "AUTORIDAD PRESENTE",
        "CHOFER DE ASISTENCIA",
        "NOMBRES USUARIO",
        "CEDULA USUARIO",
        "TELEFONO USUARIO",
        "TIPO",
        "MARCA",
        "PLACAS",
        "COLOR",
        "SITUACION ESPECIAL",
        "VEHICULO TRASLADADO A",
        "DETALLE TRASLADO VEHICULO",
        "ENCUESTA DEL SERVICIO",
      ];
    } else {
      eventAsist = data.map((e, index) => {
        const vihicle = e.vehiculo_asistencia.map((v) => {
          return {
            VEHICULO: v.categoria_vehiculo_peaje,
            PLACA: v.placa,
          };
        });
        return {
          ITEM: index + 1,
          CONCESION: e.peaje.descripcion,
          ESTACION: e.peaje.provincia,
          ...vihicle[0],
          NUMERO_DE_REPORTE: e.numero_reporte,
          FECHA_DE_ASISTENCIA: dayjs(new Date(e.created_at)).format(
            "DD/MM/YYYY"
          ),
          MEDIO_DE_AVISO: e.medio_de_aviso
            ? e.medio_de_aviso.toUpperCase()
            : "-",
          ACTIVIDAD: e.actividad ? e.actividad.toUpperCase() : "-",
          HORA_AVISO: e.fecha_registro
            ? dayjs(new Date(e.fecha_registro)).format("HH:mm")
            : "-",
          SALE_ESTACION: e.sale_estacion
            ? dayjs(new Date(e.sale_estacion)).format("HH:mm")
            : "-",
          LLEGO_INICIO_OPERATIVO: e.llegado_inicio_operativo
            ? dayjs(new Date(e.llegado_inicio_operativo)).format("HH:mm")
            : "-",
          SALE_CON_PACIENTE: e.sale_con_paciente
            ? dayjs(new Date(e.sale_con_paciente)).format("HH:mm")
            : "-",
          TERMINA_OPERATIVO: e.termina_operativo
            ? dayjs(new Date(e.sale_estacion)).format("HH:mm")
            : "-",
          REGRESA_A_ESTACION: e.regresa_estacion
            ? dayjs(new Date(e.regresa_estacion)).format("HH:mm")
            : "-",
          CODIGO_DE_VEHICULO: e.codigo_vehiculo
            ? e.codigo_vehiculo.toUpperCase()
            : "-",
          KILOMETRAJE_AL_SALIR: e.kilometraje_salida
            ? e.kilometraje_salida
            : "-",
          KILOMETRAJE_AL_LLEGAR: e.kilometraje_llegada
            ? e.kilometraje_llegada
            : "-",
          VIA: e.via ? e.via : "-",
          KILOMETRO_DE_VIA: e.ubicacion_asistencia
            ? e.ubicacion_asistencia
            : "-",
          SENTIDO_DE_VIA: e.sentido_via ? e.sentido_via.toUpperCase() : "-",
          TIPO_DE_ASISTENCIA: e.tipo_asistencia_nombre,
          DETALLE_DE_LA_ASISTENCIA: e.descripcion ? e.descripcion : "-",
          CLIMA: e.clima ? e.clima.toUpperCase() : "-",
          ESTADO_DE_LA_VIA: e.estado_via ? e.estado_via.toUpperCase() : "-",
          USUARIO_NOMBRES: e.usuario_nombres ? e.usuario_nombres : "-",
          NOMBRES_DEL_PACIENTE: e.nombres_paciente ? e.nombres_paciente : "-",
          EDAD_DEL_PACIENTE: e.edad_paciente ? e.edad_paciente : "-",
          CEDULA_DEL_PACIENTE: e.cedula_paciente ? e.cedula_paciente : "-",
          DIAGNOSTICO: e.diagnostico ? e.diagnostico : "-",
          PRESCRIPCION_MEDICA: e.prescripcion_medica
            ? e.prescripcion_medica
            : "-",
          ESTADO: e.estado_paciente ? e.estado_paciente.toUpperCase() : "-",
          RESULTADO: e.resultado_paciente
            ? e.resultado_paciente.toUpperCase()
            : "-",
          TRASLADADO_A: e.traslado_paciente
            ? e.traslado_paciente.toUpperCase()
            : "-",
          TRASLADADO_DETALLE: e.detalle_traslado ? e.detalle_traslado : "-",
          AUTORIDAD_PRESENTE: e.autoridad_presente
            ? e.autoridad_presente.toUpperCase()
            : "-",
          MEDICO_DE_TURNO: e.medico_turno ? e.medico_turno : "-",
          CHOFER_DE_ASISTENCIA: e.chofer_asistencia ? e.chofer_asistencia : "-",
          NOMBRES_ACOMPAÑANTE: e.nombres_acompanante
            ? e.nombres_acompanante
            : "-",
          CEDULA_ACOMPAÑANTE: e.cedula_acompanante ? e.cedula_acompanante : "-",
          TELEFONO_ACOMPAÑANTE: e.telefono_acompanante
            ? e.telefono_acompanante
            : "-",
          TIPO: e.tipo ? e.tipo : "-",
          MARCA: e.marca ? e.marca : "-",
          PLACAS: e.placa ? e.placa : "-",
          COLOR: e.color ? e.color : "-",
          SITUACION_ESPECIAL: e.situacion_especial ? e.situacion_especial : "-",
          TRASLADO_VEHICULO: e.traslado_vehiculo
            ? e.traslado_vehiculo.toUpperCase()
            : "-",
          DETALLE_TRASLADO_VEHICULO: e.detalle_traslado_vehiculo
            ? e.detalle_traslado_vehiculo
            : "-",
          ENCUESTA_DEL_SERVICIO: e.evento_calificacion
            ? getCalificacion(e.evento_calificacion)
            : "-",
        };
      });
      keyHeaders = [
        "ITEM",
        "CONCESION",
        "ESTACION",
        "VEHICULO",
        "PLACA",
        "NUMERO DE REPORTE",
        "FECHA DE ASISTENCIA",
        "MEDIO DE AVISO",
        "ACTIVIDAD",
        "HORA DE AVISO",
        "SALE DE LA ESTACION",
        "LLEGA/INICIO DE OPERATIVO",
        "SALE CON PACIENTE O VEHICULO",
        "TERMINA OPERATIVO",
        "REGRESA A ESTACION",
        "CODIGO DE VEHICULO",
        "KILOMETRAJE AL SALIR",
        "KILOMETRAJE AL LLEGAR",
        "VIA",
        "KILOMETRO DE VIA",
        "SENTIDO DE VIA",
        "TIPO DE ASISTENCIA",
        "DETALLE DE LA ASISTENCIA",
        "CLIMA",
        "ESTADO DE LA VIA",
        "NOMBRES DEL USUARIO",
        "NOMBRES DEL PACIENTE",
        "EDAD DEL PACIENTE",
        "CEDULA DEL PACIENTE",
        "DIAGNOSTICO",
        "PRESCRIPCION MEDICA",
        "ESTADO",
        "RESULTADO",
        "TRASLADADO A",
        "TRASLADADO DETALLE",
        "AUTORIDAD PRESENTE",
        "MEDICO DE TURNO",
        "CHOFER DE ASISTENCIA",
        "NOMBRES ACOMPAÑANTE O USUARIO",
        "CEDULA ACOMPAÑANTE O USUARIO",
        "TELEFONO ACOMPAÑANTE O USUARIO",
        "TIPO",
        "MARCA",
        "PLACAS",
        "COLOR",
        "SITUACION ESPECIAL",
        "VEHICULO TRASLADADO A",
        "DETALLE TRASLADO VEHICULO",
        "ENCUESTA DEL SERVICIO",
      ];
    }

    if (eventAsist.length === 0) {
      notifyInfo("No hay asistencias para exportar.", { autoClose: 3000 });
      return;
    }
    const wb = XLSX.utils.book_new();
    const filterArray = excelFilterRep(eventAsist);

    const ws = XLSX.utils.json_to_sheet(filterArray);
    XLSX.utils.sheet_add_aoa(ws, [keyHeaders]);
    XLSX.utils.book_append_sheet(wb, ws, `DETALLE DE ASISTENCIAS`);

    const wsResumen = XLSX.utils.json_to_sheet([datasets]);
    XLSX.utils.sheet_add_aoa(wsResumen, [keyHeadersResumen]);
    XLSX.utils.book_append_sheet(wb, wsResumen, `RESUMEN CALIFICACIONES`);

    XLSX.writeFile(wb, `ASISTENCIAS.xlsx`, { compression: false });
  };

  return (
    <div>
      <div ref={refGraphic}>
        <Pie data={dataExample} />
        <div style={{ textAlign: "center", margin: 10 }}>
          <div className="header-result">
            {labels.map((item, index) => (
              <div key={index} className="header-item">
                {item}
              </div>
            ))}
          </div>
          <div className="header-result">
            {Object.values(datasets).map((item, index) => (
              <div key={index} className="header-item">
                {item}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Button
        size="sm"
        outline
        color="secondary"
        className="mx-2"
        onClick={handleOnExport}
      >
        Exportar excel
      </Button>
      <ReactToPrint
        trigger={() => (
          <Button size="sm" outline color="secondary" className="mr-3">
            Descargar Gráfico
          </Button>
        )}
        content={() => refGraphic.current}
        bodyClass="m-5"
      />
    </div>
  );
};
