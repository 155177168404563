import React, { useState } from "react";
import { Edit, Trash2, PlusSquare } from "react-feather";
import { ToastContainer } from "react-toastify";
import {
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Badge,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Table,
} from "reactstrap";
import { api } from "../../../../../../network";
import { headers, notifyError, notifyOk } from "../../../../../../utils/utils";
import { EditRegisterProductForm } from "./EditRegisterProductForm";
import { EditProductCantForm } from "./EditProductCantForm";
import ReactTooltip from "react-tooltip";

export const TableRegisterProduct = ({ data, reload }) => {
  const [showDelete, setShowDelete] = useState(false);
  const toggleDelete = () => setShowDelete(!showDelete);

  const [editSidebar, setEditSidebar] = useState(false);
  const toggleEditSidebar = () => {
    setEditSidebar(!editSidebar);
  };
  const [editCantidadSidebar, setEditCantidadSidebar] = useState(false);
  const toggleEditCantidadSidebar = () => {
    setEditCantidadSidebar(!editCantidadSidebar);
  };

  const [registerId, setRegisterId] = useState(null);

  const deleteRegister = async (e) => {
    e.preventDefault();
    try {
      const resp = await api.delete(`/v1/productos/${registerId}`, {
        headers: headers(),
      });

      if (resp && resp.status !== 200) {
        notifyError("ERROR");
        return;
      }
      notifyOk("Borrado con Éxito", { autoClose: 3000 });
      reload();
      setShowDelete(false);
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <div>
      <ToastContainer />
      <ReactTooltip type="light" />
      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggleEditSidebar}
        isOpen={editSidebar}
      >
        <OffcanvasHeader toggle={toggleEditSidebar}>
          Editar Producto
        </OffcanvasHeader>
        <OffcanvasBody>
          <EditRegisterProductForm
            id={registerId}
            setShowEdit={setEditSidebar}
            reload={reload}
          />
        </OffcanvasBody>
      </Offcanvas>
      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggleEditCantidadSidebar}
        isOpen={editCantidadSidebar}
      >
        <OffcanvasHeader toggle={toggleEditCantidadSidebar}>
          Agregar Producto
        </OffcanvasHeader>
        <OffcanvasBody>
          <EditProductCantForm
            id={registerId}
            setShowEditCant={setEditCantidadSidebar}
            reload={reload}
          />
        </OffcanvasBody>
      </Offcanvas>
      <Modal isOpen={showDelete} toggle={toggleDelete}>
        <ModalBody>
          <h4>Seguro desea eliminar</h4>
        </ModalBody>
        <ModalFooter>
          <div
            style={{
              display: "flex",
              gap: 10,
            }}
          >
            <Button
              className="btn-tagexpress-secondary"
              onClick={() => setShowDelete(false)}
            >
              Cancelar
            </Button>
            <Button className="btn-tagexpress-danger" onClick={deleteRegister}>
              Eliminar
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      <Table>
        <thead>
          <tr>
            <td >Nombre</td>
            <td >Descripción</td>
            <td >Cantidad</td>
            <td >Estado</td>
            <td >Acciones</td>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id_producto}>
              <td >{item.nombre_producto}</td>
              <td >
                {item.descripcion_producto ? item.descripcion_producto : "-"}
              </td>
              <td >{item.cantidad}</td>
              <td >
                {item.estado === "A" && <Badge color="success">Activo</Badge>}
                {item.estado === "I" && <Badge color="warning">Inactivo</Badge>}
              </td>
              <td >
                <PlusSquare
                  className="pointer"
                  style={{
                    marginRight: 8,
                  }}
                  onClick={() => {
                    toggleEditCantidadSidebar();
                    setRegisterId(item.id_producto);
                  }}
                  data-tip="Agregar producto"
                />
                <Edit
                  className="pointer"
                  style={{
                    marginRight: 8,
                  }}
                  onClick={() => {
                    toggleEditSidebar();
                    setRegisterId(item.id_producto);
                  }}
                  data-tip="Editar producto"
                />
                <Trash2
                  className="pointer"
                  onClick={() => {
                    setShowDelete(true);
                    setRegisterId(item.id_producto);
                  }}
                  data-tip="Eliminar producto"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};
