import React, { useState } from "react";
import { Form, Button } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { ToastContainer } from "react-toastify";
import { notifyError, notifyOk } from "../../../../../utils/utils";
import { s_setDailySaleRechargeState } from "../../../../../services/dailySalesCtl";
import '../dailySales.css';


export const NewRechargeForm = ({ tolls, reload, setShowModalRecharge, store }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const [state, setState] = useState({
    isLoading: false,
    mainError: false,
  });

  const _onSubmit = async (data) => {
    try {
      setState((old) => ({ ...old, isLoading: true }));
      const dataTemp = {
        ...data,
        total: 0.56,
        store: store.punto_de_venta_id
      };
      const resp = await s_setDailySaleRechargeState(dataTemp);
      notifyOk(resp, { autoClose: 3000 });
      reload();
      setShowModalRecharge(false);
      setState((old) => ({ ...old, isLoading: false }));
    } catch (error) {
      setState((old) => ({ ...old, isLoading: false }));
      if (error.response.status === 409) {
        notifyError(error.response.data.message);
        return;
      }
      notifyError(
        "Ocurrió un error al guardar la recarga, por favor inténtelo más tarde."
      );
    }
  };

  return (
    <div>
      <ToastContainer />
      <Form onSubmit={handleSubmit(_onSubmit)}>
        <div className="form-group mb-3">
          <label>Consesión</label>
          <Controller
            control={control}
            name="concesion"
            rules={{
              required: {
                value: true,
                message: "La consesión es requerido",
              },
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <select
                className="form-control"
                onChange={onChange}
                onBlur={onBlur}
                selected={value}
              >
                <option defaultValue="">Seleccione una consesión</option>
                {tolls.map((toll, index) => (
                  <option value={`${toll.descripcion}`} key={index}>
                    {toll.descripcion}
                  </option>
                ))}
              </select>
            )}
          />
          <small className="form-text text-danger">
            {errors.concesion && errors.concesion.message}
          </small>
        </div>
        <div className="form-group mb-3">
          <label>Tipo de pago</label>
          <Controller
            control={control}
            name="tipo_pago"
            rules={{
              required: {
                value: true,
                message: "El tipo de pago es requerido",
              },
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <select
                className="form-control"
                onChange={onChange}
                onBlur={onBlur}
                selected={value}
              >
                <option defaultValue="">Seleccione un tipo de pago</option>
                <option value="EFECTIVO">Efectivo</option>
                <option value="DEPOSITO">Deposito</option>
                <option value="TRANSFERENCIA_CALPRANDINA">
                  Transferencia a cuenta CALPRANDINA
                </option>
              </select>
            )}
          />
          <small className="form-text text-danger">
            {errors.tipo_pago && errors.tipo_pago.message}
          </small>
        </div>
        <div className="form-group mb-3">
          <label>Retención ($)</label>
          <input
            type="number"
            className="form-control"
            placeholder="Retención"
            {...register("retencion")}
            step="0.01"
            min="0.00"
            max="1000.00"
          />
        </div>
        {state.isLoading ? null : <Button type="submit" className="btn-save-sales">Guardar</Button>}
      </Form>
    </div>
  );
};
