import React, { useState } from "react";
import { Form, FormGroup, Label, Button } from "reactstrap";
import { useForm } from "react-hook-form";
import { s_updateCategory } from "../../../../../services/categoryTollVehicles";
import { notifyError, notifyOk } from "../../../../../utils/utils";

const UpdateCategory = ({ closeModal, categoryToUpdate, reload }) => {
  const {
    categoria_vehiculo_peaje,
    sub_categoria_vehiculo,
    estado,
    categoria_vehiculo_peaje_id: id,
  } = categoryToUpdate;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      categoria: categoria_vehiculo_peaje,
      sub_categoria: sub_categoria_vehiculo,
      estado,
    },
  });

  const [state, setState] = useState({
    isLoading: false,
    mainError: false,
  });

  const onSubmit = async (data, e) => {
    try {
      setState((old) => ({ ...old, isLoading: true }));
      const res = await s_updateCategory(id, data);
      if (!res) {
        notifyError("Error al actualizar el vehículo");
        setState((old) => ({ ...old, isLoading: false }));
        return;
      }
      notifyOk("Categoria actualizada");
      closeModal(false);
      setState((old) => ({ ...old, isLoading: false }));
      reload();
    } catch (error) {
      setState((old) => ({ ...old, isLoading: false }));
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup>
        <Label>Categoría (*)</Label>
        <input
          className="form-control"
          placeholder="Ingrese la categoría"
          type="text"
          {...register("categoria", {
            required: {
              value: true,
              message: "La categoría es requerida",
            },
          })}
        />
        <small className="form-text text-danger">
          {errors.categoria && errors.categoria.message}
        </small>
      </FormGroup>
      <FormGroup>
        <Label>Sub-categoría</Label>
        <input
          className="form-control"
          type="text"
          placeholder="Ingrese la sub-categoría"
          {...register("sub_categoria")}
        />
      </FormGroup>
      <FormGroup>
        <Label>Estado</Label>
        <select
          className="form-control"
          {...register("estado", {
            required: {
              value: true,
              message: "El estado es requerida",
            },
          })}
        >
          <option value="A">Activo</option>
          <option value="I">Inactivo</option>
        </select>
        <small className="form-text text-danger">
          {errors.estado && errors.estado.message}
        </small>
      </FormGroup>

      {!state.isLoading && (
        <Button className="btn-tagexpress" color="info">
          Actualizar
        </Button>
      )}
    </Form>
  );
};

export default UpdateCategory;
