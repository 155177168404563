import { headers } from "../utils/utils";
import { api } from "../network";

const registerDeliveryCtlErrors = {
  ERROR_OBTENCION_DATOS: "ERROR_OBTENCION_DATOS",
  ERROR_ACTUALIZANDO_VENTA: "ERROR_ACTUALIZANDO_ENTREGA",
  ERROR_DE_CONSULTA: "ERROR_DE_CONSULTA",
};

async function s_getRegisterDelivery() {
  const response = await api.get("/v1/registerDelivery", {
    headers: headers(),
  });
  if (response && response.status !== 200) {
    throw registerDeliveryCtlErrors.ERROR_OBTENCION_DATOS;
  }

  return response.data.data;
}

async function s_getRegisterDeliveryNotification() {
  const response = await api.get("/v1/registerDelivery?notification=true", {
    headers: headers(),
  });

  if (response && response.status !== 200) {
    throw registerDeliveryCtlErrors.ERROR_OBTENCION_DATOS;
  }

  return response.data;
}

async function s_setRegisterDelivery(data) {
  const response = await api.post("/v1/registerDelivery", data, {
    headers: headers(),
  });

  if (response && response.status !== 200) {
    throw registerDeliveryCtlErrors.ERROR_ACTUALIZANDO_RECARGA;
  }

  return response.data.message;
}

async function s_updateQuantityProduct(data, id) {
  const response = await api.put(`/v1/registerDelivery/add/${id}`, data, {
    headers: headers(),
  });

  if (response && response.status !== 200) {
    throw registerDeliveryCtlErrors.ERROR_ACTUALIZANDO_RECARGA;
  }

  return response.data.message;
}

async function s_getReportRegisterDelivery(data) {
  try {
    const response = await api.get(`/v1/delivery/report/detail?userId=${data.usuario}&initDate=${data.initDate}&finalDate=${data.finalDate}`, {
      headers: headers(),
    });

    console.log(response.data.data);

    return response.data.data;
  } catch (e) {
    return false;
  }

};

async function s_getReportRegisterDeliveryCashier(data) {
  console.log('usurio ', data.user);
  try {
    const response = await api.get(`/v1/cashier/delivery/detail?user_name=${data.user}&initDate=${data.initDate}&finalDate=${data.finalDate}`, {
      headers: headers(),
    });

    console.log(response);

    if (response && response.status !== 200) {
      throw registerDeliveryCtlErrors.ERROR_DE_CONSULTA;
    }

    return response.data.data;
  } catch (error) {
    return error;
  }

}

export {
  s_getRegisterDelivery,
  s_getRegisterDeliveryNotification,
  s_getReportRegisterDeliveryCashier,
  s_setRegisterDelivery,
  s_updateQuantityProduct,
  s_getReportRegisterDelivery
};
