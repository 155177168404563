import React, { useState } from "react";
import { apiClientes } from "../../../../network";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import "./formEmp.style.css";
import { validators } from "../../../../utils/utils";
import { notifyError, notifyOk } from '../../../../utils/utils';
import { validNatural, validNoNatural } from '../../../../utils/validators';
import TheLoader from "../../../elements/theLoader";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { s_getTag } from "../../../../services/tagCtl";
import Proveedores from './proveedores';

//images
import unEje from "../../../../assets/un_eje-removebg-preview.png";
import dosEjes from "../../../../assets/dos_ejes-removebg-preview.png";
import tresEjes from "../../../../assets/tres_ejes-removebg-preview.png";
import cuatroEjes from "../../../../assets/cuatro_ejes-removebg-preview.png";
import cincoEjes from "../../../../assets/cinco_ejes-removebg-preview.png";
import seisEjes from "../../../../assets/seis_ejes-removebg-preview.png";


const HomologacionEmpresaPage = ({ pageName }) => {

  //state for form
  const [concesion1, setconcesion1] = useState("");
  const [concesion2, setconcesion2] = useState("");
  const [concesion3, setconcesion3] = useState("");
  const [concesion4, setconcesion4] = useState("");
  const [nomrazonsocial, setNomRazonSocial] = useState("");
  const [nomreplegal, setNomRepLegal] = useState("");
  const [ruc, setRuc] = useState("");
  const [direccion, setDireccion] = useState("");
  const [telefono, setTelefono] = useState("");
  const [correo, setCorreo] = useState("");
  const [fotocedula, setFotocedula] = useState("");
  const [fotoNombramiento, setFotoNombramiento] = useState("");
  const [fotoMatricula, setFotoMatricula] = useState("");
  //car
  const [placa, setPLaca] = useState("");
  const [marca, setMarca] = useState("");
  const [modelo, setModelo] = useState("");
  const [color, setColor] = useState("");
  //axis
  const [ejes, setEjes] = useState("");
  //tag
  const [numtag, setNumTag] = useState("");
  const [fototag, setFotoTag] = useState("");
  const [tagChkResult, setTagChkResult] = useState('');
  const [proveedor, setProveedor] = useState('');
  const [showProveedores, setShowProveedores] = useState(false);

  const [showLoader, setShowLoader] = useState(false);

  //console.log(proveedor);

  //image size validation
  const Filevalidation = (image) => {
    // Check if any file is selected.
    let fsize = image.size;
    // console.log("imagen ", image);
    // console.log("2 ", fsize);
    let file = Math.round(fsize / 1024);
    // The size of the file.
    if (file >= 5000) {
      return false;
    }
    return true;
  };

  //submit form func
  const handleSubmit = async (e) => {
    e.preventDefault();

    // check cedula
    if (!validNatural(ruc) && !validNoNatural(ruc)) {
      notifyError('Cédula/RUC inválido');
      return;
    }

    if (!concesion1 && !concesion2 && !concesion3 && !concesion4) {
      notifyError('Seleccione una concesión');
      return;
    }

    setShowLoader(true);

    const formTagData = new FormData();

    formTagData.append("concesion1", concesion1);
    formTagData.append("concesion2", concesion2);
    formTagData.append("concesion3", concesion3);
    formTagData.append("concesion4", concesion4);
    formTagData.append("nomrazonsocial", nomrazonsocial);
    formTagData.append("nomreplegal", nomreplegal);
    formTagData.append("ruc", ruc);
    formTagData.append("direccion", direccion);
    formTagData.append("telefono", telefono);
    formTagData.append("correo", correo);
    formTagData.append("fotocedula", fotocedula);
    formTagData.append("fotoNombramiento", fotoNombramiento);
    formTagData.append("fotoMatricula", fotoMatricula);
    formTagData.append("placa", placa);
    formTagData.append("marca", marca);
    formTagData.append("modelo", modelo);
    formTagData.append("color", color);
    formTagData.append("ejes", ejes);
    formTagData.append("numtag", numtag.toUpperCase());
    formTagData.append("fototag", fototag);
    formTagData.append("proveedor", proveedor);

    if (
      nomrazonsocial !== "" &&
      nomreplegal !== "" &&
      ruc !== "" &&
      direccion !== "" &&
      telefono !== "" &&
      correo !== "" &&
      fotocedula !== null &&
      fotoMatricula != null &&
      placa !== "" &&
      marca !== "" &&
      modelo !== "" &&
      color !== "" &&
      ejes !== "" &&
      numtag !== "" &&
      fototag !== null
    ) {
      try {
        const response = await apiClientes.post(
          "/homologaciones/empresa/create",
          formTagData
        );

        if (response) {
          if (response.data.status === "OK") {
            notifyOk('Homologación registrada', { autoClose: 3000 });
            setTimeout(() => {
              window.location.reload();
            }, 3000);
            // console.log("formulario guardado empresa");
          } else {
            notifyError(
              "Hubo un error al procesar la información. Reinténtelo, por favor."
            );
          }
        } else {
          notifyError(
            "Hubo un error al procesar la información. Reinténtelo, por favor."
          );
        }
        // setTagAccount(actTag);
      } catch (error) {
        notifyError(
          "Hubo un error al procesar la información. Reinténtelo, por favor."
        );
        // console.log(error);
      }
    } else {
      notifyError("Todos los campos son obligatorios");
    }

    setShowLoader(false);
  };

  return (
    <div>
      <h3>{pageName}</h3>
      {showLoader ? <TheLoader /> : <></>}

      <ToastContainer style={{ width: "400px" }} />

      {/* <h1 className="text-center">
        FORMULARIO PARA ACTIVACIÓN U HOMOLOGACIÓN DEL TAG
      </h1> */}
      <h1 className="text-center">FORMULARIO EMPRESA</h1>
      {/* <h2 className="text-center">
        Si adquirió el tag o ya dispone uno de otra estación de peaje, llene los
        siguientes campos:
      </h2> */}

      <Form onSubmit={handleSubmit} encType="multipart/form-data">
        <h2 className="text-left mt-5">ACTIVAR TAG EN:</h2>

        <FormGroup check className="pt-3">
          <Label for="concesion1" check>
            <Input
              id="concesion1"
              type="checkbox"
              value="aloag"
              onChange={(e) => {
                setconcesion1(concesion1.length > 0 ? '' : e.target.value);
              }}
            />{" "}
            <p>Peaje Alóag</p>
          </Label>
        </FormGroup>

        <FormGroup check className="pt-3">
          <Label for="concesion2" check>
            <Input
              id="concesion2"
              type="checkbox"
              value="conorte"
              onChange={(e) => {
                setconcesion2(concesion2.length > 0 ? '' : e.target.value);
              }}
            />{" "}
            <p>
              Peajes CONORTE S.A. (Chivería, Yahuachi, Daule, Samborondón, La
              Cadena,Colimes, Guayas Norte)
            </p>
          </Label>
        </FormGroup>

        <FormGroup check className="pt-3">
          <Label for="concesion3" check>
            <Input
              id="concesion3"
              type="checkbox"
              value="concegua"
              onChange={(e) => {
                setconcesion3(concesion3.length > 0 ? '' : e.target.value);
              }}
            />{" "}
            <p>
              Peajes CONCEGUA S.A. (Boliche,Tambo,Milagro, El Triunfo,
              Naranjal,Naranjito)
            </p>
          </Label>
        </FormGroup>

        <FormGroup check className="pt-3 mb-4">
          <Label for="concesion4" check>
            <Input
              id="concesion4"
              type="checkbox"
              value="el pan"
              onChange={(e) => {
                setconcesion4(concesion4.length > 0 ? '' : e.target.value);
              }}
            />{" "}
            <p>Peaje PAN (El Pan)</p>
            {/* <p className="text-danger">
              (No disponible por el momento, estamos trabajando para brindarte
              un mejor servicio)
            </p> */}
          </Label>
        </FormGroup>

        <h5 className="text-left">DATOS PERSONA JURÍDICA (empresas)</h5>

        <FormGroup>
          <Label for="nomRazonSocial">Razón social</Label>
          <Input
            id="nomRazonSocial"
            type="text"
            name="nombre"
            maxLength="50"
            placeholder="Ingrese el nombre de la empresa"
            onChange={(e) => {
              setNomRazonSocial(e.target.value);
            }}
            required
          />
        </FormGroup>

        <FormGroup>
          <Label for="representante">Nombre del Representante Legal:</Label>
          <Input
            id="representante"
            type="text"
            name="representante"
            maxLength="50"
            placeholder="Ingrese el nombre del representante legal"
            onChange={(e) => {
              setNomRepLegal(e.target.value);
            }}
            required
          />
        </FormGroup>

        <FormGroup>
          <Label for="ruc">RUC</Label>
          <Input
            id="ruc"
            type="text"
            name="ruc"
            value={ruc}
            maxLength="13"
            placeholder="Ingrese el RUC de la empresa"
            onChange={(e) => {
              if (validators.onlyNumber(e.target.value)) {
                setRuc(e.target.value);
              }
            }}
            required
          />
        </FormGroup>

        <FormGroup>
          <Label for="direccion">Dirección:</Label>
          <Input
            id="direccion"
            type="text"
            name="direccion"
            placeholder="Ingrese la dirección"
            onChange={(e) => {
              setDireccion(e.target.value);
            }}
            required
          />
        </FormGroup>

        <FormGroup>
          <Label for="telefono">Teléfono convencional o celular</Label>
          <Input
            id="telefono"
            type="text"
            name="telefono"
            value={telefono}
            maxLength="20"
            placeholder="Ingrese su teléfono"
            onChange={(e) => {
              if (validators.onlyNumber(e.target.value)) {
                setTelefono(e.target.value);
              }
            }}
            required
          />
        </FormGroup>

        <FormGroup className="mb-4">
          <Label for="email">Email</Label>
          <Input
            id="email"
            type="email"
            name="email"
            maxLength="50"
            placeholder="Ingrese su correo"
            onChange={(e) => {
              setCorreo(e.target.value);
            }}
            required
          />
        </FormGroup>

        <FormGroup className="mb-4">
          <Label for="imageCedula">
            Suba un pdf o una foto con el anverso y reverso del ruc, si el
            pdf o foto es ilegible no se podrá validar su petición
          </Label>
          <Input
            type="file"
            id="imageCedula"
            onChange={(e) => {
              if (!Filevalidation(e.target.files[0])) {
                notifyError(
                  "El tamaño de la imagen es mayor a 5MB, porfavor ingrese una mas pequeña"
                );
                document.getElementById("imageCedula").value = "";
                return;
              }
              setFotocedula(e.target.files[0]);
            }}
            required
          />
        </FormGroup>

        <FormGroup className="mb-4">
          <Label for="imageNombramiento">
            Suba una foto clara o escanee el nombramiento del Representante
            Legal, si la foto es ilegible no se podrá validar su petición{" "}
          </Label>
          <Input
            type="file"
            id="imageNombramiento"
            onChange={(e) => {
              if (!Filevalidation(e.target.files[0])) {
                notifyError(
                  "El tamaño de la imagen es mayor a 5MB, porfavor ingrese una mas pequeña"
                );
                document.getElementById("imageNombramiento").value = "";
                return;
              }
              setFotoNombramiento(e.target.files[0]);
            }}
            required
          />
        </FormGroup>

        <FormGroup className="mb-4">
          <Label for="imageMatricula">
            Suba una foto clara o escanee la matrícula, si la foto es ilegible no se podrá validar su petición{" "}
          </Label>
          <Input
            type="file"
            id="imageMatricula"
            onChange={(e) => {
              if (!Filevalidation(e.target.files[0])) {
                notifyError(
                  "El tamaño de la imagen es mayor a 5MB, porfavor ingrese una mas pequeña"
                );
                document.getElementById("imageMatricula").value = "";
                return;
              }
              setFotoMatricula(e.target.files[0]);
            }}
            required
          />
        </FormGroup>

        <h5 className="text-left">
          DATOS DE VEHÍCULO (como constan en la matrícula). SI LOS DATOS NO SON
          CORRECTOS, NO SE PODRÁ EFECTUAR LA HOMOLOGACIÓN
        </h5>

        <FormGroup>
          <Label for="placa">Placa</Label>
          <Input
            id="placa"
            type="text"
            name="placa"
            maxLength="15"
            value={placa}
            placeholder="Ingrese la placa del vehículo"
            onChange={(e) => {
              if (validators.onlyPlaca(e.target.value)) {
                setPLaca(e.target.value.toUpperCase());
              }
            }}
            required
          />
        </FormGroup>

        <FormGroup>
          <Label for="marca">Marca</Label>
          <Input
            id="marca"
            type="text"
            name="marca"
            maxLength="50"
            placeholder="Ingrese la marca del vehículo"
            onChange={(e) => {
              setMarca(e.target.value);
            }}
            required
          />
        </FormGroup>

        <FormGroup>
          <Label for="modelo">Modelo</Label>
          <Input
            id="modelo"
            type="text"
            name="modelo"
            maxLength="50"
            placeholder="Ingrese el modelo del vehículo"
            onChange={(e) => {
              setModelo(e.target.value);
            }}
            required
          />
        </FormGroup>

        <FormGroup className="mb-4">
          <Label for="color">Color</Label>
          <Input
            id="color"
            type="text"
            name="color"
            maxLength="50"
            placeholder="Ingrese el color del vehículo"
            onChange={(e) => {
              setColor(e.target.value);
            }}
            required
          />
        </FormGroup>

        <h5 className="text-left">SELECCIONE EL NÚMERO DE EJES</h5>

        <FormGroup tag="fieldset">
          <Row>
            <Col md="4">
              <FormGroup check>
                <Label for="radio1" check>
                  <Input
                    id="radio1"
                    type="radio"
                    name="radio1"
                    value="1"
                    onChange={(e) => {
                      setEjes(e.target.value);
                    }}
                  />{" "}
                  <p>Referencia a vehículo con un eje</p>
                </Label>
                <img src={unEje} alt="un eje" />
              </FormGroup>
            </Col>

            <Col md="4">
              <FormGroup check>
                <Label for="radio2" check>
                  <Input
                    id="radio2"
                    type="radio"
                    name="radio2"
                    value="2"
                    onChange={(e) => {
                      setEjes(e.target.value);
                    }}
                  />{" "}
                  <p> Referencia a vehículo con dos ejes</p>
                </Label>
                <img src={dosEjes} alt="dos ejes" />
              </FormGroup>
            </Col>

            <Col md="4">
              <FormGroup check>
                <Label for="radio3" check>
                  <Input
                    id="radio3"
                    type="radio"
                    name="radio3"
                    value="3"
                    onChange={(e) => {
                      setEjes(e.target.value);
                    }}
                  />{" "}
                  <p>Referencia a vehículo con tres ejes</p>
                </Label>
                <img src={tresEjes} alt="tres ejes" />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md="4">
              <FormGroup check>
                <Label for="radio4" check>
                  <Input
                    id="radio4"
                    type="radio"
                    name="radio4"
                    value="4"
                    onChange={(e) => {
                      setEjes(e.target.value);
                    }}
                  />{" "}
                  <p>Referencia a vehículo con cuatro ejes</p>
                </Label>
                <img src={cuatroEjes} alt="cuatro ejes" />
              </FormGroup>
            </Col>

            <Col md="4">
              <FormGroup check>
                <Label for="radio5" check>
                  <Input
                    id="radio5"
                    type="radio"
                    name="radio5"
                    value="5"
                    onChange={(e) => {
                      setEjes(e.target.value);
                    }}
                  />{" "}
                  <p>Referencia a vehículo con cinco ejes</p>
                </Label>
                <img src={cincoEjes} alt="cinco ejes" />
              </FormGroup>
            </Col>

            <Col md="4">
              <FormGroup check>
                <Label for="radio6" check>
                  <Input
                    id="radio6"
                    type="radio"
                    name="radio6"
                    value="seis ejes"
                    onChange={(e) => {
                      setEjes(e.target.value);
                    }}
                  />{" "}
                  <p>Referencia a vehículo con seis ejes</p>
                </Label>
                <img src={seisEjes} alt="seis ejes" />
              </FormGroup>
            </Col>
          </Row>
        </FormGroup>

        <h5 className="text-left">
          NÚMERO DEL TAG (Escriba cuidadosamente el número impreso en el tag Ej.
          E200XXXXXXX)
        </h5>

        <FormGroup>
          <FormGroup>
            <Label for="numTag">Número TAG</Label>
            <Input
              id="numTag"
              type="text"
              name="numTag"
              maxLength="24"
              value={numtag}
              placeholder="Ingrese el número impreso en el tag Ej. E200XXXXXXX"
              onChange={async (e) => {
                if (validators.onlyHex(e.target.value)) {
                  setNumTag(e.target.value.toUpperCase());

                  if (e.target.value.length === 24) {
                    try {
                      const resp = await s_getTag(e.target.value);
                      if (resp.esTagexpress) {
                        if (resp.disponible) {
                          setTagChkResult('Este es un tag oficial de TagExpress  👍');
                          setShowProveedores(false);
                          setProveedor('TagExpress');
                        }
                        else {
                          setTagChkResult('Tag ya se encuentra homologado en al menos una concesión 👌');
                          setShowProveedores(false);
                        }
                      }
                      // Tag is not tagexpress's
                      else {
                        // Tag is already homologated
                        if (resp.disponible) {
                          // Allow user to choose proveedor in proveedores container
                          setTagChkResult('Este no es un tag oficial de TagExpress 🤔');
                          setShowProveedores(true);
                        }
                        else {
                          setTagChkResult('Tag ya se encuentra homologado en al menos una concesión');
                          setShowProveedores(false);
                        }
                      }
                    }
                    catch (e) {
                      notifyError('Error al verificar estado de tag');
                      setShowProveedores(false);
                      setTagChkResult('');
                    }
                  }
                  else {
                    setShowProveedores(false);
                    setTagChkResult('');
                  }
                }
              }}
              required
            />
            <Label style={{
              display: tagChkResult.length > 0 ? 'block' : 'none', fontSize: '.8rem', color: '#000'
            }}>{tagChkResult}</Label>
          </FormGroup>

          {showProveedores ? <Proveedores setProveedor={setProveedor} /> : null}

          <FormGroup>
            <Label for="imageTag">
              Suba una foto clara del tag y de tamaño legible, si la foto es
              ilegible no se podrá validar su petición
            </Label>
            <Input
              type="file"
              accept="image/png,image/jpeg,image/bmp"
              id="imageTag"
              onChange={(e) => {
                if (!Filevalidation(e.target.files[0])) {
                  notifyError(
                    "El tamaño de la imagen es mayor a 5MB, porfavor ingrese una mas pequeña"
                  );
                  document.getElementById("imageTag").value = "";
                  return;
                }
                setFotoTag(e.target.files[0]);
              }}
              required
            />
          </FormGroup>
        </FormGroup>

        <Button>Enviar</Button>
      </Form>
    </div>
  );
};

export default HomologacionEmpresaPage;
