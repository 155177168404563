import React, { useState, useCallback, useEffect } from "react";
import { PlusSquare, RefreshCcw } from "react-feather";
import { ToastContainer } from "react-toastify";
import { Row, Modal, Button, ModalHeader, ModalBody } from "reactstrap";
import { NewRegisterProviderForm } from "./components/NewRegisterProviderForm";
import { TableProvider } from "./components/TableProvider";
import { api } from "../../../../../network";
import { headers, notifyError } from "../../../../../utils/utils.js";

const RegisterProveedor = () => {
  const [showModalNewRegister, setShowModalNewRegister] = useState(false);
  const toggle = () => setShowModalNewRegister(!showModalNewRegister);

  const [data, setData] = useState({
    registerProvider: [],
    products: [],
    reload: false,
    loading: true,
  });

  const reload = () =>
    setData((old) => ({
      registerProvider: [],
      products: [],
      reload: !old.reload,
      loading: !old.loading,
    }));

  const getProduct = useCallback(async () => {
    try {
      setData((old) => ({ ...old, loading: true }));
      const response = await api.get("/v1/productos", {
        headers: headers(), // ask only for active personas
      });

      if (response && response.status !== 200) {
        notifyError("ERROR");
        return;
      }

      setData((old) => ({
        ...old,
        loading: false,
        products: response.data.data,
      }));
    } catch (e) {
      notifyError("ERROR");
    }
  }, []);

  const getRegisterProvider = useCallback(async () => {
    try {
      setData((old) => ({ ...old, loadingprovider: true }));
      const response = await api.get(`/v1/proveedores`, {
        headers: headers(), // ask only for active personas
      });

      if (response && response.status !== 200) {
        notifyError("ERROR");
        return;
      }

      setData((old) => ({
        ...old,
        loadingprovider: false,
        registerProvider: response.data.data,
      }));
    } catch (e) {
      notifyError("ERROR");
    }
  }, []);

  useEffect(() => {
    getProduct();
    getRegisterProvider();
  }, [data.reload, getProduct, getRegisterProvider]);

  return (
    <div>
      <ToastContainer style={{ width: "400px" }} />
      <Modal isOpen={showModalNewRegister} toggle={toggle}>
        <ModalHeader toggle={toggle} style={{ color: "var(--blue)" }}>
          Ingresar Nuevo Proveedor
        </ModalHeader>
        <ModalBody>
          <NewRegisterProviderForm
            setShowModalNewRegister={setShowModalNewRegister}
            reload={reload}
          />
        </ModalBody>
      </Modal>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        className="mb-4"
      >
        <h1 style={{ color: "var(--blue)" }}>Proveedor</h1>
        <div>
          <Button color="warning" style={{ marginRight: 20 }} onClick={reload}>
            <RefreshCcw />
          </Button>
          <Button
            style={{ marginRight: 20 }}
            onClick={() => setShowModalNewRegister(true)}
          >
            <PlusSquare />
          </Button>
        </div>
      </div>
      <Row>
        {data.loading ? (
          <h1>Cargando</h1>
        ) : (
          <>
            {data.products.length > 0 ? (
              <TableProvider data={data.registerProvider} reload={reload} />
            ) : (
              <div className="mt-5">
                <h1 className="alert-title">
                  No hay registro de proveedores activos por el momento
                </h1>
              </div>
            )}
          </>
        )}
      </Row>
    </div>
  );
};

export default RegisterProveedor;
