import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Row,
  Table,
} from "reactstrap";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import { CheckCircle, PlusSquare, Trash2 } from "react-feather";
import ClearFilter from "../../../../assets/ic_filter.svg";
import {
  domain,
  excelFilter,
  notifyError,
  notifyOk,
} from "../../../../utils/utils";
import TheLoader from "../../../elements/theLoader";
import "./compensacion.css";
import {
  s_getCompensationsEnte,
  s_updataStateCompensationsEnte,
} from "../../../../services/compensacionCtl";
import excelImg from "../../../../assets/Excel-Logo.png";
import XLSX from "xlsx";
import { CommitCompensationModal } from "./components/commitCompensationModal";
import { DeleteCompensationModal } from "./components/deleteCompensationModal";
import { CreateCompensationModal } from "./components/createCompensationModal";
import { ToastContainer } from "react-toastify";

const ContableCompensacion = ({ pageName }) => {
  const { register, handleSubmit, reset, getValues } = useForm();

  const [data, setData] = useState({
    compensations: [],
    total: 0,
    reload: false,
    loading: true,
    loadingFilter: false,
  });

  const [total, setTotal] = useState(0);
  const [updateState, setUpdateState] = useState([]);
  const [showModalCompensation, setShowModalCompensation] = useState(false);
  const [showModalCreateCompensation, setShowModalCreateCompensation] =
    useState(false);
  const [showModalDeleteCompensation, setShowModalDeleteCompensation] =
    useState(false);
  const toggleCompensation = () =>
    setShowModalCompensation(!showModalCompensation);
  const toggleCreateCompensation = () =>
    setShowModalCreateCompensation(!showModalCreateCompensation);
  const toggleDeleteCompensation = () =>
    setShowModalDeleteCompensation(!showModalDeleteCompensation);

  useEffect(() => {
    getCompensations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.reload]);

  const getCompensations = async () => {
    try {
      setData((old) => ({
        ...old,
        loading: true,
      }));
      const data = getValues();
      const resp = await s_getCompensationsEnte(data);
      setData((old) => ({
        ...old,
        compensations: resp.data,
        total: resp.total,
        revenues: resp.revenues,
        expenses: resp.expenses,
        loading: false,
      }));
    } catch (error) {
      notifyError("ERROR");
    }
  };

  const clearFilter = () => {
    reset();
    setData((old) => ({
      ...old,
      compensations: [],
    }));
    getCompensations();
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      setData((old) => ({
        ...old,
        loadingFilter: true,
      }));

      const resp = await s_getCompensationsEnte(data);
      setData((old) => ({
        ...old,
        loadingFilter: false,
        compensations: resp.data,
        total: resp.total,
        revenues: resp.revenues,
        expenses: resp.expenses,
      }));
    } catch (error) {
      setData((old) => ({
        ...old,
        loadingFilter: false,
      }));
      notifyError("ERROR");
    }
  };

  const onChangeCheck = (e, compensation) => {
    if (e.target.checked) {
      setTotal(total + compensation.total);
      setUpdateState((old) => [...old, compensation.transaccion_id]);
    } else {
      const newData = updateState.filter(
        (item) => item !== compensation.transaccion_id
      );
      setTotal(total - compensation.total);
      setUpdateState(newData);
    }
  };

  const handleCompensationCommit = async () => {
    try {
      setData((old) => ({
        ...old,
        loading: true,
      }));
      await s_updataStateCompensationsEnte({
        metodo: "update",
        data: updateState,
      });
      setData((old) => ({
        ...old,
        loading: false,
        reload: !old.reload,
      }));
      setShowModalCompensation(false);
      notifyOk("Las compensaciones se confirmaron correctamente.");
      setUpdateState([]);
    } catch (error) {
      setData((old) => ({
        ...old,
        loading: false,
      }));
      notifyError("ERROR");
    }
  };

  const handleCompensationDelete = async () => {
    try {
      setData((old) => ({
        ...old,
        loading: true,
      }));
      await s_updataStateCompensationsEnte({
        metodo: "delete",
        data: updateState,
      });
      setData((old) => ({
        ...old,
        loading: false,
        reload: !old.reload,
      }));
      setShowModalDeleteCompensation(false);
      notifyOk("Las compensaciones se eliminaron correctamente.");
      setUpdateState([]);
    } catch (error) {
      setData((old) => ({
        ...old,
        loading: false,
      }));
      notifyError("ERROR");
    }
  };

  const handleOnExport = () => {
    const filterArray = excelFilter(data.compensations, [
      "codigo_transaccion",
      "descripcion",
      "dominio",
      "created",
      "fecha_compensacion",
      "ente_externo_description",
      "monto",
      "subtotal",
      "iva",
      "total",
    ]);

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(filterArray);

    XLSX.utils.book_append_sheet(wb, ws, "Reporte compensación");

    XLSX.writeFile(wb, "Reporte compensación.xlsx");
  };

  const onCompensationCreationSuccess = () => {
    setData((old) => ({
      compensations: [],
      total: 0,
      reload: !old.reload,
      loading: !old.loading,
      loadingFilter: old.loadingFilter,
    }));

    setShowModalCreateCompensation(false);
  }

  return (
    <div
      style={{ display: "grid", gridTemplateRows: "1fr 3fr", height: "100%" }}
    >
      <CreateCompensationModal
        toggle={toggleCreateCompensation}
        isOpen={showModalCreateCompensation}
        onSuccess={onCompensationCreationSuccess}
      />

      <CommitCompensationModal
        toggle={toggleCompensation}
        isOpen={showModalCompensation}
        total={total}
        onYes={handleCompensationCommit}
      />

      <DeleteCompensationModal
        toggle={toggleDeleteCompensation}
        isOpen={showModalDeleteCompensation}
        onYes={handleCompensationDelete}
      />

      <div>
        <ToastContainer style={{ width: "400px" }} />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          className="mb-4"
        >
          <h3 style={{ margin: 0 }}>{pageName}</h3>
          <div>
            <Button onClick={() => setShowModalCreateCompensation(true)}>
              <PlusSquare />
            </Button>
          </div>
        </div>
        <Card>
          <CardHeader>Filtro</CardHeader>
          <CardBody>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col md={3}>
                  <div className="form-group mb-3">
                    <label>Desde:</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Fecha de inicio"
                      {...register("start_date")}
                      defaultValue={dayjs(new Date()).format("YYYY-MM-DD")}
                    />
                  </div>
                </Col>
                <Col md={3}>
                  <div className="form-group mb-3">
                    <label>Hasta:</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Fecha de inicio"
                      {...register("end_date")}
                      defaultValue={dayjs(new Date()).format("YYYY-MM-DD")}
                    />
                  </div>
                </Col>
                <Col md={3}>
                  <div className="form-group mb-3">
                    <label>Ente Externo:</label>
                    <select
                      className="form-control"
                      {...register("external_entity")}
                      defaultValue={""}
                    >
                      <option value="">Seleccione un ente externo</option>
                      <option value="WESTUNIO">Western Union</option>
                    </select>
                  </div>
                </Col>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  {data.compensations.length > 0 && (
                    <img
                      src={excelImg}
                      alt="excel"
                      style={{ height: "40px" }}
                      onClick={handleOnExport}
                    />
                  )}
                  <Button
                    type="button"
                    color="light"
                    className="mx-2"
                    onClick={clearFilter}
                  >
                    <img
                      src={ClearFilter}
                      width="20px"
                      style={{ marginRight: 10 }}
                      alt=""
                    />
                    Limpiar filtro
                  </Button>
                  <Button color="success">Buscar</Button>
                </div>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          overflowY: "hidden",
        }}
      >
        {data.loading || data.loadingFilter ? (
          <TheLoader />
        ) : (
          <>
            {data.compensations.length > 0 ? (
              <>
                <div className="m-3">
                  {updateState.length > 1 && (
                    <div className="d-flex justify-content-between">
                      <div>
                        <Button onClick={() => setShowModalCompensation(true)}>
                          Realizar Compensación masiva
                        </Button>
                        <Button
                          className="mx-2"
                          color="danger"
                          onClick={() => setShowModalDeleteCompensation(true)}
                        >
                          Eliminar compensaciones
                        </Button>
                      </div>
                      <span>
                        El total de las compensaciones seleccionadas es de{" "}
                        <b>$ {total}.</b>
                      </span>
                    </div>
                  )}
                </div>
                <Card
                  style={{
                    height: "100%",
                    overflowY: "scroll",
                  }}
                >
                  <CardBody>
                    <Table>
                      <thead style={{ backgroundColor: "#e8b800" }}>
                        <tr style={{ textAlign: "center" }}>
                          <th></th>
                          <th>Código</th>
                          <th>Descripción</th>
                          <th>Dominio</th>
                          <th>Fecha</th>
                          <th>Fecha Compensación</th>
                          <th>Ente externo</th>
                          <th>Monto</th>
                          <th>Comisión</th>
                          <th>IVA</th>
                          <th>Total</th>
                          <th>Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.compensations.map((compensation, index) => (
                          <tr style={{ textAlign: "center" }} key={index}>
                            <th>
                              {compensation.codigo_ente_externo &&
                                compensation.compensacion_ente_externo !==
                                "P" && (
                                  <input
                                    type="checkbox"
                                    onChange={(e) =>
                                      onChangeCheck(e, compensation)
                                    }
                                  />
                                )}
                            </th>
                            <th scope="row">
                              {compensation.codigo_transaccion}
                            </th>
                            <th style={{ textAlign: "start" }}>
                              {compensation.descripcion}
                            </th>
                            <th>{domain(compensation.dominio)}</th>
                            <th>{compensation.created}</th>
                            <th>
                              {compensation.fecha_compensacion
                                ? `${compensation.fecha_compensacion}`
                                : `-`}
                            </th>

                            <th>
                              {compensation.ente_externo_description
                                ? `${compensation.ente_externo_description}`
                                : `-`}
                            </th>
                            <th>${compensation.monto}</th>
                            <th>${compensation.subtotal}</th>
                            <th>${compensation.iva}</th>
                            <th>${compensation.total}</th>
                            <th>
                              {compensation.codigo_ente_externo &&
                                compensation.compensacion_ente_externo !==
                                "P" && (
                                  <>
                                    <CheckCircle
                                      className="pointer"
                                      style={{
                                        marginRight: 8,
                                        color: "#198754",
                                      }}
                                      onClick={() => {
                                        setUpdateState([
                                          compensation.transaccion_id,
                                        ]);
                                        setShowModalCompensation(true);
                                        setTotal(compensation.total);
                                      }}
                                    />
                                    <Trash2
                                      className="pointer"
                                      style={{ marginRight: 8, color: "red" }}
                                      onClick={() => {
                                        setUpdateState([
                                          compensation.transaccion_id,
                                        ]);
                                        setShowModalDeleteCompensation(true);
                                      }}
                                    />
                                  </>
                                )}
                            </th>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
                <div className="m-3 d-flex justify-content-end">
                  <div className="h4">
                    <b>Total:</b>{" "}
                    <span className="mx-3">{data.total.toFixed(2)}</span>
                  </div>
                </div>
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <h2>No existe ninguna compensación</h2>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ContableCompensacion;
