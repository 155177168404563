import React from "react";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import L from "leaflet";

import IconMarker from "../../../assets/venue_location_icon.svg";

import "leaflet/dist/leaflet.css";

export const MapView = ({ lat, lng }) => {
  const position = [lat, lng];
  return (
    <MapContainer
      style={{ width: "100%", height: 200, marginBottom: 20 }}
      center={position}
      zoom={15}
      scrollWheelZoom={false}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <Marker position={position} icon={VenueLocationIcon} />
    </MapContainer>
  );
};

const VenueLocationIcon = L.icon({
  iconUrl: IconMarker,
  iconRetinaUrl: IconMarker,
  iconAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: [40, 35],
  className: "leaflet-venue-icon",
});
