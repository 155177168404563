import React from "react";
import dayjs from "dayjs";
import { Monitor, Smartphone } from "react-feather";
import { UncontrolledTooltip } from "reactstrap";
import { formatEstado } from "../utils/utils";

export const DetailsRequest = ({ details }) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          gap: 10,
        }}
      >
        <h6 style={{ margin: 0 }}>{formatEstado(details)}</h6>
        {details.usuario_modo_de_solicitud === "web" && (
          <>
            <Monitor id="web" />
            <UncontrolledTooltip placement="top" target="web">
              Web
            </UncontrolledTooltip>
          </>
        )}
        {details.usuario_modo_de_solicitud === "mobile" && (
          <>
            <Smartphone id="mobile" />
            <UncontrolledTooltip placement="top" target="mobile">
              Móvil
            </UncontrolledTooltip>
          </>
        )}
      </div>
      {details.usuario_modo_de_solicitud === "mobile" && (
        <>
          <h6>Detalle del usuario</h6>
          <ul>
            <li>
              <strong>Nombres: </strong>
              {details.usuario_nombres}
            </li>
            <li>
              <strong>Teléfono: </strong>
              {details.usuario_telefono}
            </li>
            <li>
              <strong>Placa: </strong>
              {details.usuario_placa}
            </li>
            <li>
              <strong>Tipo de vehículo: </strong>
              {details.tipo_vehiculo}
            </li>
            <li>
              <strong>Descripción: </strong>
              {details.usuario_descripcion_asistencia}
            </li>
          </ul>
        </>
      )}
      {details.fecha_registro && (
        <>
          <h6>Vehículos asignados</h6>
          <ul>
            {details.fecha_registro ? (
              <>
                {details.vehiculo_asistencia.map((vehiculo, index) => {
                  return (
                    <li key={index}>{`${vehiculo.categoria_vehiculo_peaje} ${vehiculo.placa}`}</li>
                  );
                })}
              </>
            ) : (
              <>-</>
            )}
          </ul>
          <h6>Datos de la solicitud</h6>
          <ul>
            <li>
              <strong>Descripción asistencia: </strong> {details.descripcion}
            </li>
            <li>
              <strong>Usuario: </strong> {details.user_registro}
            </li>
            <li>
              <strong>Fecha de creación: </strong>
              {dayjs(details.created_at).format("YYYY-MM-DD HH:mm:ss")}
            </li>
            <li>
              <strong>Fecha de atención: </strong> {details.fecha_registro}
            </li>
            <li>
              <strong>Tiempo asignado: </strong>
              {details.tiempo_asignado
                ? `${details.tiempo_asignado} minutos`
                : "-"}
            </li>
            <li>
              <strong>Tiempo real: </strong>
              {details.tiempo_real ? `${details.tiempo_real} minutos` : "-"}
            </li>
          </ul>
        </>
      )}
      {details.fecha_solucion && (
        <>
          <h6>Datos de solución</h6>
          <ul>
            <li>
              <strong>Descripción: </strong>
              {details.detalle_solucion}
            </li>
            <li>
              <strong>Fecha: </strong>
              {details.fecha_solucion}
            </li>
            <li>
              <strong>Usuario: </strong> {details.user_solucion}
            </li>
          </ul>
        </>
      )}
      {details.fecha_cancelacion && (
        <>
          <h6>Motivo de cancelación</h6>
          <ul>
            <li>
              <strong>Descripción: </strong>
              {details.detalle_cancelacion}
            </li>
            <li>
              <strong>Fecha: </strong>
              {details.fecha_cancelacion}
            </li>
            <li>
              <strong>Usuario: </strong> {details.user_cancelacion}
            </li>
          </ul>
        </>
      )}
    </>
  );
};
