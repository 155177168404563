import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import sosPhone from "../../../../assets/emergency-call.png";
import ambulance from "../../../../assets/ambulance.png";
import timer from "../../../../assets/timer.png";
import { secondsToHMS } from "../../../../utils/utils";

export const AssistanceHeader = ({ activeAssistances, availableVehicles, averageAssitanceDuration }) => {
  const averageAssitanceDurationInSeconds = Math.round(averageAssitanceDuration * 60);

  return (
    <Row className="mb-3 gap-2 gap-md-0">
      <Col md={4}>
        <Card>
          <CardBody
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img
              src={sosPhone}
              style={{ width: 45, height: 45 }}
              alt="react.js"
              aria-hidden={true}
            />
            <div style={{ display: "flex", gap: 10, justifyContent: "center" }}>
              <div className="m-l" style={{ textAlign: "end" }}>
                <h2 className="h4">
                  {activeAssistances}
                </h2>
                <p className="text-success" style={{ margin: 0 }}>
                  Activas
                </p>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col md={4}>
        <Card>
          <CardBody
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img
              src={ambulance}
              style={{ width: 60, height: 60 }}
              alt="Ambulance"
              aria-hidden={true}
            />
            <div className="m-l" style={{ textAlign: "end" }}>
              <h2 className="h4">{availableVehicles}</h2>
              <p className="text-muted" style={{ margin: 0 }}>
                Vehículos disponibles
              </p>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col md={4}>
        <Card>
          <CardBody
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img
              src={timer}
              style={{ width: 50, height: 50 }}
              alt="Bootstrap"
              aria-hidden={true}
            />
            <div className="m-l" style={{ textAlign: "end" }}>
              <h2 className="h4">{secondsToHMS(averageAssitanceDurationInSeconds)}</h2>
              <p className="text-muted" style={{ margin: 0 }}>
                Promedio de tiempo asistencias
              </p>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
