import { routesByRole } from "./routes/routesByRole";

const getRoutesByRole = (role) => {
  switch (role) {
    case "usuariot1000":
      return routesByRole.usuarioT1000;
    case "admin_asistencia_vial":
      return routesByRole.adminAsistVial;
    case "admin_tagexpress":
      return routesByRole.adminTagexpress;
    case "admin_wallet":
      return routesByRole.adminWallet;
    case "operador_tagexpress":
      return routesByRole.opTagexpress;
    case "operador_asistencia_vial":
      return routesByRole.operador_asistencia_vial;
    case "contabilidad_tagexpress":
      return routesByRole.contabilidad_tagexpress;
    case "cau":
      return routesByRole.cau;
    case "asistencia_cliente_tagexpress":
      return routesByRole.asistencia_cliente_tagexpress;
    case "cau_estado_via":
      return routesByRole.cauEstadoVia;
    case "asistencia_unlock":
      return routesByRole.asistencia_unlock;
    default:
      return [];
  }
};

export {
  getRoutesByRole
};