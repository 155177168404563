import { Button, Form, FormGroup, Label } from "reactstrap";
import { useCentralContext } from "../../../../../centralContext";
import { useForm } from "react-hook-form";
import { Fragment, useState } from "react";
import {
  headers,
  notifyError,
  notifyInfo,
  notifyOk,
} from "../../../../../utils/utils";
import { api } from "../../../../../network";
import ROLES from "../../../../../utils/roles";

function UpdateAssistanceForm({
  selectedVehicle,
  vehicleCategories,
  reload,
  onClose,
  peajes,
}) {
  const centralContext = useCentralContext();

  const {
    categoria_vehiculo_peaje_id,
    codigo_vehiculo,
    kilometraje,
    placa,
    estado,
  } = selectedVehicle.vehiculo_asistencium;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      categoria: categoria_vehiculo_peaje_id,
      codigo_vehiculo,
      kilometraje,
      placa,
      peaje_id: selectedVehicle.peaje_id,
    },
  });

  const [state, setState] = useState({
    isLoading: false,
    mainError: false,
  });

  const onSubmit = async (data, e) => {
    try {
      if (!/[A-Z]{3}[-]\d{3}/g.test(data.placa)) {
        setState((old) => ({ ...old, isLoading: false }));
        return notifyError(
          "La placa debe tener el siguiente formato, por ejemplo PKG-7891"
        );
      }
      setState((old) => ({ ...old, isLoading: true }));
      const response = await api.put(
        "/v1/asistVial/vehiculos/update/" +
          selectedVehicle.vehiculo_asistencia_id,
        {
          ...data,
          estado: selectedVehicle.estado,
          kilometraje: data.kilometraje.toString(),
        },
        { headers: headers() }
      );

      if (response) {
        if (response.data.status === "OK") {
          notifyOk("Vehículo modificado");
          reload();
          onClose();
          setState((old) => ({ ...old, isLoading: false }));
        } else {
          notifyError("Error al modificar vehículo");
        }
      } else {
        notifyInfo("No hay conexión con el servidor");
      }
    } catch (e) {
      setState((old) => ({ ...old, isLoading: false }));
    }
  };

  async function _handleDisableVehiculo(e) {
    e.preventDefault();
    setState((old) => ({ ...old, isLoading: true }));
    try {
      const response = await api.put(
        "/v1/asistVial/vehiculos/disable/" +
          selectedVehicle.vehiculo_asistencia_id,
        {},
        { headers: headers() }
      );
      if (response) {
        if (response.data.status === "OK") {
          if (selectedVehicle.estado === "A") {
            notifyOk("Vehículo deshabilitado");
          }
          reload();
          onClose();
          setState((old) => ({ ...old, isLoading: false }));
        } else {
          notifyError("Error al deshabilitar el vehículo");
        }
      } else {
        notifyInfo("No hay conexión con el servidor");
      }
    } catch (e) {
      setState((old) => ({ ...old, isLoading: false }));
    }
  }

  async function _handleEnableVehiculo(e) {
    e.preventDefault();
    setState((old) => ({ ...old, isLoading: true }));
    try {
      const response = await api.put(
        "/v1/asistVial/vehiculos/enable/" +
          selectedVehicle.vehiculo_asistencia_id,
        {},
        { headers: headers() }
      );
      if (response) {
        if (response.data.status === "OK") {
          if (selectedVehicle.estado === "I") {
            notifyOk("Vehículo habilitado");
          }
          setState((old) => ({ ...old, isLoading: false }));
          reload();
          onClose();
        } else {
          notifyError(
            "Error al habilitar el vehículo, por favor intentelo nuevamente"
          );
        }
      } else {
        notifyInfo("No hay conexión con el servidor");
      }
    } catch (e) {
      setState((old) => ({ ...old, isLoading: false }));
    }
  }

  return (
    <>
      <h3>{placa}</h3>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Label>Categoría: (*)</Label>
          <select
            {...register("categoria", {
              required: {
                value: true,
                message: "La categoría es requerida",
              },
            })}
            className="form-control"
          >
            <option value="">Seleccione una categoría...</option>
            {vehicleCategories.length > 0 ? (
              vehicleCategories.map((vehicleCategoryType, index) => {
                return (
                  <Fragment key={index}>
                    {vehicleCategoryType.estado === "A" ? (
                      <option
                        value={vehicleCategoryType.categoria_vehiculo_peaje_id}
                      >
                        {vehicleCategoryType.categoria_vehiculo_peaje +
                          " / " +
                          vehicleCategoryType.sub_categoria_vehiculo}
                      </option>
                    ) : null}
                  </Fragment>
                );
              })
            ) : (
              <option value={0}>No hay categorías disponibles</option>
            )}
          </select>
          <small className="form-text text-danger">
            {errors.categoria && errors.categoria.message}
          </small>
        </FormGroup>
        <FormGroup>
          <Label>Código vehículo: (*)</Label>
          <input
            className="form-control"
            type="text"
            placeholder="Ingrese el código vehículo"
            {...register("codigo_vehiculo", {
              required: {
                value: true,
                message: "El código vehículo es requerido",
              },
            })}
          />
          <small className="form-text text-danger">
            {errors.codigo_vehiculo && errors.codigo_vehiculo.message}
          </small>
        </FormGroup>
        <FormGroup>
          <Label>Placa: (*)</Label>
          <input
            className="form-control"
            type="text"
            maxLength={8}
            placeholder="Ingrese la placa"
            {...register("placa", {
              required: {
                value: true,
                message: "La placa es requerido",
              },
            })}
            defaultValue={selectedVehicle.placa}
          />
          <small className="form-text text-danger">
            {errors.placa && errors.placa.message}
          </small>
        </FormGroup>
        <FormGroup>
          <Label>Kilometraje: (*)</Label>
          <input
            className="form-control"
            type="number"
            maxLength={8}
            placeholder="Ingrese el kilometraje"
            {...register("kilometraje", {
              required: {
                value: true,
                message: "El kilometraje es requerido",
              },
            })}
            defaultValue={selectedVehicle.kilometraje}
          />
          <small className="form-text text-danger">
            {errors.kilometraje && errors.kilometraje.message}
          </small>
        </FormGroup>
        <FormGroup>
          {centralContext.role === ROLES.usuariot1000 ? (
            <>
              <Label>Peaje: (*)</Label>
              <select
                {...register("peaje_id", {
                  required: {
                    value: true,
                    message: "El peaje es requerida",
                  },
                })}
                className="form-control"
              >
                <option value="">Seleccione un peaje</option>
                {peajes.map((p) => (
                  <option value={p.peaje_id} key={p.peaje_id}>
                    {p.descripcion}
                  </option>
                ))}
              </select>
              <small className="form-text text-danger">
                {errors.peaje_id && errors.peaje_id.message}
              </small>
            </>
          ) : null}
        </FormGroup>
        <div
          style={{
            display: "flex",
            gap: 10,
          }}
        >
          {state.isLoading ? null : (
            <>
              <Button className="btn-tagexpress">Modificar</Button>
              {estado === "A" ? (
                <Button
                  className="btn-tagexpress-danger"
                  onClick={_handleDisableVehiculo}
                  type="button"
                >
                  Deshabilitar vehículo
                </Button>
              ) : (
                <Button
                  className="btn-tagexpress-success"
                  onClick={_handleEnableVehiculo}
                  type="button"
                >
                  Habilitar vehículo
                </Button>
              )}
            </>
          )}
        </div>
      </Form>
    </>
  );
}
export default UpdateAssistanceForm;
