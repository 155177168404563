import { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Row } from "reactstrap";
import { notifyError, notifyOk } from "../../../../../utils/utils";
import { s_updateHomologacion } from "../../../../../services/homologacionCtl";

function EditStatusObser({ homologacion, onClose }) {
  const { register, handleSubmit } = useForm();

  const [state, setState] = useState({
    isLoading: false,
    mainError: false,
  });

  const onSubmit = async (data, e) => {
    e.preventDefault();

    try {
      setState((old) => ({ ...old, isLoading: true }));
      const resp = await s_updateHomologacion(
        data,
        homologacion.id_homologacion
      );
      notifyOk(resp, { autoClose: 3000 });
      notifyOk("Homologación editada", { autoClose: 3000 });
      onClose();
      setState((old) => ({ ...old, isLoading: false }));
    } catch (error) {
      setState((old) => ({ ...old, isLoading: false }));
      if (error.response.status === 409) {
        notifyError(error.response.data.message);
        return;
      }
      notifyError(
        "Ocurrió un error al crear la asistencia, por favor inténtelo más tarde."
      );
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <div className="form-group mb-3">
          <label>Observación:</label>
          <input
            {...register("observacion")}
            defaultValue={homologacion.observacion}
            className="form-control"
            placeholder="Ingrese una observacion"
          />
        </div>
        <div className="form-group mb-3">
          <label>Estado:</label>
          <select
            {...register("status")}
            defaultValue={homologacion.status}
            className="form-control chosen-select"
          >
            <option value="">Seleccione un estado</option>
            <option value="PENDIENTE">Pendiente</option>
            <option value="EN_PROCESO">En progreso</option>
            <option value="FINALIZADA">Finalizada</option>
            <option value="RECHAZADO">Rechazado</option>
            <option value="EDITAR">Editar</option>
          </select>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {state.isLoading ? null : (
            <Button className="btn-tagexpress" type="submit">
              Guardar
            </Button>
          )}
        </div>
      </Row>
    </Form>
  );
}
export default EditStatusObser;
