import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Label, Row } from "reactstrap";
import { s_postAcceptAsistVial } from "../../../../services/asistVialCtl";
import { notifyError, notifyOk } from "../../../../utils/utils";
import { MapView } from "../../../elements/mapsView/MapView";
import "../asistencia.css";
import { formatTimeInMinute } from "../utils/utils";

export const AcceptRequestForm = ({ evento, vehicle, onClose }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [state, setState] = useState({
    isLoading: false,
    mainError: false,
  });

  const onSubmit = async (data, e) => {
    const timeAssigned = formatTimeInMinute(data.tiempo_asignado);
    if (timeAssigned <= 0) {
      notifyError("El tiempo de asistencia no puede ser 0", {
        autoClose: 3000,
      });
      return;
    }
    const vehicleSelected = vehicle.filter(
      (item) => item.vehiculo_asistencia_id === parseInt(data.vehicle)
    );
    console.log(vehicleSelected);
    const dataTemp = {
      ...data,
      vehiculo: [data.vehicle],
      evento_asistencia_id: evento.evento_asistencia_id,
      tiempo_asignado: timeAssigned,
      kilometraje_salida:
        vehicleSelected.length > 0
          ? vehicleSelected[0].vehiculo_asistencium.kilometraje
          : null,
    };
    console.log(dataTemp);
    try {
      setState((old) => ({ ...old, isLoading: true }));
      const resp = await s_postAcceptAsistVial(dataTemp);
      notifyOk(resp, { autoClose: 3000 });
      notifyOk("Asistencia atendida de manera correcta", { autoClose: 3000 });
      onClose();
      setState((old) => ({ ...old, isLoading: false }));
    } catch (error) {
      setState((old) => ({ ...old, isLoading: false }));
      if (error.response.status === 409) {
        notifyError(error.response.data.message);
        return;
      }
      notifyError(
        "Ocurrió un error al crear la asistencia, por favor inténtelo más tarde."
      );
    }
  };

  return (
    <>
      <h6>Detalle de la solucitud</h6>
      <ul>
        <li>
          <strong>Nombres: </strong>
          {evento.usuario_nombres}
        </li>
        <li>
          <strong>Teléfono: </strong>
          {evento.usuario_telefono}
        </li>
        <li>
          <strong>Placa: </strong>
          {evento.usuario_placa}
        </li>
        <li>
          <strong>Tipo de vehículo: </strong>
          {evento.tipo_vehiculo}
        </li>
        <li>
          <strong>Descripción: </strong>
          {evento.descripcion}
        </li>
        <li>
          <strong>Hora de aviso: </strong>
          {evento.hora_aviso}
        </li>
      </ul>
      {evento.usuario_latitud && evento.usuario_longitud && (
        <MapView lat={evento.usuario_latitud} lng={evento.usuario_longitud} />
      )}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <div className="form-group mb-3">
            <Label>Vehículo: (*)</Label>
            <select
              {...register("vehicle", {
                required: {
                  value: true,
                  message: "La vehículo es requerido",
                },
              })}
              defaultValue={""}
              className="form-control"
            >
              <option value="">Seleccione un vehículo</option>
              {vehicle.map((vehicle, index) => (
                <option
                  value={`${vehicle.vehiculo_asistencia_id}`}
                  name={
                    vehicle.categoria_vehiculos_peaje.categoria_vehiculo_peaje
                  }
                  key={index}
                  disabled={
                    vehicle.vehiculo_asistencium.estado_asistencia === "Ocupado"
                  }
                >
                  {`${vehicle.categoria_vehiculos_peaje.categoria_vehiculo_peaje} / ${vehicle.vehiculo_asistencium.placa}`}
                </option>
              ))}
            </select>
            <small className="form-text text-danger">
              {errors.vehicle && errors.vehicle.message}
            </small>
          </div>
          <div className="form-group mb-3">
            <Label>Tiempo de asistencia en horas y minutos: (*)</Label>
            <input
              type="time"
              className="form-control"
              {...register("tiempo_asignado", {
                required: {
                  value: true,
                  message: "El tiempo asignado es requerido",
                },
              })}
            />
            <small className="form-text text-danger">
              {errors.tiempo_asignado && errors.tiempo_asignado.message}
            </small>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {state.isLoading ? null : (
              <Button className="btn-tagexpress" type="submit">
                Guardar
              </Button>
            )}
          </div>
        </Row>
      </Form>
    </>
  );
};
