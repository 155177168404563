import { headers } from "../utils/utils";
import { api } from "../network";
const s_getProduct = async () => {
    try {
        const response = await api.get("/v1/productos", {
            headers: headers()
        });

        return response.data.data;

    } catch (e) {
        return false;
    }
};

export {
    s_getProduct,
}