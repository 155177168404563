import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Badge,
  Table,
} from "reactstrap";
import { api } from "../../../../network";
import TheLoader from "../../../elements/theLoader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./users.css";
import { headers } from "../../../../utils/utils";
import { notifyError } from "../../../../utils/utils";
import { Edit, PlusSquare } from "react-feather";
import ReactTooltip from "react-tooltip";
import NewUsersForm from "./components/newUsersForm";
import UpdateUsersForm from "./components/updateUsersForm";

const UserView = ({ pageName }) => {
  const [userToUpdate, setUserToUpdate] = useState({});

  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [createSidebar, setCreateSidebar] = useState(false);
  const toggleCreateSidebar = () => setCreateSidebar(!createSidebar);

  const [updateSidebar, setUpdateSidebar] = useState(false);
  const toggleUpdateSidebar = () => setUpdateSidebar(!updateSidebar);

  const getUsers = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await api.get("/v1/usuarios", {
        headers: headers(),
      });
      setUsers(response.data.data);
    } catch (e) {
      notifyError("Error, por favor recargue la página e intente nuevamente");
    }
    setIsLoading(false);
  }, []);

  function _handleRowClick(user) {
    setUserToUpdate((prevUserToUpdate) => {
      return {
        ...prevUserToUpdate,
        ...user,
      };
    });

    toggleUpdateSidebar();
  }

  // Get data on first render
  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <div>
      {isLoading ? <TheLoader /> : null}
      <ToastContainer style={{ width: "400px" }} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        className="mb-4"
      >
        <h3>{pageName}</h3>
        <div>
          <Button
            onClick={toggleCreateSidebar}
            className="btn-tagexpress"
            data-tip="Crear nuevo usuario"
          >
            <PlusSquare />
          </Button>
          <ReactTooltip type="light" />
        </div>
      </div>
      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggleCreateSidebar}
        isOpen={createSidebar}
      >
        <OffcanvasHeader toggle={toggleCreateSidebar}>
          Crear nuevo usuario
        </OffcanvasHeader>
        <OffcanvasBody>
          <NewUsersForm
            toggleSidebar={toggleCreateSidebar}
            getUsers={getUsers}
          />
        </OffcanvasBody>
      </Offcanvas>
      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggleUpdateSidebar}
        isOpen={updateSidebar}
      >
        <OffcanvasHeader toggle={toggleUpdateSidebar}>
          Actualizar usuario
        </OffcanvasHeader>
        <OffcanvasBody>
          <UpdateUsersForm
            selectedUser={userToUpdate}
            getUsers={getUsers}
            toggleSidebar={toggleUpdateSidebar}
          />
        </OffcanvasBody>
      </Offcanvas>
      <Table responsive className="table-container">
        <thead className="tableHeader">
          <tr>
            <td>ID</td>
            <td>Usuario</td>
            <td>Último ingreso </td>
            <td>Persona</td>
            <td>Rol</td>
            <td>Punto de venta</td>
            <td>Estado</td>
            <td>Creación</td>
            <td>Modificación</td>
            <td>Eliminación</td>
            <td>Acciones</td>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr key={user.usuario_id}>
              <td>{index + 1}</td>
              <td>{user.usuario}</td>
              <td>
                {user.fecha_ultimo_ingreso ? user.fecha_ultimo_ingreso : "N/A"}
              </td>
              <td>{user.persona.nombres + " " + user.persona.apellidos}</td>
              <td>{user.rol.descripcion}</td>
              <td>
                {user.punto_de_ventum ? user.punto_de_ventum.nombre : "-"}
              </td>
              <td>
                {user.estado === "A" ? (
                  <Badge color="success">Activo</Badge>
                ) : (
                  <Badge color="warning">Inactivo</Badge>
                )}
              </td>
              <td>{user.fecha_creacion}</td>
              <td>
                {user.fecha_modificacion ? user.fecha_modificacion : "N/A"}
              </td>
              <td>{user.fecha_eliminacion ? user.fecha_eliminacion : "N/A"}</td>
              <td>
                <Edit onClick={() => _handleRowClick(user)} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default UserView;
