import { api } from "../network";
import { headers, queryString } from "../utils/utils";

const homologacionCtlErrors = {
  ERROR_OBTENCION_DATOS: "ERROR_OBTENCION_DATOS",
};

async function s_getHomologacion(params) {
  const { status, identificacion, id_usuario } = params;

  const queryObj = {};

  if (identificacion) {
    queryObj.identificacion = identificacion;
  }

  if (id_usuario) {
    queryObj.id_usuario = id_usuario;
  }

  if (status) {
    queryObj.status = status;
  }

  const query = queryString(queryObj);

  const response = await api.get(`/v1/homologacion?${query}`, {
    headers: headers(),
  });
  if (response && response.status !== 200) {
    throw homologacionCtlErrors.ERROR_OBTENCION_DATOS;
  }

  return response.data.data;
}

async function s_updateHomologacion(data, id) {
  const response = await api.patch(`/v1/homologacion/observation/${id}`, data, {
    headers: headers(),
  });

  if (response && response.status !== 200) {
    throw homologacionCtlErrors.ERROR_OBTENCION_DATOS;
  }

  return response.data;
}

export { s_getHomologacion, s_updateHomologacion };
