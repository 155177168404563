import { api } from "../network";
import { headers, queryString } from "../utils/utils";

const qualificationCtlErrors = {
  ERROR_OBTENCION_DATOS: "ERROR_OBTENCION_DATOS",
};

async function s_getQualification(params) {
  const { end_date, start_date, status, page, size } = params;

  const queryObj = { page };

  if (end_date) {
    queryObj.end_date = end_date;
  }

  if (start_date) {
    queryObj.start_date = start_date;
  }

  if (status) {
    queryObj.status = status;
  }

  if (size) {
    queryObj.size = size;
  }

  const query = queryString(queryObj);

  const response = await api.get(`/v1/qualification?${query}`, {
    headers: headers(),
  });
  if (response && response.status !== 200) {
    throw qualificationCtlErrors.ERROR_OBTENCION_DATOS;
  }

  return response.data.response;
}

async function s_postQualification(data, id) {
  const response = await api.post(`/v1/qualification/web/${id}`, data, {
    headers: headers(),
  });

  if (response && response.status !== 200) {
    throw qualificationCtlErrors.ERROR_OBTENCION_DATOS;
  }

  return response.data;
}

export { s_getQualification, s_postQualification };
