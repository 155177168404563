import React, { useState } from "react";
import { Form, FormGroup, Label, Button } from "reactstrap";
import { notifyError, notifyOk } from "../../../../../utils/utils";

import { s_createCategory } from "../../../../../services/categoryTollVehicles";
import { useForm } from "react-hook-form";

const CreateCategory = ({ closeModal, reload }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [state, setState] = useState({
    isLoading: false,
    mainError: false,
  });

  const onSubmit = async (data, e) => {
    try {
      setState((old) => ({ ...old, isLoading: true }));
      const res = await s_createCategory(data);
      if (!res) {
        setState((old) => ({ ...old, isLoading: false }));
        notifyError("Error al crear vehículo");
        return;
      }
      notifyOk("Vehículo creado");
      setState((old) => ({ ...old, isLoading: false }));
      reload();
      closeModal(false);
    } catch (error) {
      setState((old) => ({ ...old, isLoading: false }));
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup>
        <Label>Categoría (*)</Label>
        <input
          className="form-control"
          type="text"
          placeholder="Ingrese la categoría"
          {...register("categoria", {
            required: {
              value: true,
              message: "La categoría es requerida",
            },
          })}
        />
        <small className="form-text text-danger">
          {errors.categoria && errors.categoria.message}
        </small>
      </FormGroup>
      <FormGroup>
        <Label>Sub-categoría</Label>
        <input
          className="form-control"
          type="text"
          placeholder="Ingrese la sub-categoría"
          {...register("sub_categoria")}
        />
      </FormGroup>
      {!state.isLoading && <Button className="btn-tagexpress">Crear</Button>}
    </Form>
  );
};

export default CreateCategory;
