import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";

export const BaseModal = ({ isOpen, toggle, header, body, footer }) => {
  return (
    <Modal
    isOpen={isOpen}
    toggle={toggle}
    centered
    size="sm"
    backdrop="static"
    style={{ maxWidth: "500px" }}
  >
    <ModalHeader
      toggle={toggle}
      style={{ borderBottom: "none" }}
    >
      {header}
    </ModalHeader>
    <ModalBody style={{ padding: "0px 1rem" }}>
      {body}
    </ModalBody>
    {
      footer && (
        <ModalFooter style={{ borderTop: "none" }}>
          {footer}
        </ModalFooter>
      )
    }
  </Modal>
  );
}