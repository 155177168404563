import { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import { secondsToHMS } from "../../utils";

const StopwatchTimer = ({ registryDate, assignedMinutes }) => {
  const intervalRef = useRef(0);
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    const calculateTimeLeft = () => {
      const assumedEndDate = dayjs(registryDate).add(assignedMinutes, 'minute');
      const currentTime = dayjs();
      const timeLeft = assumedEndDate.diff(currentTime, 'second');

      return timeLeft;
    }

    setTimeLeft(calculateTimeLeft());

    intervalRef.current = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [assignedMinutes, registryDate]);


  const getColor = () => {
    return timeLeft < 0 ? "danger" : "success";
  }

  return (
    <label className={`text-${getColor(timeLeft)}`}>
      {`${timeLeft < 0 ? '- ': ''}${secondsToHMS(Math.abs(timeLeft))}`}
    </label>
  );
};

export default StopwatchTimer;
