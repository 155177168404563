import { api } from "../network";
import { headers, queryString } from "../utils/utils";

const homologacionCtlErrors = {
  ERROR_OBTENCION_DATOS: "ERROR_OBTENCION_DATOS",
};

async function s_getEstadoVia(params) {
  const { status, identificacion, id_usuario } = params;

  const queryObj = {};

  if (identificacion) {
    queryObj.identificacion = identificacion;
  }

  if (id_usuario) {
    queryObj.id_usuario = id_usuario;
  }

  if (status) {
    queryObj.status = status;
  }

  const query = queryString(queryObj);
  const response = await api.get(`/v1/estado-via?${query}`, {
    headers: headers(),
  });
  if (response && response.status !== 200) {
    throw homologacionCtlErrors.ERROR_OBTENCION_DATOS;
  }

  return response.data.data;
}

async function s_deleteEstadoVia(id) {
  const response = await api.delete(`/v1/estado-via/${id}`, {
    headers: headers(),
  });
  if (response && response.status !== 200) {
    throw homologacionCtlErrors.ERROR_OBTENCION_DATOS;
  }

  return response.data.data;
}

async function s_finalizarEstadoVia(id) {
  const response = await api.patch(
    `/v1/estado-via/status/${id}`,
    { status: "INACTIVO" },
    {
      headers: headers(),
    }
  );
  if (response && response.status !== 200) {
    throw homologacionCtlErrors.ERROR_OBTENCION_DATOS;
  }

  return response.data.data;
}

const s_createEstadoVia = async (data) => {
  try {
    const res = await api.post("/v1/estado-via", data, {
      headers: headers(),
    });

    if (!res) {
      return false;
    }
    return true;
  } catch (e) {
    return false;
  }
};

export {
  s_getEstadoVia,
  s_deleteEstadoVia,
  s_finalizarEstadoVia,
  s_createEstadoVia,
};
