import React, { useCallback, useState, useEffect } from "react";
import { Form, Button } from "reactstrap";
import { useForm } from "react-hook-form";
import { api } from "../../../../../network";
import { headers, notifyError, notifyOk } from "../../../../../utils/utils";
import { s_setRegisterDelivery } from "../../../../../services/registerDeliveryCtl";
import { s_getUserStore } from "../../../../../services/userCtl";
import { s_geStores } from "../../../../../services/store.Ctl";
export const NewRegisterDeliveryForm = ({
  setShowModalNewResgister,
  reload,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [data, setData] = useState({
    users: [],
    products: [],
    stores: [],
    reload: false,
    loading: true,
  });

  const [state, setState] = useState({
    isLoading: false,
    mainError: false,
  });

  const getUsers = async (storeId) => {
    try {
      setData((old) => ({ ...old, loading: true }));
      const response = await s_getUserStore({ estado: 'A', store: storeId });
      setData((old) => ({
        ...old,
        loading: false,
        users: response,
      }));

    } catch (error) {
      if (error.response.status === 500) {
        notifyError("ERROR");
        return;
      }
      notifyError(error.response.data.message, { autoClose: 3000 });
    }
  };

  const getStore = useCallback(async () => {
    try {
      setData((old) => ({ ...old, loading: true }));
      const response = await s_geStores();

      setData((old) => ({
        ...old,
        loading: false,
        stores: response,
      }));

    } catch (error) {
      console.log('entre aqui');
      if (error.response.status === 500) {
        notifyError("ERROR");
        return;
      }
      notifyError(error.response.data.message, { autoClose: 3000 });
    }
  }, []);

  const getProduct = useCallback(async () => {
    try {
      setData((old) => ({ ...old, loading: true }));
      const response = await api.get("/v1/productos", {
        headers: headers(), // ask only for active personas
      });

      if (response && response.status !== 200) {
        notifyError("ERROR");
        return;
      }

      setData((old) => ({
        ...old,
        loading: false,
        products: response.data.data,
      }));
    } catch (error) {
      if (error.response.status >= 500) {
        notifyError("ERROR");
        return;
      }
      notifyError(error.response.data.message, { autoClose: 3000 });
    }
  }, []);

  const onSubmit = async (data) => {
    try {
      setState((old) => ({ ...old, isLoading: true }));
      await s_setRegisterDelivery(data);
      notifyOk('Registro guardado de manera exitosa');
      setState((old) => ({ ...old, isLoading: false }));
      setShowModalNewResgister(false);
      reload();
    } catch (error) {
      setState((old) => ({ ...old, isLoading: false }));
      if (error.response.status >= 500) {
        notifyError(
          "Ocurrió un error al guardar el registro, por favor inténtelo más tarde."
        );
        return;
      }
      notifyError(error.response.data.message, { autoClose: 3000 });
    }
  };

  useEffect(() => {
    getProduct();
    getStore();
  }, [getProduct, getStore]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group mb-3">
          <label>Producto</label>
          <select
            className="form-control"
            placeholder="Seleccione el producto"
            {...register("producto_id", {
              required: { value: true, message: "El producto es requerido" },
            })}
          >
            <option value="">Seleccione...</option>
            {data.products.map((product) => (
              <option
                value={`${product.id_producto}`}
                key={product.id_producto}
              >
                {product.nombre_producto}
              </option>
            ))}
          </select>
          <small className="form-text text-danger">
            {errors.producto_id && errors.producto_id.message}
          </small>
        </div>
        <div className="form-group mb-3">
          <label>Punto de venta</label>
          <select
            className="form-control"
            placeholder="Seleccione el punto de venta"
            {...register("puntoVentaId", {
              onChange: (e) => { getUsers(e.target.value) },
              required: { value: true, message: "El punto de venta es requerido" },
            })}
          >
            <option value="">Seleccione...</option>
            {data.stores.map((store) => (
              <option
                value={`${store.punto_de_venta_id}`}
                key={store.punto_de_venta_id}
              >
                {store.nombre}
              </option>
            ))}
          </select>
          <small className="form-text text-danger">
            {errors.puntoVentaId && errors.puntoVentaId.message}
          </small>
        </div>
        <div className="form-group mb-3">
          <label>Usuario</label>
          <select
            className="form-control"
            {...register("usuario_destino", {
              required: { value: true, message: "El usuario es requerido" },
            })}
          >
            <option value="">Seleccione un usuario...</option>
            {data?.users.map((user, index) => (
              <option
                value={user.usuario_id}
                key={user.usuario_id}
              >
                {user.estado === 'A' ? user.usuario : null}
              </option>
            ))}
          </select>
          <small className="form-text text-danger">
            {errors.usuario_destino && errors.usuario_destino.message}
          </small>
        </div>
        <div className="form-group mb-3">
          <label>Cantidad</label>
          <input
            type="number"
            className="form-control"
            placeholder="Cantidad"
            {...register("cantidad", {
              required: { value: true, message: "La cantidad es requerido" },
            })}
          />
          <small className="form-text text-danger">
            {errors.cantidad && errors.cantidad.message}
          </small>
        </div>
        <div className="form-group mb-3">
          <label>Descripción</label>
          <textarea
            className="form-control"
            placeholder="Descripción"
            {...register("descripcion", {
              maxLength: { value: 100, message: "Maximo de letras 100" },
            })}
            rows="3"
          ></textarea>

          <small className="form-text text-danger">
            {errors.descripcion && errors.descripcion.message}
          </small>
        </div>
        {state.isLoading ? null : (
          <Button type="submit" className="btn-tagexpress">
            Guardar
          </Button>
        )}
      </Form>
    </>
  );
};
