function validNoNatural(id) {
    // Si el 3er digito es 6 (Entidad publica):
    //    - Digito verificador es el 9no.
    //    - Coeficientes son [3, 2, 7, 6, 5, 4, 3, 2]
    //
    // Si el 3er digito es 9 (Entidad juridica): 
    //    - Digito verificador es el 10mo.
    //    - Coeficientes son [4, 3, 2, 7, 6, 5, 4, 3, 2]
    //
    // * Ambos casos trabajan con modulo 11
  
  
    const coefsEntidadPublica = [3, 2, 7, 6, 5, 4, 3, 2];
    const coefsEntidadJuridica = [4, 3, 2, 7, 6, 5, 4, 3, 2];
    let coefs;
    let verif;
    let total = 0;
  
    // Validaciones
    if (id.length !== 13) {
      // console.log('entra length: ' + id.length);
      return false;
    }
  
    if (id.substring(10, 13) !== '001') {
      // console.log('entra 001: ' + id.substring(10, 13));
      return false;
    }
  
    // Definir coeficientes y digito verificador de acuerdo al tercer digito
    if (id[2] === '6') {
      verif = id[8];
      coefs = coefsEntidadPublica;
      // console.log('publico');
    }
    else if (id[2] === '9') {
      verif = id[9];
      coefs = coefsEntidadJuridica;
      // console.log('juridico');
    }
    else {
      // console.log('tercer digito invalido: ', id[2]);
      return false;
    }
  
    // Algoritmo
    // console.log('RUC: ', id);
    coefs.forEach((coef, i) => {
      total += coef * Number(id[i]);
      // console.log('coef: ' + coef + ' * i: ' + id[i] + ' = ' + coef * Number(id[i]));
    });
    // console.log('total: ' + total);
  
    total %= 11;
    // console.log('mod 11: ' + total);
    total = total === 0 ? 0 : 11 - total;
  
    // console.log('total: ' + total + ' oficial: ' + verif);
    if (total.toString() === verif) { // VALID
      return true;
    }
  
    return false;
  }
  
  function validNatural(id) {
    //  - Digito verificador es el 10mo.
    //  - Coeficientes son [2, 1, 2, 1, 2, 1, 2, 1, 2]
  
    let total = 0;
  
    // Check if it's posible cedula or ruc
    if (id.length !== 10 && id.length !== 13) {
      // console.log('entra length: id.length');
      return false;
    }
  
    // If it's a ruc then it must have 001 at the end
    if (id.length === 13) {
      if (id.substring(10, 13) !== '001') {
        // console.log('entra 001: ' + id.substring(10, 13));
        return false;
      }
    }
  
    for (let i = 0; i < 9; i++) {
      if (i % 2 === 0) {
        let tmp = Number(id[i]) * 2;
        // console.log(`PAR: d[${i}]: ${id[i]} * 2 = ${tmp > 9 ? tmp - 9 : tmp}`)
        total += tmp > 9 ? tmp - 9 : tmp;
      }
      else {
        total += Number(id[i]);
        // console.log(`IMPAR: d[${i}]: ${id[i]}`)
      }
    }
  
    total %= 10;
    total = total === 0 ? 0 : 10 - total;
  
    // console.log('verif: ' + total + ' orig: ' + id[9]);
    if (total.toString() === id[9]) { // VALID
      return true;
    }
  
    return false;
  }
  
  export {
    validNoNatural,
    validNatural
  }