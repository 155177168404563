import { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Card, CardBody, Col, Form, Label } from "reactstrap";
import { s_userUnlocking } from "../../../../services/userCtl";
import { notifyError, notifyOk } from "../../../../utils/utils";
import { ToastContainer } from "react-toastify";

function DesbloqueoUsuario({ pageName }) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [state, setState] = useState({
    isLoading: false,
    mainError: false,
  });

  const onSubmit = async (data, e) => {
    try {
      setState((old) => ({ ...old, isLoading: true }));
      await s_userUnlocking(data);
      setState((old) => ({ ...old, isLoading: false }));
      notifyOk("Usuario desbloqueado", {
        autoClose: 3000,
      });
      reset();
    } catch (error) {
      setState((old) => ({ ...old, isLoading: false }));
      if (error.response.status === 409) {
        notifyError(error.response.data.message);
        return;
      }
      notifyError(
        "Ocurrió un error al desbloquear al usuario, por favor inténtelo más tarde."
      );
    }
  };

  return (
    <div>
      <ToastContainer />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3>{pageName}</h3>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Col md={4}>
          <Card className="my-3">
            <CardBody>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group mb-3">
                  <Label>Correo electrónico: (*)</Label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Escriba el correo electrónico"
                    {...register("email", {
                      required: {
                        value: true,
                        message: "El correo electrónico es requerido",
                      },
                    })}
                  />
                  <small className="form-text text-danger">
                    {errors.email && errors.email.message}
                  </small>
                </div>
                <div className="form-group mb-3">
                  <Label>Cédula: (*)</Label>
                  <input
                    className="form-control"
                    placeholder="Escriba la cédula"
                    {...register("identification", {
                      required: {
                        value: true,
                        message: "La cédula es requerido",
                      },
                    })}
                  />
                  <small className="form-text text-danger">
                    {errors.identification && errors.identification.message}
                  </small>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {state.isLoading ? null : (
                    <Button className="btn-tagexpress" type="submit">
                      Desbloquear
                    </Button>
                  )}
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </div>
    </div>
  );
}
export default DesbloqueoUsuario;
