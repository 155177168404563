import React from 'react';
import { FormGroup, Label, Input, Row } from "reactstrap";
// Images
import municQuitoImg from "../../../../assets/municQuito.bmp";
import panavialImg from "../../../../assets/panavial.bmp";
import gobPichinchaImg from "../../../../assets/gobPichincha.png";
import nuoImg from "../../../../assets/nuo.bmp";


const Proveedores = ({ setProveedor }) => {

  return (
    <div style={{ textAlign: 'center' }}>
      <Label>Seleccione el proveedor del tag (Si lo desconoce, no seleccione ninguno)</Label>
      <FormGroup >
        <Row className="small-img white-rounded-container">
          <FormGroup check >
            <Label for="panavial" check>
              <Input
                id="panavial"
                type="radio"
                name="proveedor"
                value="Panavial"
                onChange={(e) => {
                  setProveedor(e.target.value);
                }}
              />
              <img src={panavialImg} alt="Panavial" />
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label for="gobPichincha" check>
              <Input
                id="gobPichincha"
                type="radio"
                name="proveedor"
                value="Gobierno provincial de Pichincha"
                onChange={(e) => {
                  setProveedor(e.target.value);
                }}
              />
              <img src={gobPichinchaImg} alt="Gobierno provincial de Pichincha" />
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label for="municQuito" check>
              <Input
                id="municQuito"
                type="radio"
                name="proveedor"
                value="Municipio de Quito"
                onChange={(e) => {
                  setProveedor(e.target.value);
                }}
              />
              <img src={municQuitoImg} alt="Municipio de Quito" />
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label for="nuo" check>
              <Input
                id="nuo"
                type="radio"
                name="proveedor"
                value="NUO"
                onChange={(e) => {
                  setProveedor(e.target.value);
                }}
              />
              <img src={nuoImg} alt="NUO" />
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label for="revendedor" check style={{ fontSize: '.9rem' }}>
              <Input
                id="revendedor"
                type="radio"
                name="proveedor"
                value="revendedor"
                onChange={(e) => {
                  setProveedor(e.target.value);
                }}
              />Revendedor
            </Label>
          </FormGroup>
        </Row>
      </FormGroup>
    </div>
  );
};

export default Proveedores;