import React, { useEffect, useState, useCallback } from "react";
import { useCentralContext } from "../../../centralContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { api } from "../../../network";
import "./login.css";
import { headers } from "../../../utils/utils";
import { useNavigate } from "react-router";
import LoginImg from "../../../assets/img-login.png";
import Logo from "../../../assets/logo-sin-fondo-tag-express.png";

const LoginView = () => {
  const navigate = useNavigate();
  const [usuario_input, setUsuario] = useState("");
  const [password_input, setPassword] = useState("");
  const centralContext = useCentralContext();

  // Check server response and grants access
  const _setContextAndLetPass = useCallback(
    (response) => {
      if (!response) {
        localStorage.removeItem("token");
        localStorage.removeItem("t");
        return;
      }

      if (response.data.status === "autorizado_ok") {
        centralContext.updateRole(response.data.data.r);
        centralContext.updateUser(response.data.data.u);
        centralContext.updateIsLogged(true);

        if (response.data.data.t) {
          localStorage.setItem("token", response.data.data.t);
        }
      }
      navigate("/");
    },
    [centralContext, navigate]
  );

  const _verifyToken = useCallback(async () => {
    const t = localStorage.getItem("token");

    if (t == null) {
      return;
    }

    if (centralContext.isLogged === false) {
      navigate("/login");
      return;
    }

    if (centralContext.isLogged === true) {
      navigate("/");
      return;
    }



    try {
      const response = await api.post(
        "/v1/auth/ack",
        {},
        {
          headers: headers(),
        }
      );

      _setContextAndLetPass(response);
    } catch (error) { }
  }, [centralContext, navigate, _setContextAndLetPass]);

  // Try to login user if she has an active session
  useEffect(() => {
    _verifyToken();
  }, [_verifyToken]);

  const _handleSubmit = async (e) => {
    e.preventDefault();

    const headers = {
      "Content-Type": "application/json",
    };

    const data = {
      usuario_input,
      password_input,
    };

    try {
      const response = await api.post("/v1/auth/login", data, {
        headers: headers,
      });
      _setContextAndLetPass(response);
    } catch (error) {
      toast.dark("usuario o contraseña incorrectos", { autoClose: 2000 });
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="content">
        <div className="content-left">
          <div className="content-login">
            <div className="login-header">
              <h3 data-testid="Login-section">Inicio de sesión</h3>
              <label>
                ¡Bienvenido de nuevo! Por favor, introduzca sus datos
              </label>
            </div>

            <form onSubmit={_handleSubmit} className="login-form">
              <div className="form-group first mb-3">
                <label htmlFor="username" className="form-label">
                  Usuario:
                </label>
                <input
                  type="text"
                  placeholder="Ingrese su usuario"
                  className="form-control"
                  id="username"
                  onChange={(e) => setUsuario(e.target.value)}
                  autoComplete="off"
                />
              </div>
              <div className="form-group last mb-4">
                <label htmlFor="password" className="form-label">
                  Contraseña:
                </label>
                <input
                  type="password"
                  placeholder="Ingrese su contraseña"
                  className="form-control"
                  id="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <button type="submit" className="btn btn-login">
                Ingresar
              </button>
            </form>
          </div>
        </div>
        <div className="content-right">
          <img src={Logo} className="img-logo" alt="tagexpress" />
          <img src={LoginImg} alt="login" />
        </div>
      </div>
    </>
  );
};

export default LoginView;
