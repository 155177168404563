import { api } from "../network";
import { headers } from "../utils/utils";

const homologacionCtlErrors = {
  ERROR_OBTENCION_DATOS: "ERROR_OBTENCION_DATOS",
};

async function s_getCategorias() {
  const response = await api.get(`/v1/categoria`, {
    headers: headers(),
  });
  if (response && response.status !== 200) {
    throw homologacionCtlErrors.ERROR_OBTENCION_DATOS;
  }

  return response.data.data;
}

const s_createCategory = async (data) => {
  try {
    const res = await api.post("/v1/categoria", data, {
      headers: headers(),
    });

    if (!res) {
      return false;
    }
    return true;
  } catch (e) {
    return false;
  }
};

async function s_updateCategoria(data, id) {
  const response = await api.patch(`/v1/categoria/update/${id}`, data, {
    headers: headers(),
  });

  if (response && response.status !== 200) {
    throw homologacionCtlErrors.ERROR_OBTENCION_DATOS;
  }

  return response.data;
}

export { s_getCategorias, s_createCategory, s_updateCategoria };
