import React, { useState } from "react";
import { Button, ModalBody, ModalHeader, Table, Modal } from "reactstrap";
import { AddRetention } from "./AddRetention";

export const Details = ({ details, reload, setShowModalDetails }) => {
  const [showModalRetention, setShowModalRetention] = useState(false);
  const toggleRetention = () => setShowModalRetention(!showModalRetention);

  console.log('Details wallet ', details.type);


  const [id, setId] = useState(null);
  return (
    <div>
      <Modal isOpen={showModalRetention} toggle={toggleRetention}>
        <ModalHeader toggle={toggleRetention}>Nueva Retención</ModalHeader>
        <ModalBody>
          <AddRetention
            reload={reload}
            setShowModalRetention={setShowModalRetention}
            setShowModalDetails={setShowModalDetails}
            type={details.type}
            id={id}
          />
        </ModalBody>
      </Modal>
      {details.type === 'recharge' || details.type === 'homologation' || details.type === 'salesTag' ?
        <Table>
          <thead>
            <tr>
              {details.type === "recharge" ? null : <th>Cantidad</th>}
              <th>{details.stock ? "Tipo de Tags" : "Concesión"}</th>
              <th>Fecha</th>
              {details.type === "recharge" ? null : <th>T. unidad</th>}

              <th style={{ textAlign: "center" }}>Tipo de pago</th>
              <th style={{ textAlign: "end" }}>Retención</th>
              <th style={{ textAlign: "end" }}>Total</th>
              <th style={{ textAlign: "end" }}>Add Retención</th>
            </tr>
          </thead>
          <tbody>
            {details.details.map((item, index) => (
              <tr key={index}>
                {details.type === "recharge" ? null : <th>{item.cantidad}</th>}

                <td>{item.tipo ? item.tipo : item.concesion}</td>
                <td>{item.created}</td>
                {details.type === "salesTag" && (
                  <td>
                    {item.tipo === "Cliente" && "$ 7.00"}
                    {item.tipo === "Revendedor" && "$ 6.25"}
                    {item.tipo === "Cruz azul / Phamarcy" && "$ 8.12"}
                  </td>
                )}
                {details.type === "homologation" && (
                  <td>
                    {item.concesion === "Guayasamin" ||
                      item.concesion === "AGR" ||
                      item.concesion === "Panavial"
                      ? "$ 2.00"
                      : "$ 1.00"}
                  </td>
                )}
                {details.type === "wallet" && (
                  <td>
                    item.
                  </td>
                )}
                <td style={{ textAlign: "center" }}>
                  {item.tipo_pago ? item.tipo_pago : "-"}
                </td>
                <td style={{ textAlign: "end" }}>
                  $ {item.retencion ? item.retencion : "0.00"}
                </td>
                <td style={{ textAlign: "end" }}>$ {item.total}</td>
                <td style={{ textAlign: "center" }}>
                  <Button
                    color="success"
                    size="sm"
                    onClick={() => {
                      item.id_venta_homologacion &&
                        setId(item.id_venta_homologacion);
                      item.id_venta_recarga && setId(item.id_venta_recarga);
                      item.id_venta_tag && setId(item.id_venta_tag);
                      setShowModalRetention(true);
                    }}
                  >
                    {" "}
                    +
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th
                id="total"
                colSpan={details.type === "recharge" ? 4 : 6}
                style={{ textAlign: "end" }}
              >
                Total :
              </th>
              <td style={{ textAlign: "end" }}> $ {details.total}</td>
            </tr>
          </tfoot>
        </Table>
        :
        <Table className="text-center">
          <thead>
            <tr>
              <th>Código transaccion</th>
              <th>monto</th>
              <th>Fecha</th>
              <th>Dominio</th>
            </tr>
          </thead>
          <tbody>
            {details.map((item, index) => (
              <tr key={index}>

                <td>{item.codigo_transaccion}</td>
                <td>{item.monto}</td>
                <td>{item.created}</td>
                <td>{item.dominio}</td>

                {/* <td style={{ textAlign: "end" }}>$ {item.total}</td>
             <td style={{ textAlign: "center" }}> */}
                {/* <Button
                 color="success"
                 size="sm"
                 onClick={() => {
                   item.id_venta_homologacion &&
                     setId(item.id_venta_homologacion);
                   item.id_venta_recarga && setId(item.id_venta_recarga);
                   item.id_venta_tag && setId(item.id_venta_tag);
                   setShowModalRetention(true);
                 }}
               >
                 {" "}
                 +
               </Button> */}
                {/* </td> */}
              </tr>
            ))}
          </tbody>
        </Table>
      }
    </div>
  );
};
