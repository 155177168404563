import React, { useCallback, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import {
  Button,
  UncontrolledTooltip,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Table,
  Card,
  CardBody,
} from "reactstrap";
import { notifyError, notifyInfo } from "../../../utils/utils";
import { useCentralContext } from "../../../centralContext";
import StopwatchTimer from "../../../utils/components/stopwatchTimer/StopwatchTimer";
import {
  PlusSquare,
  Monitor,
  Smartphone,
  CheckCircle,
  XCircle,
  Edit,
  FilePlus,
  MessageSquare,
  Home,
} from "react-feather";
import { AssistanceHeader } from "./components/AssistanceHeader";
import { AcceptRequestForm } from "./components/AcceptRequestForm";
import { NewRequestForm } from "./components/NewRequestForm";
import { getRoadSupportVehicles, s_getAsistVial } from "../../../services/asistVialCtl";
import { EndRequestForm } from "./components/EndRequestForm";
import { CancelRequestForm } from "./components/CancelRequestForm";
import { formatEstado, formatStatusString } from "./utils/utils";
import { Rating } from "./components/Rating";
import EditRequestForm from "./components/EditRequestForm";

import "./asistencia.css";

const AsistenciaVial = ({ pageName }) => {
  const centralContext = useCentralContext();
  const [assistances, setAssistances] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [averageAssistance, setAverageAssistance] = useState(0);
  const [evento, setEvento] = useState();
  const [edit, setEdit] = useState(false);
  const [attendSidebar, setAttendSidebar] = useState(false);
  const [createAssistance, setCreateAssistance] = useState(false);
  const [endAssistance, setEndAssistance] = useState(false);
  const [cancelAssistance, setCancelAssistance] = useState(false);
  const [rating, setRating] = useState(false);
  const [editAssistance, setEditAssistance] = useState(false);
  const availableVehicles = vehicles.filter((vehicle) => vehicle.vehiculo_asistencium.estado_asistencia === "Disponible").length;
  const activeAssistances = assistances.filter((assistance) => !assistance.fecha_solucion && !assistance.fecha_cancelacion).length;
  const isAnyVehicleAvailable = availableVehicles > 0;

  const toggleCreateAssistance = () => setCreateAssistance(!createAssistance);

  const toggleEndAssistance = () => setEndAssistance(!endAssistance);

  const toggleRating = () => setRating(!rating);

  const toggleCancelAssistance = () => setCancelAssistance(!cancelAssistance);

  const toggleEditAssistance = () => setEditAssistance(!editAssistance);

  const toggleAttendSidebar = () => setAttendSidebar(!attendSidebar);

  const getVehicles = useCallback(async () => {
    try {
      const fetchedVehicles = await getRoadSupportVehicles();
      setVehicles(fetchedVehicles);
    } catch (error) {
      notifyError(error.response.data.message, { autoClose: 3000 });
    }
  }, []);

  const showNoVehiclesAvailableNotification = () => {
    notifyInfo("No hay vehículos disponibles", { autoClose: 3000 });
  }

  const getAssistance = useCallback(async () => {
    try {
      const resp = await s_getAsistVial({ status: 'in_process' });

      setAverageAssistance(resp.time);
      setAssistances(resp.data);
    } catch (error) {
      setAverageAssistance(0);
      setAssistances([]);
    }
  }, []);

  const fetchData = useCallback(() => {
    getVehicles();
    getAssistance();
  }, [getVehicles, getAssistance]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);


  function displayModalCreate() {
    toggleCreateAssistance();
    getVehicles();
  }

  return (
    <div>
      <ToastContainer />
      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggleAttendSidebar}
        isOpen={attendSidebar}
        style={{ width: 600 }}
      >
        <OffcanvasHeader toggle={toggleAttendSidebar}>
          Atender solicitud
        </OffcanvasHeader>
        <OffcanvasBody>
          {evento && (
            <AcceptRequestForm
              evento={evento}
              vehicle={vehicles}
              onClose={() => {
                setAttendSidebar(false);
                fetchData();
              }}
            />
          )}
        </OffcanvasBody>
      </Offcanvas>
      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggleCreateAssistance}
        isOpen={createAssistance}
        style={{ width: 600 }}
      >
        <OffcanvasHeader toggle={toggleCreateAssistance}>
          Crear asistencia
        </OffcanvasHeader>
        <OffcanvasBody>
          <NewRequestForm
            vehicle={vehicles}
            onClose={() => {
              setCreateAssistance(false);
              fetchData();
            }}
          />
        </OffcanvasBody>
      </Offcanvas>
      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggleEndAssistance}
        isOpen={endAssistance}
        style={{ width: 800 }}
      >
        <OffcanvasHeader toggle={toggleEndAssistance}>
          {edit ? 'Editar asistencia' : 'Finalizar asistencia'}
        </OffcanvasHeader>
        <OffcanvasBody>
          {evento && (
            <>
              <EndRequestForm
                onClose={() => {
                  setEndAssistance(false);
                  fetchData();
                }}
                evento={evento}
                setEdit={setEdit}
                edit={edit}
              />
            </>
          )}
        </OffcanvasBody>
      </Offcanvas>
      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggleCancelAssistance}
        isOpen={cancelAssistance}
        style={{ width: 600 }}
      >
        <OffcanvasHeader toggle={toggleCancelAssistance}>
          Cancelar asistencia
        </OffcanvasHeader>
        <OffcanvasBody>
          {evento && (
            <>
              <CancelRequestForm
                onClose={() => {
                  setCancelAssistance(false);
                  fetchData();
                }}
                evento={evento}
              />
            </>
          )}
        </OffcanvasBody>
      </Offcanvas>
      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggleRating}
        isOpen={rating}
      >
        <OffcanvasHeader toggle={toggleRating}>Encuesta</OffcanvasHeader>
        <OffcanvasBody>
          {evento && (
            <Rating
              evento={evento}
              onClose={() => {
                setRating(false);
                fetchData();
              }}
            />
          )}
        </OffcanvasBody>
      </Offcanvas>
      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggleEditAssistance}
        isOpen={editAssistance}
        style={{ width: 800 }}
      >
        <OffcanvasHeader toggle={toggleEditAssistance}>
          Editar asistencia
        </OffcanvasHeader>
        <OffcanvasBody>
          {evento && (
            <EditRequestForm
              evento={evento}
              vehicle={vehicles}
              onClose={() => {
                setEditAssistance(false);
                fetchData();
              }}
            />
          )}
        </OffcanvasBody>
      </Offcanvas>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        className="mb-4"
      >
        <h3>{pageName}</h3>
        <div>
          <Button
            onClick={isAnyVehicleAvailable ? displayModalCreate : showNoVehiclesAvailableNotification}
            className="btn-tagexpress"
            style={{
              cursor: isAnyVehicleAvailable ? "pointer" : "not-allowed"
            }}
          >
            <PlusSquare /> Crear asistencia
          </Button>
        </div>
      </div>
      <>
        <AssistanceHeader
          vehicle={vehicles}
          activeAssistances={activeAssistances}
          availableVehicles={availableVehicles}
          averageAssitanceDuration={averageAssistance}
        />
        <Card>
          <CardBody style={{ cursor: "default" }}>
            {assistances.length !== 0 ? (
              <Table responsive>
                <thead>
                  <tr>
                    <th>Plataforma</th>
                    <th>Vehículo</th>
                    <th>Descripción asistencia</th>
                    <th>Tiempo restante</th>
                    <th>Estado</th>
                    {centralContext.role !== "cau" ? <th>Acciones</th> : null}
                  </tr>
                </thead>
                <tbody>
                  {assistances.map((vehiculoData) => {
                    const isFinished = vehiculoData.fecha_solucion || vehiculoData.fecha_cancelacion;

                    return (
                      <tr key={vehiculoData.evento_asistencia_id}>
                        <td>
                          {vehiculoData.usuario_modo_de_solicitud === "web" && (
                            <>
                              <Monitor
                                id={`web-${vehiculoData.evento_asistencia_id}`}
                              />
                              <UncontrolledTooltip
                                placement="top"
                                target={`web-${vehiculoData.evento_asistencia_id}`}
                              >
                                Web
                              </UncontrolledTooltip>
                            </>
                          )}
                          {vehiculoData.usuario_modo_de_solicitud ===
                            "mobile" && (
                              <>
                                <Smartphone
                                  id={`mobile-${vehiculoData.evento_asistencia_id}`}
                                />
                                <UncontrolledTooltip
                                  placement="top"
                                  target={`mobile-${vehiculoData.evento_asistencia_id}`}
                                >
                                  Móvil
                                </UncontrolledTooltip>
                              </>
                            )}
                          {vehiculoData.usuario_modo_de_solicitud === "cau" && (
                            <>
                              <Home
                                id={`cau-${vehiculoData.evento_asistencia_id}`}
                              />
                              <UncontrolledTooltip
                                placement="top"
                                target={`cau-${vehiculoData.evento_asistencia_id}`}
                              >
                                CAU
                              </UncontrolledTooltip>
                            </>
                          )}
                        </td>
                        <td>
                          {vehiculoData.fecha_registro ? (
                            <>
                              {vehiculoData.vehiculo_asistencia.map(
                                (vehiculo, index) => (
                                  <div
                                    key={index}
                                  >{`${vehiculo.categoria_vehiculo_peaje} / ${vehiculo.placa}`}</div>
                                )
                              )}
                            </>
                          ) : (
                            <>-</>
                          )}
                        </td>
                        <td>{vehiculoData.descripcion || "-"}</td>
                        <td>
                          {
                            isFinished ? (
                              <label className="text-black-50">00:00:00</label>
                            ) : (
                              <StopwatchTimer
                                vehiculoData={vehiculoData}
                                registryDate={vehiculoData.fecha_registro}
                                assignedMinutes={vehiculoData.tiempo_asignado}
                              />
                            )
                          }
                        </td>
                        <td>
                          <h6>{formatEstado(vehiculoData)}</h6>
                        </td>
                        <td>
                          <>
                            {formatStatusString(vehiculoData) ===
                              "finalizado" ||
                              formatStatusString(vehiculoData) ===
                              "cancelado" ? null : (
                              <>
                                {formatStatusString(vehiculoData) ===
                                  "sin_atencion" ? (
                                  <>
                                    <FilePlus
                                      style={{
                                        width: 30,
                                        height: 30,
                                        cursor: "pointer",
                                      }}
                                      id={`attend-${vehiculoData.evento_asistencia_id}`}
                                      onClick={() => {
                                        toggleAttendSidebar();
                                        setEvento(vehiculoData);
                                      }}
                                    />
                                    <UncontrolledTooltip
                                      placement="top"
                                      target={`attend-${vehiculoData.evento_asistencia_id}`}
                                    >
                                      Atender solicitud
                                    </UncontrolledTooltip>
                                  </>
                                ) : (
                                  <>
                                    <CheckCircle
                                      style={{
                                        width: 30,
                                        height: 30,
                                        cursor: "pointer",
                                        marginRight: 5,
                                      }}
                                      onClick={() => {
                                        toggleEndAssistance();
                                        setEvento(vehiculoData);
                                      }}
                                      id={`end-${vehiculoData.evento_asistencia_id}`}
                                    />
                                    <UncontrolledTooltip
                                      placement="top"
                                      target={`end-${vehiculoData.evento_asistencia_id}`}
                                    >
                                      Finalizar
                                    </UncontrolledTooltip>
                                    <XCircle
                                      style={{
                                        width: 30,
                                        height: 30,
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        toggleCancelAssistance();
                                        setEvento(vehiculoData);
                                      }}
                                      id={`cancel-${vehiculoData.evento_asistencia_id}`}
                                    />
                                    <UncontrolledTooltip
                                      placement="top"
                                      target={`cancel-${vehiculoData.evento_asistencia_id}`}
                                    >
                                      Cancelar
                                    </UncontrolledTooltip>

                                    <Edit
                                      style={{
                                        width: 30,
                                        height: 30,
                                        cursor: "pointer",
                                        marginRight: 5,
                                      }}
                                      onClick={() => {
                                        toggleEditAssistance();
                                        setEvento(vehiculoData);
                                      }}
                                      id={`edit-${vehiculoData.evento_asistencia_id}`}
                                    />
                                    <UncontrolledTooltip
                                      placement="top"
                                      target={`edit-${vehiculoData.evento_asistencia_id}`}
                                    >
                                      Editar
                                    </UncontrolledTooltip>
                                  </>
                                )}
                              </>
                            )}
                            {vehiculoData.evento_calificacion_estado === "P" &&
                              vehiculoData.estado === "R" && (
                                <>
                                  <MessageSquare
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    id={`rating-${vehiculoData.evento_asistencia_id}`}
                                    onClick={() => {
                                      toggleRating();
                                      setEvento(vehiculoData);
                                    }}
                                  />
                                  <UncontrolledTooltip
                                    placement="top"
                                    target={`rating-${vehiculoData.evento_asistencia_id}`}
                                  >
                                    Realizar encuesta
                                  </UncontrolledTooltip>
                                </>
                              )}
                          </>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <div className="mt-5">
                <h1 className="alert-title">
                  No hay asistencias activas al momento
                </h1>
              </div>
            )}
          </CardBody>
        </Card>
      </>
    </div>
  );
};

export default AsistenciaVial;
