import { headers } from "../utils/utils";
import { apiClientes } from "../network";

const compensationCtlErrors = {
    ERROR_OBTENCION_DATOS: "ERROR_OBTENCION_DATOS",
};

const s_getExternalEntities = async () => {
    const response = await apiClientes.get('/external-entities', {
        headers: headers(),
    });

    if (response && response.status !== 200) {
        throw compensationCtlErrors.ERROR_OBTENCION_DATOS;
    }

    return response.data;
}

export {
    s_getExternalEntities
}