const ROLES = {
  usuariot1000: "usuariot1000",
  admin_asistencia_vial: "admin_asistencia_vial",
  operador_asistencia_vial: "operador_asistencia_vial",
  admin_tagexpress: "admin_tagexpress",
  operador_tagexpress: "operador_tagexpress",
  cau: "cau",
};

export default ROLES;
