import { api } from "../network";
import { headers, queryString } from "../utils/utils";

const reportedProductCtlErrors = {
  ERROR_OBTENCION_DATOS: "ERROR_OBTENCION_DATOS",
  ERROR_ACTUALIZANDO_VENTA: "ERROR_ACTUALIZANDO_ENTREGA",
};

async function s_setReportedProduct(data) {
  try {
    const response = await api.post("/v1/reportedProduct", data, {
      headers: headers(),
    });

    if (response && response.status !== 200) {
      throw reportedProductCtlErrors.ERROR_ACTUALIZANDO_VENTA;
    }

    return response.data.message;
  } catch (e) {
    return false;
  }
}

async function s_getReportedProduct(params) {
  const { end_date, start_date, product, user, status } = params;
  const queryObj = {};

  if (end_date) {
    queryObj.end_date = end_date;
  }
  if (start_date) {
    queryObj.start_date = start_date;
  }
  if (product) {
    queryObj.product = product;
  }
  if (status) {
    queryObj.status = status;
  }
  if (user) {
    queryObj.user = user;
  }

  const query = queryString(queryObj);

  const response = await api.get(`/v1/reportedProduct?${query}`, {
    headers: headers(),
  });
  if (response && response.status !== 200) {
    throw reportedProductCtlErrors.ERROR_OBTENCION_DATOS;
  }

  return response.data.data;
}

async function s_getMeReportedProduct(params) {
  const { end_date, start_date, product, status } = params;
  const queryObj = {};

  if (end_date) {
    queryObj.end_date = end_date;
  }
  if (start_date) {
    queryObj.start_date = start_date;
  }
  if (product) {
    queryObj.product = product;
  }
  if (status) {
    queryObj.status = status;
  }

  const query = queryString(queryObj);

  const response = await api.get(`/v1/reportedProduct/me?${query}`, {
    headers: headers(),
  });
  if (response && response.status !== 200) {
    throw reportedProductCtlErrors.ERROR_OBTENCION_DATOS;
  }

  return response.data.data;
}

async function s_updateStatusReportedProduct(data) {
  const { status, id } = data;
  const response = await api.put(
    `/v1/reportedProduct/status/${id}`,
    { status },
    {
      headers: headers(),
    }
  );

  if (response && response.status !== 200) {
    throw reportedProductCtlErrors.ERROR_ACTUALIZANDO_VENTA;
  }
  return response.data.message;
}

export {
  s_getReportedProduct,
  s_updateStatusReportedProduct,
  s_setReportedProduct,
  s_getMeReportedProduct,
};
