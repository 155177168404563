import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginView from "./views/pages/login";
import "./App.css";
import ProtectedRoute from "./views/elements/protectedRoutes";
import Dashboard from "./layout/Dashboard";
import ErrorPage from "./views/pages/error/errorPage";
import ClockDashboard from "./views/pages/clockDashboard";
import { getRoutesByRole } from "./routing/utils";
import { useCentralContext } from "./centralContext";

const AppRouter = () => {
  const context = useCentralContext();

  const buildRoutes = () => {
    const routesByRole = getRoutesByRole(context.role);
    return routesByRole.map((elem) => {
      return elem.children.map((child) => {
        const Component = child.route.component;
        return (
          <Route
            path={child.route.path.substring(1)}
            element={<Component pageName={child.route.name} />}
            key={child.route.path}
          />
        );
      });
    });
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" exact element={<LoginView />} />
        <Route path="/404" exact element={<ErrorPage type="notFound" />} />
        <Route path="/500" exact element={<ErrorPage type="serverError" />} />
        <Route path="/*" element={<ProtectedRoute />}>
          <Route element={<Dashboard />}>
            <Route index element={<ClockDashboard />} />
            {buildRoutes()}
          </Route>
          <Route path="*" element={<ErrorPage type="notFound" />} />
        </Route>
        <Route path="*" element={<ErrorPage type="notFound" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
