import DesbloqueoUsuario from "../../views/pages/atencionCliente/desbloqueoUsuario";
import Homologacion from "../../views/pages/atencionCliente/homologacion";
import EstadoVia from "../../views/pages/atencionCliente/estadoVia";
import VerifyUser from "../../views/pages/atencionCliente/verificarUsuario";

export const customerSupportRoutes = {
  desbloqueUsuario: {
    name: "Desbloqueo de usuarios",
    icon: "fa-users",
    url: "/atencion/desbloqueo",
    route: {
      name: "Desbloqueo de usuarios",
      path: "/atencion/desbloqueo",
      component: DesbloqueoUsuario,
      exact: true,
      sensitive: true,
    },
  },
  homologacion: {
    name: "Homologaciones",
    icon: "fa-file-text",
    url: "/homologacion",
    route: {
      name: "Homologaciones",
      path: "/homologacion",
      component: Homologacion,
      exact: true,
      sensitive: true,
    },
  },
  estadoVia: {
    name: "Estado de vías",
    icon: "fa-road",
    url: "/estado-via",
    route: {
      name: "Estado de vías",
      path: "/estado-via",
      component: EstadoVia,
      exact: true,
      sensitive: true,
    },
  },
  verificarUsuario: {
    name: "Verificación de usuarios",
    icon: "fa-users",
    url: "/atencion/verificacion",
    route: {
      name: "Verificación de usuarios",
      path: "/atencion/verificacion",
      component: VerifyUser,
      exact: true,
      sensitive: true,
    },
  },
};