import { headers } from "../utils/utils";
import { api } from "../network";

const vehicleCtlErrors = {
  ERROR_OBTENCION_DATOS: "ERROR_OBTENCION_DATOS",
  ERROR_ACTUALIZANDO_VEHICULO: "ERROR_ACTUALIZANDO_VEHICULO",
};

async function s_getVehicle() {
  const response = await api.get(`/v1/asistVial/vehiculos`, {
    headers: headers(),
  });
  if (response && response.status !== 200) {
    throw vehicleCtlErrors.ERROR_OBTENCION_DATOS;
  }

  return response.data.data;
}

export { s_getVehicle };
