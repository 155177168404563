import React from "react";
import "./App.css";
import AppRouter from "./appRouter";
import { CentralContextProvider } from "./centralContext";

export default function App() {
  return (
    <CentralContextProvider>
      <AppRouter />
    </CentralContextProvider>
  );
}
