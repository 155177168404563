import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Col, Form, Label, Row } from "reactstrap";
import { s_updateCreateAsistVial } from "../../../../services/asistVialCtl";
import { s_getTypeAssistance } from "../../../../services/typeAssistanceCtl";
import { notifyError, notifyOk } from "../../../../utils/utils";

export const CancelRequestForm = ({ onClose, evento }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      numero_reporte: evento.numero_reporte ?? "",
      sale_estacion: evento.sale_estacion ?? "",
      llegada_inicio_operativo: evento.llegado_inicio_operativo ?? "",
      termina_operativo: evento.termina_operativo ?? "",
      sale_con_paciente: evento.sale_con_paciente ?? "",
      regresa_estacion: evento.regresa_estacion ?? "",
      kilometraje_llegada: evento.kilometraje_llegada ?? "",
      via: evento.via ?? "",
      kilometro_via: evento.kilometro_via ?? "",
      detalle_estado_via: evento.detalle_estado_via ?? "",
      nombres_paciente: evento.nombres_paciente ?? "",
      edad_paciente: evento.edad_paciente ?? "",
      cedula_paciente: evento.cedula_paciente ?? "",
      medico_turno: evento.medico_turno ?? "",
      diagnostico: evento.diagnostico ?? "",
      prescripcion_medica: evento.prescripcion_medica ?? "",
      detalle_traslado: evento.detalle_traslado ?? "",
      nombres_acompanante: evento.nombres_acompanante ?? "",
      cedula_acompanante: evento.cedula_acompanante ?? "",
      telefono_acompanante: evento.telefono_acompanante ?? "",
      tipo: evento.tipo ?? "",
      marca: evento.marca ?? "",
      placa: evento.placa ?? "",
      color: evento.color ?? "",
      situacion_especial: evento.situacion_especial ?? "",
      detalle_traslado_vehiculo: evento.detalle_traslado_vehiculo ?? "",
      detalle_autoridad_presente: evento.detalle_autoridad_presente ?? "",
      detalle_cancelacion: evento.detalle_cancelacion ?? ""

    }
  });

  const [data, setData] = useState({
    typeAssistance: [],
    reload: false,
    status: "PROCESSING",
  });

  const [state, setState] = useState({
    isLoading: false,
    mainError: false,
  });

  const onSubmit = async (data, e) => {
    const dataTemp = {
      evento_asistencia_id: evento.evento_asistencia_id,
      vehiculo_id: evento.vehiculo_asistencia_id,
      detalleDesCancelacion: data.descripcion,
      type_req: "C",
      ...data,
    };
    try {
      setState((old) => ({ ...old, isLoading: true }));
      await s_updateCreateAsistVial(dataTemp);
      setState((old) => ({ ...old, isLoading: false }));
      notifyOk("Asistencia se cancelo de manera correcta", {
        autoClose: 3000,
      });
      onClose();
    } catch (error) {
      setState((old) => ({ ...old, isLoading: false }));
      if (error.response.status === 409) {
        notifyError(error.response.data.message);
        return;
      }
      notifyError(
        "Ocurrió un error al agregar producto, por favor inténtelo más tarde."
      );
    }
  };

  const _getTypeAssistance = async () => {
    try {
      setData((old) => ({ ...old, status: "PROCESSING" }));
      const response = await s_getTypeAssistance({
        size: 1000,
        page: 0,
        status: "A",
      });
      setData((old) => ({
        ...old,
        typeAssistance: response.rows,
        status: "SUCCESS",
      }));
    } catch (error) {
      setData((old) => ({ ...old, status: "FAILED" }));
      if (error.response.status >= 500) {
        notifyError("ERROR");
        return;
      }
      notifyError(error.response.data.message, { autoClose: 3000 });
    }
  };

  useEffect(() => {
    _getTypeAssistance();
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col md="12">
          <div className="form-group mb-3">
            <Label>Número de reporte: (*)</Label>
            <input
              type="text"
              placeholder="(130299)"
              className="form-control"
              {...register("numero_reporte", {
                required: {
                  value: true,
                  message: "El número de reporte es requerido",
                },
              })}
            />
            <small className="form-text text-danger">
              {errors.numero_reporte && errors.numero_reporte.message}
            </small>
          </div>
        </Col>
        <div className="form-group mb-3">
          <Label>Tipo de asistencia: (*)</Label>
          <select
            {...register("tipo_asistencia_id", {
              required: {
                value: true,
                message: "El tipo de asistencia es requerido",
              },
            })}
            defaultValue={""}
            className="form-control"
          >
            <option value={`${evento.tipo_asistencia_id}` ?? ""}>{evento.tipo_asistencia_nombre ?? "Seleccione"}</option>
            {data.typeAssistance.map((type, index) => (
              <option value={`${type.tipo_asistencia_id}`} key={index}>
                {`${type.nombre}`}
              </option>
            ))}
          </select>
          <small className="form-text text-danger">
            {errors.tipo_asistencia_id && errors.tipo_asistencia_id.message}
          </small>
        </div>
        <Col md="6">
          <div className="form-group mb-3">
            <Label>Llega / inicio de operativo:</Label>
            <input
              type="datetime-local"
              className="form-control"
              {...register("llegada_inicio_operativo")}
            />
          </div>
        </Col>
        <Col md="6">
          <div className="form-group mb-3">
            <Label>Termina operativo:</Label>
            <input
              type="datetime-local"
              className="form-control"
              {...register("termina_operativo")}
            />
          </div>
        </Col>
        {evento.vehiculo_asistencia[0].categoria_vehiculo_peaje !==
          "AMBULANCIA" ? (
          <Col md="6">
            <div className="form-group mb-3">
              <Label>Sale con vehículo:</Label>
              <input
                type="datetime-local"
                className="form-control"
                {...register("sale_con_paciente")}
              />
            </div>
          </Col>
        ) : null}
        <Col md="6">
          <div className="form-group mb-3">
            <Label>Regresa a la estación:</Label>
            <input
              type="datetime-local"
              className="form-control"
              {...register("regresa_estacion")}
            />
          </div>
        </Col>
        <Col md="4">
          <div className="form-group mb-3">
            <Label>Código del vehículo:</Label>
            <input
              type="text"
              placeholder="(120)"
              className="form-control"
              {...register("codigo_vehiculo")}
              defaultValue={
                evento.vehiculo_asistencia &&
                  evento.vehiculo_asistencia.length > 0
                  ? evento.vehiculo_asistencia[0].codigo_vehiculo
                  : ""
              }
            />
          </div>
        </Col>
        <Col md="4">
          <div className="form-group mb-3">
            <Label>Kilometraje al salir:</Label>
            <input
              type="number"
              className="form-control"
              placeholder="Kilometraje al salir (100)"
              {...register("kilometraje_salida")}
              defaultValue={evento.kilometraje_salida}
            />
          </div>
        </Col>
        <Col md="4">
          <div className="form-group mb-3">
            <Label>Kilometraje al llegar:(*)</Label>
            <input
              type="number"
              className="form-control"
              placeholder="Kilometraje al llegar (100)"
              {...register("kilometraje_llegada", {
                required: {
                  value: true,
                  message: "Debe ingresar el kilometraje usado por el traslado",
                },
                valueAsNumber: true,
                validate: (value) => {
                  if (value < watch("kilometraje_salida")) {
                    return "El kilometraje al llegar debe ser mayor al kilometraje al salir.";
                  }
                },
              })}
            />
            <small className="form-text text-danger">
              {errors.kilometraje_llegada && errors.kilometraje_llegada.message}
            </small>
          </div>
        </Col>
        <Col md="6">
          <div className="form-group mb-3">
            <Label>Vía:</Label>
            <input
              type="text"
              placeholder="Nombre de la vía"
              className="form-control"
              {...register("via")}
            />
          </div>
        </Col>
        <Col md="6">
          <div className="form-group mb-3">
            <Label>Kilometro de vía:</Label>
            <input
              type="text"
              className="form-control"
              placeholder="Kilometro de vía (Km 20)"
              {...register("kilometro_via")}
            />
          </div>
        </Col>
        <Col md="6">
          <Label>Sentido de vía:</Label>
          <select
            {...register("sentido_via")}
            defaultValue={""}
            className="form-control mb-3"
          >
            <option value={evento.sentido_via ?? ""}>{evento.sentido_via ?? "Seleccione un sentido de vía"}</option>
            <option value="ascendente">Ascendente</option>
            <option value="descendente">Descendente</option>
          </select>
        </Col>
        <Col md="6">
          <Label>Clima:</Label>
          <select
            {...register("clima")}
            defaultValue={""}
            className="form-control mb-3"
          >
            <option value={evento.clima ?? ""}>{evento.clima ?? "Clima"}</option>
            <option value="normal">Normal</option>
            <option value="lluvia">Lluvia</option>
            <option value="nublado">Nublado</option>
          </select>
        </Col>
        <Col md="6">
          <Label>Estado de la vía:</Label>
          <select
            {...register("estado_via")}
            defaultValue={""}
            className="form-control mb-3"
          >
            <option value={evento.estado_via ?? ""}>{evento.estado_via ?? "Estado de la vía"}</option>
            <option value="dañada">Dañada</option>
            <option value="cerrada">Cerrada</option>
            <option value="normal">Normal</option>
            <option value="otro">Otro</option>
          </select>
        </Col>
        <Col md="6">
          <Label>Detalle el estado de la vía:</Label>
          <input
            type="text"
            className="form-control"
            placeholder="Si selecciono otro, por favor detalle el estado de la vía"
            {...register("detalle_estado_via")}
          />
        </Col>
        {evento.vehiculo_asistencia[0].categoria_vehiculo_peaje ===
          "AMBULANCIA" ? (
          <>
            <Col md="4">
              <div className="form-group mb-3">
                <Label>Sale con paciente:</Label>
                <input
                  type="datetime-local"
                  className="form-control"
                  {...register("sale_con_paciente")}
                />
              </div>
            </Col>
            <Col md="4">
              <div className="form-group mb-3">
                <Label>Nombres del paciente:</Label>
                <input
                  type="text"
                  placeholder="Nombres del paciente"
                  className="form-control"
                  {...register("nombres_paciente")}
                />
              </div>
            </Col>
            <Col md="4">
              <div className="form-group mb-3">
                <Label>Edad del paciente:</Label>
                <input
                  type="number"
                  placeholder="Edad del paciente"
                  className="form-control"
                  {...register("edad_paciente")}
                />
              </div>
            </Col>
            <Col md="4">
              <div className="form-group mb-3">
                <Label>Cédula del paciente:</Label>
                <input
                  type="number"
                  placeholder="Cédula del paciente"
                  className="form-control"
                  {...register("cedula_paciente")}
                />
              </div>
            </Col>
            <Col md="4">
              <Label>Actividad:</Label>
              <select
                {...register("actividad")}
                defaultValue={""}
                className="form-control mb-3"
              >
                <option value={evento.actividad ?? ""}>{evento.actividad ?? "Estado de la vía"}</option>
                <option value="usuario de via">Usuario de vía</option>
                <option value="personal de peaje">Personal de peaje</option>
                <option value="asistencia a comunidad">
                  Asistencia a comunidad
                </option>
              </select>
            </Col>
            <Col md="4">
              <div className="form-group mb-3">
                <Label>Médico en turno:</Label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nombre del médico"
                  defaultValue={evento.medico_turno}
                  {...register("medico_turno")}
                />
              </div>
            </Col>
            <Col md="12">
              <div className="form-group mb-3">
                <Label>Diagnostico del paciente:</Label>
                <textarea
                  rows="3"
                  type="text"
                  placeholder="Diagnostico del paciente"
                  className="form-control"
                  {...register("diagnostico")}
                />
              </div>
            </Col>
            <Col md="12">
              <div className="form-group mb-3">
                <Label>Prescripción medica:</Label>
                <textarea
                  rows="3"
                  type="text"
                  placeholder="Prescripción medica"
                  className="form-control"
                  {...register("prescripcion_medica")}
                />
              </div>
            </Col>
            <Col md="4">
              <Label>Estado del paciente:</Label>
              <select
                {...register("estado_paciente")}
                defaultValue={""}
                className="form-control mb-3"
              >
                <option value={evento.estado_paciente ?? ""}>{evento.estado_paciente ?? "Seleccione el estado del paciente"}</option>
                <option value="leve">Leve</option>
                <option value="moderado">Moderado</option>
                <option value="severo">Severo</option>
              </select>
            </Col>
            <Col md="4">
              <Label>Resultado del paciente:</Label>
              <select
                {...register("resultado_paciente")}
                defaultValue={""}
                className="form-control mb-3"
              >
                <option value={evento.resultado_paciente ?? ""}>{evento.resultado_paciente ?? "Seleccione un resultado del paciente"}</option>
                <option value="muere en">Muere en</option>
                <option value="llaga vivo">Llega vivo</option>
                <option value="para en">Para en</option>
                <option value="no. total">No. total</option>
              </select>
            </Col>
            <Col md="4">
              <Label>Traslado del paciente:</Label>
              <select
                {...register("traslado_paciente")}
                defaultValue={""}
                className="form-control mb-3"
              >
                <option value={evento.traslado_paciente ?? ""}>{evento.traslado_paciente ?? "Seleccione un traslado del paciente"}</option>
                <option value="hospital">Hospital</option>
                <option value="centro de salud">Centro de salud</option>
                <option value="clinica">Clínica</option>
                <option value="morgue">Morgue</option>
                <option value="otro">Otro</option>
              </select>
            </Col>
            <Col md="12">
              <div className="form-group mb-3">
                <Label>Detalle del traslado:</Label>
                <textarea
                  rows="3"
                  type="text"
                  placeholder="Detalle del translado"
                  className="form-control"
                  {...register("detalle_traslado")}
                />
              </div>
            </Col>
            <Col md="4">
              <div className="form-group mb-3">
                <Label>Nombre del usuario y/o acompañante:</Label>
                <input
                  type="text"
                  placeholder="Nombres"
                  className="form-control"
                  {...register("nombres_acompanante")}
                />
              </div>
            </Col>
            <Col md="4">
              <div className="form-group mb-3">
                <Label>Cédula del acompañante:</Label>
                <input
                  type="number"
                  placeholder="Cédula"
                  className="form-control"
                  {...register("cedula_acompanante")}
                />
              </div>
            </Col>
            <Col md="4">
              <div className="form-group mb-3">
                <Label>Teléfono del acompañante:</Label>
                <input
                  type="number"
                  placeholder="Teléfono"
                  className="form-control"
                  {...register("telefono_acompanante")}
                />
              </div>
            </Col>
          </>
        ) : (
          <>
            <Col md="4">
              <div className="form-group mb-3">
                <Label>Tipo de vehículo:</Label>
                <input
                  type="text"
                  placeholder="Bus - Camion"
                  className="form-control"
                  {...register("tipo")}
                />
              </div>
            </Col>
            <Col md="4">
              <div className="form-group mb-3">
                <Label>Marca:</Label>
                <input
                  type="text"
                  placeholder="Hino - VOLKSWAGEN"
                  className="form-control"
                  {...register("marca")}
                />
              </div>
            </Col>
            <Col md="4">
              <div className="form-group mb-3">
                <Label>Placa:</Label>
                <input
                  type="text"
                  placeholder="AAA-1234"
                  className="form-control"
                  {...register("placa")}
                />
              </div>
            </Col>
            <Col md="4">
              <div className="form-group mb-3">
                <Label>Color:</Label>
                <input
                  type="text"
                  placeholder="Blanco"
                  className="form-control"
                  {...register("color")}
                />
              </div>
            </Col>
            <Col md="12">
              <div className="form-group mb-3">
                <Label>Situación especial (Razón de demora):</Label>
                <textarea
                  rows="3"
                  type="text"
                  placeholder="Razón de demora"
                  className="form-control"
                  {...register("situacion_especial")}
                />
              </div>
            </Col>
            <Col md="12">
              <Label>Vehículo trasladado a:</Label>
              <select
                {...register("traslado_vehiculo")}
                defaultValue={""}
                className="form-control mb-3"
              >
                <option value={evento.traslado_vehiculo ?? ""}>{evento.traslado_vehiculo ?? "Seleccione el traslado del vehículo"}</option>
                <option value="destacamento atm">Destacamento ATM</option>
                <option value="canchong cte">Canchong CTE</option>
                <option value="estacion de peaje">Estación de peaje</option>
                <option value="taller mecanico">Taller mecanico</option>
                <option value="gasolinera">Gasolinera</option>
                <option value="buen recaudo">Buen recaudo</option>
                <option value="pueblo mas cercano">Pueblo mas cercano</option>
                <option value="otro">Otro</option>
              </select>
            </Col>
            <Col md="12">
              <div className="form-group mb-3">
                <Label>Detalle traslado del vehículo:</Label>
                <textarea
                  rows="3"
                  type="text"
                  placeholder="Detalle translado del vehículo"
                  className="form-control"
                  {...register("detalle_estado_vehiculo")}
                />
              </div>
            </Col>
            <Col md="6">
              <Label>Autoridad presente:</Label>
              <select
                {...register("autoridad_presente")}
                defaultValue={""}
                className="form-control mb-3"
              >
                <option value={evento.autoridad_presente ?? ""}>{evento.autoridad_presente ?? "Seleccione una autoridad presente"}</option>
                <option value="policia">Policia</option>
                <option value="cte">CTE</option>
                <option value="otros">Otros</option>
              </select>
            </Col>
            <div className="form-group mb-3">
              <Label>Descripción autoridad presente:</Label>
              <textarea
                rows="3"
                className="form-control"
                placeholder="Escriba el autoridad presente (Patrulla 54 oficial Pedro)"
                {...register("detalle_autoridad_presente")}
              />
            </div>
            <Col md="4">
              <div className="form-group mb-3">
                <Label>Nombres del usuario:</Label>
                <input
                  type="text"
                  placeholder="Nombres"
                  className="form-control"
                  {...register("nombres_acompanante")}
                />
              </div>
            </Col>
            <Col md="4">
              <div className="form-group mb-3">
                <Label>Cédula del usuario:</Label>
                <input
                  type="number"
                  placeholder="Cédula"
                  className="form-control"
                  {...register("cedula_acompanante")}
                />
              </div>
            </Col>
            <Col md="4">
              <div className="form-group mb-3">
                <Label>Teléfono del usuario:</Label>
                <input
                  type="number"
                  placeholder="Teléfono"
                  className="form-control"
                  {...register("telefono_acompanante")}
                />
              </div>
            </Col>
          </>
        )}
        <Col md="12">
          <div className="form-group mb-3">
            <Label>Describa porque se cancelo de la asistencia: (*)</Label>
            <textarea
              rows="3"
              type="text"
              placeholder="Describa porque se cancelo de la asistencia"
              className="form-control"
              {...register("detalle_cancelacion", {
                required: {
                  value: true,
                  message: "La descripción de la cancelación es requerida",
                },
              })}
            />
            <small className="form-text text-danger">
              {errors.detalle_cancelacion && errors.detalle_cancelacion.message}
            </small>
          </div>
        </Col>
        <div
          className="mt-4"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {state.isLoading ? null : (
            <Button className="btn-tagexpress" type="submit">
              Cancelar
            </Button>
          )}
        </div>
      </Row>
    </Form>
  );
};
