import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { notifyError, notifyOk } from "../../../../../utils/utils";
import { Button, Form, Label, Modal, ModalBody } from "reactstrap";
import { s_getPeajes } from "../../../../../services/peajesCtl";
import { s_getCategorias } from "../../../../../services/categoriaCtl";
import MapDraggableMarker from "../../../../elements/mapDraggableMarker/MapDraggableMarker";
import { Map } from "react-feather";
import dayjs from "dayjs";

import relativeTime from "dayjs/plugin/relativeTime";
import moment from "moment";
import duration from "dayjs/plugin/duration";
import { s_createEstadoVia } from "../../../../../services/estadoViaCtl";
dayjs.extend(duration);
dayjs.extend(relativeTime);

export default function NewEstadoVia({ onClose, reload }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const statePosition = useState({
    lat: null,
    lng: null,
  });
  const [position] = statePosition;

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [state, setState] = useState({
    isLoading: false,
    mainError: false,
  });
  const [data, setData] = useState({
    tolls: [],
    categorias: [],
    reload: false,
    status: "PROCESSING",
  });

  const _getToll = async () => {
    try {
      setData((old) => ({ ...old, status: "PROCESSING" }));
      const response = await s_getPeajes();
      setData((old) => ({
        ...old,
        tolls: response,
        status: "SUCCESS",
      }));
    } catch (error) {
      setData((old) => ({ ...old, status: "FAILED" }));
      if (error.response.status >= 500) {
        notifyError("ERROR");
        return;
      }
      notifyError(error.response.data.message, { autoClose: 3000 });
    }
  };

  const _getCategorias = async () => {
    try {
      setData((old) => ({ ...old, status: "PROCESSING" }));
      const response = await s_getCategorias({ estado: "ACTICO" });
      setData((old) => ({
        ...old,
        categorias: response,
        status: "SUCCESS",
      }));
    } catch (error) {
      setData((old) => ({ ...old, status: "FAILED" }));
      if (error.response.status >= 500) {
        notifyError("ERROR");
        return;
      }
      notifyError(error.response.data.message, { autoClose: 3000 });
    }
  };

  useEffect(() => {
    _getToll();
    _getCategorias();
  }, []);

  useEffect(() => {
    if (position.lat && position.lng) {
      setValue("lat", position.lat);
      setValue("long", position.lng);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position]);

  const onSubmit = async (data, e) => {
    var nowDate = moment(new Date(), "YYYY-MM-DD HH:mm:ss");
    var tiempoEstimado = moment(data.tiempo_estimado, "YYYY-MM-DD HH:mm:ss");
    var diff = tiempoEstimado.diff(nowDate, "m");

    try {
      setState((old) => ({ ...old, isLoading: true }));
      await s_createEstadoVia({
        ...data,
        tiempo_estimado: diff,
      });
      setState((old) => ({ ...old, isLoading: false }));
      notifyOk("Estado de vía se creada de manera correcta", {
        autoClose: 3000,
      });
      reload();
      onClose();
    } catch (error) {
      setState((old) => ({ ...old, isLoading: false }));
      if (error.response.status === 409) {
        notifyError(error.response.data.message);
        return;
      }
      notifyError(
        "Ocurrió un error al agregar el estado de la vía, por favor inténtelo más tarde."
      );
    }
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} size="xl" centered>
        <ModalBody>
          <MapDraggableMarker statePosition={statePosition} />
        </ModalBody>
      </Modal>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group mb-3">
          <Label>Peaje: (*)</Label>
          <select
            {...register("peaje_id", {
              required: {
                value: true,
                message: "El peaje es requerido",
              },
            })}
            defaultValue={""}
            className="form-control"
          >
            <option value="">Seleccione un peaje</option>
            {data.tolls.map((toll, index) => (
              <option value={`${toll.peaje_id}`} key={index}>
                {toll.descripcion}
              </option>
            ))}
          </select>
          <small className="form-text text-danger">
            {errors.peaje_id && errors.peaje_id.message}
          </small>
        </div>
        <div className="form-group mb-3">
          <Label>Categoría: (*)</Label>
          <select
            {...register("categoria_id", {
              required: {
                value: true,
                message: "La categoría es requerido",
              },
            })}
            defaultValue={""}
            className="form-control"
          >
            <option value="">Seleccione un categoría</option>
            {data.categorias.map((categoria, index) => (
              <option
                value={`${categoria.categoria_estado_peaje_id}`}
                key={index}
              >
                {categoria.descripcion}
              </option>
            ))}
          </select>
          <small className="form-text text-danger">
            {errors.categoria_id && errors.categoria_id.message}
          </small>
        </div>
        <div className="form-group mb-3">
          <Label>Vía:</Label>
          <input
            className="form-control"
            placeholder="Escriba la dirección, por ejemplo: derrumbe en el km 4"
            {...register("via")}
          />
        </div>
        <div className="form-group mb-3">
          <Label>Tiempo estimado:</Label>
          <input
            type="datetime-local"
            className="form-control"
            {...register("tiempo_estimado")}
            min={moment(new Date()).format("YYYY-MM-DDThh:mm")}
          />
        </div>
        <div className="form-group mb-3">
          <Label>Seleccione la zona donde sucedio el evento</Label>
          <br />
          <small onClick={toggle} className="map">
            <Map /> Ver mapa
          </small>
          <div className="d-flex flex-column gap-2 my-2">
            <div className="form-group">
              <Label>Latitud:</Label>
              <input
                type="text"
                className="form-control"
                placeholder="Ingrese la latitud"
                {...register("lat", {
                  required: {
                    value: true,
                    message: "La latitud es requerido",
                  },
                })}
                defaultValue={position.lat}
              />
              <small className="form-text text-danger">
                {errors.lat && errors.lat.message}
              </small>
            </div>
            <div className="form-group">
              <Label>Longitud:</Label>
              <input
                type="text"
                className="form-control"
                placeholder="Ingrese la longitud"
                {...register("long", {
                  required: {
                    value: true,
                    message: "La longitud es requerido",
                  },
                })}
                defaultValue={position.lng}
              />
              <small className="form-text text-danger">
                {errors.long && errors.long.message}
              </small>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {state.isLoading ? null : (
            <Button className="btn-tagexpress" type="submit">
              Guardar
            </Button>
          )}
        </div>
      </Form>
    </>
  );
}
