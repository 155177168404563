import React, { useState, useEffect } from "react";
import { Button, Form, Label, Row } from "reactstrap";
import {
  s_postCreateAsistVial,
  s_postCreateAsistVialCau,
} from "../../../../services/asistVialCtl";
import { notifyError, notifyOk } from "../../../../utils/utils";
import { formatTimeInMinute } from "../utils/utils";
import { useCentralContext } from "../../../../centralContext";
import { s_getPeajes, s_getTollVehicles } from "../../../../services/peajesCtl";
import { TimeInput } from "../../../elements/timeInput";
import { useForm, Controller } from "react-hook-form";

export const NewRequestForm = ({ vehicle, onClose }) => {
  const [tollVehicles, setTollVehicles] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useForm();
  const context = useCentralContext();

  const [state, setState] = useState({
    isLoading: false,
    mainError: false,
  });

  const [data, setData] = useState({
    tolls: [],
    reload: false,
    status: "PROCESSING",
  });

  const onSubmit = async (data, e) => {
    if (context.role === "cau") {
      const timeAssigned = formatTimeInMinute(data.tiempo_asignado);
      if (timeAssigned <= 0) {
        notifyError("El tiempo de asistencia debe ser mayor a 0", {
          autoClose: 3000,
        });
        return;
      }
      const dataTemp = {
        ...data,
        tiempo_asignado: timeAssigned,
      };
      try {
        setState((old) => ({ ...old, isLoading: true }));
        await s_postCreateAsistVialCau(dataTemp);
        setState((old) => ({ ...old, isLoading: false }));
        notifyOk("Asistencia registrada correctamente", {
          autoClose: 3000,
        });
        onClose();
      } catch (error) {
        setState((old) => ({ ...old, isLoading: false }));
        if (error.response.status === 409) {
          notifyError(error.response.data.message);
          return;
        }
        notifyError(
          "Ocurrió un error al crear la asistencia. Por favor, reinténtelo."
        );
      }
    } else {
      const timeAssigned = formatTimeInMinute(data.tiempo_asignado);
      if (timeAssigned <= 0) {
        notifyError("El tiempo de asistencia no puede ser 0", {
          autoClose: 3000,
        });
        return;
      }
      const vehicleSelected = vehicle.filter(
        (item) => item.vehiculo_asistencia_id === parseInt(data.vehicle)
      );
      const dataTemp = {
        ...data,
        vehiculo: [data.vehicle],
        tiempo_asignado: timeAssigned,
        tipo_vehiculo:
          vehicleSelected.length > 0
            ? vehicleSelected[0].categoria_vehiculos_peaje
              .categoria_vehiculo_peaje
            : null,
        kilometraje_salida:
          vehicleSelected.length > 0
            ? vehicleSelected[0].vehiculo_asistencium.kilometraje
            : null,
      };
      try {
        setState((old) => ({ ...old, isLoading: true }));
        await s_postCreateAsistVial(dataTemp);
        setState((old) => ({ ...old, isLoading: false }));
        notifyOk("Asistencia registrada de manera correcta", {
          autoClose: 3000,
        });
        onClose();
      } catch (error) {
        setState((old) => ({ ...old, isLoading: false }));
        if (error.response.status === 409) {
          notifyError(error.response.data.message);
          return;
        }
        notifyError(
          "Ocurrió un error al crear la asistencia, por favor inténtelo más tarde."
        );
      }
    }
  };

  const _getToll = async () => {
    try {
      setData((old) => ({ ...old, status: "PROCESSING" }));
      const response = await s_getPeajes();
      setData((old) => ({
        ...old,
        tolls: response,
        status: "SUCCESS",
      }));
    } catch (error) {
      setData((old) => ({ ...old, status: "FAILED" }));
      if (error.response.status >= 500) {
        notifyError("ERROR");
        return;
      }
      notifyError(error.response.data.message, { autoClose: 3000 });
    }
  };

  const _getTollVehicles = async (toll) => {
    const res = await s_getTollVehicles(toll);
    setTollVehicles(res);
  };

  useEffect(() => {
    _getToll();
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {context.role === "cau" ? (
        <Row>
          <div className="form-group mb-3">
            <Label>Nombres: (*)</Label>
            <input
              type="text"
              className="form-control"
              placeholder="Nombre del usuario"
              {...register("usuario_nombres", {
                required: {
                  value: true,
                  message: "Nombre es requerido",
                },
              })}
            />
            <small className="form-text text-danger">
              {errors.usuario_nombres && errors.usuario_nombres.message}
            </small>
          </div>
          <div className="form-group mb-3">
            <Label>Teléfono: (*)</Label>
            <input
              type="text"
              className="form-control"
              placeholder="teléfono del usuario"
              {...register("usuario_telefono", {
                required: {
                  value: true,
                  message: "Teléfono es requerido",
                },
              })}
            />
            <small className="form-text text-danger">
              {errors.usuario_telefono && errors.usuario_telefono.message}
            </small>
          </div>
          <div className="form-group mb-3">
            <Label>Placa: (*)</Label>
            <input
              type="text"
              className="form-control"
              placeholder="AAA-1234"
              {...register("usuario_placa", {
                required: {
                  value: true,
                  message: "Placa es requerida",
                },
              })}
            />
            <small className="form-text text-danger">
              {errors.usuario_placa && errors.usuario_placa.message}
            </small>
          </div>
          <div className="form-group mb-3">
            <Label>Descripción del vehiculo: (*)</Label>
            <textarea
              rows="3"
              className="form-control"
              placeholder="Color rojo, marca mazda"
              {...register("vehiculo_descripcion", {
                required: {
                  value: true,
                  message: "Descripción del vehículo es requerida",
                },
              })}
            />
            <small className="form-text text-danger">
              {errors.vehiculo_descripcion &&
                errors.vehiculo_descripcion.message}
            </small>
          </div>
          <div className="form-group mb-3">
            <Label>Descripción de la asistencia: (*)</Label>
            <textarea
              rows="3"
              className="form-control"
              placeholder="Escriba una descripción"
              {...register("descripcion", {
                required: {
                  value: true,
                  message: "Descripción de la asistencia es requerida",
                },
              })}
            />
            <small className="form-text text-danger">
              {errors.descripcion && errors.descripcion.message}
            </small>
          </div>
          <div className="form-group mb-3">
            <Label>Peaje: (*)</Label>
            <select
              {...register("peaje_id", {
                required: {
                  value: true,
                  message: "Peaje es requerido",
                },
              })}
              defaultValue={""}
              className="form-control"
              onChange={(e) => _getTollVehicles(e.target.value)}
            >
              <option value="">Seleccione un peaje</option>
              {data.tolls.map((toll, index) => (
                <option
                  value={`${toll.peaje_id}`}
                  name={vehicle.descripcion}
                  key={index}
                >
                  {toll.descripcion}
                </option>
              ))}
            </select>
            <small className="form-text text-danger">
              {errors.peaje_id && errors.peaje_id.message}
            </small>
          </div>
          {tollVehicles.length > 0 ? (
            <div className="form-group mb-3">
              <Label>Tipo vehículo: (*)</Label>
              <select
                {...register("tipo_vehiculo", {
                  required: {
                    value: true,
                    message: "Tipo de vehículo es requerido",
                  },
                })}
                defaultValue={""}
                className="form-control"
              >
                <option value="">Seleccione un vehículo</option>
                {tollVehicles.map((vehicle, index) => (
                  <option
                    value={vehicle.vehiculo_asistencia_id}
                    name={
                      vehicle.categoria_vehiculos_peaje.categoria_vehiculo_peaje
                    }
                    key={index}
                    disabled={
                      vehicle.vehiculo_asistencium.estado_asistencia ===
                      "Ocupado"
                    }
                  >
                    {vehicle.vehiculo_asistencium.codigo_vehiculo +
                      "/" +
                      vehicle.categoria_vehiculos_peaje
                        .categoria_vehiculo_peaje +
                      "/" +
                      vehicle.categoria_vehiculos_peaje.sub_categoria_vehiculo}
                  </option>
                ))}
              </select>
              <small className="form-text text-danger">
                {errors.tipo_vehiculo && errors.tipo_vehiculo.message}
              </small>
            </div>
          ) : null}
          <div className="form-group mb-3">
            <Label>
              Asigne el tiempo de asistencia en horas y minutos: (*)
            </Label>

            <Controller
              control={control}
              name="tiempo_asignado"
              render={({ field: { onChange } }) => (
                <TimeInput onChange={onChange} />
              )}
              required={{
                value: true,
                message: "Tiempo asignado es requerido",
              }}
            />

            <small className="form-text text-danger">
              {errors.tiempo_asignado && errors.tiempo_asignado.message}
            </small>
          </div>
        </Row>
      ) : (
        <Row>
          <div className="form-group mb-3">
            <Label>Nombres del usuario: (*)</Label>
            <input
              type="text"
              className="form-control"
              placeholder="Nombre del usuario"
              {...register("usuario_nombres", {
                required: {
                  value: true,
                  message: "Nombre es requerido",
                },
              })}
            />
            <small className="form-text text-danger">
              {errors.usuario_nombres && errors.usuario_nombres.message}
            </small>
          </div>
          <div className="form-group mb-3">
            <Label>Teléfono: (*)</Label>
            <input
              type="text"
              className="form-control"
              placeholder="Teléfono del usuario"
              {...register("usuario_telefono", {
                required: {
                  value: true,
                  message: "Teléfono es requerido",
                },
              })}
            />
            <small className="form-text text-danger">
              {errors.usuario_nombres && errors.usuario_nombres.message}
            </small>
          </div>
          <div className="form-group mb-3">
            <Label>Placa: (*)</Label>
            <input
              type="text"
              className="form-control"
              placeholder="AAA-1234"
              {...register("usuario_placa", {
                required: {
                  value: true,
                  message: "Placa es requerida",
                },
              })}
            />
            <small className="form-text text-danger">
              {errors.usuario_placa && errors.usuario_placa.message}
            </small>
          </div>
          <div className="form-group mb-3">
            <Label>Ubicacion: (*)</Label>
            <input
              type="text"
              className="form-control"
              placeholder="Km 12, vía a el pan"
              {...register("ubicacion", {
                required: {
                  value: true,
                  message: "Ubicación es requerida",
                },
              })}
            />
            <small className="form-text text-danger">
              {errors.ubicacion && errors.ubicacion.message}
            </small>
          </div>
          <div className="form-group mb-3">
            <Label>Descripción del vehiculo: (*)</Label>
            <textarea
              rows="3"
              className="form-control"
              placeholder="Color rojo, marca mazda"
              {...register("vehiculo_descripcion", {
                required: {
                  value: true,
                  message: "Descripción del vehículo es requerida",
                },
              })}
            />
            <small className="form-text text-danger">
              {errors.vehiculo_descripcion &&
                errors.vehiculo_descripcion.message}
            </small>
          </div>
          <div className="form-group mb-3">
            <Label>Detalle de la asistencia: (*)</Label>
            <textarea
              rows="3"
              className="form-control"
              placeholder="Escriba una descripción"
              {...register("descripcion", {
                required: {
                  value: true,
                  message: "Descripción de la asistencia es requerida",
                },
              })}
            />
            <small className="form-text text-danger">
              {errors.descripcion && errors.descripcion.message}
            </small>
          </div>
          <div className="form-group mb-3">
            <Label>Vehículo asistencia: (*)</Label>
            <select
              {...register("vehicle", {
                required: {
                  value: true,
                  message: "Vehículo es requerido",
                },
              })}
              defaultValue={""}
              className="form-control"
            >
              <option value="">Seleccione un vehículo</option>
              {vehicle.map((vehicle, index) => (
                <option
                  value={`${vehicle.vehiculo_asistencia_id}`}
                  name={
                    vehicle.categoria_vehiculos_peaje.categoria_vehiculo_peaje
                  }
                  key={index}
                  disabled={
                    vehicle.vehiculo_asistencium.estado_asistencia === "Ocupado"
                  }
                >
                  {`${vehicle.categoria_vehiculos_peaje.categoria_vehiculo_peaje} / ${vehicle.vehiculo_asistencium.placa}`}
                </option>
              ))}
            </select>
            <small className="form-text text-danger">
              {errors.vehicle && errors.vehicle.message}
            </small>
          </div>
          <div className="form-group mb-3">
            <Label>Nombre del chofer de asistencia: (*)</Label>
            <textarea
              rows="3"
              className="form-control"
              placeholder="Nombre del chofer"
              {...register("chofer_asistencia", {
                required: {
                  value: true,
                  message: "Nombre del chofer es requerido",
                },
              })}
            />
            <small className="form-text text-danger">
              {errors.chofer_asistencia && errors.chofer_asistencia.message}
            </small>
          </div>

          <div className="form-group mb-3">
            <Label>
              Asigne el tiempo de asistencia en horas y minutos: (*)
            </Label>

            <Controller
              control={control}
              name="tiempo_asignado"
              render={({ field: { onChange } }) => (
                <TimeInput onChange={onChange} />
              )}
              required={{
                value: true,
                message: "Tiempo asignado es requerido",
              }}
            />

            <small className="form-text text-danger">
              {errors.tiempo_asignado && errors.tiempo_asignado.message}
            </small>
          </div>
        </Row>
      )}

      <div style={{ display: "flex", justifyContent: "center" }}>
        {state.isLoading ? null : (
          <Button className="btn-tagexpress" type="submit">
            Guardar
          </Button>
        )}
      </div>
    </Form>
  );
};
