import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Col, Form, Row } from "reactstrap";
import { s_updateStatusReportedProduct } from "../../../../../services/reportedProduct";
import { notifyError } from "../../../../../utils/utils";

export const ChangeStatus = ({
  reported,
  setData,
  rows,
  setShowChangeStatus,
}) => {
  const { register, handleSubmit } = useForm();

  const [state, setState] = useState({
    isLoading: false,
    mainError: false,
  });

  const onSubmit = async (data) => {
    try {
      setState((old) => ({ ...old, isLoading: true }));
      const dataTemp = { ...data, id: reported.id };
      await s_updateStatusReportedProduct(dataTemp);
      setState((old) => ({
        ...old,
        isLoading: false,
      }));
      const reportedProduct = rows.reportedProduct.map((item) =>
        item.id === reported.id
          ? { ...item, estado: dataTemp.status }
          : { ...item }
      );
      setData((old) => ({ ...old, reportedProduct }));
      setShowChangeStatus(false);
    } catch (error) {
      setState((old) => ({ ...old, isLoading: false }));
      if (error.response.status >= 500) {
        notifyError("ERROR");
        return;
      }
      notifyError(error.response.data.message, { autoClose: 3000 });
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <div className="form-group mb-3">
              <label>Estado:</label>
              <select
                {...register("status")}
                defaultValue={reported.estado}
                className="form-control"
              >
                <option value="">Seleccione un estado</option>
                <option value="REPORTADO">Reportado</option>
                <option value="VALIDADO">Validado</option>
                <option value="ELIMINADO">Eliminado</option>
                <option value="RECHAZADO">Rechazado</option>
              </select>
            </div>
          </Col>
        </Row>
        {state.isLoading ? null : <Button type="submit">Guardar</Button>}
      </Form>
    </div>
  );
};
