import { apiClientes } from '../network';


// Gets specific tag's info from server
async function s_getTag(serie) {
  const response = await apiClientes.get("/tags", {
    params: {
      serie
    }
  });

  if (response && response.status !== 200) {
    throw new Error();
  }

  return response.data.data;
}


export {
  s_getTag
}