import React, { useState, useRef } from "react";
import { Form, Button } from "reactstrap";
import { useForm } from "react-hook-form";
import { api } from "../../../../../network";
import { headers, notifyError, notifyOk } from "../../../../../utils/utils";
import { ToastContainer } from "react-toastify";
import CanvasDraw from "react-canvas-draw";
import { DataURIToBlob } from "../../../../../utils/dataURItoBlob";

export const ApproveRegisterDeliveryForm = ({ id, setShowApprove, reload }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const signCanvas = useRef(null);

  const [state, setState] = useState({
    isLoading: false,
    mainError: false,
  });

  const [imageBase64, setImageBase64] = useState(null);

  const createSignImg = async (e) => {
    e.preventDefault();
    const signImg = signCanvas.current.getDataURL("image/png");
    setImageBase64(signImg);
  };

  const clearSign = async (e) => {
    e.preventDefault();
    signCanvas.current.clear();
    setImageBase64(null);
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      setState((old) => ({ ...old, isLoading: true }));
      const form = new FormData();
      form.append(
        "fotoFirma",
        DataURIToBlob(imageBase64),
        `${Math.random()}mySign.png`
      );
      form.append("comentario", data.comentario);
      const resp = await api.put(`/v1/registerDelivery/approve/${id}`, form, {
        headers: headers(),
      });
      notifyOk(resp.data.message, { autoClose: 3000 });
      reload();
      setImageBase64(null);
      setState((old) => ({ ...old, isLoading: false }));
      setShowApprove(false);
    } catch (error) {
      notifyError(
        "Ocurrió un error al aprobar el registro, por favor inténtelo más tarde."
      );
    }
  };

  return (
    <div>
      <ToastContainer />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group mb-3">
          <label>Comentario</label>
          <textarea
            className="form-control"
            placeholder="Comentario"
            {...register("comentario", {
              maxLength: { value: 20, message: "Maximo de letras 20" },
            })}
            rows="3"
          ></textarea>

          <small className="form-text text-danger">
            {errors.comentario && errors.comentario.message}
          </small>
        </div>
        <div className="form-group mb-3">
          <label>Firma</label>
          <CanvasDraw
            ref={signCanvas}
            brushColor="#000"
            brushRadius={1}
            lazyRadius={10}
            canvasWidth={250}
            canvasHeight={250}
          />
        </div>
        <div>
          <Button
            type="button"
            color="danger"
            onClick={clearSign}
            style={{ marginRight: 10 }}
          >
            Vacía el lienzo
          </Button>
          {imageBase64 === null ? (
            <Button onClick={createSignImg} type="button">
              Generar firma
            </Button>
          ) : (
            <>
              {state.isLoading ? null : (
                <Button type="submit" className="btn-tagexpress">
                  Guardar
                </Button>
              )}
            </>
          )}
        </div>
      </Form>
    </div>
  );
};
