import React, { useState } from "react";
import { Form, Button } from "reactstrap";
import { useForm } from "react-hook-form";
import { api } from "../../../../../../network";
import { headers, notifyError, notifyOk } from "../../../../../../utils/utils";
import { ToastContainer } from "react-toastify";

export const NewRegisterProductForm = ({ setShowModalNewRegister, reload }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [state, setState] = useState({
    isLoading: false,
    mainError: false,
  });

  const onSubmit = async (data) => {
    try {
      setState((old) => ({ ...old, isLoading: true }));
      await api.post("/v1/productos/create", data, {
        headers: headers(),
      });
      notifyOk("Producto creado", { autoClose: 3000 });
      reload();
      setState((old) => ({ ...old, isLoading: false }));
      setShowModalNewRegister(false);
    } catch (error) {
      notifyError("ERROR DE SERVIDOR");
    }
  };

  return (
    <>
      <ToastContainer />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group mb-3">
          <label>Nombre Producto</label>
          <input
            className="form-control"
            placeholder="Escriba el producto"
            {...register("nombre_producto", {
              required: { value: true, message: "El producto es requerido" },
            })}
          ></input>
          <small className="form-text text-danger">
            {errors.nombre_producto && errors.nombre_producto.message}
          </small>
        </div>
        <div className="form-group mb-3">
          <label>Cantidad</label>
          <input
            type="number"
            className="form-control"
            placeholder="Cantidad"
            {...register("cantidad", {
              required: { value: true, message: "La cantidad es requerido" },
            })}
          />
          <small className="form-text text-danger">
            {errors.cantidad && errors.cantidad.message}
          </small>
        </div>
        <div className="form-group mb-3">
          <label>Descripción</label>
          <textarea
            className="form-control"
            placeholder="Descripción"
            {...register("descripcion", {
              maxLength: { value: 100, message: "Maximo de letras 100" },
            })}
            rows="3"
          ></textarea>
          <small className="form-text text-danger">
            {errors.descripcion && errors.descripcion.message}
          </small>
        </div>
        {state.isLoading ? null : (
          <Button className="btn-tagexpress" type="submit">
            Guardar
          </Button>
        )}
      </Form>
    </>
  );
};
