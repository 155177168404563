import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Row,
  Table,
} from "reactstrap";
import dayjs from "dayjs";
import {
  notifyError,
  notifyInfo,
  excelFilterRep,
  capitalizeHeader,
} from "../../../../utils/utils";
import TheLoader from "../../../elements/theLoader";
import { s_getDailySaleFilter } from "../../../../services/dailySalesCtl";
import XLSX from "xlsx";
import excelImg from "../../../../assets/Excel-Logo.png";
import { s_geStores } from "../../../../services/store.Ctl";

export const DailysSalesReport = ({ pageName }) => {
  const { register, handleSubmit, reset } = useForm();

  const [data, setData] = useState({
    stores: [],
    reports: [],
    reportsTable: [],
    reload: false,
    loading: true,
    loadingFilter: false,
  });

  const _getStores = async () => {
    try {
      setData((old) => ({
        ...old,
        loading: true,
      }));
      const resp = await s_geStores();
      setData((old) => ({
        ...old,
        stores: resp,
        loading: false,
      }));
    } catch (error) {
      notifyError("ERROR");
    }
  };

  const _onSubmit = async (data, e) => {
    e.preventDefault();

    try {
      setData((old) => ({
        ...old,
        loadingFilter: true,
      }));
      const resp = await s_getDailySaleFilter(data);
      const dataTemp = [];
      resp.homologation?.details.map((item) => {
        dataTemp.push({
          ...item,
          usuario: item.usuario.usuario,
          product: "Homologación",
        });
        return [];
      });
      resp.recharge?.details.map((item) => {
        dataTemp.push({
          ...item,
          usuario: item.usuario.usuario,
          product: "Recargas",
        });
        return [];
      });
      resp.salesTag?.details.map((item) => {
        dataTemp.push({
          ...item,
          usuario: item.usuario.usuario,
          product: "Tags",
        });
        return [];
      });
      if (dataTemp.length === 0) {
        notifyInfo("No hay resultados");
      }
      setData((old) => ({
        ...old,
        reports: resp,
        reportsTable: dataTemp,
        loadingFilter: false,
      }));
    } catch (error) {
      notifyError("ERROR");
    }
  };

  useEffect(() => {
    _getStores();
  }, [data.reload]);

  const _clearFilter = () => {
    reset();
    setData((old) => ({
      ...old,
      reports: [],
      reportsTable: [],
    }));
  };

  const _handleOnExport = () => {
    const filterArray = excelFilterRep(
      data.reportsTable,
      "id_venta_homologacion",
      "cierre_de_caja",
      "updated",
      "usuario_id",
      "id_venta_recarga",
      "id_venta_tag"
    );
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(filterArray);

    XLSX.utils.book_append_sheet(wb, ws, "Reporte ventas diarias");

    XLSX.writeFile(wb, "Ventas diarias.xlsx");
  };

  return (
    <div className="pb-3">
      <ToastContainer style={{ width: "400px" }} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        className="mb-4"
      >
        <h3 style={{ margin: 0 }}>{pageName}</h3>
      </div>
      {data.loading || data.loadingFilter ? (
        <TheLoader />
      ) : (
        <>
          <Card>
            <CardHeader>Filtro</CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit(_onSubmit)}>
                <Row>
                  <Col md={4}>
                    <div className="form-group mb-3">
                      <label>Desde:</label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Fecha de inicio"
                        {...register("start_date")}
                        defaultValue={dayjs(new Date()).format("YYYY-MM-DD")}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="form-group mb-3">
                      <label>Hasta:</label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Fecha de inicio"
                        {...register("end_date")}
                        defaultValue={dayjs(new Date()).format("YYYY-MM-DD")}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="form-group mb-3">
                      <label>Producto:</label>
                      <select
                        {...register("product")}
                        defaultValue={""}
                        className="form-control"
                      >
                        <option value="">Seleccione un producto</option>
                        <option value="homologation">Homologacion</option>
                        <option value="recharge">Recargas</option>
                        <option value="salesTag">Tags</option>
                        <option value="salesTag">Wallet</option>
                      </select>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="form-group mb-3">
                      <label>Punto de venta:</label>
                      <select
                        {...register("store")}
                        defaultValue={""}
                        className="form-control"
                      >
                        <option value="">Seleccione un punto de venta</option>
                        {data.stores.map((store, index) => (
                          store.estado === 'A' ? (
                            <option value={`${store.punto_de_venta_id}`} key={index}>
                              {store.nombre}
                            </option>
                          ) : null
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="form-group mb-3">
                      <label>Tipo:</label>
                      <select
                        {...register("type")}
                        defaultValue={""}
                        className="form-control"
                      >
                        <option value="">Seleccione un tipo</option>
                        <option value="cliente">Cliente</option>
                        <option value="revendedor">Revendedor</option>
                        <option value="Cruz azul / Phamarcy">
                          Cruz azul / Phamarcy
                        </option>
                      </select>
                    </div>
                  </Col>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    {data.reportsTable.length > 0 && (
                      <img
                        src={excelImg}
                        alt="excel"
                        style={{ height: "40px" }}
                        onClick={_handleOnExport}
                      />
                    )}
                    <Button
                      type="button"
                      color="light"
                      className="mx-2"
                      onClick={_clearFilter}
                    >
                      <img
                        src={_clearFilter}
                        width="20px"
                        style={{ marginRight: 10 }}
                        alt=""
                      />
                      Limpiar filtro
                    </Button>
                    <Button color="success">Buscar</Button>
                  </div>
                </Row>
              </Form>
            </CardBody>
          </Card>
          {data.reportsTable.length > 0 && (
            <>
              <Card className="mt-3">
                <CardBody>
                  <Table>
                    <thead style={{ backgroundColor: "#e8b800" }}>
                      <tr>
                        <th style={{ textAlign: "center" }}>Cantidad</th>
                        <th style={{ textAlign: "center" }}>Producto</th>
                        <th style={{ textAlign: "center" }}>Consesión</th>
                        <th style={{ textAlign: "center" }}>Tipo</th>
                        <th style={{ textAlign: "center" }}>Fecha</th>
                        <th style={{ textAlign: "center" }}>Usuario</th>
                        <th style={{ textAlign: "center" }}>Tipo de venta</th>
                        <th style={{ textAlign: "center" }}>Retención</th>
                        <th style={{ textAlign: "end" }}>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.reportsTable.map((report, index) => (
                        <tr key={index}>
                          <th scope="row" style={{ textAlign: "center" }}>
                            {report.cantidad}
                          </th>
                          <td style={{ textAlign: "center" }}>
                            {report.product}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {!report.concesion ? " - " : report.concesion}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {!report.tipo ? " - " : report.tipo}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {report.created}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {report.usuario}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {!report.tipo_pago
                              ? " - "
                              : capitalizeHeader(report.tipo_pago)}
                          </td>
                          {!report.retencion ? (
                            <td style={{ textAlign: "center" }}>-</td>
                          ) : (
                            <td style={{ textAlign: "end" }}>
                              $ {report.retencion}
                            </td>
                          )}
                          <td style={{ textAlign: "end" }}>$ {report.total}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
              <Row className="my-3 d-flex justify-content-end">
                <Col md={7}>
                  <Card>
                    <CardBody>
                      <Table>
                        <tfoot style={{ textAlign: "end" }}>
                          <tr>
                            <td>
                              <b>Valor transferencia a cuenta CALPRANDINA</b>
                            </td>
                            <td>
                              ${" "}
                              {!data.reports.totalPaymentType
                                ? "0.00"
                                : data.reports.totalPaymentType.transferCalprandina.toFixed(
                                  2
                                )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Deposito</b>
                            </td>
                            <td>
                              ${" "}
                              {!data.reports.totalPaymentType
                                ? "0.00"
                                : data.reports?.totalPaymentType.deposit.toFixed(
                                  2
                                )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Efectivo</b>
                            </td>
                            <td>
                              ${" "}
                              {!data.reports.totalPaymentType
                                ? "0.00"
                                : data.reports?.totalPaymentType.cash.toFixed(
                                  2
                                )}
                            </td>
                          </tr>
                          <tr>
                            <th id="total" style={{ textAlign: "end" }}>
                              Total :
                            </th>
                            <td style={{ textAlign: "end" }}>
                              {" "}
                              $ {data.reports.totalPaymentType.total.toFixed(2)}
                            </td>
                          </tr>
                        </tfoot>
                      </Table>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={3}>
                  <Card>
                    <CardBody>
                      <Table>
                        <tfoot style={{ textAlign: "end" }}>
                          <tr>
                            <td>
                              <b>Tags</b>
                            </td>
                            <td>
                              ${" "}
                              {!data.reports.salesTag
                                ? "0.00"
                                : data.reports?.salesTag.total}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Homologaciones</b>
                            </td>
                            <td>
                              ${" "}
                              {!data.reports.homologation
                                ? "0.00"
                                : data.reports.homologation.total}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Recargas</b>
                            </td>
                            <td>
                              ${" "}
                              {!data.reports.recharge
                                ? "0.00"
                                : data.reports?.recharge.total}
                            </td>
                          </tr>
                          <tr style={{ color: "red" }}>
                            <th id="total" style={{ textAlign: "end" }}>
                              Retención :
                            </th>
                            <td style={{ textAlign: "end" }}>
                              {" "}
                              $ {data.reports.allRetention}
                            </td>
                          </tr>
                          <tr>
                            <th id="total" style={{ textAlign: "end" }}>
                              Total :
                            </th>
                            <td style={{ textAlign: "end" }}>
                              {" "}
                              $ {data.reports.allTotal}
                            </td>
                          </tr>
                        </tfoot>
                      </Table>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </>
      )}
    </div>
  );
};
