import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Row,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import { ToastContainer } from "react-toastify";
import { CheckCircle, AlertCircle } from "react-feather";
import clearFilter from "../../../../assets/ic_filter.svg";
import { domain, notifyError, notifyOk } from "../../../../utils/utils";
import TheLoader from "../../../elements/theLoader";
import "./compensacion.css";
import {
  s_getCompensations,
  s_updataStateCompensations,
} from "../../../../services/compensacionCtl";
import { s_getExternalEntities } from "../../../../services/configs";

const Compensacion = ({ pageName }) => {
  const { register, handleSubmit, reset, getValues } = useForm();

  const [data, setData] = useState({
    compensations: [],
    total: 0,
    reverses: 0,
    expenses: 0,
    reload: false,
    loading: true,
    loadingFilter: false,
  });
  const [externalEntity, setExternalEntity] = useState([]);

  const [updateState, setUpdateState] = useState([]);
  const [showModalCompensation, setShowModalCompensation] = useState(false);
  const _toggleCompensation = () =>
    setShowModalCompensation(!showModalCompensation);

  useEffect(() => {
    _getCompensations();
    _getExternalEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.reload]);

  const _getExternalEntities = async () => {
    const result = await s_getExternalEntities();
    setExternalEntity(result.data);
  };

  const _getCompensations = async () => {
    try {
      setData((old) => ({
        ...old,
        loading: true,
      }));
      const data = getValues();
      const resp = await s_getCompensations(data);
      setData((old) => ({
        ...old,
        compensations: resp.data,
        total: resp.total,
        reverses: resp.reverses,
        expenses: resp.expenses,
        loading: false,
      }));
    } catch (error) {
      notifyError("ERROR");
    }
  };

  const _clearFilter = () => {
    reset();
    setData((old) => ({
      ...old,
      compensations: [],
    }));
    _getCompensations();
  };

  const _onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      setData((old) => ({
        ...old,
        loadingFilter: true,
      }));

      const resp = await s_getCompensations(data);

      setData((old) => ({
        ...old,
        loadingFilter: false,
        compensations: resp.data,
        total: resp.total,
        reverses: resp.reverses,
        expenses: resp.expenses,
      }));
    } catch (error) {
      setData((old) => ({
        ...old,
        loadingFilter: false,
      }));
      notifyError("ERROR");
    }
  };

  const _onChangeCheck = (e, compensation) => {
    if (e.target.checked) {
      setUpdateState((old) => [...old, compensation.transaccion_id]);
    } else {
      const newData = updateState.filter(
        (item) => item !== compensation.transaccion_id
      );
      setUpdateState(newData);
    }
  };

  const _compensationState = async () => {
    try {
      setData((old) => ({
        ...old,
        loading: true,
      }));
      await s_updataStateCompensations(updateState);
      setData((old) => ({
        ...old,
        loading: false,
        reload: !old.reload,
      }));
      setShowModalCompensation(false);
      notifyOk("Las compensaciones se realizaron correctamente.");
      setUpdateState([]);
    } catch (error) {
      setData((old) => ({
        ...old,
        loading: false,
      }));
      notifyError("ERROR");
    }
  };

  return (
    <div>
      <ToastContainer style={{ width: "400px" }} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        className="mb-4"
      >
        <h3 style={{ margin: 0 }}>{pageName}</h3>
      </div>
      <Modal
        isOpen={showModalCompensation}
        toggle={_toggleCompensation}
        centered
        size="sm"
        backdrop={"static"}
        style={{ maxWidth: "500px" }}
      >
        <ModalHeader
          toggle={_toggleCompensation}
          style={{ borderBottom: "none" }}
        >
          Compensación Peajes
        </ModalHeader>
        <ModalBody style={{ padding: "0px 1rem" }}>
          <div style={{ display: "flex" }}>
            <AlertCircle style={{ marginRight: 8 }} />
            <p style={{ margin: 0 }}>
              Estas seguro de realizar la compensacion, no sé podra revertir
              esta acción.
            </p>
          </div>
        </ModalBody>
        <ModalFooter style={{ borderTop: "none" }}>
          <Button
            size="sm"
            style={{ padding: "5px 20px" }}
            color="light"
            onClick={_toggleCompensation}
          >
            No
          </Button>
          <Button
            size="sm"
            style={{ padding: "5px 20px" }}
            color="success"
            onClick={_compensationState}
          >
            Si
          </Button>
        </ModalFooter>
      </Modal>
      {data.loading || data.loadingFilter ? (
        <TheLoader />
      ) : (
        <>
          <Card>
            <CardHeader>Filtro</CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit(_onSubmit)}>
                <Row>
                  <Col md={3}>
                    <div className="form-group mb-3">
                      <label>Desde:</label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Fecha de inicio"
                        {...register("start_date")}
                        defaultValue={dayjs(new Date()).format("YYYY-MM-DD")}
                        min={dayjs(
                          new Date().setDate(new Date().getDate() - 30)
                        ).format("YYYY-MM-DD")}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="form-group mb-3">
                      <label>Hasta:</label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Fecha de inicio"
                        {...register("end_date")}
                        defaultValue={dayjs(new Date()).format("YYYY-MM-DD")}
                      />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="form-group mb-3">
                      <label>Ente Externo:</label>
                      <select
                        className="form-control"
                        {...register("external_entity")}
                        defaultValue={""}
                      >
                        <option value="">Seleccione una ente externo</option>
                        {externalEntity.map((entity) => (
                          <option value={entity.codigo_ente_externo}>
                            {entity.descripcion}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      type="button"
                      color="light"
                      className="mx-2"
                      onClick={_clearFilter}
                    >
                      <img
                        src={clearFilter}
                        width="20px"
                        style={{ marginRight: 10 }}
                        alt=""
                      />
                      Limpiar filtro
                    </Button>
                    <Button color="success">Buscar</Button>
                  </div>
                </Row>
              </Form>
            </CardBody>
          </Card>
          {data.compensations.length > 0 ? (
            <>
              <div className="m-3 d-flex justify-content-between">
                <div>
                  {updateState.length > 1 && (
                    <Button onClick={() => setShowModalCompensation(true)}>
                      Realizar Compensacion Masiva
                    </Button>
                  )}
                </div>
                <div className="h4">
                  <b>Reversos:</b> <span className="mx-3">{data.reverses}</span>
                  <b>Debitos:</b> <span className="mx-3">${data.expenses}</span>
                  <b>Total a compensar:</b>{" "}
                  <span className="mx-3">${data.total}</span>
                </div>
              </div>
              <Card className="mt-3">
                <CardBody>
                  <Table>
                    <thead style={{ backgroundColor: "#e8b800" }}>
                      <tr style={{ textAlign: "center" }}>
                        <th></th>
                        <th>Codigo</th>
                        <th>Descripcion</th>
                        <th>Dominio</th>
                        <th>Fecha</th>
                        <th>Fecha Compensacion</th>
                        <th>Ente externo</th>
                        <th>Monto</th>
                        <th>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.compensations.map((compensation, index) => (
                        <tr style={{ textAlign: "center" }} key={index}>
                          <th>
                            {compensation.codigo_ente_externo &&
                              compensation.estado !== "P" && (
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    _onChangeCheck(e, compensation)
                                  }
                                />
                              )}
                          </th>
                          <th scope="row">{compensation.codigo_transaccion}</th>
                          <th style={{ textAlign: "start" }}>
                            {compensation.descripcion}
                          </th>
                          <th>{domain(compensation.dominio)}</th>
                          <th>{compensation.created}</th>
                          <th>
                            {compensation.fecha_compensacion
                              ? `${compensation.fecha_compensacion}`
                              : `-`}
                          </th>

                          <th>
                            {compensation.ente_externo
                              ? `${compensation.ente_externo.descripcion}`
                              : `-`}
                          </th>
                          <th
                            style={{
                              color:
                                compensation.tipo_transaccion === "I"
                                  ? "#198754"
                                  : "red",
                            }}
                          >
                            {compensation.tipo_transaccion === "I" ? "+" : "-"}$
                            {compensation.monto}
                          </th>
                          <th>
                            {compensation.codigo_ente_externo &&
                              compensation.estado !== "P" && (
                                <CheckCircle
                                  className="pointer"
                                  style={{ marginRight: 8, color: "#198754" }}
                                  onClick={() => {
                                    setUpdateState([
                                      compensation.transaccion_id,
                                    ]);
                                    setShowModalCompensation(true);
                                  }}
                                />
                              )}
                          </th>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </>
          ) : (
            <h2>No existe ninguna compensacion</h2>
          )}
        </>
      )}
    </div>
  );
};

export default Compensacion;
