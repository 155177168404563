import React, { useState, useCallback, useEffect } from "react";
import { Form, Button } from "reactstrap";
import { useForm } from "react-hook-form";
import { api } from "../../../../../../network";
import { headers, notifyError, notifyOk } from "../../../../../../utils/utils";
import { ToastContainer } from "react-toastify";

export const EditRegisterProductForm = ({ id, setShowEdit, reload }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [data, setData] = useState({
    product: {},
    reload: false,
    loadingProducts: true,
  });

  const [state, setState] = useState({
    isLoading: false,
    mainError: false,
  });

  const getProduct = useCallback(async () => {
    try {
      setData((old) => ({ ...old, loadingProducts: true }));
      const response = await api.get(`/v1/productos/${id}`, {
        headers: headers(), // ask only for active personas
      });

      if (response && response.status !== 200) {
        notifyError("ERROR");
        return;
      }

      setData((old) => ({
        ...old,
        product: response.data.data,
        loadingProducts: false,
      }));
    } catch (e) {
      notifyError("ERROR");
    }
  }, [id]);

  const onSubmit = async (data) => {
    try {
      setState((old) => ({ ...old, isLoading: true }));
      await api.put(`/v1/productos/${id}`, data, {
        headers: headers(),
      });
      notifyOk("Producto Editado", { autoClose: 3000 });
      reload();
      setState((old) => ({ ...old, isLoading: false }));
      setShowEdit(false);
    } catch (error) {
      notifyError("ERROR");
    }
  };

  useEffect(() => {
    getProduct();
  }, [getProduct]);

  setValue(
    "nombre_producto",
    data.product.nombre_producto ? data.product.nombre_producto : ""
  );
  setValue("cantidad", data.product.cantidad ? data.product.cantidad : "");
  setValue(
    "descripcion_producto",
    data.product.descripcion_producto ? data.product.descripcion_producto : ""
  );

  return (
    <>
      <ToastContainer />
      {data.loadingProducts ? (
        <h1>Loading</h1>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group mb-3">
            <label>Nombre Producto</label>
            <input
              className="form-control"
              placeholder="Escriba el producto"
              {...register("nombre_producto", {
                required: { value: true, message: "El producto es requerido" },
                value: data.product.nombre_producto,
              })}
              defaultValue={data.product.nombre_producto}
            ></input>
            <small className="form-text text-danger">
              {errors.nombre_producto && errors.nombre_producto.message}
            </small>
          </div>
          <div className="form-group mb-3">
            <label>Cantidad</label>
            <input
              type="number"
              className="form-control"
              placeholder="Cantidad"
              {...register("cantidad", {
                required: { value: true, message: "La cantidad es requerido" },
              })}
              disabled
            />
            <small className="form-text text-danger">
              {errors.cantidad && errors.cantidad.message}
            </small>
          </div>
          <div className="form-group mb-3">
            <label>Descripción</label>
            <textarea
              className="form-control"
              placeholder="Descripción"
              {...register("descripcion_producto", {
                maxLength: { value: 100, message: "Maximo de letras 100" },
              })}
              rows="3"
            ></textarea>

            <small className="form-text text-danger">
              {errors.descripcion_producto &&
                errors.descripcion_producto.message}
            </small>
          </div>
          {state.isLoading ? null : (
            <Button className="btn-tagexpress" type="submit">
              Guardar
            </Button>
          )}
        </Form>
      )}
    </>
  );
};
