import React, { useState,useEffect,useCallback} from "react";
import { Edit, Trash2} from "react-feather";
import { ToastContainer } from "react-toastify";
import {
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { api } from "../../../../../../network";
import { headers, notifyError, notifyOk } from "../../../../../../utils/utils";
import { EditProviderForm } from "./EditProviderForm";

export const TableProvider =({ reload }) =>{

    const [data, setData] = useState({
        provider: [],
        reload: false,
        loading: true,
    });
    
    // const [state, setState] = useState({
    //     isLoading: false,
    //     mainError: false,
    // });

    const getProvider = useCallback(async () => {
        try {
          setData((old) => ({ ...old, loading: true }));
          const response = await api.get("/v1/proveedores", {
            headers: headers(), // ask only for active personas
          });
    
          if (response && response.status !== 200) {
            notifyError("ERROR");
            return;
          }
    
          setData((old) => ({
            ...old,
            loading: false,
            provider: response.data.data,
          }));
        } catch (e) {
          notifyError("ERROR");
        }
    }, []);
        const [showEdit, setShowEdit] = useState(false);
        const toggleEdit = () => setShowEdit(!showEdit);
        const [showDelete, setShowDelete] = useState(false);
        const toggleDelete = () => setShowDelete(!showDelete);
        const [registerId, setRegisterId] = useState(null);

        const deleteRegister = async (e) => {
            e.preventDefault();
            console.log("hola");
            try {
              const resp = await api.delete(`/v1/proveedores/${registerId}`, {
                headers: headers(),
              });
        
              if (resp && resp.status !== 200) {
                notifyError("ERROR");
                return;
              }
              notifyOk("Borrado con Éxito", { autoClose: 3000 });
              reload();
              setShowDelete(false);
            } catch (error) {
              debugger;
              console.log(error);
            }
          };

    useEffect(() => {
       getProvider();
    }, [getProvider]);

    return(
        <div>
            <ToastContainer/>
                <Modal isOpen={showEdit} toggle={toggleEdit}>
                <ModalHeader toggle={toggleEdit} style={{ color:"var(--blue)"}}>
                    Editar Producto
                </ModalHeader>
                <ModalBody>
                <EditProviderForm
                    id={registerId}
                    setShowEdit={setShowEdit}
                    reload={reload}
                />
                </ModalBody>
                </Modal>
                <Modal isOpen={showDelete} toggle={toggleDelete}>
                <ModalBody>
                    <h4>¿Seguro desea Eliminar?</h4>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={() => setShowDelete(false)}>Cancelar</Button>
                    <Button color="danger" onClick={deleteRegister}>
                        Eliminar
                    </Button>
                </ModalFooter>
                </Modal>
            <Table className="report-table text-center table-font table table-striped table-hover">
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Contacto</th>
                        <th>Pais</th>
                        <th>Estado</th>
                        <th>Producto</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {data.provider.map((item)=>(
                        <tr key={item.id}>
                            <td>{item.nombre_proveedor}</td>
                            <td>{item.contacto_proveedor}</td>
                            <td>{item.pais_proveedor}</td>
                            <td>
                                {item.estado === "A" && (
                                <label className="text-success">Activo</label>
                                )}
                                {item.estado === "I" && (
                                <label className="text-warning">Inactivo</label>
                                )}
                            </td>
                            <td>{item.producto.nombre_producto}</td>
                            <td>
                                <Edit
                                    className=" pointer"
                                    style={{ 
                                        marginRight: 8,
                                        color: '#0d6efd' 
                                     }}
                                     onClick={() => {
                                        setShowEdit(true);
                                        setRegisterId(item.id);
                                      }}
                                >
                                </Edit>
                                <Trash2
                                    className=" pointer"
                                    style={{ 
                                        marginRight: 8,
                                        color: 'red' 
                                    }}
                                    onClick={() => {
                                        setShowDelete(true);
                                        setRegisterId(item.id);
                                    }}
                                >
                                </Trash2>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}