import { toast } from "react-toastify";
import dayjs from "dayjs";

// Create limit method to limit string length and put '...'
/*eslint no-extend-native: ["error", { "exceptions": ["String"] }]*/
String.prototype.limit = function (length) {
  return this.length > length ? this.substring(0, length) + "..." : this;
};

// Receives ISO UTC Datetime and returns localtime as '2020-01-29' or '2020-01-29 20:12:07'
function ecDate(utcDate, withTime = true) {
  debugger;
  let format = "yyyy-MM-DD";

  if (withTime) {
    format = "yyyy-MM-DD HH:mm:ss";
  }

  return new dayjs(utcDate).local().format(format);
}

// Returns date in format: yyyy-mm-dd <HH:mm:ss>
const getDate = (withTime) => {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  let hours = String(today.getHours()).padStart(2, "0");
  let minutes = String(today.getMinutes()).padStart(2, "0");
  let seconds = String(today.getSeconds()).padStart(2, "0");

  today = yyyy + "-" + mm + "-" + dd;

  if (withTime) {
    today += " " + hours + ":" + minutes + ":" + seconds;
  }

  return today;
};

// Converts a date to a human friendly format: Viernes, 3 de febrero del 2021
const toHumanStyleDate = (originalDate, withTime) => {
  const weekdays = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ];
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  let result = "";
  const date = dayjs(originalDate);
  const weekDay = date.day();
  const monthDay = date.date();
  const month = date.month();
  const year = date.year();
  result +=
    weekdays[weekDay] +
    ", " +
    monthDay +
    " de " +
    months[month] +
    " del " +
    year;

  if (withTime) {
    const hours = date.hour();
    const minutes = date.minute();
    result += `, ${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}`;
  }

  return result;
};

const domain = (type) => {
  switch (type) {
    case "peajes":
      return "Peajes";
    case "calpradolar":
      return "Calpradolar";

    default:
      return "-";
  }
};

// HTTP headers template
const headers = function () {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
};

// For data validation
const validators = {
  onlyNumber: (value) => {
    const re = /^[0-9\b]+$/;
    if (value !== "" && !re.test(value)) {
      return false;
    }

    return true;
  },

  onlyHex: (value) => {
    const re = /^[a-fA-F0-9]+$/;
    if (value !== "" && !re.test(value)) {
      return false;
    }

    return true;
  },

  onlyDollars: (value) => {
    const re = /^\d{1,5}\.?\d{0,2}$/;
    if (value !== "" && !re.test(value)) {
      return false;
    }

    return true;
  },

  onlyPlaca: (value) => {
    const re = /^[a-zA-Z]{1,3}-?\d{0,4}$/;
    if (value !== "" && !re.test(value)) {
      return false;
    }

    return true;
  },

  cedula: (id) => {
    let totalSuma = 0;
    let verif;

    // Check if it's posible cedula or ruc
    if (id.length !== 10 && id.length !== 13) {
      return false;
    }

    // Validate provincia code
    let provincia = Number(id[0] + id[1]);
    if (provincia < 0 || provincia > 30) {
      return false;
    }
    // Validate tipo de id code
    let tipo = Number(id[2]);
    if (tipo !== 9 && tipo > 6) {
      return false;
    }

    // If it's a ruc then it must have 001 at the end
    if (id.length === 13) {
      if (id.substring(10, 13) !== "001") {
        return false;
      }
    }

    for (let i = 0; i < 9; i++) {
      if (i % 2 === 0) {
        let tmp = Number(id[i]) * 2;
        totalSuma += tmp < 10 ? tmp : tmp - 9;
      } else {
        totalSuma += Number(id[i]);
      }
    }

    verif = 10 - (totalSuma % 10);
    verif = verif === 10 ? 0 : verif;

    if (verif.toString() === id[9]) {
      // VALID
      return true;
    }

    return false;
  },
};

function notifyError(message, params) {
  toast.error(message, params || { autoClose: 2000 });
}

function notifyOk(message, params) {
  toast.success(message, params || { autoClose: 2000 });
}

function notifyInfo(message, params) {
  toast.info(message, params || { autoClose: 2000 });
}

  function secondsToHMS(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
  
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = remainingSeconds.toString().padStart(2, '0');
  
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

const convertMinuteHour = (tiempo) => {
  const horas = Math.floor(tiempo / 60);
  const minutos = tiempo % 60;
  const horaCo = new Date().setHours(horas);
  const fecha = new Date(horaCo).setMinutes(minutos);
  return new Date(fecha);
};

const estimatedTimeToComplete = (tiempo, tiempoAsignado) => {
  const hora_registro = new Date(tiempoAsignado);
  const horaNueva = new Date().setHours(
    hora_registro.getHours() + tiempo.getHours()
  );
  const estimatedTimeToComplete = new Date(horaNueva).setMinutes(
    hora_registro.getMinutes() + tiempo.getMinutes()
  );
  return new Date(estimatedTimeToComplete);
};

const queryString = (object) => {
  const objectCopy = {};

  for (const key of Object.keys(object)) {
    objectCopy[key] = object[key];
  }

  const keys = Object.keys(objectCopy);
  return keys
    .map((key) => {
      const value = object[key];
      if (value === undefined) {
        return "";
      }

      if (value === null) {
        return key;
      }

      return key + "=" + value;
    })
    .filter((x) => x.length > 0)
    .join("&");
};

const excelFilterRep = (
  arr,
  item1,
  item2,
  item3,
  item4,
  item5,
  item6,
  item7,
  item8,
  item9
) => {
  arr.forEach((object) => {
    delete object[item1];
    delete object[item2];
    delete object[item3];
    delete object[item4];
    delete object[item5];
    delete object[item6];
    delete object[item7];
    delete object[item8];
    delete object[item9];
  });
  return arr;
};

/**
 * It takes an array of objects and returns a new array of objects with only the keys specified in the
 * second argument
 * @param array - the array of objects you want to filter
 * @param keys - an array of strings that are the keys you want to filter out
 * @returns An array of objects with only the keys that are in the keys array.
 */
const excelFilter = (array, keys) => {
  const resp = array.map((item) => {
    const filtered = Object.keys(item)
      .filter((key) => keys.includes(key))
      .reduce((obj, key) => {
        obj[key] = item[key];
        return obj;
      }, {});
    return filtered;
  });
  return resp;
};

const capitalizeHeader = (str) => {
  if (str === "") return "";
  const allLowercaseLetters = str.toLowerCase();
  const separated = allLowercaseLetters.split("_").join(" ");
  const capitalizeFirstLetter =
    separated.charAt(0).toUpperCase() + separated.slice(1);
  return capitalizeFirstLetter;
};

const getCalificacion = (cali) => {
  switch (cali) {
    case 1:
      return "MUY MALO";
    case 2:
      return "MALO";
    case 3:
      return "REGULAR";
    case 4:
      return "BUENO";
    case 5:
      return "EXELENTE";
    default:
      return "REGULAR";
  }
};

const getDatasets = (cali) => {
  let data = [0, 0, 0, 0, 0];
  switch (cali) {
    case 1:
      return data[0] + 1;
    case 2:
      return data[1] + 1;
    case 3:
      return data[2] + 1;
    case 4:
      return data[3] + 1;
    case 5:
      return data[4] + 1;
    default:
      return data[2] + 1;
  }
};

export {
  ecDate,
  toHumanStyleDate,
  getDate,
  headers,
  validators,
  notifyOk,
  notifyError,
  notifyInfo,
  secondsToHMS,
  convertMinuteHour,
  estimatedTimeToComplete,
  queryString,
  excelFilterRep,
  domain,
  capitalizeHeader,
  excelFilter,
  getCalificacion,
  getDatasets,
};
