import { useEffect, useState } from "react";
import {
  s_deleteEstadoVia,
  s_finalizarEstadoVia,
  s_getEstadoVia,
} from "../../../../services/estadoViaCtl";
import { notifyError, notifyOk } from "../../../../utils/utils";
import ClearFilter from "../../../../assets/ic_filter.svg";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import { Check, FileText, PlusSquare, Trash2 } from "react-feather";
import { useForm } from "react-hook-form";
import DetalleEstadoVia from "./components/DetalleEstadoVia";
import { minutosATiempo } from "../../../../utils/minutosTiempo";
import ConfirmModal from "../../../../utils/components/ConfirmModal";
import { ToastContainer } from "react-toastify";
import NewEstadoVia from "./components/NewEstadoVia";
import ReactTooltip from "react-tooltip";

import "./estadoVia.css";

export default function EstadoVia({ pageName }) {
  const { register, handleSubmit, reset } = useForm();

  const [selectedEstadoVia, setSelectedEstadoVia] = useState(null);
  const [showDetalle, setShowDetalle] = useState(false);
  const toggleDetails = (eventoRow) => {
    setShowDetalle(!showDetalle);
    setSelectedEstadoVia(eventoRow);
  };
  const [showNewEstado, setShowNewEstado] = useState(false);
  const toggleNewEstado = () => setShowNewEstado(!showNewEstado);

  const [confirmDelete, setConfirmDelete] = useState(false);
  const toggleConfirmDelete = () => setConfirmDelete(!confirmDelete);
  const [confirmFinalizar, setConfirmFinalizar] = useState(false);
  const toggleConfirmFinalizar = () => setConfirmFinalizar(!confirmFinalizar);

  const [data, setData] = useState({
    estadoVias: [],
    reload: false,
    loading: true,
    status: "SUCCESS",
  });

  const [, setFilter] = useState({
    status: "",
    identificacion: "",
  });

  const _getEstadoVias = async (data) => {
    try {
      setData((old) => ({ ...old, status: "PROCESSING" }));
      const response = await s_getEstadoVia({ ...data });
      setData((old) => ({
        ...old,
        estadoVias: response,
        status: "SUCCESS",
      }));
    } catch (error) {
      setData((old) => ({ ...old, status: "FAILED" }));
      if (error.response.status >= 500) {
        notifyError("ERROR");
        return;
      }
      notifyError(error.response.data.message, { autoClose: 3000 });
    }
  };

  useEffect(() => {
    _getEstadoVias();
  }, [data.reload]);

  const reload = () =>
    setData((old) => ({
      estadoVias: [],
      reload: !old.reload,
      loading: !old.loading,
      status: "PROCESSING",
    }));

  const onSubmit = async (data, e) => {
    e.preventDefault();
    setFilter(data);
    const dataTemp = { ...data, page: 0, size: 15 };
    _getEstadoVias(dataTemp);
  };

  const clearFilter = () => {
    reset();
    setFilter({
      status: "",
      identificacion: "",
    });
    setData((old) => ({
      ...old,
      qualifications: [],
      status: "SUCCESS",
    }));
    _getEstadoVias();
  };

  const _handleDeleteEstado = async () => {
    try {
      await s_deleteEstadoVia(selectedEstadoVia.estado_peaje_id);
      notifyOk("El estado de la vía se a eliminado sactisfactoriamente", {
        autoClose: 3000,
      });
      setConfirmDelete(false);
      reload();
    } catch (error) {
      if (error.response.status === 409) {
        notifyError(error.response.data.message);
        return;
      }
      notifyError(
        "Ocurrió un error al agregar el tipo, por favor inténtelo más tarde."
      );
    }
  };

  const _handleFinalizarEstado = async () => {
    try {
      await s_finalizarEstadoVia(selectedEstadoVia.estado_peaje_id);
      notifyOk("El estado de la vía se a finalizo sactisfactoriamente", {
        autoClose: 3000,
      });
      setConfirmFinalizar(false);
      reload();
    } catch (error) {
      if (error.response.status === 409) {
        notifyError(error.response.data.message);
        return;
      }
      notifyError(
        "Ocurrió un error al agregar el tipo, por favor inténtelo más tarde."
      );
    }
  };

  return (
    <div>
      <ToastContainer />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3>{pageName}</h3>
        <div>
          <Button className="btn-tagexpress" onClick={toggleNewEstado}>
            <PlusSquare data-tip="Crear un nuevo estado" />
          </Button>
          <ReactTooltip type="light" />
        </div>
      </div>
      <ConfirmModal
        toggle={toggleConfirmDelete}
        isOpen={confirmDelete}
        onClickYes={_handleDeleteEstado}
        body="¿Estás seguro de eliminar el estado de la vía?"
      />
      <ConfirmModal
        toggle={toggleConfirmFinalizar}
        isOpen={confirmFinalizar}
        onClickYes={_handleFinalizarEstado}
        body="¿Estás seguro de finalizar el estado de la vía?"
      />
      <Offcanvas
        direction="end"
        fade={false}
        toggle={() => toggleDetails(null)}
        isOpen={showDetalle}
      >
        <OffcanvasHeader toggle={() => toggleDetails(null)}>
          Detalle
        </OffcanvasHeader>
        <OffcanvasBody>
          {selectedEstadoVia && (
            <DetalleEstadoVia selectedEstadoVia={selectedEstadoVia} />
          )}
        </OffcanvasBody>
      </Offcanvas>
      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggleNewEstado}
        isOpen={showNewEstado}
      >
        <OffcanvasHeader toggle={toggleNewEstado}>
          Nuevo estado de vía
        </OffcanvasHeader>
        <OffcanvasBody>
          <NewEstadoVia
            reload={reload}
            onClose={() => setShowNewEstado(false)}
          />
        </OffcanvasBody>
      </Offcanvas>
      <Card className="my-3">
        <CardHeader style={{ backgroundColor: "var(--yellow)" }}>
          Filtro
        </CardHeader>
        <CardBody>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={3}>
                <div className="form-group mb-3">
                  <label>Estado:</label>
                  <select
                    {...register("status")}
                    defaultValue={""}
                    className="form-control chosen-select"
                  >
                    <option value="">Seleccione un estado</option>
                    <option value="ACTIVO">Activo</option>
                    <option value="INACTIVO">Inactivo</option>
                  </select>
                </div>
              </Col>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div></div>
                <div>
                  <Button
                    type="button"
                    color="light"
                    className="mx-2"
                    onClick={clearFilter}
                  >
                    <img
                      src={ClearFilter}
                      width="20px"
                      style={{ marginRight: 10 }}
                      alt=""
                    />
                    Limpiar filtro
                  </Button>
                  <Button color="success">Buscar</Button>
                </div>
              </div>
            </Row>
          </Form>
        </CardBody>
      </Card>
      {data.status === "SUCCESS" && (
        <>
          {data.estadoVias.length !== 0 ? (
            <div>
              <Table>
                <thead className="tableHeader">
                  <tr>
                    <th>Peaje</th>
                    <th>Categoria</th>
                    <th>Tiempo estimado</th>
                    <th>Vía</th>
                    <th>Fecha de creación</th>
                    <th>Estado</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data.estadoVias.map((estadoVia) => (
                    <tr key={estadoVia.estado_peaje_id}>
                      <td>{estadoVia.peaje.descripcion}</td>
                      <td>{estadoVia.categoria_estado_peaje.descripcion}</td>
                      <td>
                        {estadoVia.tiempo_estimado
                          ? minutosATiempo(estadoVia.tiempo_estimado)
                          : "-"}
                      </td>
                      <td>{estadoVia.via || "-"}</td>
                      <td>{estadoVia.created}</td>
                      <td>
                        {estadoVia.estado === "ACTIVO" && (
                          <Badge color="success">Activo</Badge>
                        )}
                        {estadoVia.estado === "INACTIVO" && (
                          <Badge color="danger">Inactivo</Badge>
                        )}
                      </td>
                      <td>
                        <div className="d-flex gap-2">
                          <FileText
                            style={{
                              width: 20,
                              height: 20,
                              cursor: "pointer",
                              marginRight: 5,
                            }}
                            id={`detalle-${estadoVia.estado_peaje_id}`}
                            onClick={() => toggleDetails(estadoVia)}
                          />
                          <UncontrolledTooltip
                            placement="top"
                            target={`detalle-${estadoVia.estado_peaje_id}`}
                          >
                            Ver detalle
                          </UncontrolledTooltip>
                          {estadoVia.estado === "ACTIVO" && (
                            <>
                              <Check
                                style={{
                                  width: 20,
                                  height: 20,
                                  cursor: "pointer",
                                  marginRight: 5,
                                }}
                                id={`aprovada-${estadoVia.estado_peaje_id}`}
                                onClick={() => {
                                  setConfirmFinalizar(!confirmFinalizar);
                                  setSelectedEstadoVia(estadoVia);
                                }}
                              />
                              <UncontrolledTooltip
                                placement="top"
                                target={`aprovada-${estadoVia.estado_peaje_id}`}
                              >
                                Finalizar
                              </UncontrolledTooltip>
                            </>
                          )}
                          {estadoVia.estado === "ACTIVO" && (
                            <>
                              <Trash2
                                style={{
                                  width: 20,
                                  height: 20,
                                  cursor: "pointer",
                                  marginRight: 5,
                                }}
                                id={`delete-${estadoVia.estado_peaje_id}`}
                                onClick={() => {
                                  setConfirmDelete(!confirmDelete);
                                  setSelectedEstadoVia(estadoVia);
                                }}
                              />
                              <UncontrolledTooltip
                                placement="top"
                                target={`delete-${estadoVia.estado_peaje_id}`}
                              >
                                Eliminar
                              </UncontrolledTooltip>
                            </>
                          )}
                        </div>
                        {/* 
                            {homologacion.status !== "FINALIZADA" &&
                              homologacion.status !== "RECHAZADO" && (
                                <>
                                  <FilePlus
                                    style={{
                                      width: 20,
                                      height: 20,
                                      cursor: "pointer",
                                      marginRight: 5,
                                    }}
                                    id={`observacion-${homologacion.id_homologacion}`}
                                    onClick={() => toggleDetails(homologacion)}
                                  />
                                  <UncontrolledTooltip
                                    placement="top"
                                    target={`observacion-${homologacion.id_homologacion}`}
                                  >
                                    Editar observacion y estado
                                  </UncontrolledTooltip>
                                  <XCircle
                                    style={{
                                      width: 20,
                                      height: 20,
                                      cursor: "pointer",
                                      marginRight: 5,
                                    }}
                                    id={`rechazar-${homologacion.id_homologacion}`}
                                    onClick={() => {
                                      _updateHomologacion(
                                        { status: "RECHAZADO" },
                                        homologacion.id_homologacion
                                      );
                                    }}
                                  />
                                  <UncontrolledTooltip
                                    placement="top"
                                    target={`rechazar-${homologacion.id_homologacion}`}
                                  >
                                    Rechazar
                                  </UncontrolledTooltip>
                                  <Check
                                    style={{
                                      width: 20,
                                      height: 20,
                                      cursor: "pointer",
                                      marginRight: 5,
                                    }}
                                    id={`aprovada-${homologacion.id_homologacion}`}
                                    onClick={() => {
                                      _updateHomologacion(
                                        { status: "FINALIZADA" },
                                        homologacion.id_homologacion
                                      );
                                    }}
                                  />
                                  <UncontrolledTooltip
                                    placement="top"
                                    target={`aprovada-${homologacion.id_homologacion}`}
                                  >
                                    Aprobar
                                  </UncontrolledTooltip>
                                </>
                              )} */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <div className="mt-5">
              <h1 className="alert-title">No hay registros al momentos</h1>
            </div>
          )}
        </>
      )}
    </div>
  );
}
