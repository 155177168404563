import { ConfirmModal } from "../../../../elements/modals/confirm"

export const CommitCompensationModal = ({ isOpen, total, toggle, onYes }) => {
  return (
    <ConfirmModal
      isOpen={isOpen}
      toggle={toggle}
      header="Confirmar compensación"
      body={
        <div>
          <p style={{ margin: 0 }}>
            ¿Estás seguro de querer realizar la compensación? No se podrá revertir esta acción.
          </p>
          <p>
            El total a compensar es <b>${total} .</b>
          </p>
        </div>
      }
      onYes={onYes}
    />
  )
}