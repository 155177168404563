import { useEffect, useState } from "react";
import { Edit, PlusSquare } from "react-feather";
import ReactTooltip from "react-tooltip";
import {
  Badge,
  Button,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import { s_getCategorias } from "../../../../services/categoriaCtl";
import { notifyError } from "../../../../utils/utils";
import NewCategory from "./components/NewCategory";
import UpdateCategory from "./components/UpdateCategory";

export const Categoria = ({ pageName }) => {
  const [newCategoria, setNewCategoria] = useState(false);
  const toggleCategoria = () => setNewCategoria(!newCategoria);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [editCategoria, setEditCategoria] = useState(false);
  const toggleEditCategoria = (categoria) => {
    setEditCategoria(!editCategoria);
    setSelectedCategory(categoria);
  };

  const [data, setData] = useState({
    categorias: [],
    reload: false,
    loading: true,
    status: "SUCCESS",
  });

  const _getCategorias = async (data) => {
    try {
      setData((old) => ({ ...old, status: "PROCESSING" }));
      const response = await s_getCategorias({ ...data });
      setData((old) => ({
        ...old,
        categorias: response,
        status: "SUCCESS",
      }));
    } catch (error) {
      setData((old) => ({ ...old, status: "FAILED" }));
      if (error.response.status >= 500) {
        notifyError("ERROR");
        return;
      }
      notifyError(error.response.data.message, { autoClose: 3000 });
    }
  };

  const reload = () => setData((old) => ({ ...old, reload: true }));

  useEffect(() => {
    _getCategorias();
  }, [data.reload]);

  return (
    <div>
      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggleCategoria}
        isOpen={newCategoria}
      >
        <OffcanvasHeader toggle={toggleCategoria}>
          Nueva categoría
        </OffcanvasHeader>
        <OffcanvasBody>
          <NewCategory
            setShowCreate={() => setNewCategoria(false)}
            getCategoria={() => reload()}
          />
        </OffcanvasBody>
      </Offcanvas>
      <Offcanvas
        direction="end"
        fade={false}
        toggle={() => toggleEditCategoria(null)}
        isOpen={editCategoria}
      >
        <OffcanvasHeader toggle={() => toggleEditCategoria(null)}>
          Editar categoría
        </OffcanvasHeader>
        <OffcanvasBody>
          {selectedCategory && (
            <UpdateCategory
              setShowEdit={() => setEditCategoria(false)}
              getCategoria={() => reload()}
              selectedCategory={selectedCategory}
            />
          )}
        </OffcanvasBody>
      </Offcanvas>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        className="mb-4"
      >
        <h3>{pageName}</h3>
        <div>
          <Button
            onClick={() => setNewCategoria(true)}
            className="btn-tagexpress"
            data-tip="Crear nueva categoría"
          >
            <PlusSquare />
          </Button>
          <ReactTooltip type="light" />
        </div>
      </div>
      {data.status === "SUCCESS" && (
        <>
          {data.categorias.length !== 0 ? (
            <div>
              <Table>
                <thead className="tableHeader">
                  <tr>
                    <th>Descripción</th>
                    <th>Estado</th>
                    <th>Fecha de creación</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data.categorias.map((categoria) => (
                    <tr key={categoria.categoria_estado_peaje_id}>
                      <td>{categoria.descripcion}</td>
                      <td>
                        {categoria.estado === "INACTIVO" && (
                          <Badge color="warning">Inactivo</Badge>
                        )}
                        {categoria.estado === "ACTIVO" && (
                          <Badge color="success">Activo</Badge>
                        )}
                      </td>
                      <td>{categoria.created}</td>
                      <td>
                        <Edit
                          style={{
                            width: 20,
                            height: 20,
                            cursor: "pointer",
                            marginRight: 5,
                          }}
                          id={`editar-${categoria.categoria_estado_peaje_id}`}
                          onClick={() => toggleEditCategoria(categoria)}
                        />
                        <UncontrolledTooltip
                          placement="top"
                          target={`editar-${categoria.categoria_estado_peaje_id}`}
                        >
                          Editar
                        </UncontrolledTooltip>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <div className="mt-5">
              <h1 className="alert-title">No hay categorias al momento</h1>
            </div>
          )}
        </>
      )}
    </div>
  );
};
