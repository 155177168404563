import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Label } from "reactstrap";
import { Star } from "react-feather";
import { notifyError, notifyOk } from "../../../../utils/utils";
import { s_postQualification } from "../../../../services/qualification.Ctl";

export const Rating = ({ evento, onClose }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [state, setState] = useState({
    isLoading: false,
    mainError: false,
  });

  const [clicked, setClicked] = React.useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const handleStarClick = (e, index) => {
    e.preventDefault();
    let clickStates = [...clicked];
    for (let i = 0; i < 5; i++) {
      if (i <= index) clickStates[i] = true;
      else clickStates[i] = false;
    }
    setClicked(clickStates);
    setState((old) => ({ ...old, mainError: false }));
  };

  const onSubmit = async (data, e) => {
    const rating = clicked.filter((resp) => resp === true).length;
    if (rating === 0) {
      setState((old) => ({ ...old, mainError: true }));
      return;
    }

    try {
      setState((old) => ({ ...old, isLoading: true }));

      const dataTemp = {
        calificacion: rating,
        observacion: data.observation,
        estado: "R",
        usuario_nombres: data.names,
        usuario_telefono: data.phone,
        usuario_email: data.email,
      };
      const resp = await s_postQualification(
        dataTemp,
        evento.evento_asistencia_id
      );
      notifyOk(resp, { autoClose: 3000 });
      notifyOk("Asistencia atendida de manera correcta", { autoClose: 3000 });
      onClose();
      setState((old) => ({ ...old, isLoading: false }));
    } catch (error) {
      setState((old) => ({ ...old, isLoading: false }));
      if (error.response.status === 409) {
        notifyError(error.response.data.message);
        return;
      }
      notifyError(
        "Ocurrió un error al agregar producto, por favor inténtelo más tarde."
      );
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group mb-3">
          <Label>Nombre completo (*)</Label>
          <input
            className="form-control"
            placeholder="Escriba su nombre completo"
            {...register("names", {
              required: {
                value: true,
                message: "Los nombres son requerido",
              },
            })}
          />
          <small className="form-text text-danger">
            {errors.names && errors.names.message}
          </small>
        </div>
        <div className="form-group mb-3">
          <Label>Teléfono:</Label>
          <input
            className="form-control"
            placeholder="Escriba un teléfono"
            {...register("phone")}
          />
        </div>
        <div className="form-group mb-3">
          <Label>Correo electrónico:</Label>
          <input
            type="email"
            className="form-control"
            placeholder="Escriba un correo electrónico"
            {...register("email")}
          />
        </div>
        <div className="form-group mb-3">
          <Label>Calificación:</Label>
          <div className="rating">
            <Star
              onClick={(e) => handleStarClick(e, 0)}
              className={clicked[0] ? "clickedstar" : null}
            />
            <Star
              onClick={(e) => handleStarClick(e, 1)}
              className={clicked[1] ? "clickedstar" : null}
            />
            <Star
              onClick={(e) => handleStarClick(e, 2)}
              className={clicked[2] ? "clickedstar" : null}
            />
            <Star
              onClick={(e) => handleStarClick(e, 3)}
              className={clicked[3] ? "clickedstar" : null}
            />
            <Star
              onClick={(e) => handleStarClick(e, 4)}
              className={clicked[4] ? "clickedstar" : null}
            />
          </div>
          <small className="form-text text-danger">
            {state.mainError && "La calificación es requerida"}
          </small>
        </div>
        <div className="form-group mb-3">
          <Label>Observación:</Label>
          <textarea
            rows="3"
            className="form-control"
            placeholder="Escriba una observación"
            {...register("observation")}
          />
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          {state.isLoading ? null : (
            <Button className="btn-tagexpress" type="submit">
              Guardar
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
};
