import { adminRoutes } from "./admin";
import { customerSupportRoutes } from "./customerSupport";
import { roadSupportRoutes } from "./roadSupport";
import { tagExpressRoutes } from "./tagExpress";

export const routesByRole = {
  usuarioT1000: [
    {
      name: "Asistencia vial",
      icon: "fa-medkit",
      children: Object.values(roadSupportRoutes),
    },
    {
      name: "Tag Express",
      icon: "fa-home",
      children: Object.values(tagExpressRoutes),
    },
    {
      name: "Administración",
      icon: "fa-lock",
      children: Object.values(adminRoutes),
    },
    {
      name: "Atención al cliente",
      icon: "fa-headphones",
      children: Object.values(customerSupportRoutes),
    },
  ],
  adminAsistVial: [
    {
      name: "Asistencia vial",
      icon: "fa-medkit",
      children: [
        roadSupportRoutes.crearAsistencia,
        roadSupportRoutes.vehiculos,
        roadSupportRoutes.reportes,
        roadSupportRoutes.mantenimientos,
        roadSupportRoutes.calificaciones,
        roadSupportRoutes.typeAssistance,
      ],
    },
  ],
  operador_asistencia_vial: [
    {
      name: "Asistencia vial",
      icon: "fa-medkit",
      children: [
        roadSupportRoutes.crearAsistencia,
        roadSupportRoutes.reportes,
        roadSupportRoutes.vehiculos,
        roadSupportRoutes.calificaciones,
      ],
    },
  ],
  adminTagexpress: [
    {
      name: "Tag Express",
      icon: "fa-home",
      children: [
        tagExpressRoutes.registroDelivery,
        tagExpressRoutes.dailySalesReport,
        tagExpressRoutes.reporteRecargasWallet,
        tagExpressRoutes.producto,
        tagExpressRoutes.proveedores,
        tagExpressRoutes.reportedProduct,
        tagExpressRoutes.storeView,
      ],
    },
    {
      name: "Asistencia vial",
      icon: "fa-medkit",
      children: [
        roadSupportRoutes.crearAsistencia,
        roadSupportRoutes.reportes,
        roadSupportRoutes.vehiculos,
        roadSupportRoutes.mantenimientos,
        roadSupportRoutes.calificaciones,
        roadSupportRoutes.typeAssistance,
        roadSupportRoutes.tollVehiclesCategory,
      ],
    },
  ],
  opTagexpress: [
    {
      name: "Tag Express",
      icon: "fa-home",
      children: [
        tagExpressRoutes.dailySales,
        tagExpressRoutes.registroDelivery,
        tagExpressRoutes.deliveryReportCashier,
      ],
    },
    {
      name: "Atención al cliente",
      icon: "fa-headphones",
      children: [customerSupportRoutes.desbloqueUsuario],
    },
  ],
  contabilidad_tagexpress: [
    {
      name: "Tag Express",
      icon: "fa-home",
      children: [
        tagExpressRoutes.compensacion,
        tagExpressRoutes.contableCompensacion,
      ],
    },
  ],
  adminWallet: [
    {
      name: "Billetera",
      icon: "fa-credit-card",
      children: [],
    },
  ],
  cau: [
    {
      name: "Asistencia vial",
      icon: "fa-medkit",
      children: [
        roadSupportRoutes.crearAsistencia,
        roadSupportRoutes.reportes,
        roadSupportRoutes.vehiculos,
        roadSupportRoutes.mantenimientos,
        roadSupportRoutes.calificaciones,
        roadSupportRoutes.typeAssistance,
        roadSupportRoutes.tollVehiclesCategory,
      ],
    },
  ],
  asistencia_cliente_tagexpress: [
    {
      name: "Atención al cliente",
      icon: "fa-headphones",
      children: [
        customerSupportRoutes.desbloqueUsuario,
        customerSupportRoutes.homologacion,
        customerSupportRoutes.estadoVia,
        customerSupportRoutes.verificarUsuario,
      ],
    },
  ],
  cauEstadoVia: [
    {
      name: "Atención al cliente",
      icon: "fa-headphones",
      children: [customerSupportRoutes.estadoVia],
    },
  ],
  asistencia_unlock: [
    {
      name: "Atención al cliente",
      icon: "fa-headphones",
      children: [
        customerSupportRoutes.desbloqueUsuario,
        customerSupportRoutes.verificarUsuario,
      ],
    },
  ],
};
