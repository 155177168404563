import { Button, Form, FormGroup, Label } from "reactstrap";
import {
  headers,
  notifyError,
  notifyOk,
} from "../../../../../utils/utils";
import { useForm } from "react-hook-form";
import { Fragment, useState } from "react";
import { useCentralContext } from "../../../../../centralContext";
import ROLES from "../../../../../utils/roles";
import { api } from "../../../../../network";

function NewAssistanceForm({ onClose, reload, vehicleCategories, peajes }) {
  const centralContext = useCentralContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [state, setState] = useState({
    isLoading: false,
    mainError: false,
  });

  const onSubmit = async (data, e) => {
    setState((old) => ({ ...old, isLoading: true }));
    if (!/[A-Z]{3}[-]\d{3}/g.test(data.placa)) {
      setState((old) => ({ ...old, isLoading: false }));
      return notifyError(
        "La placa debe tener el siguiente formato, por ejemplo PKG-7891"
      );
    }

    try {
      await api.post("/v1/asistVial/vehiculos/create", data, {
        headers: headers(),
      });

      notifyOk("Vehículo creado");
      onClose();
      reload();
    } catch (e) {
      notifyError(
        e.response?.data?.message ?? "Ocurrió un error al guardar los datos."
      );
    }

    setState((old) => ({ ...old, isLoading: false }));
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup>
        <Label>Categoría: (*)</Label>
        <select
          {...register("categoria", {
            required: {
              value: true,
              message: "La categoría es requerida",
            },
          })}
          defaultValue={""}
          className="form-control"
        >
          <option value="">Seleccione una categoría...</option>
          {vehicleCategories.length > 0 ? (
            vehicleCategories.map((vehicleCategoryType, index) => {
              return (
                <Fragment key={index}>
                  {vehicleCategoryType.estado === "A" ? (
                    <option
                      value={vehicleCategoryType.categoria_vehiculo_peaje_id}
                    >
                      {vehicleCategoryType.categoria_vehiculo_peaje +
                        " / " +
                        vehicleCategoryType.sub_categoria_vehiculo}
                    </option>
                  ) : null}
                </Fragment>
              );
            })
          ) : (
            <option value={0}>No hay categorías disponibles</option>
          )}
        </select>
        <small className="form-text text-danger">
          {errors.categoria && errors.categoria.message}
        </small>
      </FormGroup>
      <FormGroup>
        <Label>Código vehículo: (*)</Label>
        <input
          className="form-control"
          type="text"
          placeholder="Ingrese el código vehículo"
          {...register("codigo_vehiculo", {
            required: {
              value: true,
              message: "El código vehículo es requerido",
            },
          })}
        />
        <small className="form-text text-danger">
          {errors.codigo_vehiculo && errors.codigo_vehiculo.message}
        </small>
      </FormGroup>
      <FormGroup>
        <Label>Placa: (*)</Label>
        <input
          className="form-control"
          type="text"
          maxLength={8}
          placeholder="Ingrese la placa"
          {...register("placa", {
            required: {
              value: true,
              message: "La placa es requerido",
            },
          })}
        />
        <small className="form-text text-danger">
          {errors.placa && errors.placa.message}
        </small>
      </FormGroup>
      <FormGroup>
        <Label>Kilometraje: (*)</Label>
        <input
          className="form-control"
          type="number"
          maxLength={8}
          placeholder="Ingrese el kilometraje"
          {...register("kilometraje", {
            required: {
              value: true,
              message: "El kilometraje es requerido",
            },
          })}
        />
        <small className="form-text text-danger">
          {errors.kilometraje && errors.kilometraje.message}
        </small>
      </FormGroup>
      <FormGroup>
        {centralContext.role === ROLES.usuariot1000 ? (
          <>
            <Label>Peaje: (*)</Label>
            <select
              {...register("peaje_id", {
                required: {
                  value: true,
                  message: "El peaje es requerida",
                },
              })}
              defaultValue={""}
              className="form-control"
            >
              <option value="">Seleccione un peaje</option>
              {peajes.map((p, index) => (
                <option value={p.peaje_id} key={p.peaje_id}>
                  {p.descripcion}
                </option>
              ))}
            </select>
            <small className="form-text text-danger">
              {errors.peaje_id && errors.peaje_id.message}
            </small>
          </>
        ) : null}
      </FormGroup>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {state.isLoading ? null : (
          <Button className="btn-tagexpress">Guardar</Button>
        )}
      </div>
    </Form>
  );
}
export default NewAssistanceForm;
