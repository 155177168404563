import { Badge } from "reactstrap";

export const formatTimeInMinute = (tiempo_asignado) => {
  if (tiempo_asignado !== "00:00") {
    let tiempo = tiempo_asignado.split(":");
    return parseInt(tiempo[0]) * 60 + parseInt(tiempo[1]);
  } else {
    return 0;
  }
};

export const formatStatusString = (asistData) => {
  if (asistData.fecha_registro === null) {
    return "sin_atencion";
  } else if (asistData.fecha_cancelacion !== null) {
    return "cancelado";
  } else if (asistData.fecha_solucion !== null) {
    return "finalizado";
  } else {
    return "en_proceso";
  }
};
export const formatEstado = (asistData) => {
  switch (formatStatusString(asistData)) {
    case "sin_atencion":
      return <Badge color="warning">Sin atención</Badge>;
    case "cancelado":
      return <Badge color="danger">Cancelado</Badge>;
    case "finalizado":
      return <Badge color="primary">Finalizado</Badge>;
    default:
      return <Badge color="success">En proceso</Badge>;
  }
};
