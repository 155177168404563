import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Col, Form, Row } from "reactstrap";
import { s_postCompensationsEnte } from "../../../../../services/compensacionCtl";
import { notifyError, notifyOk } from "../../../../../utils/utils";

export const NewCompensation = ({ onSuccess }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [state, setState] = useState({
    isLoading: false,
    mainError: false,
  });

  const onSubmit = async (data, e) => {
    try {
      setState((old) => ({ ...old, isLoading: true }));
      const resp = await s_postCompensationsEnte(data);
      notifyOk(resp, { autoClose: 3000 });
      setState((old) => ({ ...old, isLoading: false }));
      onSuccess();
    } catch (error) {
      setState((old) => ({ ...old, isLoading: false }));
      if (error.response.status === 409) {
        notifyError(error.response.data.message);
        return;
      }
      notifyError(
        "Ocurrió un error al agregar producto, por favor inténtelo más tarde."
      );
    }
  };
  return (
    <div style={{ paddingBottom: '1rem' }}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Col>
          <div className="form-group mb-3">
            <label>Identificación del usuario: (*)</label>
            <input
              type="text"
              className="form-control"
              placeholder="Escriba la identificación del usuario"
              {...register("user_id", {
                required: {
                  value: true,
                  message: "La identificación del usuario es requerido",
                },
              })}
            />
            <small className="form-text text-danger">
              {errors.user_id && errors.user_id.message}
            </small>
          </div>
        </Col>
        <Col>
          <div className="form-group mb-3">
            <label>Código de transacción: (*)</label>
            <input
              type="text"
              className="form-control"
              placeholder="Escriba un código de transacción"
              {...register("transaction_code", {
                required: {
                  value: true,
                  message: "El código de transacción es requerido",
                },
              })}
            />
            <small className="form-text text-danger">
              {errors.transaction_code && errors.transaction_code.message}
            </small>
          </div>
        </Col>
        <Row>
          <Col>
            <div className="form-group mb-3">
              <label>Fecha: (*)</label>
              <input
                type="date"
                className="form-control"
                {...register("date_created", {
                  required: {
                    value: true,
                    message: "La fecha es requerido",
                  },
                })}
              />
              <small className="form-text text-danger">
                {errors.date_created && errors.date_created.message}
              </small>
            </div>
          </Col>
          <Col>
            <div className="form-group mb-3">
              <label>Fecha compensación: (*)</label>
              <input
                type="date"
                className="form-control"
                {...register("date_compensation", {
                  required: {
                    value: true,
                    message: "La fecha compensación es requerido",
                  },
                })}
              />
              <small className="form-text text-danger">
                {errors.date_compensation && errors.date_compensation.message}
              </small>
            </div>
          </Col>
        </Row>
        <Col>
          <div className="form-group mb-3">
            <label>Dominio: (*)</label>
            <input
              type="text"
              className="form-control"
              placeholder="Escriba un dominio"
              {...register("domain", {
                required: {
                  value: true,
                  message: "El domino es requerido",
                },
              })}
            />
            <small className="form-text text-danger">
              {errors.domain && errors.domain.message}
            </small>
          </div>
        </Col>
        <Col>
          <div className="form-group mb-3">
            <label>Ente Externo: (*)</label>
            <select
              className="form-control"
              {...register("external_entity", {
                required: {
                  value: true,
                  message: "El Ente Externo es requerido",
                },
              })}
              defaultValue={""}
            >
              <option value="">Seleccione una ente externo</option>
              <option value="WESTUNIO">Western Union</option>
            </select>
            <small className="form-text text-danger">
              {errors.external_entity && errors.external_entity.message}
            </small>
          </div>
        </Col>
        <Col>
          <div className="form-group mb-3">
            <label>Información adicional:</label>
            <input
              type="text"
              className="form-control"
              placeholder="Escriba una información adicional"
              {...register("additional_information")}
            />
          </div>
        </Col>
        <Col>
          <div className="form-group mb-3">
            <label>Monto: (*)</label>
            <input
              type="text"
              className="form-control"
              placeholder="Escriba un monto"
              {...register("amount", {
                required: {
                  value: true,
                  message: "El monto es requerido",
                },
              })}
            />
            <small className="form-text text-danger">
              {errors.amount && errors.amount.message}
            </small>
          </div>
        </Col>
        <div style={{ display: "flex", justifyContent: "end" }}>
          {state.isLoading ? null : <Button type="submit">Guardar</Button>}
        </div>
      </Form>
    </div>
  );
};
