import React from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import error500 from '../../../assets/500_server_error.svg'
import './error.css';

const errorData = {
  serverError: {
    code: '500',
    msg: 'Hubo un error en el servidor. Reinténte la operación y si vuelve a fallar, por favor, contacte a soporte.'
  },
  notFound: {
    code: '404',
    msg: 'Esta página no está disponible'
  }
}

const ErrorPage = ({ type }) => {
  if (!Object.keys(errorData).includes(type)) {
    throw new Error(`Unrecognized ErrorPage type: ${type}`);
  }

  return (
    <div className='error-view'>
      <Row>
        <Col>
          <img src={error500} alt='server error' />
        </Col>
        <Col className='mt-5'>
          <div className="m-t error-white-section text-center">
            <h1 className="error-number">{errorData[type].code}</h1>
            <h3 className="m-b">{errorData[type].msg}</h3>
            <NavLink className='home-link' to='/login'><strong><h5>Regresar a terreno conocido</h5></strong></NavLink>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ErrorPage;