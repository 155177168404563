import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Badge,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap";
import { s_getCategory } from "../../../../services/categoryTollVehicles";
import TheLoader from "../../../elements/theLoader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./tollVehiclesCategory.css";
import { notifyError } from "../../../../utils/utils";
import { PlusSquare, Edit } from "react-feather";
import ReactTooltip from "react-tooltip";
import CreateCategory from "./components/createCategory";
import UpdateCategory from "./components/updateCategory";

const TollVehiclesCategory = () => {
  const [categories, setCategories] = useState([]);
  const [categoryToUpdate, setCategoryToUpdate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const _getVehiculos = useCallback(async () => {
    const res = await s_getCategory();
    setIsLoading(false);
    if (!res) {
      notifyError("Error al mostrar los vehículos, por favor intente de nuevo");
      return;
    }
    setCategories(res);
  }, []);

  // show or hide create category Modal
  const [createCategorymodal, setCreatecategorymodal] = useState(false);
  const toggle = useCallback(() => {
    setCreatecategorymodal(!createCategorymodal);
  }, [createCategorymodal]);

  // show or hide update category Modal
  const [updateCategorymodal, setUpdatecategorymodal] = useState(false);
  const toggleUpdate = useCallback(() => {
    setUpdatecategorymodal(!updateCategorymodal);
  }, [updateCategorymodal]);

  useEffect(() => {
    _getVehiculos();
  }, [_getVehiculos]);

  return (
    <div style={{ maxHeight: "50%" }}>
      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggle}
        isOpen={createCategorymodal}
      >
        <OffcanvasHeader toggle={toggle}>
          Crear categoría de vehículos
        </OffcanvasHeader>
        <OffcanvasBody>
          <CreateCategory
            closeModal={setCreatecategorymodal}
            reload={_getVehiculos}
          />
        </OffcanvasBody>
      </Offcanvas>
      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggleUpdate}
        isOpen={updateCategorymodal}
      >
        <OffcanvasHeader toggle={toggleUpdate}>
          Editar categoría de vehículos
        </OffcanvasHeader>
        <OffcanvasBody>
          {categoryToUpdate !== null && (
            <UpdateCategory
              closeModal={setUpdatecategorymodal}
              categoryToUpdate={categoryToUpdate}
              reload={_getVehiculos}
            />
          )}
        </OffcanvasBody>
      </Offcanvas>

      {isLoading ? <TheLoader /> : null}

      <ReactTooltip type="light" />
      <ToastContainer style={{ width: "400px" }} />
      <Row>
        <Col md={11}>
          <h3>Administración categoría de vehículos peajes</h3>
        </Col>
        <Col>
          <Button
            onClick={() => setCreatecategorymodal(true)}
            className="btn-tagexpress"
            data-tip="Crear nuevo vehículo"
          >
            <PlusSquare />
          </Button>
        </Col>
      </Row>
      <Card className="mt-2">
        <CardBody style={{ cursor: "default" }}>
          {categories.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th className="header-item">Categoría</th>
                  <th className="header-item">Sub-categoría</th>
                  <th className="header-item">Fecha de creación</th>
                  <th className="header-item">Estado</th>
                  <th className="header-item">Acciones</th>
                </tr>
              </thead>
              {categories.map((veh) => {
                return (
                  <tbody key={veh.categoria_vehiculo_peaje_id}>
                    <tr>
                      <td className="body-item">
                        {veh.categoria_vehiculo_peaje}
                      </td>
                      <td className="body-item">
                        {veh.sub_categoria_vehiculo
                          ? veh.sub_categoria_vehiculo
                          : "N/A"}
                      </td>
                      <td className="body-item">{veh.fecha_creacion}</td>
                      <td className="body-item">
                        {veh.estado === "A" ? (
                          <Badge color="success">Activo</Badge>
                        ) : (
                          <Badge color="warning">Inactivo</Badge>
                        )}
                      </td>
                      <td className="body-item" style={{ cursor: "pointer" }}>
                        <Row>
                          <Col>
                            <Edit
                              onClick={() => {
                                setUpdatecategorymodal(true);
                                setCategoryToUpdate(veh);
                              }}
                              data-tip="Editar categoría"
                            />
                          </Col>
                        </Row>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </Table>
          ) : (
            <h3 className="text-center">No hay vehículos registrados</h3>
          )}
          {/* <Row className="mt-2">
            <Col>
              <div className="overflowed">
              </div>
            </Col>
          </Row> */}
        </CardBody>
      </Card>
    </div>
  );
};

export default TollVehiclesCategory;
