import AsistenciaVial from "../../views/pages/asistenciaVial";
import MantenimientosView from "../../views/pages/mantenimientos";
import ReporteAsist from "../../views/pages/asistenciaVial/reporteAsist";
import VehiculosAsistView from "../../views/pages/asistenciaVial/vehiculosAsist";
import TollVehiclesCategory from "../../views/pages/asistenciaVial/tollVehiclesCategory/tollVehiclesCategory";
import Qualification from "../../views/pages/asistenciaVial/qualification/qualification";
import TypeAssistance from "../../views/pages/asistenciaVial/typeAssistance/typeAssistance";

export const roadSupportRoutes = {
  crearAsistencia: {
    name: "Asistencias",
    icon: "fa-exclamation-triangle",
    url: "/asistencia",
    route: {
      name: "Asistencias",
      path: "/asistencia",
      component: AsistenciaVial,
      exact: true,
      sensitive: true,
    },
  },
  vehiculos: {
    name: "Vehículos",
    icon: "fa-ambulance",
    url: "/asistencia/vehiculos",
    route: {
      name: "Vehículos",
      path: "/asistencia/vehiculos",
      component: VehiculosAsistView,
    },
  },
  reportes: {
    name: "Reporte",
    icon: "fa-book",
    url: "/asistencia/reporte",
    route: {
      name: "Reporte",
      path: "/asistencia/reporte",
      component: ReporteAsist,
      exact: true,
      sensitive: true,
    },
  },
  mantenimientos: {
    name: "Mantenimientos",
    icon: "fa-wrench",
    url: "/asistencia/mantenimientos",
    route: {
      name: "Mantenimientos",
      path: "/asistencia/mantenimientos",
      component: MantenimientosView,
      exact: true,
      sensitive: true,
    },
  },
  calificaciones: {
    name: "Calificaciones",
    icon: "fa-star",
    url: "/asistencia/calificaciones",
    route: {
      name: "Calificaciones",
      path: "/asistencia/calificaciones",
      component: Qualification,
      exact: true,
      sensitive: true,
    },
  },
  typeAssistance: {
    name: "Tipos de asistencia",
    icon: "fa-file",
    url: "/asistencia/tipo-asistencia",
    route: {
      name: "Tipos de asistencia",
      path: "/asistencia/tipo-asistencia",
      component: TypeAssistance,
      exact: true,
      sensitive: true,
    },
  },
  tollVehiclesCategory: {
    name: "Categoría de vehículos",
    icon: "fa fa-car",
    url: "/tagexpress/administrar/vehiculos",
    route: {
      name: "Categoría de vehículos",
      path: "/tagexpress/administrar/vehiculos",
      component: TollVehiclesCategory,
    },
  },
};