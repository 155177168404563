import PeajesView from "../../views/pages/admin/peajes";
import PersonaView from "../../views/pages/admin/personas";
import RoleView from "../../views/pages/admin/roles";
import UserView from "../../views/pages/admin/users";
import Store from "../../views/pages/admin/store/store";
import { Categoria } from "../../views/pages/admin/categorias";

export const adminRoutes = {
  users: {
    name: "Usuarios",
    icon: "fa-users",
    url: "/usuarios",
    route: {
      name: "Usuarios",
      path: "/usuarios",
      component: UserView,
      exact: true,
      sensitive: true,
    },
  },
  roles: {
    name: "Roles",
    icon: "fa-address-book-o",
    url: "/roles",
    route: {
      name: "Roles",
      path: "/roles",
      component: RoleView,
      exact: true,
      sensitive: true,
    },
  },
  people: {
    name: "Personas",
    icon: "fa-male",
    url: "/personas",
    route: {
      name: "Personas",
      path: "/personas",
      component: PersonaView,
      exact: true,
      sensitive: true,
    },
  },
  tolls: {
    name: "Peajes",
    icon: "fa-map-signs",
    url: "/peajes",
    route: {
      name: "Peajes",
      path: "/peajes",
      component: PeajesView,
      exact: true,
      sensitive: true,
    },
  },
  sellPoints: {
    name: "Puntos de venta",
    icon: "fa-building",
    url: "/store",
    route: {
      name: "Puntos de venta",
      path: "/store",
      component: Store,
      exact: true,
      sensitive: true,
    },
  },
  categories: {
    name: "Categorías",
    icon: "fa-tag",
    url: "/categorias",
    route: {
      name: "Categorías",
      path: "/categorias",
      component: Categoria,
      exact: true,
      sensitive: true,
    },
  }
}