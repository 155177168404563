import React, { useState, useEffect, useCallback } from "react";
import { Card, CardBody, Table, Offcanvas, OffcanvasBody, OffcanvasHeader, Form, Button } from "reactstrap";
import { Search } from "react-feather";
import { s_getReportRegisterDeliveryCashier } from "../../../../services/registerDeliveryCtl";
import { useCentralContext } from "../../../../centralContext";
import { ToastContainer } from "react-toastify";
import { notifyInfo, notifyOk, notifyError } from "../../../../utils/utils";
import { useForm } from "react-hook-form";


const DeliveryReportCashier = () => {
  const context = useCentralContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [data, setData] = useState({
    users: [],
    reportTable: [],
  });
  const [isLoading, seIsLoading] = useState(false);
  const [filterSidebar, setFilterSidebar] = useState(false);
  const toggleSearchSidebar = () => {
    setFilterSidebar(!filterSidebar);
  };

  const getReport = useCallback(
    async () => {
      const data = await s_getReportRegisterDeliveryCashier(context.user);
      if (data.length === 0) {
        notifyInfo("No tiene registros de entregas");
        return;
      }
      console.log('data en la vista ', data);
      setData((old) => ({
        ...old,
        reportTable: data,
      }));
    }, [context.user]);

  const onSubmit = async (data) => {
    data.user = context.user;
    seIsLoading(true)
    const res = await s_getReportRegisterDeliveryCashier(data);
    console.log(res);
    if (!res) {
      notifyError('Error al traer los datos, por favor intente nuevamente');
      seIsLoading(false);
      setFilterSidebar(false);
      return;
    }
    setData((old) => ({
      ...old,
      reportTable: res,
    }));
    notifyOk('Filtrado realizado de manera exitosa');
    seIsLoading(false);
    setFilterSidebar(false);
    return
  }

  useEffect(() => {
    getReport();
  }, [getReport]);

  return (
    <div>
      <ToastContainer style={{ width: "400px" }} />
      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggleSearchSidebar}
        isOpen={filterSidebar}
      >
        <OffcanvasHeader toggle={toggleSearchSidebar}>
          Buscar Registros
        </OffcanvasHeader>
        <OffcanvasBody>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group mb-3">
              <label>Desde</label>
              <div className="form-group mb-3">
                <input
                  type="date"
                  className="form-control"
                  {...register("initDate", {
                    required: { value: true, message: "La fecha de inicio es requerida" },
                  })}
                />
              </div>
              <small className="form-text text-danger">
                {errors.initDate && errors.initDate.message}
              </small>
            </div>
            <div className="form-group mb-3">
              <label>Hasta</label>
              <div className="form-group mb-3">
                <input
                  type="date"
                  className="form-control"
                  {...register("finalDate", {
                    required: { value: true, message: "La fecha final es requerido" },
                  })}
                />
              </div>
              <small className="form-text text-danger">
                {errors.finalDate && errors.finalDate.message}
              </small>
            </div>
            {isLoading ? null : (
              <Button type="submit" className="btn-tagexpress">
                Buscar
              </Button>
            )}
          </Form>
        </OffcanvasBody>
      </Offcanvas>
      <h1 className="text-center">Detalle de entregas</h1>
      <Button
        onClick={() => toggleSearchSidebar()}
        data-tip="Buscar entregas"
        color="warning"
      >
        <Search />
      </Button>
      {data.reportTable.length > 0 ? (
        <Card className="mt-3">
          <CardBody>
            <Table>
              <thead style={{ backgroundColor: "#e8b800" }}>
                <tr>
                  <th style={{ textAlign: "center" }}>Usuario registro</th>
                  <th style={{ textAlign: "center" }}>Usuario</th>
                  <th style={{ textAlign: "center" }}>Cantidad entregada</th>
                  <th style={{ textAlign: "center" }}>Fecha</th>
                </tr>
              </thead>
              <tbody>
                {data.reportTable.map((data, index) => (
                  <tr key={index}>
                    <th scope="row" style={{ textAlign: "center" }}>
                      {data.usuario_registro}
                    </th>
                    <th scope="row" style={{ textAlign: "center" }}>
                      {data.usuario_destino}
                    </th>
                    <td style={{ textAlign: "center" }}>{data.cantidad}</td>
                    <td style={{ textAlign: "center" }}>{data.created}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      ) : (
        <h1>No tiene registros al momento</h1>
      )}
    </div>
  );
};

export default DeliveryReportCashier;
