import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import TheLoader from "../../../../elements/theLoader";
import "react-toastify/dist/ReactToastify.css";
import { notifyError, notifyOk } from "../../../../../utils/utils";
import { Form, Button } from "reactstrap";
import { s_createStore } from "../../../../../services/store.Ctl";
import { s_getProduct } from "../../../../../controllers/productCtl";
import '../../../../../css/Store.css';


const AddStore = ({ setShowModalCreate, getStores }) => {
    const [products, setProducts] = useState([]);
    const [loader, setloadeder] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
        control
    } = useForm();

    const _onSubmit = async (data) => {
        setloadeder(true)
        const res = await s_createStore(data);
        if (!res) {
            setShowModalCreate(false);
            return notifyError('Error en el servidor, por favor intente nuevamente');
        }
        setloadeder(false);
        setShowModalCreate(false);
        getStores();
        return notifyOk('Punto de venta creado de manera satisfactoria');
    }

    const getProducts = async () => {
        const res = await s_getProduct();
        if (!res) {
            return notifyError('Error al traer el listado de productos, por favor intente nuevamente');
        }
        setProducts(res);
    }

    useEffect(() => {
        getProducts();
    }, []);



    return (
        <div>
            {loader ? <TheLoader /> : null}
            <Form onSubmit={handleSubmit(_onSubmit)}>
                <div className="form-group mb-3">
                    <label>Nombre del punto de venta</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Nombre del punto de venta"
                        {...register("name", {
                            required: { value: true, message: "El nombre del punto de venta es obligatorio" },
                        })}
                    />
                    <small className="form-text text-danger">
                        {errors.name && errors.name.message}
                    </small>
                </div>
                <div className="form-group mb-3">
                    <label>Producto</label>
                    <Controller
                        control={control}
                        name="product"
                        rules={{
                            required: {
                                value: true,
                                message: "El producto es requerido",
                            },
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <select
                                className="form-control"
                                onChange={(e) => {
                                    onChange(e);
                                }}
                                onBlur={onBlur}
                                selected={value}
                            >
                                <option defaultValue={null}>Seleccione un tipo</option>
                                {products.map(product => {
                                    return (
                                        <option key={product.id_producto} defaultValue={product.id_producto} value={product.id_producto}>{product.nombre_producto}</option>
                                    )
                                })}
                            </select>
                        )}
                    />

                    <small className="form-text text-danger">
                        {errors.product && errors.product.message}
                    </small>
                </div>
                <Button className="succes-btn-store store-btn">Guardar</Button>
            </Form>
        </div>
    )
}

export default AddStore;