import React from "react";
import { Form, FormGroup, Label, Input, Row, Col, Button } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import "./mantenimientos.css";
import { notifyError, validators } from "../../../utils/utils";
import { ToastContainer } from "react-toastify";

const ModalDetalleMantenimiento = ({
  onClick,
  isUpdate,
  vehicles,
  vehicleForMaintenance,
  onVehicleChange,
  descripcion,
  kilometraje,
  conclusion,
  taller_encargado,
  mecanico_encargado,
  telefono_mecanico,
  costo,
  fecha_inicio_planeada,
  fecha_fin_planeada,
  fecha_inicio_real,
  fecha_fin_real,
  setDescripcion,
  setKilometraje,
  setTaller_encargado,
  setMecanico_encargado,
  setTelefono_mecanico,
  setCosto,
  setFecha_inicio_planeada,
  setFecha_fin_planeada,
  setFecha_inicio_real,
  setFecha_fin_real,
  setConclusion,
}) => {
  return (
    <div>
      <ToastContainer />
      <Form>
        <Row>
          <FormGroup>
            <div className="form-group mb-3">
              <label>Vehículo:</label>
              <select
                defaultValue={vehicleForMaintenance}
                className="form-control"
                onChange={(e) => {
                  onVehicleChange(e.target.value)
                }}
                disabled={isUpdate}
              >
                <option value="">Seleccione un vehículo</option>
                {vehicles.map((vehicle) => (
                  <option
                    value={
                      vehicle.vehiculo_asistencium.vehiculo_asistencia_id
                    }
                    key={vehicle.vehiculo_asistencium.vehiculo_asistencia_id}
                  >
                    {`${vehicle.categoria_vehiculos_peaje.categoria_vehiculo_peaje} / ${vehicle.vehiculo_asistencium.placa}`}
                  </option>
                ))}
              </select>
            </div>
          </FormGroup>
        </Row>
        <Row>
          <Col md="6">
            <FormGroup>
              <Label>Fecha inicio planeada</Label>
              <Input
                type="date"
                value={fecha_inicio_planeada}
                maxLength={10}
                onChange={(e) => {
                  if (new Date(e.target.value) > new Date(fecha_fin_planeada)) {
                    notifyError("Fecha inicio no puede ser mayor que fecha final");
                    return;
                  }
                  setFecha_inicio_planeada(e.target.value);
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label>Fecha inicio real</Label>
              <Input
                type="date"
                value={fecha_inicio_real}
                maxLength={10}
                onChange={(e) => {
                  if (new Date(e.target.value) > new Date(fecha_fin_real)) {
                    notifyError("Fecha inicio no puede ser mayor que fecha final");
                    return;
                  }
                  setFecha_inicio_real(e.target.value);
                }}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label>Fecha fin planeada</Label>
              <Input
                type="date"
                value={fecha_fin_planeada}
                maxLength={10}
                onChange={(e) => {
                  if (
                    new Date(e.target.value) < new Date(fecha_inicio_planeada)
                  ) {
                    notifyError("Fecha final no puede ser menor que fecha inicio");
                    return;
                  }
                  setFecha_fin_planeada(e.target.value);
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label>Fecha fin real</Label>
              <Input
                type="date"
                value={fecha_fin_real}
                maxLength={10}
                onChange={(e) => {
                  if (new Date(e.target.value) < new Date(fecha_inicio_real)) {
                    notifyError("Fecha final no puede ser menor que fecha inicio");
                    return;
                  }
                  setFecha_fin_real(e.target.value);
                }}
              />
            </FormGroup>
          </Col>
          <FormGroup>
            <Label>Kilometraje</Label>
            <Input
              type="number"
              value={kilometraje}
              rows={4}
              maxLength={500}
              onChange={(e) => {
                setKilometraje(e.target.value);
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label>Descripción</Label>
            <Input
              type="textarea"
              value={descripcion}
              rows={4}
              maxLength={500}
              onChange={(e) => {
                setDescripcion(e.target.value);
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label>Taller</Label>
            <Input
              type="text"
              value={taller_encargado}
              maxLength={50}
              onChange={(e) => {
                setTaller_encargado(e.target.value);
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label>Mecánico</Label>
            <Input
              type="text"
              value={mecanico_encargado}
              maxLength={50}
              onChange={(e) => {
                setMecanico_encargado(e.target.value);
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label>Teléfono contacto</Label>
            <Input
              type="text"
              value={telefono_mecanico}
              maxLength={10}
              onChange={(e) => {
                if (validators.onlyNumber(e.target.value)) {
                  setTelefono_mecanico(e.target.value);
                }
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label>Costo del mantenimiento</Label>
            <Input
              type="text"
              value={costo}
              maxLength={7}
              onChange={(e) => {
                if (validators.onlyDollars(e.target.value)) {
                  setCosto(e.target.value);
                }
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label>Conclusión</Label>
            <Input
              type="textarea"
              value={conclusion}
              rows={4}
              maxLength={500}
              onChange={(e) => {
                setConclusion(e.target.value);
              }}
            />
          </FormGroup>
        </Row>
        <Button className="btn-tagexpress" onClick={onClick}>
          Guardar
        </Button>
      </Form>
    </div>
  );
};

export default ModalDetalleMantenimiento;
