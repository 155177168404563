import React, { useState } from "react";
import { Form, Button } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { ToastContainer } from "react-toastify";
import { notifyError, notifyOk } from "../../../../../utils/utils";
import { s_checkIfUserHasWallet, s_setDailySaleWalletTransaction, s_setWalletTransaction } from "../../../../../services/dailySalesCtl";

export const AddBalanceWalletForm = ({ setShowModalAddBalanceWallet, reload, store }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm();

    const [state, setState] = useState({
        isLoading: false,
        mainError: false,
        showInput: false
    });
    const _onSubmit = async (data) => {
        data.store = store.punto_de_venta_id;
        setState((old) => ({ ...old, isLoading: true }));
        const dataTemp = {
            ...data,
            comision: 0.46,
        };
        const resp = await s_setDailySaleWalletTransaction(dataTemp);
        if (!resp) {
            notifyError(
                "Ocurrió un error al crear la recarga, por favor inténtelo más tarde."
            );
            return;
        }
        const respWallet = await s_setWalletTransaction(dataTemp);
        if (!respWallet) {
            return notifyError(
                "Ocurrió un error al intentar agregar el saldo a la billetera del usaurio, por favor inténtelo más tarde."
            );
        }
        notifyOk(resp, { autoClose: 3000 });
        reload();
        setShowModalAddBalanceWallet(false);
        setState((old) => ({ ...old, isLoading: false }));
    };

    const _chkUserWallet = async (identificacion) => {
        const resp = await s_checkIfUserHasWallet(identificacion);
        if (!resp) {
            notifyError('Usuario no tiene billetera, por favor intente con otro número de identificación');
            return;
        }
        return;
    };

    return (
        <div>
            <ToastContainer />
            <Form onSubmit={handleSubmit(_onSubmit)}>
                <div className="form-group mb-3">
                    <label>Identificación</label>
                    <input
                        type="number"
                        className="form-control"
                        placeholder="Identificación"
                        {...register("identificacion", {
                            required: { value: true, message: "La identificación es requerida" },
                        })}
                        onBlur={(e) => _chkUserWallet(e.target.value)}
                    />
                    <small className="form-text text-danger">
                        {errors.identificacion && errors.identificacion.message}
                    </small>
                </div>
                <div className="form-group mb-3">
                    <label>Tipo de pago</label>
                    <Controller
                        control={control}
                        name="tipo_pago"
                        rules={{
                            required: {
                                value: true,
                                message: "El tipo de pago es requerido",
                            },
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <select
                                className="form-control"
                                onChange={onChange}
                                onBlur={onBlur}
                                selected={value}
                            >
                                <option defaultValue="">Seleccione un tipo de pago</option>
                                <option value="EFECTIVO">Efectivo</option>
                                <option value="TRANSFERENCIA_CALPRANDINA">Transferencia a cuenta CALPRANDINA</option>
                            </select>
                        )}
                    />
                    <small className="form-text text-danger">
                        {errors.tipo_pago && errors.tipo_pago.message}
                    </small>
                </div>
                <div className="form-group mb-3">
                    <label>Monto ($)</label>
                    <input
                        type="number"
                        className="form-control"
                        placeholder="$0.00"
                        {...register("monto")}
                        step="0.01"
                        min="5.00"
                        max="100.00"
                        onChange={() => setState({ showInput: true })}
                    />
                </div>
                {state.showInput ?
                    <div className="form-group mb-3">
                        <label>Comision ($)</label>
                        <input
                            type="text"
                            className="form-control"
                            value={'0.46'}
                            disabled
                        />
                    </div>
                    :
                    null
                }


                {state.isLoading ? null : <Button type="submit">Guardar</Button>}
            </Form>
        </div>
    );
};
