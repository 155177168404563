import { headers } from '../utils/utils'
import { api } from '../network';


const recargaCtlErrors = {
  ERROR_OBTENCION_DATOS: 'ERROR_OBTENCION_DATOS',
  //   ERROR_ACTUALIZANDO_RECARGA: 'ERROR_ACTUALIZANDO_RECARGA'
}

// Gets recargas from server
async function s_getPeajes() {
  const response = await api.get("/v1/peajes", {

    headers: headers()
  });

  if (response && response.status !== 200) {
    throw recargaCtlErrors.ERROR_OBTENCION_DATOS;
  }


  return response.data.data;
}

const s_getProvince = async () => {
  let response
  try {
    response = await api.get("/v1/provincias", {
      headers: headers()
    });
    console.log('reapuesta de provincias ', response);

    return response.data.data;
  } catch (e) {
    console.log(e);
    return response.status;
  }
}

const s_getTollVehicles = async (toll) => {
  let response
  try {
    response = await api.get(`/v1/vehiculos/asistencia/peaje?tollId=${toll}`, {
      headers: headers()
    });
    console.log('reapuesta de provincias ', response);

    return response.data.data;
  } catch (e) {
    return response.status;
  }
}




export {
  s_getPeajes,
  s_getProvince,
  s_getTollVehicles,
}