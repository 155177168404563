import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import "./confirmModal.css";

const ConfirmModal = ({
  isOpen,
  toggle,
  onClickYes,
  onClickNo,
  header = "Confirmación",
  body = "¿Está seguro?",
  yes = "Sí",
  no = "No",
}) => {
  return (
    <div>
      <Modal
        id="confirmModal"
        isOpen={isOpen}
        toggle={toggle}
        style={{ maxWidth: "30%" }}
        centered
        backdrop="static"
      >
        <ModalHeader toggle={toggle} tag="h4">
          {header}
        </ModalHeader>
        <ModalBody>{body}</ModalBody>
        <ModalFooter>
          <div style={{ display: "flex", gap: 10 }}>
            <Button className="btn-tagexpress-success" onClick={onClickYes}>
              {yes}
            </Button>
            <Button
              className="btn-tagexpress-secondary"
              onClick={onClickNo || toggle}
            >
              {no}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ConfirmModal;
