import { api } from "../network";
import { headers } from "../utils/utils";

const s_getCategory = async (categoryToUpdate) => {
  let response;
  try {
    if (categoryToUpdate) {
      response = await api.get(`/v1/vehiculos/peaje/${categoryToUpdate}`, {
        headers: headers(),
      });
      return response.data.data;
    }
    response = await api.get("/v1/vehiculos/peaje", {
      headers: headers(),
    });

    if (!response && response.status !== 200) {
      return false;
    }
    return response.data.data;
  } catch (e) {
    return e;
  }
};

const s_createCategory = async (data) => {
  try {
    const res = await api.post("/v1/vehiculo/peaje", data, {
      headers: headers(),
    });

    if (!res) {
      return false;
    }
    return true;
  } catch (e) {
    return false;
  }
};

const s_updateCategory = async (vehiculoId, data) => {
  try {
    const res = await api.put(`v1/vehiculo/peaje/${vehiculoId}`, data, {
      headers: headers(),
    });
    if (!res) {
      return false;
    }
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export { s_getCategory, s_createCategory, s_updateCategory };
