import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Badge,
  Table,
} from "reactstrap";
import { api } from "../../../../network";
import TheLoader from "../../../elements/theLoader";
import { ToastContainer } from "react-toastify";
import { notifyError, notifyOk } from "../../../../utils/utils";
import "react-toastify/dist/ReactToastify.css";
import "./personas.css";
import { Edit, PlusSquare } from "react-feather";
import ReactTooltip from "react-tooltip";

const PersonaView = ({ pageName }) => {
  const [personaToUpdate, setPersonaToUpdate] = useState({});
  const [personas, setPersonas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [nombres, setNombres] = useState("");
  const [apellidos, setApellidos] = useState("");
  const [cargo, setCargo] = useState("");
  const [cedula, setCedula] = useState("");
  const [correo, setCorreo] = useState("");
  const [telefono, setTelefono] = useState("");

  const [createSidebar, setCreateSidebar] = useState(false);
  const toggleCreateSidebar = () => {
    _clearForm();
    setCreateSidebar(!createSidebar);
  };

  const [updateSidebar, setUpdateSidebar] = useState(false);
  const toggleUpdateSidebar = () => {
    setUpdateSidebar(!updateSidebar);
  };

  const headers = useMemo(
    () => () => ({
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    }),
    []
  );

  const _getPersonas = useCallback(async () => {
    try {
      const response = await api.get("/v1/personas", {
        headers: headers(),
      });

      if (response && response.status !== 200) {
        notifyError("ERROR");
        return;
      }

      setPersonas(response.data.data);
    } catch (e) {}
  }, [headers]);

  function _clearForm() {
    setPersonaToUpdate({});
    setNombres("");
    setApellidos("");
    setCargo("");
    setCedula("");
    setCorreo("");
    setTelefono("");
  }

  async function _handleCreatePersona(e) {
    e.preventDefault();

    if (
      nombres.trim() === "" ||
      apellidos.trim() === "" ||
      cedula.trim() === "" ||
      telefono.trim() === "" ||
      cargo.trim() === "" ||
      correo.trim() === ""
    ) {
      notifyError("Llene todos los campos");
      return;
    }

    const data = {
      nombres,
      apellidos,
      cedula,
      telefono,
      cargo,
      correo,
    };
    setIsLoading(true);
    try {
      const response = await api.post("/v1/personas/create", data, {
        headers: headers(),
      });
      if (response) {
        if (response.data.status === "OK") {
          notifyOk("Persona creada", { autoClose: 2000 });
          _getPersonas();
          toggleCreateSidebar();
          // reset form
          _clearForm();
        } else {
          notifyError("Error al crear persona", { autoClose: 2000 });
        }
      } else {
        notifyError("No hay conexión con el servidor");
      }
    } catch (e) {
      if (e.response.data.data === "PERSONA_YA_EXISTE") {
        return notifyError("El número de cédula ya esta registrado");
      }
      return notifyError("Error de servidor, intente nuevamente");
    }
    setIsLoading(false);
  }

  async function _handleUpdatePersona(e) {
    e.preventDefault();

    if (
      nombres.trim() === "" ||
      apellidos.trim() === "" ||
      cedula.trim() === "" ||
      telefono.trim() === "" ||
      cargo.trim() === "" ||
      correo.trim() === ""
    ) {
      notifyError("Llene todos los campos");
      return;
    }
    const data = {
      persona_id: personaToUpdate.persona_id,
      nombres,
      apellidos,
      cedula,
      telefono,
      cargo,
      correo,
    };
    setIsLoading(true);
    try {
      const response = await api.put("/v1/personas/update", data, {
        headers: headers(),
      });
      if (response) {
        if (response.data.status === "OK") {
          notifyOk("Persona modificada", { autoClose: 2000 });
          _getPersonas();
          toggleUpdateSidebar();

          // reset form
          _clearForm();
        } else {
          notifyError("Error al modificar persona", { autoClose: 2000 });
        }
      } else {
        notifyError("No hay conexión con el servidor");
      }
    } catch (e) {}
    setIsLoading(false);
  }

  async function _handleDeletePersona(e) {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await api.delete(
        "/v1/personas/delete/" + personaToUpdate.persona_id,
        { headers: headers() }
      );
      if (response) {
        if (response.data.status === "OK") {
          if (personaToUpdate.estado === "A") {
            notifyOk("Persona deshabilitada", { autoClose: 2000 });
          } else {
            notifyOk("Persona habilitada", { autoClose: 2000 });
          }
          _getPersonas();
          toggleUpdateSidebar();
          _clearForm();
        } else {
          notifyError("Error al eliminar persona", { autoClose: 2000 });
        }
      } else {
        notifyError("No hay conexión con el servidor");
      }
    } catch (e) {}

    setIsLoading(false);
  }

  function _handleRowClick(persona) {
    setPersonaToUpdate((prevPersonaToUpdate) => {
      return {
        ...prevPersonaToUpdate,
        ...persona,
      };
    });

    setNombres(persona.nombres);
    setApellidos(persona.apellidos);
    setCorreo(persona.correo);
    setCargo(persona.cargo);
    setCedula(persona.cedula);
    setTelefono(persona.telefono);

    toggleUpdateSidebar();
  }

  function formCreate() {
    return (
      <div>
        <Form onSubmit={_handleCreatePersona}>
          <FormGroup>
            <Label>Nombres</Label>
            <Input
              type="text"
              value={nombres}
              maxLength={50}
              onChange={(e) => {
                setNombres(e.target.value);
              }}
            />

            <Label>Apellidos</Label>
            <Input
              type="text"
              value={apellidos}
              maxLength={50}
              onChange={(e) => {
                setApellidos(e.target.value);
              }}
            />

            <Label>Cédula</Label>
            <Input
              type="text"
              value={cedula}
              maxLength={10}
              onChange={(e) => {
                setCedula(e.target.value);
              }}
            />

            <Label>Cargo</Label>
            <Input
              type="text"
              value={cargo}
              maxLength={30}
              onChange={(e) => {
                setCargo(e.target.value);
              }}
            />

            <Label>Correo</Label>
            <Input
              type="email"
              value={correo}
              maxLength={50}
              onChange={(e) => {
                setCorreo(e.target.value);
              }}
            />

            <Label>Telefono</Label>
            <Input
              type="text"
              value={telefono}
              maxLength={10}
              onChange={(e) => {
                setTelefono(e.target.value);
              }}
            />
          </FormGroup>
          <Button className="btn-tagexpress" onClick={_handleCreatePersona}>
            Crear
          </Button>
        </Form>
      </div>
    );
  }

  function formUpdate() {
    return (
      <div>
        <Form onSubmit={_handleUpdatePersona}>
          <FormGroup>
            <h3>{personaToUpdate.nombres + " " + personaToUpdate.apellidos}</h3>
            <Label>Nombres</Label>
            <Input
              type="text"
              value={nombres}
              maxLength={50}
              onChange={(e) => {
                setNombres(e.target.value);
              }}
            />

            <Label>Apellidos</Label>
            <Input
              type="text"
              value={apellidos}
              maxLength={50}
              onChange={(e) => {
                setApellidos(e.target.value);
              }}
            />

            <Label>Cédula</Label>
            <Input
              type="text"
              value={cedula}
              maxLength={10}
              onChange={(e) => {
                setCedula(e.target.value);
              }}
            />

            <Label>Cargo</Label>
            <Input
              type="text"
              value={cargo}
              maxLength={30}
              onChange={(e) => {
                setCargo(e.target.value);
              }}
            />

            <Label>Correo</Label>
            <Input
              type="email"
              value={correo}
              maxLength={50}
              onChange={(e) => {
                setCorreo(e.target.value);
              }}
            />

            <Label>Telefono</Label>
            <Input
              type="text"
              value={telefono}
              maxLength={10}
              onChange={(e) => {
                setTelefono(e.target.value);
              }}
            />
          </FormGroup>
          <div
            style={{
              display: "flex",
              gap: 10,
            }}
          >
            <Button className="btn-tagexpress" onClick={_handleUpdatePersona}>
              Modificar
            </Button>
            {personaToUpdate.estado === "A" ? (
              <Button
                className="btn-tagexpress-danger"
                onClick={_handleDeletePersona}
              >
                Deshabilitar
              </Button>
            ) : (
              <Button
                className="btn-tagexpress-success"
                onClick={_handleDeletePersona}
              >
                Habilitar
              </Button>
            )}
          </div>
        </Form>
      </div>
    );
  }

  // Get data on first render
  useEffect(() => {
    _getPersonas();
  }, [_getPersonas]);

  return (
    <div>
      {isLoading ? <TheLoader /> : null}
      <ToastContainer style={{ width: "400px" }} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        className="mb-4"
      >
        <h3>{pageName}</h3>
        <div>
          <Button
            onClick={toggleCreateSidebar}
            className="btn-tagexpress"
            data-tip="Crear nueva persona"
          >
            <PlusSquare />
          </Button>
          <ReactTooltip type="light" />
        </div>
      </div>
      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggleCreateSidebar}
        isOpen={createSidebar}
      >
        <OffcanvasHeader toggle={toggleCreateSidebar}>
          Crear nuevo usuario
        </OffcanvasHeader>
        <OffcanvasBody>{formCreate()}</OffcanvasBody>
      </Offcanvas>
      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggleUpdateSidebar}
        isOpen={updateSidebar}
      >
        <OffcanvasHeader toggle={toggleUpdateSidebar}>
          Actualizar usuario
        </OffcanvasHeader>
        <OffcanvasBody>{formUpdate()}</OffcanvasBody>
      </Offcanvas>
      <Table responsive>
        <thead className="tableHeader">
          <tr>
            <td>ID</td>
            <td>Nombres</td>
            <td>Teléfono</td>
            <td>Cargo</td>
            <td>Correo</td>
            <td>Cédula</td>
            <td>Creación</td>
            <td>Estado</td>
            <td>Acciones</td>
          </tr>
        </thead>
        <tbody>
          {personas.map((persona, index) => (
            <tr key={persona.persona_id}>
              <td>{index + 1}</td>
              <td>{persona.apellidos + " " + persona.nombres}</td>
              <td>{persona.telefono || "N/A"}</td>
              <td>{persona.cargo || "N/A"}</td>
              <td>{persona.correo || "N/A"}</td>
              <td>{persona.cedula || "N/A"}</td>
              <td>{persona.fecha_creacion}</td>
              <td>
                {persona.estado === "A" ? (
                  <Badge color="success">Activo</Badge>
                ) : (
                  <Badge color="warning">Inactivo</Badge>
                )}
              </td>
              <td>
                <Edit onClick={() => _handleRowClick(persona)} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default PersonaView;
