import dayjs from "dayjs";
import React, { useState, useEffect, useCallback } from "react";
import { CheckSquare, Eye } from "react-feather";
import { useForm } from "react-hook-form";
import {
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  CardHeader,
  Card,
  CardBody,
  Col,
  Form,
  Row,
  Table,
} from "reactstrap";
import { s_getReportedProduct } from "../../../../services/reportedProduct";
import { s_getUser } from "../../../../services/userCtl";
import { headers, notifyError } from "../../../../utils/utils";
import TheLoader from "../../../elements/theLoader";
import ClearFilter from "../../../../assets/ic_filter.svg";
import { api } from "../../../../network";
import { ChangeStatus } from "./components/ChangeStatus";
export const ReportedProduct = ({ pageName }) => {
  const { register, handleSubmit, reset } = useForm();

  const [data, setData] = useState({
    reportedProduct: [],
    users: [],
    products: [],
    reload: false,
    loading: true,
  });

  const getUsers = async () => {
    try {
      setData((old) => ({
        ...old,
        loading: true,
      }));
      const resp = await s_getUser();
      setData((old) => ({
        ...old,
        users: resp,
        loading: false,
      }));
    } catch (error) {
      notifyError("ERROR");
    }
  };

  const getProduct = useCallback(async () => {
    try {
      setData((old) => ({ ...old, loading: true }));
      const response = await api.get("/v1/productos", {
        headers: headers(), // ask only for active personas
      });

      if (response && response.status !== 200) {
        notifyError("ERROR");
        return;
      }

      setData((old) => ({
        ...old,
        loading: false,
        products: response.data.data,
      }));
    } catch (error) {
      if (error.response.status >= 500) {
        notifyError("ERROR");
        return;
      }
      notifyError(error.response.data.message, { autoClose: 3000 });
    }
  }, []);

  const [showViewProduct, setShowViewProduct] = useState(false);
  const toggleViewProduct = () => setShowViewProduct(!showViewProduct);
  const [showChangeStatus, setShowChangeStatus] = useState(false);
  const toggleChangeStatus = () => setShowChangeStatus(!showChangeStatus);

  const [dataEdit, setDataEdit] = useState(null);

  useEffect(() => {
    getUsers();
    getProduct();
  }, [data.reload, getProduct]);

  const clearFilter = () => {
    reset();
    setData((old) => ({
      ...old,
      reportedProduct: [],
      users: [],
      products: [],
    }));
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      setData((old) => ({ ...old, loading: true }));
      const response = await s_getReportedProduct(data);
      setData((old) => ({
        ...old,
        loading: false,
        reportedProduct: response,
      }));
    } catch (error) {
      setData((old) => ({ ...old, loading: false }));
      if (error.response.status >= 500) {
        notifyError("ERROR");
        return;
      }
      notifyError(error.response.data.message, { autoClose: 3000 });
    }
  };

  return (
    <div>
      {dataEdit && (
        <Modal isOpen={showViewProduct} toggle={toggleViewProduct} size="lg">
          <ModalHeader toggle={toggleViewProduct}>
            Producto reportado
          </ModalHeader>
          <ModalBody>
            <img
              src={dataEdit.foto !== null ? dataEdit.foto : ""}
              alt=""
              width={770}
              height={500}
            />
          </ModalBody>
        </Modal>
      )}
      <Modal isOpen={showChangeStatus} toggle={toggleChangeStatus}>
        <ModalHeader toggle={toggleChangeStatus}>Cambiar estado</ModalHeader>
        <ModalBody>
          <ChangeStatus
            reported={dataEdit}
            setShowChangeStatus={setShowChangeStatus}
            setData={setData}
            rows={data}
          />
        </ModalBody>
      </Modal>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        className="mb-4"
      >
        <h3 style={{ margin: 0 }}>{pageName}</h3>
      </div>
      {data.loading ? (
        <TheLoader />
      ) : (
        <>
          <Card>
            <CardHeader>Filtro</CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col md={4}>
                    <div className="form-group mb-3">
                      <label>Desde:</label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Fecha de inicio"
                        {...register("start_date")}
                        defaultValue={dayjs(new Date()).format("YYYY-MM-DD")}
                        min={dayjs(
                          new Date().setDate(new Date().getDate() - 30)
                        ).format("YYYY-MM-DD")}
                      />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="form-group mb-3">
                      <label>Hasta:</label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Fecha de inicio"
                        {...register("end_date")}
                        defaultValue={dayjs(new Date()).format("YYYY-MM-DD")}
                      />
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="form-group mb-3">
                      <label>Usuario:</label>
                      <select
                        {...register("user")}
                        defaultValue={""}
                        className="form-control"
                      >
                        <option value="">Seleccione un usuario</option>
                        {data.users.map((user, index) => (
                          <option value={`${user.usuario_id}`} key={index}>
                            {user.usuario}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="form-group mb-3">
                      <label>Estado:</label>
                      <select
                        {...register("status")}
                        defaultValue={""}
                        className="form-control"
                      >
                        <option value="">Seleccione un estado</option>
                        <option value="REPORTADO">Reportado</option>
                        <option value="VALIDADO">Validado</option>
                        <option value="ELIMINADO">Eliminado</option>
                        <option value="RECHAZADO">Rechazado</option>
                      </select>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="form-group mb-3">
                      <label>Producto:</label>
                      <select
                        className="form-control"
                        placeholder="Descripción"
                        {...register("product")}
                      >
                        <option value="">Seleccione...</option>
                        {data.products.map((product) => (
                          <option
                            value={`${product.nombre_producto}`}
                            key={product.id_producto}
                          >
                            {product.nombre_producto}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      type="button"
                      color="light"
                      className="mx-2"
                      onClick={clearFilter}
                    >
                      <img
                        src={ClearFilter}
                        width="20px"
                        style={{ marginRight: 10 }}
                        alt=""
                      />
                      Limpiar filtro
                    </Button>
                    <Button color="success">Buscar</Button>
                  </div>
                </Row>
              </Form>
            </CardBody>
          </Card>
          {data.reportedProduct.length > 0 ? (
            <Card className="mt-3">
              <CardBody>
                <Table>
                  <thead style={{ backgroundColor: "#e8b800" }}>
                    <tr>
                      <th style={{ textAlign: "center" }}>Usuario</th>
                      <th style={{ textAlign: "center", width: 340 }}>
                        Descripción
                      </th>
                      <th style={{ textAlign: "center" }}>Fecha</th>
                      <th style={{ textAlign: "center" }}>Producto</th>
                      <th style={{ textAlign: "center" }}>Cantidad</th>
                      <th style={{ textAlign: "center" }}>Numeración</th>
                      <th style={{ textAlign: "center" }}>Estado</th>
                      <th style={{ textAlign: "center" }}>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.reportedProduct.map((reported) => (
                      <tr key={reported.id}>
                        <th scope="row" style={{ textAlign: "center" }}>
                          {reported.usuario.usuario}
                        </th>
                        <td>{reported.descripcion}</td>
                        <td style={{ textAlign: "center" }}>
                          {reported.created}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {reported.producto}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {reported.cantidad}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {reported.numeracion}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {reported.estado}
                        </td>
                        <td style={{ textAlign: "start" }}>
                          <Eye
                            className=" pointer"
                            style={{ marginRight: 8 }}
                            onClick={() => {
                              setShowViewProduct(true);
                              setDataEdit(reported);
                            }}
                          />
                          {reported.estado !== "VALIDADO" && (
                            <CheckSquare
                              className=" pointer"
                              style={{ marginRight: 8 }}
                              onClick={() => {
                                setShowChangeStatus(true);
                                setDataEdit(reported);
                              }}
                            />
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          ) : (
            <div className="mt-5">
              <h1 className="alert-title">No se a aplicado ningún filtro</h1>
            </div>
          )}
        </>
      )}
    </div>
  );
};
