import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form } from "reactstrap";
import { s_updateRetention } from "../../../../../services/dailySalesCtl";
import { notifyError, notifyOk } from "../../../../../utils/utils";

export const AddRetention = ({
  reload,
  setShowModalRetention,
  setShowModalDetails,
  type,
  id,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [state, setState] = useState({
    isLoading: false,
    mainError: false,
  });

  const onSubmit = async (data) => {
    try {
      const dataTemp = {
        ...data,
        id,
        type,
      };

      setState((old) => ({ ...old, isLoading: true }));
      const resp = await s_updateRetention(dataTemp);
      notifyOk(resp, { autoClose: 3000 });
      reload();
      setShowModalRetention(false);
      setShowModalDetails(false);
      setState((old) => ({ ...old, isLoading: false }));
    } catch (error) {
      setState((old) => ({ ...old, isLoading: false }));
      if (error.response.status === 409) {
        notifyError(error.response.data.message);
        return;
      }
      notifyError(
        "Ocurrió un error al agregar la retencion, por favor inténtelo más tarde."
      );
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group mb-3">
          <label>Retención ($)</label>
          <input
            type="number"
            className="form-control"
            placeholder="Retención"
            {...register("retencion", {
              required: { value: true, message: "La retención es requerido" },
            })}
            step="0.01"
            min="0.00"
            max="1000.00"
          />
          <small className="form-text text-danger">
            {errors.retencion && errors.retencion.message}
          </small>
        </div>
        {state.isLoading ? null : <Button type="submit">Guardar</Button>}
      </Form>
    </div>
  );
};
