import React, { useState } from "react";
import { Edit, Trash2, CheckSquare, Eye } from "react-feather";
import ReactTooltip from "react-tooltip";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Badge,
  OffcanvasHeader,
  OffcanvasBody,
  Offcanvas,
  Table,
} from "reactstrap";
import { api } from "../../../../../network";
import { headers, notifyError, notifyOk } from "../../../../../utils/utils";
import { AddProduct } from "./AddProduct";
import { ApproveRegisterDeliveryForm } from "./ApproveRegisterDeliveryForm";
import { EditRegisterDeliveryForm } from "./EditRegisterDeliveryForm";

export const TableRegisterDelivery = ({ data, reload, user }) => {
  const [showDelete, setShowDelete] = useState(false);
  const toggleDelete = () => setShowDelete(!showDelete);
  const [showViewFirm, setShowViewFirm] = useState(false);
  const toggleViewFirm = () => setShowViewFirm(!showViewFirm);

  const [editSidebar, setEditSidebar] = useState(false);
  const toggleEditSidebar = () => {
    setEditSidebar(!editSidebar);
  };
  const [addProductSidebar, setAddProductSidebar] = useState(false);
  const toggleAddProductSidebar = () => {
    setAddProductSidebar(!addProductSidebar);
  };
  const [approveSidebar, setApproveSidebar] = useState(false);
  const toggleApproveSidebar = () => {
    setApproveSidebar(!approveSidebar);
  };

  const [registerId, setRegisterId] = useState(null);
  const [dataEdit, setDataEdit] = useState(null);
  const [dataToUpdate, setDataToUpdate] = useState({});


  const deleteRegister = async (e) => {
    e.preventDefault();
    try {
      const resp = await api.delete(`/v1/registerDelivery/${registerId}`, {
        headers: headers(),
      });

      if (resp && resp.status !== 200) {
        notifyError("ERROR");
        return;
      }
      notifyOk(resp.data.message, { autoClose: 3000 });
      setShowDelete(false);
      reload();
    } catch (error) {
      notifyError(
        "Ocurrió un error al eliminar el registro, por favor inténtelo más tarde."
      );
    }
  };
  return (
    <div>
      <ReactTooltip type="light" />
      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggleEditSidebar}
        isOpen={editSidebar}
      >
        <OffcanvasHeader toggle={toggleEditSidebar}>
          Editar datos del Registro de entrega
        </OffcanvasHeader>
        <OffcanvasBody>
          <EditRegisterDeliveryForm
            item={dataToUpdate}
            setShowEdit={setEditSidebar}
            reload={reload}
          />
        </OffcanvasBody>
      </Offcanvas>
      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggleAddProductSidebar}
        isOpen={addProductSidebar}
      >
        <OffcanvasHeader toggle={toggleAddProductSidebar}>
          Agregar cantidad de producto
        </OffcanvasHeader>
        <OffcanvasBody>
          <AddProduct
            setShowAddProduct={setAddProductSidebar}
            reload={reload}
            id={registerId}
          />
        </OffcanvasBody>
      </Offcanvas>
      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggleApproveSidebar}
        isOpen={approveSidebar}
      >
        <OffcanvasHeader toggle={toggleApproveSidebar}>
          Validar Registro de entrega
        </OffcanvasHeader>
        <OffcanvasBody>
          <ApproveRegisterDeliveryForm
            id={registerId}
            setShowApprove={setApproveSidebar}
            reload={reload}
          />
        </OffcanvasBody>
      </Offcanvas>

      <Modal isOpen={showDelete} toggle={toggleDelete}>
        <ModalBody>
          <h4>Estas seguro de eliminar este registro</h4>
        </ModalBody>
        <ModalFooter>
          <div
            style={{
              display: "flex",
              gap: 10,
            }}
          >
            <Button
              className="btn-tagexpress-secondary"
              onClick={() => setShowDelete(false)}
            >
              Cancelar
            </Button>
            <Button className="btn-tagexpress-danger" onClick={deleteRegister}>
              Eliminar
            </Button>
          </div>
        </ModalFooter>
      </Modal>
      {dataEdit && (
        <Modal isOpen={showViewFirm} toggle={toggleViewFirm}>
          <ModalHeader toggle={toggleViewFirm}>Firma</ModalHeader>
          <ModalBody>
            <img src={dataEdit.firma !== null ? dataEdit.firma : ""} alt="" />
          </ModalBody>
        </Modal>
      )}

      <Table>
        <thead>
          <tr className="card-headers">
            <td>Usuario</td>
            <td>Punto</td>
            <td>Producto</td>
            <td>Cantidad</td>
            <td>Fecha</td>
            <td>Estado</td>
            <td>Acciones</td>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            item.estado !== "E" ? (
              <tr
                key={item.id_registro}
              >
                <td >{item.usuario_destino}</td>
                <td >{item.nombre}</td>
                <td >{item.nombre_producto}</td>
                <td >{item.cantidad}</td>
                <td >{item.created}</td>
                <td >
                  {item.estado === "A" && <Badge color="success">Aprobado</Badge>}
                  {item.estado === "P" && (
                    <Badge color="warning">Pendiente</Badge>
                  )}
                </td>
                <td >
                  {user !== item.usuario_destino && (
                    <>
                      {item.estado === "P" && (
                        <Edit
                          className=" pointer"
                          style={{ marginRight: 8 }}
                          onClick={() => {
                            toggleEditSidebar();
                            setDataToUpdate(item);
                          }}
                          data-tip="Editar datos"
                        />
                      )}
                    </>
                  )}
                  {user === item.usuario_destino && (
                    <>
                      {item.estado === "P" && (
                        <CheckSquare
                          className=" pointer"
                          style={{ marginRight: 8 }}
                          onClick={() => {
                            toggleApproveSidebar();
                            setRegisterId(item.id_registro);
                          }}
                          data-tip="Validar registro de entrega"
                        />
                      )}
                    </>
                  )}
                  {user !== item.usuario_destino && (
                    <Trash2
                      className=" pointer"
                      style={{ marginRight: 8 }}
                      onClick={() => {
                        setShowDelete(true);
                        setRegisterId(item.id_registro);
                      }}
                      data-tip="Eliminar registro de entrega"
                    />
                  )}
                  {item.firma !== null && (
                    <Eye
                      className=" pointer"
                      style={{ marginRight: 8 }}
                      onClick={() => {
                        setShowViewFirm(true);
                        setDataEdit(item.id_registro);
                      }}
                      data-tip="Ver firma"
                    />
                  )}
                </td>
              </tr>
            ) : null
          ))}
        </tbody>
      </Table>
    </div>
  );
};
