import { headers } from "../utils/utils";
import { api, apiClientes } from "../network";

const userCtlErrors = {
  ERROR_OBTENCION_DATOS: "ERROR_OBTENCION_DATOS",
  ERROR_USER_UNLOCKING: "ERROR_BLOQUEAR_USUARIO",
  ERROR_USER_DONT_EXIST: "USUARIO_NO_EXISTE",
};

async function s_getUser(userParams, filter) {
  let response;
  if (filter) {
    console.log("si ingrese a esta petivion");
    response = await api.get(`/v1/usuarios?rol=${filter.rol}`, {
      headers: headers(),
      params: userParams,
    });
    return response.data.data;
  }
  response = await api.get("/v1/usuarios", {
    headers: headers(),
    params: userParams,
  });

  console.log(response);
  if (response && response.status !== 200) {
    throw userCtlErrors.ERROR_OBTENCION_DATOS;
  }
  return response.data.data;
}

async function s_getUserStore(userParams) {
  const response = await api.get("/v1/store/usuarios", {
    headers: headers(),
    params: userParams,
  });

  console.log(response);
  if (response && response.status !== 200) {
    throw userCtlErrors.ERROR_OBTENCION_DATOS;
  }
  return response.data.data;
}

async function s_getUserPerson() {
  const response = await api.get("/v1/persona/usuarios", {
    headers: headers(),
  });
  if (response && response.status !== 200) {
    throw userCtlErrors.ERROR_OBTENCION_DATOS;
  }

  return response.data.data;
}

async function checkCashClosing() {
  const response = await api.get("/v1/dailySale/check", {
    headers: headers(),
  });
  if (response && response.status !== 200) {
    throw userCtlErrors.ERROR_OBTENCION_DATOS;
  }

  return response.data;
}

async function s_userUnlocking(data) {
  const response = await api.put(`/v1/usuarios/resetLogin`, data, {
    headers: headers(),
  });

  if (response && response.status !== 200) {
    throw userCtlErrors.ERROR_USER_UNLOCKING;
  }

  return response.data;
}

async function sVerifyUser(data) {
  const response = await apiClientes.get(`/usuario/?identificacion=${data.id}&correo=${data.email}`, {
    headers: headers(),
  });
  return response.data;
}

export {
  s_getUser,
  s_getUserStore,
  checkCashClosing,
  s_getUserPerson,
  s_userUnlocking,
  sVerifyUser
};
