import { AlertCircle } from "react-feather"
import { BaseModal } from "../base"
import { Button } from "reactstrap"

export const ConfirmModal = ({ isOpen, toggle, header, body, onYes, onNo }) => {
  return (
    <BaseModal
      isOpen={isOpen}
      toggle={toggle}
      header={header}
      body={
        <div style={{ display: "flex" }}>
          <AlertCircle style={{ marginRight: 8 }} />
          {body}
        </div>
      }
      footer={
        <>
          <Button
            size="sm"
            style={{ padding: "5px 20px" }}
            color="light"
            onClick={onNo ?? toggle}
          >
            No
          </Button>
          <Button
            size="sm"
            style={{ padding: "5px 20px" }}
            color="success"
            onClick={onYes}
          >
            Sí
          </Button>
        </>
      }
    />
  )
}