import RegisterProduct from "../../views/pages/tagexpress/inventario/producto";
import ProveedorView from "../../views/pages/tagexpress/inventario/proveedor";
import RegisterDeliveryView from "../../views/pages/tagexpress/registerDelivery";
import DailySales from "../../views/pages/tagexpress/dailySales";
import HomologacionEmpresaPage from "../../views/pages/tagexpress/homologEmpresa";
import HomologacionPersonaPage from "../../views/pages/tagexpress/homologPersona";
import ReporteRecargWalletPage from "../../views/pages/tagexpress/reporteRecargWallet";
import Compensacion from "../../views/pages/tagexpress/compensacion";
import { DailysSalesReport } from "../../views/pages/tagexpress/dailysSalesReport";
import DeliveryReportCashier from "../../views/pages/tagexpress/deliveryReportCashier";
import { ReportedProduct } from "../../views/pages/tagexpress/reportedProduct";
import ContableCompensacion from "../../views/pages/tagexpress/contableCompensacion";
import Store from "../../views/pages/admin/store/store";


export const tagExpressRoutes = {
  reporteRecargasWallet: {
    name: "Reporte de recargas Wallet",
    icon: "fa-book",
    url: "/tagexpress/recargaswallet/reporte",
    route: {
      name: "Reporte de recargas Wallet",
      path: "/tagexpress/recargaswallet/reporte",
      component: ReporteRecargWalletPage,
      exact: true,
      sensitive: true,
    },
  },
  homologPersona: {
    name: "Homologación persona",
    icon: "fa-male",
    url: "/tagexpress/homologacion/persona",
    route: {
      name: "Homologación persona",
      path: "/tagexpress/homologacion/persona",
      component: HomologacionPersonaPage,
    },
  },
  homologEmpresa: {
    name: "Homologación empresa",
    icon: "fa-industry",
    url: "/tagexpress/homologacion/empresa",
    route: {
      name: "Homologación empresa",
      path: "/tagexpress/homologacion/empresa",
      component: HomologacionEmpresaPage,
    },
  },
  compensacion: {
    name: "Compensación peajes",
    icon: "fa-ticket",
    url: "/tagexpress/compensacion",
    route: {
      name: "Compensación peajes",
      path: "/tagexpress/compensacion",
      component: Compensacion,
    },
  },
  contableCompensacion: {
    name: "Contable compensación",
    icon: "fa-ticket",
    url: "/tagexpress/contable-compensacion",
    route: {
      name: "Contable compensación",
      path: "/tagexpress/contable-compensacion",
      component: ContableCompensacion,
    },
  },
  registroDelivery: {
    name: "Entregas",
    icon: "fa fa-list-alt",
    url: "/tagexpress/register-delivery",
    route: {
      name: "Entregas",
      path: "/tagexpress/register-delivery",
      component: RegisterDeliveryView,
      exact: true,
      sensitive: true,
    },
  },
  deliveryReportCashier: {
    name: "Reporte de entregas",
    icon: "fa fa-list-alt",
    url: "/tagexpress/cashier/delivery/report",
    route: {
      name: "Reporte de entregas",
      path: "/tagexpress/cashier/delivery/report",
      component: DeliveryReportCashier,
      exact: true,
      sensitive: true,
    },
  },
  producto: {
    name: "Productos",
    icon: "fa-tag ",
    url: "/productos",
    route: {
      name: "Productos",
      path: "/productos",
      component: RegisterProduct,
      exact: true,
      sensitive: true,
    },
  },
  proveedores: {
    name: "Proveedores",
    icon: "fa-truck ",
    url: "/proveedores",
    route: {
      name: "Proveedores",
      path: "/proveedores",
      component: ProveedorView,
      exact: true,
      sensitive: true,
    },
  },
  dailySales: {
    name: "Ventas diarias",
    icon: "fa fa-money",
    url: "/tagexpress/daily-sales",
    route: {
      name: "Ventas diarias",
      path: "/tagexpress/daily-sales",
      component: DailySales,
    },
  },
  dailySalesReport: {
    name: "Reporte de ventas",
    icon: "fa fa-file-text",
    url: "/tagexpress/daily-sales-report",
    route: {
      name: "Reporte de ventas",
      path: "/tagexpress/daily-sales-report",
      component: DailysSalesReport,
    },
  },
  reportedProduct: {
    name: "Productos reportados",
    icon: "fa fa-file-text",
    url: "/tagexpress/reported-product",
    route: {
      name: "Productos reportados",
      path: "/tagexpress/reported-product",
      component: ReportedProduct,
    },
  },
  storeView: {
    name: "Puntos de venta",
    icon: "fa-building",
    url: "/tagexpress/store",
    route: {
      name: "Puntos de venta",
      path: "/tagexpress/store",
      component: Store,
    },
  },
};