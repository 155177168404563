import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Card,
  CardBody,
  CardHeader,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import { ToastContainer } from "react-toastify";

import { notifyError } from "../../../../utils/utils";
import { s_getAsistVial } from "../../../../services/asistVialCtl";
import TheLoader from "../../../elements/theLoader";
import { formatEstado } from "../utils/utils";
import { File, Home, Monitor, Smartphone, Edit } from "react-feather";
import { DetailsRequest } from "../components/DetailsRequest";
import { Preview } from "./components/preview";
import { EndRequestForm } from "../components/EndRequestForm";
import { CancelRequestForm } from "../components/CancelRequestForm";
import FilterForm from "./components/filterForm";

import "./reporteAsist.css";

const ReporteAsist = ({ pageName }) => {
  const [showFilters, setShowFilters] = useState(false);
  const [data, setData] = useState({
    assistanceVehicle: [],
    reload: false,
    loading: true,
    status: "PROCESSING",
  });
  const [selectedItem, setSelectedItem] = useState({});
  const [typeVehicle, setType] = useState("");
  const [evento, setEvento] = useState();
  const [edit, setEdit] = useState(false);
  const [endAssistance, setEndAssistance] = useState(false);
  const [cancelAssistance, setCancelAssistance] = useState(false);


  //modals
  const toggleEndAssistance = () => setEndAssistance(!endAssistance);
  const toggleCancelAssistance = () => setCancelAssistance(!cancelAssistance);



  const [details, setDetails] = useState(false);
  const toggleDetails = (eventoRow) => {
    setDetails(!details);
    setSelectedItem(eventoRow);
  };

  const [exportData, setExport] = useState(false);
  const toggleExport = () => setExport(!exportData);

  useEffect(() => {
    getAssistance();
  }, [data.reload]);

  const getAssistance = async () => {
    try {
      setData((old) => ({ ...old, status: "PROCESSING", loading: true }));
      const response = await s_getAsistVial({});
      setData((old) => ({
        ...old,
        assistanceVehicle: response.data,
        loading: false,
        status: "SUCCESS",
      }));
    } catch (error) {
      setData((old) => ({ ...old, status: "FAILED" }));
      if (error.response.status >= 500) {
        notifyError("ERROR");
        return;
      }
      notifyError(error.response.data.message, { autoClose: 3000 });
    }
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      setType(data.type);
      setData((old) => ({ ...old, status: "PROCESSING" }));
      const response = await s_getAsistVial(data);
      setData((old) => ({
        ...old,
        assistanceVehicle: response.data,
        status: "SUCCESS",
      }));
      toggleAccordion();
    } catch (error) {
      setData((old) => ({ ...old, status: "FAILED" }));
      if (error.response.status >= 500) {
        notifyError("ERROR");
        return;
      }
      notifyError(error.response.data.message, { autoClose: 3000 });
    }
  };

  const toggleAccordion = () => {
    setShowFilters(!showFilters);
  };

  return (
    <>
      <ToastContainer />
      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggleDetails}
        isOpen={details}
      >
        <OffcanvasHeader toggle={toggleDetails}>Detalle</OffcanvasHeader>
        <OffcanvasBody>
          {details && <DetailsRequest details={selectedItem} />}
        </OffcanvasBody>
      </Offcanvas>
      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggleExport}
        isOpen={exportData}
        style={{ width: 500 }}
      >
        <OffcanvasHeader toggle={toggleExport}>
          Previsualización
        </OffcanvasHeader>
        <OffcanvasBody>
          <Preview data={data.assistanceVehicle} typeVehicle={typeVehicle} />
        </OffcanvasBody>
      </Offcanvas>
      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggleEndAssistance}
        isOpen={endAssistance}
        style={{ width: 800 }}
      >
        <OffcanvasHeader toggle={toggleEndAssistance}>
          {'Editar asistencia'}
        </OffcanvasHeader>
        <OffcanvasBody>
          {evento && (
            <>
              <EndRequestForm
                onClose={() => {
                  setEndAssistance(false);
                  getAssistance();
                }}
                evento={evento}
                setEdit={setEdit}
                edit={edit}
              />
            </>
          )}
        </OffcanvasBody>
      </Offcanvas>

      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggleCancelAssistance}
        isOpen={cancelAssistance}
        style={{ width: 600 }}
      >
        <OffcanvasHeader toggle={toggleCancelAssistance}>
          {'Editar asistencia'}
        </OffcanvasHeader>
        <OffcanvasBody>
          {evento && (
            <>
              <CancelRequestForm
                onClose={() => {
                  setCancelAssistance(false);
                  getAssistance();
                }}
                evento={evento}
              />
            </>
          )}
        </OffcanvasBody>
      </Offcanvas>
      {data.loading ? (
        <TheLoader />
      ) : (
        <div
          style={{
            padding: "10px 0px",
          }}
        >
          <h3>{pageName}</h3>
          <Accordion
            open={showFilters ? "1" : "0"}
            toggle={toggleAccordion}
            className="my-3 d-md-none accordion-report"
          >
            <AccordionItem>
              <AccordionHeader
                targetId="1"
                cssModule={{ backgroundColor: "var(--yellow)" }}
              >
                Filtro
              </AccordionHeader>
              <AccordionBody accordionId="1">
                <FilterForm
                  onSubmit={onSubmit}
                  toggleExport={toggleExport}
                  assistanceVehicle={data.assistanceVehicle}
                  getAssistance={getAssistance}
                />
              </AccordionBody>
            </AccordionItem>
          </Accordion>
          <Card className="my-3 d-none d-md-block">
            <CardHeader style={{ backgroundColor: "var(--yellow)" }}>
              Filtro
            </CardHeader>
            <CardBody>
              <FilterForm
                onSubmit={onSubmit}
                toggleExport={toggleExport}
                assistanceVehicle={data.assistanceVehicle}
                getAssistance={getAssistance}
              />
            </CardBody>
          </Card>
          {data.status === "FAILED" && <h2>Ocurrió un error</h2>}
          {data.status === "PROCESSING" && <TheLoader />}
          {data.status === "SUCCESS" && (
            <>
              {data.assistanceVehicle.length !== 0 ? (
                <div
                  className="table-report"
                  style={{
                    height:
                      showFilters
                        ? "calc(100vh - 695px)"
                        : "calc(100vh - 165px)",
                  }}
                >
                  <Table className="table-fixed-header" >
                    <thead>
                      <tr>
                        <th></th>
                        <th>Vehículo</th>
                        <th>Placa</th>
                        <th style={{ width: 400 }}>Descripción asistencia</th>
                        <th>Estado</th>
                        <th>Tiempo asignado</th>
                        <th>Tiempo real</th>
                        <th>Fecha registro</th>
                        <th>Fecha solución</th>
                        <th>Fecha cancelación</th>
                        <th>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.assistanceVehicle.map((vehiculoData) => {
                        return (
                          <tr key={vehiculoData.evento_asistencia_id}>
                            <td>
                              {vehiculoData.usuario_modo_de_solicitud ===
                                "web" && (
                                  <>
                                    <Monitor
                                      id={`web-${vehiculoData.evento_asistencia_id}`}
                                    />
                                    <UncontrolledTooltip
                                      placement="top"
                                      target={`web-${vehiculoData.evento_asistencia_id}`}
                                    >
                                      Web
                                    </UncontrolledTooltip>
                                  </>
                                )}
                              {vehiculoData.usuario_modo_de_solicitud ===
                                "mobile" && (
                                  <>
                                    <Smartphone
                                      id={`mobile-${vehiculoData.evento_asistencia_id}`}
                                    />
                                    <UncontrolledTooltip
                                      placement="top"
                                      target={`mobile-${vehiculoData.evento_asistencia_id}`}
                                    >
                                      Móvil
                                    </UncontrolledTooltip>
                                  </>
                                )}
                              {vehiculoData.usuario_modo_de_solicitud ===
                                "cau" && (
                                  <>
                                    <Home
                                      id={`cau-${vehiculoData.evento_asistencia_id}`}
                                    />
                                    <UncontrolledTooltip
                                      placement="top"
                                      target={`cau-${vehiculoData.evento_asistencia_id}`}
                                    >
                                      CAU
                                    </UncontrolledTooltip>
                                  </>
                                )}
                            </td>
                            <td>
                              <div>
                                {vehiculoData.vehiculo_asistencia
                                  ? vehiculoData.vehiculo_asistencia.map(
                                    (vehiculo, index) => (
                                      <div key={index}>
                                        {vehiculo.categoria_vehiculo_peaje}
                                      </div>
                                    )
                                  )
                                  : "-"}
                              </div>
                            </td>
                            <td>
                              <div>
                                {vehiculoData.vehiculo_asistencia
                                  ? vehiculoData.vehiculo_asistencia.map(
                                    (vehiculo, index) => (
                                      <div key={index}>{vehiculo.placa}</div>
                                    )
                                  )
                                  : "-"}
                              </div>
                            </td>
                            <td>{vehiculoData.descripcion || "-"}</td>
                            <td>
                              <h6>{formatEstado(vehiculoData)}</h6>
                            </td>
                            <td>
                              {vehiculoData.tiempo_asignado
                                ? `${vehiculoData.tiempo_asignado} minutos`
                                : "-"}
                            </td>
                            <td>
                              {vehiculoData.tiempo_real
                                ? `${vehiculoData.tiempo_real} minutos`
                                : "-"}
                            </td>
                            <td>{vehiculoData.fecha_registro || "-"}</td>
                            <td>{vehiculoData.fecha_solucion || "-"}</td>
                            <td>{vehiculoData.fecha_cancelacion || "-"}</td>
                            <td>
                              <File
                                style={{
                                  width: 20,
                                  height: 20,
                                  cursor: "pointer",
                                }}
                                id={`attend-${vehiculoData.evento_asistencia_id}`}
                                onClick={() => toggleDetails(vehiculoData)}
                              />
                              <UncontrolledTooltip
                                placement="top"
                                target={`attend-${vehiculoData.evento_asistencia_id}`}
                              >
                                Ver detalle
                              </UncontrolledTooltip>
                            </td>

                            {formatEstado(vehiculoData).props.children === "Finalizado" ?
                              <td>
                                <Edit
                                  style={{
                                    width: 30,
                                    height: 30,
                                    cursor: "pointer",
                                    marginRight: 5,
                                  }}
                                  onClick={() => {
                                    toggleEndAssistance();
                                    setEvento(vehiculoData);
                                    setEdit(!edit)
                                  }}
                                  id={`end-${vehiculoData.evento_asistencia_id}`}
                                />
                                <UncontrolledTooltip
                                  placement="top"
                                  target={`end-${vehiculoData.evento_asistencia_id}`}
                                >
                                  Editar finalización
                                </UncontrolledTooltip>
                              </td>
                              :
                              <td>
                                <Edit
                                  style={{
                                    width: 30,
                                    height: 30,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    toggleCancelAssistance();
                                    setEvento(vehiculoData);
                                  }}
                                  id={`cancel-${vehiculoData.evento_asistencia_id}`}
                                />
                                <UncontrolledTooltip
                                  placement="top"
                                  target={`cancel-${vehiculoData.evento_asistencia_id}`}
                                >
                                  Editar Cancelación
                                </UncontrolledTooltip>
                              </td>
                            }

                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <div className="mt-5">
                  <h1 className="alert-title">No hay asistencia.</h1>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ReporteAsist;


