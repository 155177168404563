import React, { useState, useEffect } from "react";
import { PlusSquare, RefreshCcw, Search } from "react-feather";
import { ToastContainer } from "react-toastify";
import {
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap";
import { notifyError } from "../../../../utils/utils";
import { NewRegisterDeliveryForm } from "./components/NewRegisterDeliveryForm";
import { TableRegisterDelivery } from "./components/TableRegisterDelivery";
import TheLoader from "../../../elements/theLoader";
import { s_getRegisterDelivery } from "../../../../services/registerDeliveryCtl";
import { useCentralContext } from "../../../../centralContext";
import { NewReportedProduct } from "./components/NewReportedProduct";
import { MeReportedProduct } from "./components/MeReportedProduct";
import ReactTooltip from "react-tooltip";
import SearchFilterDelivery from "./components/SearchFilterDelivery";

const RegisterDelivety = ({ pageName }) => {
  const context = useCentralContext();

  const [data, setData] = useState({
    registerDelivery: [],
    reload: false,
    loading: true,
  });

  const [showModalMeReportedProduct, setShowModalMeReportedProduct] =
    useState(false);
  const toggleMeReportedProduct = () =>
    setShowModalMeReportedProduct(!showModalMeReportedProduct);

  const [createSidebar, setCreateSidebar] = useState(false);
  const toggleCreateSidebar = () => {
    setCreateSidebar(!createSidebar);
  };

  const [filterSidebar, setFilterSidebar] = useState(false);
  const toggleSearchSidebar = () => {
    setFilterSidebar(!filterSidebar);
  };

  const [reportedProductSidebar, setReportedProductSidebar] = useState(false);
  const toggleReportedProductSidebar = () => {
    setReportedProductSidebar(!reportedProductSidebar);
  };

  const reload = () =>
    setData((old) => ({
      registerDelivery: [],
      user: "",
      reload: !old.reload,
      loading: !old.loading,
    }));

  const getRegisterDelivery = async () => {
    try {
      setData((old) => ({ ...old, loading: true }));
      const response = await s_getRegisterDelivery();
      setData((old) => ({
        ...old,
        loading: false,
        registerDelivery: response.registerDelivery,
        user: response.user,
      }));
    } catch (error) {
      setData((old) => ({ ...old, loading: false }));
      if (error.response.status >= 500) {
        notifyError("ERROR");
        return;
      }
      notifyError(error.response.data.message, { autoClose: 3000 });
    }
  };

  console.log();
  useEffect(() => {
    getRegisterDelivery();
  }, [data.reload]);

  return (
    <div>
      <ToastContainer style={{ width: "400px" }} />
      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggleCreateSidebar}
        isOpen={createSidebar}
      >
        <OffcanvasHeader toggle={toggleCreateSidebar}>
          Crear nuevo registro de entrada
        </OffcanvasHeader>
        <OffcanvasBody>
          <NewRegisterDeliveryForm
            setShowModalNewResgister={setCreateSidebar}
            reload={reload}
          />
        </OffcanvasBody>
      </Offcanvas>
      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggleSearchSidebar}
        isOpen={filterSidebar}
        style={{ width: '30%' }}
      >
        <OffcanvasHeader toggle={toggleSearchSidebar}>
          Buscar Registros
        </OffcanvasHeader>
        <OffcanvasBody>
          <SearchFilterDelivery
            setShowModalFilter={setFilterSidebar}
            reload={reload}
          />
        </OffcanvasBody>
      </Offcanvas>
      <Offcanvas
        direction="end"
        fade={false}
        toggle={toggleReportedProductSidebar}
        isOpen={reportedProductSidebar}
      >
        <OffcanvasHeader toggle={toggleReportedProductSidebar}>
          Reportar un producto
        </OffcanvasHeader>
        <OffcanvasBody>
          <NewReportedProduct
            setShowModalReportedProduct={setReportedProductSidebar}
            reload={reload}
          />
        </OffcanvasBody>
      </Offcanvas>

      <Modal
        isOpen={showModalMeReportedProduct}
        toggle={toggleMeReportedProduct}
        size="xl"
      >
        <ModalHeader toggle={toggleMeReportedProduct}>
          Mis productos reportados
        </ModalHeader>
        <ModalBody>
          <MeReportedProduct
            setShowModalMeReportedProduct={setShowModalMeReportedProduct}
          />
        </ModalBody>
      </Modal>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        className="mb-4"
      >
        <h3 style={{ margin: 0 }}>{pageName}</h3>
        <div>
          <Button
            style={{ marginRight: 20 }}
            onClick={() => setShowModalMeReportedProduct(true)}
          >
            Mis productos reportados
          </Button>
          <Button
            style={{ marginRight: 20 }}
            onClick={() => toggleReportedProductSidebar()}
          >
            Reportar producto
          </Button>
          <Button color="warning" style={{ marginRight: 20 }} onClick={reload}>
            <RefreshCcw />
          </Button>
          {context.role === "admin_tagexpress" && (
            <Button style={{ marginRight: 20 }}
              onClick={() => toggleCreateSidebar()}
              data-tip="Crear nuevo registro de entrada"
            >
              <PlusSquare />
            </Button>
          )}

          {context.role === "admin_tagexpress" && (
            <Button
              onClick={() => toggleSearchSidebar()}
              data-tip="Buscar entregas"
            >
              <Search />
            </Button>
          )}
          <ReactTooltip type="light" />
        </div>
      </div>
      <Row>
        {data.loading ? (
          <TheLoader />
        ) : (
          <>
            {data.registerDelivery.length > 0 ? (
              <TableRegisterDelivery
                data={data.registerDelivery}
                user={context.user}
                reload={reload}
              />
            ) : (
              <div className="mt-5">
                <h1 className="alert-title">
                  No hay registro de entrega activos por el momento
                </h1>
              </div>
            )}
          </>
        )}
      </Row>
    </div>
  );
};

export default RegisterDelivety;
