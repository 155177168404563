import React, { useState, useEffect } from "react";
import {
    Button,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardFooter,
    Row,
    Col
} from "reactstrap";
import TheLoader from "../../../elements/theLoader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { notifyError, notifyOk } from "../../../../utils/utils";
import ReactTooltip from "react-tooltip";
import { Edit, Plus, Trash } from "react-feather";
import AddStore from "./components/addStore";
import { s_deleteStore, s_geStores } from "../../../../services/store.Ctl";
import UpdateStore from "./components/updateStore";
import '../../../../css/Store.css';


const Store = ({ pageName }) => {
    const [showModalCreate, setShowModalCreate] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalUpdate, setShowModalUpdate] = useState(false);
    const [storeId, setStoreId] = useState(false);
    const [loader, setloadeder] = useState(false);
    const [data, setData] = useState([]);

    const toggleCreate = () => setShowModalCreate(!showModalCreate);
    const toggleDelete = () => setShowModalDelete(!showModalDelete);
    const toggleUpdate = () => setShowModalUpdate(!showModalUpdate);

    const getStores = async () => {
        setloadeder(true);
        const res = await s_geStores();
        if (!res) {
            return notifyError('Error al mostrar los datos, por favor recargue la página');
        }
        setloadeder(false);
        setData(res);
    }

    const deleteStore = async () => {
        const res = await s_deleteStore(storeId);
        if (!res) {
            toggleDelete();
            return notifyError('Error al eliminar el punto de venta, por favor recargue la página');
        }
        toggleDelete();
        getStores();
        return notifyOk('Punto de venta eliminado exitosamente');
    }

    useEffect(() => {
        getStores();
    }, []);

    return (
        <div>
            {loader ? <TheLoader /> : null}
            <h1>{pageName}</h1>
            <ToastContainer style={{ width: "400px" }} />
            <Offcanvas
                direction="end"
                fade={false}
                toggle={toggleCreate}
                isOpen={showModalCreate}
                style={{ width: 500 }}
            >
                <OffcanvasHeader toggle={toggleCreate}>
                    Crear punto de venta
                </OffcanvasHeader>
                <OffcanvasBody>
                    <AddStore
                        setShowModalCreate={setShowModalCreate}
                        getStores={getStores}
                    />
                </OffcanvasBody>
            </Offcanvas>
            <Offcanvas
                direction="end"
                fade={false}
                toggle={toggleUpdate}
                isOpen={showModalUpdate}
                style={{ width: 500 }}
            >
                <OffcanvasHeader toggle={toggleUpdate}>
                    Actualizar punto de venta
                </OffcanvasHeader>
                <OffcanvasBody>
                    <UpdateStore
                        toggleUpdate={toggleUpdate}
                        getStores={getStores}
                        storeId={storeId}
                    />
                </OffcanvasBody>
            </Offcanvas>
            <Modal isOpen={showModalDelete} toggle={toggleDelete}>
                <ModalHeader toggle={toggleDelete}>Eliminar punto de venta</ModalHeader>
                <ModalBody>
                    <p>¿Esta seguro de eliminar este punto de venta?</p>
                </ModalBody>
                <ModalFooter className="justify-content-center">
                    <Button className="btn-danger" onClick={deleteStore}>
                        Eliminar
                    </Button>{' '}
                    <Button className="succes-btn-store" onClick={toggleDelete}>
                        Cancelar
                    </Button>
                </ModalFooter>
            </Modal>
            <div>
                <Button
                    className='btn-sales'
                    onClick={() => setShowModalCreate(true)}
                    data-tip="Crear nuevo punto de venta"
                >
                    <Plus /> Crear punto de venta
                </Button>
                <ReactTooltip type="light" />
            </div>
            <div className="mt-4">
                <Row>
                    {data.map((item) => {
                        return (
                            <Col md={4} key={item.punto_de_venta_id}>
                                <Card
                                    className="my-2 h-100"
                                    style={{
                                        width: '18rem'
                                    }}
                                    key={item.punto_de_venta_id}
                                >
                                    <CardHeader tag="h4">
                                        <strong>{item.nombre}</strong>
                                    </CardHeader>
                                    <CardBody className="text-center">
                                        <CardTitle tag="h3">
                                            Tags {item.cantidad}
                                        </CardTitle>

                                    </CardBody>
                                    <CardFooter>
                                        <div>
                                            <Row>
                                                <Col md={6}>
                                                    <Button
                                                        className="succes-btn-store store-btn"
                                                        onClick={() => {
                                                            toggleUpdate();
                                                            setStoreId(item.punto_de_venta_id)
                                                        }}
                                                    >
                                                        <Edit /> Editar
                                                    </Button>
                                                </Col>
                                                <Col md={6}>
                                                    <Button className="btn-danger store-btn" onClick={() => {
                                                        setShowModalDelete(true);
                                                        setStoreId(item.punto_de_venta_id)
                                                    }}>
                                                        <Trash /> Eliminar
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </CardFooter>
                                </Card>

                            </Col>
                        )
                    })}
                </Row>
            </div>

        </div>
    );
}

export default Store;
