import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import { s_getQualification } from "../../../../services/qualification.Ctl";
import { notifyError } from "../../../../utils/utils";
import { generateSequentialArray } from "../../mantenimientos";
import ClearFilter from "../../../../assets/ic_filter.svg";
import IconStar from "../../../../assets/ic_star.png";
import ROLES from "../../../../utils/roles";
import { useCentralContext } from "../../../../centralContext";

function Qualification({ pageName }) {
  const centralContext = useCentralContext();

  const { register, handleSubmit, reset } = useForm();
  const [data, setData] = useState({
    qualifications: [],
    reload: false,
    loading: true,
    status: "SUCCESS",
  });
  const [filter, setFilter] = useState({
    status: "",
    end_date: "",
    start_date: "",
  });
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const _getQualification = async (data) => {
    try {
      setData((old) => ({ ...old, status: "PROCESSING" }));
      const response = await s_getQualification({ ...data, size: 15 });
      setData((old) => ({
        ...old,
        qualifications: response.rows,
        status: "SUCCESS",
      }));
      setTotalPages(response.totalPages);
      setCurrentPage(response.currentPage);
    } catch (error) {
      setData((old) => ({ ...old, status: "FAILED" }));
      if (error.response.status >= 500) {
        notifyError("ERROR");
        return;
      }
      notifyError(error.response.data.message, { autoClose: 3000 });
    }
  };

  useEffect(() => {
    _getQualification();
  }, [data.reload]);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    setFilter(data);
    const dataTemp = { ...data, page: 0, size: 15 };
    _getQualification(dataTemp);
  };

  const clearFilter = () => {
    reset();
    setFilter({
      status: "",
      end_date: "",
      start_date: "",
    });
    setData((old) => ({
      ...old,
      qualifications: [],
      status: "SUCCESS",
    }));
    setTotalPages(0);
    setCurrentPage(0);
  };

  return (
    <div>
      <h3>{pageName}</h3>
      <Card className="my-3">
        <CardHeader style={{ backgroundColor: "var(--yellow)" }}>
          Filtro
        </CardHeader>
        <CardBody style={{ cursor: "default" }}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={3}>
                <div className="form-group mb-3">
                  <label>Desde:</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Fecha de inicio"
                    {...register("start_date")}
                    defaultValue={dayjs(new Date()).format("YYYY-MM-DD")}
                  />
                </div>
              </Col>
              <Col md={3}>
                <div className="form-group mb-3">
                  <label>Hasta:</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Fecha de inicio"
                    {...register("end_date")}
                    defaultValue={dayjs(new Date()).format("YYYY-MM-DD")}
                  />
                </div>
              </Col>
              <Col md={3}>
                <div className="form-group mb-3">
                  <label>Estado:</label>
                  <select
                    {...register("status")}
                    defaultValue={""}
                    className="form-control"
                  >
                    <option value="">Seleccione un estado</option>
                    <option value="P">Pendiente</option>
                    <option value="R">Realizada</option>
                    <option value="O">Omitida</option>
                  </select>
                </div>
              </Col>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <p className="font-weight-lighter">
                    * El rango de fecha se aplicará al registro de una
                    asistencia
                  </p>
                </div>
                <div>
                  <Button
                    type="button"
                    color="light"
                    className="mx-2"
                    onClick={clearFilter}
                  >
                    <img
                      src={ClearFilter}
                      width="20px"
                      style={{ marginRight: 10 }}
                      alt=""
                    />
                    Limpiar filtro
                  </Button>
                  <Button color="success">Buscar</Button>
                </div>
              </div>
            </Row>
          </Form>
        </CardBody>
      </Card>
      <Card className="my-3">
        <CardBody style={{ cursor: "default" }}>
          {data.status === "SUCCESS" && (
            <>
              {data.qualifications.length !== 0 ? (
                <div>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Usuario</th>
                        <th>Teléfono</th>
                        <th>Observación</th>
                        <th>Calificación</th>
                        <th>Estado</th>
                        {(centralContext.role === ROLES.usuariot1000 ||
                          centralContext.role === ROLES.cau ||
                          centralContext.role === ROLES.admin_tagexpress) && (
                          <>
                            <th>Peaje</th>
                            <th>Provincia</th>
                          </>
                        )}
                        <th>Descripción</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.qualifications.map((qualification) => (
                        <tr key={qualification.evento_calificacion_id}>
                          <td>{qualification.usuario_nombres || "-"}</td>
                          <td>{qualification.usuario_telefono || "-"}</td>
                          <td>{qualification.observacion || "-"}</td>
                          <td>
                            <div>
                              {qualification.calificacion === null
                                ? "-"
                                : generateSequentialArray(
                                    qualification.calificacion
                                  ).map((star, index) => (
                                    <img
                                      src={IconStar}
                                      alt=""
                                      key={index}
                                      style={{ width: 20, height: 20 }}
                                    />
                                  ))}
                            </div>
                          </td>
                          <td>
                            {qualification.estado === "P" && (
                              <Badge color="warning">Pendiente</Badge>
                            )}
                            {qualification.estado === "R" && (
                              <Badge color="success">Realizada</Badge>
                            )}
                            {qualification.estado === "O" && (
                              <Badge color="secondary">Omitida</Badge>
                            )}
                          </td>
                          {(centralContext.role === ROLES.usuariot1000 ||
                            centralContext.role === ROLES.cau ||
                            centralContext.role === ROLES.admin_tagexpress) && (
                            <>
                              <td>
                                {qualification.evento_asistencium
                                  ? qualification.evento_asistencium.peaje
                                      .descripcion
                                  : "-"}
                              </td>
                              <td>
                                {qualification.evento_asistencium
                                  ? qualification.evento_asistencium.peaje
                                      .provincium.nombre_provincia
                                  : "-"}
                              </td>
                            </>
                          )}
                          <td width={400}>
                            {qualification.evento_asistencium.descripcion
                              .length > 40 && (
                              <UncontrolledTooltip
                                placement="top"
                                target={`description-${qualification.evento_calificacion_id}`}
                              >
                                {qualification.evento_asistencium.descripcion}
                              </UncontrolledTooltip>
                            )}
                            <div
                              id={`description-${qualification.evento_calificacion_id}`}
                            >
                              {`${
                                qualification.evento_asistencium.descripcion
                                  .length > 40
                                  ? `${qualification.evento_asistencium.descripcion.substring(
                                      0,
                                      40
                                    )}...`
                                  : qualification.evento_asistencium.descripcion
                              } 
                            (
                            ${dayjs(
                              new Date(
                                qualification.evento_asistencium.created_at
                              )
                            ).format("YYYY-MM-DD")}
                            )`}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <div className="mt-5">
                  <h1 className="alert-title">
                    No hay calificaciones al momento
                  </h1>
                </div>
              )}
            </>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Pagination>
              {generateSequentialArray(totalPages).map((item, index) => (
                <PaginationItem
                  key={index}
                  active={index === currentPage}
                  onClick={() => _getQualification({ ...filter, page: index })}
                >
                  <PaginationLink href="#">{index + 1}</PaginationLink>
                </PaginationItem>
              ))}
            </Pagination>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default Qualification;
