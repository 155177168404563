import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { MapView } from "../../../../elements/mapsView/MapView";
import { minutosATiempo } from "../../../../../utils/minutosTiempo";

dayjs.extend(duration);

function DetalleEstadoVia({ selectedEstadoVia }) {
  return (
    <div>
      <ul>
        <li>
          <strong>Peaje: </strong>
          {selectedEstadoVia.peaje.descripcion}
        </li>
        <li>
          <strong>Categoria: </strong>
          {selectedEstadoVia.categoria_estado_peaje.descripcion}
        </li>
        <li>
          <strong>Via: </strong>
          {selectedEstadoVia.via || "N/D"}
        </li>
        <li>
          <strong>Tiempo estimado: </strong>
          {selectedEstadoVia.tiempo_estimado &&
            minutosATiempo(selectedEstadoVia.tiempo_estimado)}
        </li>
        <li>
          <strong>Fecha de creación: </strong>
          {selectedEstadoVia.created}
        </li>
      </ul>
      <div>
        {selectedEstadoVia.lat && selectedEstadoVia.long && (
          <MapView lat={selectedEstadoVia.lat} lng={selectedEstadoVia.long} />
        )}
      </div>
    </div>
  );
}
export default DetalleEstadoVia;
